import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { accountNumberRule } from '../rules';

export const AccountNumberFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Account number')}
      name="accountNumber"
      rules={[accountNumberRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
