import {
  Row, Col, Form, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { GetAllParentsBriefResponse } from 'src/gql/queries';
import { getFullName, itemContainsFullName } from 'src/shared/utils';
import { FormItemCommonProps } from '.';

export const RegisteredParentsFormItem = (
  { disabled, parents }: Partial<GetAllParentsBriefResponse> & FormItemCommonProps,
) => {
  const { t } = useTranslation();
  const parentsMap = new Map(parents?.map((parent) => [parent.parentId, parent]));
  const options = parents?.map(
    (parent) => ({
      value: parent.parentId,
      label: getFullName({ item: parent }),
    }),
  );

  return (
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <Form.Item
          label={t('Registered parents')}
          name="registeredParentIds"
          style={{ marginBottom: 0 }}
        >
          <Select
            mode="multiple"
            disabled={disabled}
            allowClear
            options={options}
            filterOption={(inputValue, option) => {
              if (!inputValue) return true;
              if (typeof option?.value !== 'string') return false;
              const item = parentsMap.get(option.value);
              if (!item) return false;

              return itemContainsFullName({
                item,
                searchText: inputValue,
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
