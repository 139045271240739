import { createContext } from 'react';

export type BoardersFilter = {
  name?: string;
  groupIds?: string[];
  labels?: string[];
  minBalance?: number;
  maxBalance?: number;
  onlyAwaitingBoarders?: boolean;
}

export const FilterContext = createContext<BoardersFilter>({});
