import { FileSyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsTable, ReportsTableData } from 'src/components/tables/reports';
import { useGenerateReport } from 'src/gql/mutations';
import { useGetReports } from 'src/gql/queries';

export const DormitoryReportsTab = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ReportsTableData[]>();

  const [
    generateReport,
    generateReportLoading,
  ] = useGenerateReport();

  const [reports, loading] = useGetReports();

  useEffect(() => {
    if (!reports) {
      return;
    }

    const newTableData: ReportsTableData[] = reports.map((reportKey) => {
      const date = reportKey.slice(25, 35).replaceAll('_', '-');
      const time = reportKey.slice(36, 41).replace('_', ':');
      const key = reportKey;

      return {
        datetime: `${date} • ${time}`,
        key,
      };
    });

    setTableData(newTableData);
  }, [reports]);

  return (
    <>
      <div className="sub-actions-wrapper">
        <Button
          type="primary"
          size="large"
          onClick={generateReport}
          loading={generateReportLoading}
        >
          <FileSyncOutlined />
          {t('Generate PDF report')}
        </Button>
      </div>
      <ReportsTable
        loading={loading}
        reports={tableData}
      />
    </>
  );
};
