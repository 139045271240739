import { gql, useMutation } from '@apollo/client';
import { BoarderUpdateGeneralInfoRequestForm } from 'src/views/BoarderTabs';
import { BoarderWithRequests, GET_SPECIFIC_BOARDER, GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS } from 'src/gql/queries';
import { CreateUpdateBoarderGeneralInfoRequestInput } from 'src/shared/types/api';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { toIsoDateString } from 'src/shared/utils';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_BOARDER_UPDATE_GENERAL_INFO_REQUEST = gql`
  mutation CreateBoarderUpdateGeneralInfoRequest($input: CreateUpdateBoarderGeneralInfoRequestInput!) {
    createUpdateBoarderGeneralInfoRequest(input: $input) {
      boarderId
    }
  }
`;

type UpdateBoarderFormToMutationInputArgs = {
  formValues: BoarderUpdateGeneralInfoRequestForm;
}

const updateBoarderFormToMutationInput = (
  { formValues }: UpdateBoarderFormToMutationInputArgs,
): CreateUpdateBoarderGeneralInfoRequestInput['generalInfo'] => ({
  address: {
    city: formValues.city,
    postCode: formValues.postCode,
    streetAddress: formValues.streetAddress,
  },
  birthDate: formValues.birthDate ? toIsoDateString(formValues.birthDate.toDate()) : undefined,
  birthPlace: formValues.birthPlace,
  email: formValues.email,
  firstName: formValues.firstName,
  lastName: formValues.lastName,
  personalIdentityNumber: formValues.personalIdentityNumber,
  phoneNumber: phoneNumberToDto(formValues.phoneNumber),
  schoolDetails: {
    classNumber: Number(formValues.classNumber) ?? undefined,
    schoolKind: formValues.schoolKind,
    schoolName: formValues.schoolName,
  },
});

type UseCreateBoarderUpdateGeneralInfoRequestArgs =
  UseOnMutationArgs<BoarderUpdateGeneralInfoRequestForm> & Partial<Pick<BoarderWithRequests, 'boarderId'>>

export const useCreateBoarderUpdateGeneralInfoRequest = ({
  form,
  boarderId,
  onSuccess,
}: UseCreateBoarderUpdateGeneralInfoRequestArgs): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarderUpdateGeneralInfoRequest, { loading }] = useMutation<
    unknown, { input: CreateUpdateBoarderGeneralInfoRequestInput }
  >(
    CREATE_BOARDER_UPDATE_GENERAL_INFO_REQUEST,
    { refetchQueries: [GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS, GET_SPECIFIC_BOARDER] },
  );

  const onCreateBoarderUpdateGeneralInfoRequest = useCallback(async () => {
    if (!boarderId) {
      return notification.error({ message: t('Boarder update general info request has not been created successfully') });
    }
    const formValues = await form.validateFields();
    try {
      await createBoarderUpdateGeneralInfoRequest({
        variables: {
          input: {
            boarderId,
            generalInfo: updateBoarderFormToMutationInput({ formValues }),
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder update general info request has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder update general info request has not been created successfully') });
    }
  }, [form, boarderId, onSuccess]);

  return [onCreateBoarderUpdateGeneralInfoRequest, loading];
};
