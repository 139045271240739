import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { RecruitmentQuestionnaireFormFields } from './forms';
import { ClosedQuestionRadioOption } from './ClosedQuestionRadio';

type RecruitmentSummaryQuestionnaireProps = {
  values?: RecruitmentQuestionnaireFormFields
}

export const RecruitmentSummaryQuestionnaire = ({
  values,
}: RecruitmentSummaryQuestionnaireProps) => {
  const { t } = useTranslation();

  if (!values) {
    return null;
  }

  const isAcolyte = values.isAcolyte === ClosedQuestionRadioOption.Yes;
  const isVolunteer = values.isVolunteer === ClosedQuestionRadioOption.Yes;
  const isLargeFamily = values.isLargeFamily === ClosedQuestionRadioOption.Yes;
  const isRefugee = values.isRefugee === ClosedQuestionRadioOption.Yes;
  const isSingleParent = values.isSingleParent === ClosedQuestionRadioOption.Yes;
  const isFoster = values.isFoster === ClosedQuestionRadioOption.Yes;
  const hadReligiousEducation = values.hadReligiousEducation === ClosedQuestionRadioOption.Yes;
  const religiousEducationPlans = values.religiousEducationPlans === ClosedQuestionRadioOption.Yes;
  const isOrWantToBeConfirmed = values.isOrWantsToBeConfirmed === ClosedQuestionRadioOption.Yes;
  const isSportsman = values.isSportsman === ClosedQuestionRadioOption.Yes;
  const riskFactors = [];
  if (values.alcohol === ClosedQuestionRadioOption.Yes) riskFactors.push(t('Alcohol'));
  if (values.cigarettes === ClosedQuestionRadioOption.Yes) riskFactors.push(t('Cigarettes/e-cigarettes'));
  if (values.narcotics === ClosedQuestionRadioOption.Yes) riskFactors.push(t('Narcotics/drugs'));
  if (values.gambling === ClosedQuestionRadioOption.Yes) riskFactors.push(t('Gambling'));
  if (values.addiction === ClosedQuestionRadioOption.Yes) riskFactors.push(t('Addiction to computer/Internet'));
  const isDemoralized = values.isDemoralized === ClosedQuestionRadioOption.Yes;
  const isPsychotic = values.isPsychotic === ClosedQuestionRadioOption.Yes;
  const isIll = values.isIll === ClosedQuestionRadioOption.Yes;
  const isAllergic = values.isAllergic === ClosedQuestionRadioOption.Yes;

  return (
    <Descriptions size="small" bordered column={2}>
      <Descriptions.Item label={t('Is acolyte? short')}>{isAcolyte ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Parish name')}>{values.parishName || '-'}</Descriptions.Item>
      <Descriptions.Item label={t('Is volunteer? short')}>{isVolunteer ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Group name')}>{values.groupName || '-'}</Descriptions.Item>
      <Descriptions.Item label={t('Is large family?')} span={2}>{isLargeFamily ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is single parent?')} span={2}>{isSingleParent ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is refugee?')} span={2}>{isRefugee ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is foster?')} span={2}>{isFoster ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Had religious education?')} span={2}>{hadReligiousEducation ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Religious education plans?')} span={2}>{religiousEducationPlans ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is or wants to be confirmed?')} span={2}>{isOrWantToBeConfirmed ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is sportsman?')}>{isSportsman ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Club name')}>{values.clubName || '-'}</Descriptions.Item>
      <Descriptions.Item label={t('Risk factors')} span={2}>
        {
          riskFactors.length ? <ul>{riskFactors.map((riskFactor, i) => <li key={i}>{riskFactor}</li>)}</ul> : '-'
        }
      </Descriptions.Item>
      <Descriptions.Item label={t('Is demoralized?')} span={2}>{isDemoralized ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Is psychotic? short')}>{isPsychotic ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Treatment details')}>{values.psychotic || '-'}</Descriptions.Item>
      <Descriptions.Item label={t('Is ill? short')}>{isIll ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Treatment details')}>{values.ill || '-'}</Descriptions.Item>
      <Descriptions.Item label={t('Is allergic? short')}>{isAllergic ? t('YES') : t('NO')}</Descriptions.Item>
      <Descriptions.Item label={t('Allergies')}>{values.allergies || '-'}</Descriptions.Item>
    </Descriptions>
  );
};
