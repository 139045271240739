import { DocumentNode } from 'graphql';
import {
  UseOnMutation,
  UseOnMutationArgs,
  useCreateBoarderPayment,
} from 'src/gql/mutations';
import { useCreateBoarderRecruitmentPayment } from 'src/gql/mutations/recruitment';
import { BoarderPaymentInput } from 'src/shared/types/common';

type UseOnBoarderPaymentModalSubmitArgs =
  UseOnMutationArgs<BoarderPaymentInput> & {
    isRecruitmentBoarder?: boolean,
    boarderId?: string,
    refetchQueries?: DocumentNode[],
  };

export const useOnBoarderPaymentModalSubmit = (
  args: UseOnBoarderPaymentModalSubmitArgs,
): UseOnMutation => {
  const [createBoarderPayment, createBoarderPaymentLoading] = useCreateBoarderPayment(args);
  const [
    createRecruitmentPayment,
    createRecruitmentPaymentLoading,
  ] = useCreateBoarderRecruitmentPayment({
    ...args,
    boarderRecruitmentId: args.boarderId,
  });

  const onSubmit = async () => {
    const action = args.isRecruitmentBoarder
      ? createRecruitmentPayment
      : createBoarderPayment;

    await action();
  };

  return [onSubmit, createBoarderPaymentLoading || createRecruitmentPaymentLoading];
};
