import { Form, Modal, Spin } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDormitoryGroupOptions, useOnTutorModalSubmit } from 'src/hooks';
import { Tutor } from 'src/shared/types/api';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { ModalAction, ModalState } from '.';
import { TutorModalForm, TutorForm } from '../forms';

type TutorModalProps = {
  modalState: ModalState<Tutor>
  setModalState: Dispatch<SetStateAction<ModalState<Tutor>>>;
}

export const TutorModal = (
  { modalState, setModalState }: TutorModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<TutorForm>();
  const [dormitoryGroupOptions, getDormitoryGroupsLoading] = useDormitoryGroupOptions();

  const [onSubmit, loading] = useOnTutorModalSubmit({
    form,
    onSuccess: () => setModalState({ visible: false }),
    tutorId: (modalState.visible && modalState.data?.tutorId) || undefined,
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue({
        ...modalState.data,
        phoneNumber: phoneNumberToDisplay(modalState.data.phoneNumber),
      });
    }

    if (modalState.action === ModalAction.Create) {
      form.setFieldsValue({
        groupIds: [],
      });
    }
  }, [modalState.action]);

  return (
    <Modal
      title={modalState.action === ModalAction.Create ? t('Add tutor') : t('Edit tutor')}
      visible={modalState.visible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={modalState.action === ModalAction.Create ? t('Add') : t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <Spin spinning={getDormitoryGroupsLoading}>
        <TutorModalForm form={form} groupIdOptions={dormitoryGroupOptions} />
      </Spin>
    </Modal>
  );
};
