import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClauseOption } from '../RecruitmentStartStepForm';
import { clausesRule } from '../rules';

export const ClausesFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="clauses"
      rules={[clausesRule()]}
    >
      <Checkbox.Group>
        <Checkbox value={ClauseOption.RecruitmentClause}>
          {t('Agree with clause')}
          <a href="https://www.bursa-ts.szczecin.pl/klauzula--proces-rekrutacji">klauzulę informacyjna dla wychowanków i rodziców – proces rekrutacji</a>
        </Checkbox>
        <Checkbox value={ClauseOption.DormitoryClause}>
          {t('Agree with clause')}
          <a href="https://www.bursa-ts.szczecin.pl/klauzula--po-przyjeciu">klauzulę informacyjną dla wychowanków i rodziców – po przyjęciu</a>
        </Checkbox>
      </Checkbox.Group>
    </Form.Item>
  );
};
