import { List } from 'antd';
import { BoarderBrief } from 'src/gql/queries';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { BoarderDormitoryInfoLink } from './BoarderDormitoryInfoLink';

type BoardersListProps = {
  boarders: BoarderBrief[];
  lastNameFirst?: boolean;
};

export const BoardersList = ({ boarders, lastNameFirst }: BoardersListProps) => (
  <List
    dataSource={boarders}
    renderItem={(boarder) => (
      <List.Item key={boarder.boarderId}>
        <List.Item.Meta
          title={<BoarderDormitoryInfoLink boarder={boarder} lastNameFirst={lastNameFirst} />}
          description={`${boarder.email} · ${phoneNumberToDisplay(boarder.phoneNumber)}`}
        />
      </List.Item>
    )}
  />
);
