import { ResourcesConfig } from 'aws-amplify';

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_E_BURSA_USER_POOL_ID ?? '',
      userPoolClientId: process.env.REACT_APP_E_BURSA_USER_POOL_WEB_CLIENT_ID ?? '',
    },
  },
};
