import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { MinMaxRange } from 'src/utils';

export const classNumberRule = ({ min, max }: MinMaxRange): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct class number'),
    validator: (_, value) => {
      if (value === undefined) {
        return Promise.resolve();
      }

      const valueNumber = Number(value);

      return (min <= valueNumber && valueNumber <= max ? Promise.resolve() : Promise.reject());
    },
  });
};
