import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ROUTE } from 'src/constants';
import { useGetAllAdministrators } from 'src/gql/queries';
import {
  AdministratorModal,
  BreadcrumbItem,
  ViewHeader,
  useAdministratorsTableColumns,
  ModalState,
  ModalAction,
  ViewHeaderButton,
  ViewHeaderSearch,
} from 'src/components';
import { Administrator } from 'src/shared/types/api';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';
import { useNavigate } from 'react-router-dom';
import { defaultRoute } from 'src/utils';
import { filterByFullName } from 'src/shared/utils';
import { UserAddOutlined } from '@ant-design/icons';

export const AdministratorsView = () => {
  const { t } = useTranslation();
  const [allAdministrators, setAllAdministrators] = useState<Administrator[]>();
  const [filteredAdministrators, setFilteredAdministrators] = useState<Administrator[]>();
  const [modalState, setModalState] = useState<ModalState<Administrator>>({ visible: false });
  const user = useUserContext();
  const navigate = useNavigate();

  const [administrators, loading] = useGetAllAdministrators();

  useEffect(() => {
    setAllAdministrators(administrators);
    setFilteredAdministrators(administrators);
  }, [administrators]);

  const onSearch = useCallback((searchText: string) => {
    setFilteredAdministrators(filterByFullName({ array: allAdministrators, searchText }));
  }, [allAdministrators]);

  const onUpdateAdministrator = (administrator: Administrator) => setModalState({
    visible: true,
    action: ModalAction.Update,
    data: administrator,
  });

  const administratorsTableColumns = useAdministratorsTableColumns(onUpdateAdministrator);

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.ADMINISTRATORS, title: t('Administrators') },
  ];

  const HeaderButton = (
    <ViewHeaderButton
      PrefixIcon={UserAddOutlined}
      title={t('Add administrator')}
      onClick={() => setModalState({ visible: true, action: ModalAction.Create })}
      type="primary"
    />
  );

  const HeaderFilter = <ViewHeaderSearch onSearch={onSearch} />;

  if (user && !user?.isAllowed(GqlQuery.GetAdministrators)) {
    navigate(defaultRoute(user));
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
        Filter={HeaderFilter}
        Buttons={HeaderButton}
      />
      <Table
        loading={loading}
        dataSource={filteredAdministrators}
        size="middle"
        columns={administratorsTableColumns}
        pagination={false}
        rowKey="administratorId"
      />
      {modalState.visible && (
        <AdministratorModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
