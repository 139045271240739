import { gql, useMutation } from '@apollo/client';
import { UpdateAdministratorInput } from 'src/shared/types/api';
import { CreateAdministratorForm } from 'src/components';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_ADMINISTRATORS } from 'src/gql/queries';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_ADMINISTRATOR = gql`
  mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
    updateAdministrator(input: $input) {
      administratorId
    }
  }
`;

type UseUpdateAdministratorArgs =
  UseOnMutationArgs<CreateAdministratorForm> & {administratorId?: string}

export const useUpdateAdministrator = (
  { form, administratorId, onSuccess }: UseUpdateAdministratorArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateAdministrator, { loading }] = useMutation<
    unknown, { input: UpdateAdministratorInput }
  >(UPDATE_ADMINISTRATOR, { refetchQueries: [GET_ALL_ADMINISTRATORS] });

  const onUpdateAdministrator = useCallback(async () => {
    if (!administratorId) {
      return notification.error({ message: t('Administrator has not been updated successfully') });
    }

    const formValues = await form.validateFields();
    const administratorDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await updateAdministrator({
        variables: {
          input: {
            administratorId,
            administrator: administratorDto,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Administrator has been updated successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Administrator has not been updated successfully. User exists') });
      }

      return notification.error({ message: t('Administrator has not been updated successfully') });
    }
  }, [form, administratorId, onSuccess]);

  return [onUpdateAdministrator, loading];
};
