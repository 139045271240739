import { DeleteOutlined } from '@ant-design/icons';
import {
  Popconfirm, Popover, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useArchiveRecruitmentRequest } from 'src/gql/mutations/recruitment';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';

type ArchiveRecruitmentButtonProps = {
  record: RecruitmentBoarderRequest;
}

export const ArchiveRecruitmentButton = ({ record }: ArchiveRecruitmentButtonProps) => {
  const { t } = useTranslation();

  const [popConfirmVisible, setPopConfirmVisible] = useState(false);

  const [
    archiveRecruitmentRequest,
    loading,
  ] = useArchiveRecruitmentRequest({
    boarderRecruitmentId: record.boarderRecruitmentId,
    onComplete: () => setPopConfirmVisible(false),
  });

  return (
    <Popconfirm
      title={t('Confirm archive recruitment request')}
      okText={t('Yes')}
      visible={popConfirmVisible}
      onConfirm={archiveRecruitmentRequest}
      okButtonProps={{ loading }}
      onCancel={() => setPopConfirmVisible(false)}
    >
      <Popover content={t('Archive recruitment request')}>
        <Typography.Link>
          <DeleteOutlined onClick={() => setPopConfirmVisible(true)} />
        </Typography.Link>
      </Popover>
    </Popconfirm>
  );
};
