import { gql, useMutation } from '@apollo/client';
import { DormitoryYear } from 'src/shared/types/common';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_DORMITORY_YEAR } from 'src/gql/queries';
import { GqlCalendar } from 'src/shared/types/api';
import { stringifyYear } from 'src/shared/utils';
import { ALL_MONTHS_FRAGMENT } from '../../fragments';
import { UseOnMutation } from '..';

export const UPDATE_DORMITORY_YEAR = gql`
  ${ALL_MONTHS_FRAGMENT}

  mutation UpdateDormitoryYear($input: YearInput!) {
    updateDormitoryYear(input: $input) {
      ...AllMonthsFragment
    }
  }
`;

type useUpdateDormitoryYearArgs = {
  dormitoryYear?: DormitoryYear;
  onSuccess?: () => void;
};

export const useUpdateDormitoryYear = (
  { dormitoryYear, onSuccess } : useUpdateDormitoryYearArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateDormitoryYear, { loading }] = useMutation<
    unknown, { input: GqlCalendar }
  >(UPDATE_DORMITORY_YEAR, { refetchQueries: [GET_DORMITORY_YEAR] });

  const onUpdateDormitoryYear = useCallback(async () => {
    if (!dormitoryYear) {
      return notification.error({ message: t('Dormitory year has not been updated successfully') });
    }
    try {
      await updateDormitoryYear({
        variables: {
          input: stringifyYear(dormitoryYear),
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Dormitory year has been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Dormitory year has not been updated successfully') });
    }
  }, [dormitoryYear, onSuccess]);

  return [onUpdateDormitoryYear, loading];
};
