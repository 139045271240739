import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { t } from 'i18next';
import { ALL_MONTHS_FRAGMENT } from 'src/gql/fragments';
import { Dormitory, GqlCalendar } from 'src/shared/types/api';
import { UseOnQueryArgs, UseQueryReturnType } from '..';

export const GET_DORMITORY_CHARGED_MONTHS = gql`
  ${ALL_MONTHS_FRAGMENT}

  query GetDormitoryChargedMonths {
    dormitory {
      chargedMonths {
        ...AllMonthsFragment
      }
    }
  }
`;

export type GetDormitoryChargedMonthsResponse = { dormitory: Pick<Dormitory, 'chargedMonths'> }

export const useGetDormitoryChargedMonths = (
  { onError }: UseOnQueryArgs,
): UseQueryReturnType<GqlCalendar> => {
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryChargedMonthsResponse>(
    GET_DORMITORY_CHARGED_MONTHS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory charged month') });
    onError?.();
  }

  return [data?.dormitory.chargedMonths, loading, error];
};
