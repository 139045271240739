export const amplifyTranslations = {
  pl: {
    'An unknown error has occurred.': 'Wystąpił nieznany błąd, spróbuj ponownie. Jeśli problem się powtarza, skontaktuj się z administratorem.',
    'Incorrect username or password.': 'Błędny adres e-mail lub hasło.',
    'Invalid verification code provided, please try again.': 'Podano nieprawidłowy kod.',
    'Network error': 'Błąd połączenia z Internetem.',
    'New Password': 'Nowe Hasło',
    'User does not exist.': 'Błędny adres e-mail lub hasło.',
    'User password cannot be reset in the current state.': 'Hasło nie może być zresetowane w obecnym stanie użytkownika. Poproś administratora o ponowne wysłanie hasła na Twój adres mailowy.',
    'Username/client id combination not found.': 'Błędny adres e-mail lub hasło.',
    'Your passwords must match': 'Hasła muszą być identyczne.',
  },
};
