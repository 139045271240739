import { Form, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { RecruitmentRegisteredBoarderDataFormFields, RecruitmentRegisteredBoarderFormItems } from 'src/components';
import { useSetCurrentRecruitmentForm, useSetInitialFormFields } from 'src/hooks';

const { Paragraph, Text } = Typography;

export const RegisteredBoarderDataStep = () => {
  const { t } = useTranslation();
  const [form] = useForm<RecruitmentRegisteredBoarderDataFormFields>();
  useSetCurrentRecruitmentForm(form);
  useSetInitialFormFields(form, 'registeredBoarderData');

  return (
    <>
      <Paragraph>
        <Text>{t('Registered boarder data recruitment step')}</Text>
      </Paragraph>
      <Form form={form} layout="vertical" className="boarder-tab-content-wrapper">
        <RecruitmentRegisteredBoarderFormItems />
      </Form>
    </>

  );
};
