import { MS } from '../types/common';

export const range = (
  startAt: number, size: number,
): ReadonlyArray<number> => Array.from(Array(size).keys()).map((i) => i + startAt);

export type FullName = { firstName: string; lastName: string };

type GetFullNameArgs<T> = {
  item?: T,
  lastNameFirst?: boolean
}

export const getFullName = <T extends FullName>(
  { item, lastNameFirst }: GetFullNameArgs<T>,
): string => {
  if (!item) {
    return '';
  }

  const { firstName, lastName } = item;

  if (!lastName) {
    return firstName;
  }

  if (!firstName) {
    return lastName;
  }

  return (
    lastNameFirst
      ? `${lastName} ${firstName}`
      : `${firstName} ${lastName}`);
};

export const extractFirstName = (fullName: string) => fullName.split(' ')[0];

export const sleep = (ms: MS) => new Promise((r) => setTimeout(r, ms));
