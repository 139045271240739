import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_REPORTS = gql`
  query GetReports {
    dormitory {
      reports
    }
  }
`;

export type GetReportsResponse = {
  dormitory: Pick<Dormitory, 'reports'>
}

export const useGetReports = (): UseQueryReturnType<string[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetReportsResponse>(
    GET_REPORTS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch reports') });
  }

  return [data?.dormitory.reports, loading, error];
};
