import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Parent } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_PARENTS = gql`
  query GetAllParents {
    parents {
      parentId
      firstName
      lastName
      email
      phoneNumber
      accountStatus
      boarderIds
    }
  }
`;

export type ParentWithoutBoarders = Omit<Parent, 'boarders'>;

export type GetAllParentsResponse = { parents: ParentWithoutBoarders[] };

export const useGetAllParents = (): UseQueryReturnType<ParentWithoutBoarders[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllParentsResponse>(
    GET_ALL_PARENTS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch parents') });
  }

  return [data?.parents, loading, error];
};
