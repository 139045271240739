import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_RECRUITMENT_BOARDERS } from 'src/gql/queries/recruitment';
import { ArchiveRecruitmentRequestInput } from 'src/shared/types/api';
import { UseOnMutation } from '..';

export const ARCHIVE_RECRUITMENT_REQUEST = gql`
  mutation ArchiveRecruitmentRequest($input: ArchiveRecruitmentRequestInput!) {
    archiveRecruitmentRequest(input: $input)
  }
`;

type UseArchiveRecruitmentRequestArgs =
  Pick<ArchiveRecruitmentRequestInput, 'boarderRecruitmentId'> & {onComplete?: () => void};

export const useArchiveRecruitmentRequest = (
  { boarderRecruitmentId, onComplete }: UseArchiveRecruitmentRequestArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [archiveRecruitment, { loading }] = useMutation<
    unknown, { input: ArchiveRecruitmentRequestInput }
  >(ARCHIVE_RECRUITMENT_REQUEST, { refetchQueries: [GET_RECRUITMENT_BOARDERS] });

  const onArchiveRecruitmentRequest = useCallback(async () => {
    try {
      await archiveRecruitment({
        variables: {
          input: {
            boarderRecruitmentId,
          },
        },
      });

      notification.success({ message: t('Recruitment request has been archived successfully') });
    } catch (error) {
      notification.error({ message: t('Recruitment request has not been archived successfully') });
    } finally {
      onComplete?.();
    }
  }, [boarderRecruitmentId, onComplete]);

  return [onArchiveRecruitmentRequest, loading];
};
