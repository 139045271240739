import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { isValidAmount } from 'src/shared/validatorUtils';

export const amountThresholdRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct amount threshold'),
    required: true,
    validator: async (_, value: string) => {
      if (Number(value) > 0) {
        throw new Error();
      }
      if (!isValidAmount(Number(value))) {
        throw new Error();
      }
    },
  });
};
