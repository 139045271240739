import { Spin } from 'antd';
import { DormitoryDetails } from 'src/components';
import { DormitorySchoolYear } from 'src/components/DormitorySchoolYear';
import { useGetDormitoryGeneralInfo } from 'src/gql/queries';

export const DormitoryGeneralInfoTab = () => {
  const [dormitoryGeneralInfo, loading] = useGetDormitoryGeneralInfo();

  return (
    <div className="dormitory-tab-content-wrapper">
      <Spin spinning={loading}>
        <DormitoryDetails dormitoryDetails={dormitoryGeneralInfo?.dormitoryDetails} />
        <DormitorySchoolYear schoolYear={dormitoryGeneralInfo?.schoolYear} />
      </Spin>
    </div>
  );
};
