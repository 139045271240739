import {
  Alert, Col, Form, Row,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { BoarderPaymentInput, PaymentType } from 'src/shared/types/common';
import { FormProps } from '.';
import { AmountFormItem, PaymentCommentFormItem, PaymentTypeFormItem } from './items';

type BoarderPaymentFormProps = FormProps<BoarderPaymentInput> & {
  isRecruitmentBoarder?: boolean;
}

export const BoarderPaymentForm = ({ form, isRecruitmentBoarder }: BoarderPaymentFormProps) => {
  const { t } = useTranslation();
  const [isAmountNegative, setIsAmountNegative] = useState(false);
  const setAmount = (amount: number | undefined) => form.setFieldsValue({
    amount,
  });

  if (isRecruitmentBoarder) {
    form.setFieldsValue({
      type: PaymentType.RECRUITMENT_PAYMENT,
      amount: 250,
    });
  }

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Row gutter={STYLE.BASE_MARGIN}>
        <Col xs={24} sm={12}>
          <PaymentTypeFormItem
            setIsAmountNegative={setIsAmountNegative}
            setAmount={setAmount}
            disabled={isRecruitmentBoarder}
          />
        </Col>
        <Col xs={24} sm={12}>
          <AmountFormItem isAmountNegative={isAmountNegative} />
        </Col>
      </Row>
      <PaymentCommentFormItem required={false} />
      {!isRecruitmentBoarder && (
        <Alert
          message={t('Tip')}
          description={t('Boarder payment form tip')}
          type="info"
          showIcon
        />
      )}
    </Form>
  );
};
