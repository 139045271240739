import { Form, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddBoarderPaymentComment } from 'src/gql/mutations';
import { AddBoarderPaymentCommentInput } from 'src/shared/types/api';
import { BoarderPaymentCommentForm } from '../forms';

export type AddBoarderPaymentCommentModalState = Partial<Omit<AddBoarderPaymentCommentInput, 'comment'>> & { visible: boolean }

type AddBoarderPaymentCommentModalProps = {
  modalState: AddBoarderPaymentCommentModalState
  setModalState: Dispatch<SetStateAction<AddBoarderPaymentCommentModalState>>;
}

export const AddBoarderPaymentCommentModal = (
  { modalState, setModalState }: AddBoarderPaymentCommentModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<{ comment: string; }>();
  const [
    addBoarderPaymentComment,
    loading,
  ] = useAddBoarderPaymentComment({
    form,
    boarderId: modalState.boarderId,
    paymentId: modalState.paymentId,
    onSuccess: () => setModalState({ visible: false }),
  });

  if (!modalState.visible) {
    return null;
  }

  return (
    <Modal
      title={t('Creating boarder payment comment')}
      visible={modalState.visible}
      onOk={addBoarderPaymentComment}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={t('Add')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <BoarderPaymentCommentForm form={form} />
    </Modal>
  );
};
