import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';
import { sorterByString } from '../sorters';

export const archivedAtColumn = <T extends { archivedAt?: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Archived at'),
    key: 'archivedAt',
    render: (_, { archivedAt }) => (
      archivedAt && new Date(archivedAt).toLocaleString(CONFIG.LOCALE)
    ),
    sorter: (obj1, obj2, order) => sorterByString({
      obj1, obj2, key: 'archivedAt', order,
    }),
  });
