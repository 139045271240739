import { useRecruitmentFormContext } from 'src/context';
import { ClosedQuestionRadioOption } from 'src/components';
import { UnregisteredBoarderDataStep } from './UnregisteredBoarderDataStep';
import { RegisteredBoarderDataStep } from './RegisteredBoarderDataStep';

export const BoarderDataStep = () => {
  const recruitmentForm = useRecruitmentFormContext();

  if (
    recruitmentForm?.recruitmentForm.startStep?.existingBoarder === ClosedQuestionRadioOption.Yes
  ) {
    return <RegisteredBoarderDataStep />;
  }

  return <UnregisteredBoarderDataStep />;
};
