import {
  Form, RadioChangeEvent, Typography, Input,
} from 'antd';
import { useState } from 'react';
import { ClosedQuestionRadio, ClosedQuestionRadioOption } from 'src/components';
import { questionnaireRule } from '../rules';

const { Paragraph, Text } = Typography;

type QuestionnaireFormItemProps = {
  name: string;
  question: string;
  additionalPlaceholder?: string;
  additionalName?: string;
  disabled?: boolean;
}

export const QuestionnaireFormItem = ({
  name,
  question,
  additionalName,
  additionalPlaceholder,
  disabled,
}: QuestionnaireFormItemProps) => {
  const [value, setValue] = useState<boolean>(
    false,
  );

  const onChange = (event: RadioChangeEvent) => {
    setValue(event.target.value === ClosedQuestionRadioOption.Yes);
  };

  return (
    <>
      <Paragraph>
        <Text strong>
          {question}
        </Text>
      </Paragraph>
      <Form.Item name={name} rules={[questionnaireRule()]}>
        <ClosedQuestionRadio
          direction="vertical"
          onChange={onChange}
          disabled={disabled}
        />
      </Form.Item>
      {value && additionalName && additionalPlaceholder && (
      <Form.Item name={additionalName}>
        <Input placeholder={additionalPlaceholder} disabled={disabled} />
      </Form.Item>
      )}
    </>
  );
};
