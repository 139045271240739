import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory, FinanceStats } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_FINANCE_STATS = gql`
  query GetDormitoryFinanceStats {
    dormitory {
      financeStats {
        boardersSurplus
        boardersDebt
        boardersBalance
      }
    }
  }
`;

export type GetDormitoryFinanceStatsResponse = { dormitory: Pick<Dormitory, 'financeStats'>}

export const useGetDormitoryFinanceStats = (): UseQueryReturnType<
  FinanceStats
> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryFinanceStatsResponse>(
    GET_DORMITORY_FINANCE_STATS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch finance stats') });
  }

  return [data?.dormitory.financeStats, loading, error];
};
