import { gql } from '@apollo/client';

export const WHOLE_NOTE_FRAGMENT = gql`
  fragment WholeNoteFragment on Note {
    noteId
    createdAt
    createdBy
    description
    title
    updatedAt
  }
`;
