import { AuthenticatorProps } from '@aws-amplify/ui-react';
import { FormFieldOptions } from '@aws-amplify/ui';
import { I18n } from 'aws-amplify/utils';

const labelHidden = true;
const isRequired = true;

const email: FormFieldOptions = {
  placeholder: I18n.get('Email'),
  labelHidden,
  isRequired,
};

const password: FormFieldOptions = {
  placeholder: I18n.get('Password'),
  labelHidden,
  isRequired,
};

const code: FormFieldOptions = {
  label: I18n.get('Code'),
  placeholder: undefined,
  isRequired,
};

const newPassword: FormFieldOptions = {
  label: I18n.get('New Password'),
  placeholder: undefined,
  isRequired,
};

const confirmPassword: FormFieldOptions = {
  label: I18n.get('Confirm Password'),
  placeholder: undefined,
  isRequired,
};

export const amplifyFormFields: AuthenticatorProps['formFields'] = {
  signIn: {
    username: email,
    password,
  },
  forgotPassword: {
    username: email,
  },
  confirmResetPassword: {
    confirmation_code: code,
    password: newPassword,
    confirm_password: confirmPassword,
  },
  forceNewPassword: {
    password: newPassword,
    confirm_password: confirmPassword,
  },
};
