import { gql } from '@apollo/client';
import { FormInstance } from 'antd';
import { IsLoading } from '../queries';

export * from './administrators';
export * from './boarders';
export * from './dormitory';
export * from './parents';
export * from './tutors';

export const FAKE_MUTATION = gql`
  mutation FakeMutation {
    fakeMutation
  }
`;

export type UseOnMutationArgs<TForm> = {
  form: FormInstance<TForm>;
  onSuccess?: () => void;
};
type OnClick = () => Promise<void>;
export type UseOnMutation = [OnClick, IsLoading];
