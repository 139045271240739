import { CarryOutOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Activity, BoarderActivityType } from 'src/shared/types/common';

const activityTypeIcon = (activityType: BoarderActivityType) => {
  switch (activityType) {
    case BoarderActivityType.ENTER:
      return <LoginOutlined className="green" />;
    case BoarderActivityType.LEAVE:
      return <LogoutOutlined className="red" />;
    case BoarderActivityType.PICK_UP_BREAKFAST:
    case BoarderActivityType.PICK_UP_LUNCH:
    case BoarderActivityType.PICK_UP_DINNER:
      return <CarryOutOutlined />;
    default:
      throw new Error('Implementation error');
  }
};

export const activityTypeColumn = (t: TFunction): ColumnType<Activity> => ({
  title: t('Activity type'),
  key: 'activityType',
  render: (_, item) => {
    const Icon = activityTypeIcon(item.activityType);

    return (
      <Space>
        {Icon}
        {t(item.activityType)}
      </Space>
    );
  },
});
