import { GenerateExcelReportFormType, sorterByFullName } from 'src/components';
import { GetAllBoardersReportDataBoarder, ParentWithoutBoarders } from 'src/gql/queries';
import { ContactDetails } from 'src/shared/types/common';

export type BoarderReportData = GetAllBoardersReportDataBoarder & { parents: Partial<Omit<ContactDetails, 'address'>>[] }

type GetExcelReportDataArgs = {
  boarders: GetAllBoardersReportDataBoarder[];
  parents: ParentWithoutBoarders[];
  filters: Pick<GenerateExcelReportFormType, 'groupIds' | 'labels' | 'minBalance' | 'maxBalance'>;
}

type AllBoardersReportData = {
  boarders: BoarderReportData[];
  maxParentCount: number;
}

export const getExcelReportData = (
  { boarders, parents, filters }: GetExcelReportDataArgs,
): AllBoardersReportData => {
  const parentsMap = new Map(parents?.map((parent) => [parent.parentId, parent]));

  let maxParentCount = 0;
  const boardersWithParents = boarders
    .filter((boarder) => {
      const matchesGroupIds = filters.groupIds?.length
        ? filters.groupIds.some(
          (groupId) => groupId === boarder.groupId,
        )
        : true;
      const matchesLabels = filters.labels?.length
        ? filters.labels.some(
          (label) => boarder.labels.some(((boarderLabel) => boarderLabel === label)),
        )
        : true;
      const matchesMinBalance = typeof filters.minBalance === 'number'
        ? boarder.balance > filters.minBalance
        : true;
      const matchesMaxBalance = typeof filters.maxBalance === 'number'
        ? boarder.balance < filters.maxBalance
        : true;

      return matchesGroupIds
     && matchesLabels
     && matchesMinBalance
     && matchesMaxBalance;
    })
    .map((boarder) => {
      maxParentCount = Math.max(
        maxParentCount,
        boarder.unregisteredParents.length + boarder.registeredParentIds.length,
      );

      return {
        ...boarder,
        parents: [
          ...boarder.unregisteredParents,
          ...boarder.registeredParentIds.map((parentId) => {
            const parent = parentsMap.get(parentId);

            return {
              firstName: parent?.firstName,
              lastName: parent?.lastName,
              email: parent?.email,
              phoneNumber: parent?.phoneNumber,
            };
          }),
        ],
      };
    })
    .sort(sorterByFullName({ lastNameFirst: true }));

  return {
    boarders: boardersWithParents,
    maxParentCount,
  };
};
