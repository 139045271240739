export * from './AddBoarderPaymentCommentModal';
export * from './AdministratorModal';
export * from './BoarderModal';
export * from './ChargeMonthlyFeeModal';
export * from './CreateBoarderPaymentModal';
export * from './CreateBoarderUpdateGeneralInfoRequestModal';
export * from './GenerateExcelReportModal';
export * from './NoteModal';
export * from './ParentModal';
export * from './RoomRentalModal';
export * from './SendMessageModal';
export * from './InitOnlinePaymentModal';
export * from './SendPaymentReminderModal';
export * from './TutorModal';
export * from './UpdateBoarderSubscriptionModal';

/* eslint-disable */ // eslint doesn't handle const enums properly
export const enum ModalAction {
  Create = "create",
  Update = "update"
}

export type ModalState<T> = {
  visible: false;
} | {
  visible: true;
  action: ModalAction.Create;
  data?: Partial<T>;
} | {
  visible: true;
  action: ModalAction.Update;
  data: T;
}
