import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

export const emailsRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct emails'),
    validator: (_, value: string[]) => (
      value.every((email) => isEmail(email))
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
