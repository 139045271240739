import { CSSProperties } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { getRouteTranslated, ROUTE } from 'src/constants';
import { assertIsString } from './assertIsString';
import { uuidToBase64Url } from './uuidToBase64Url';

export const navigateToItem = <T>(args: {
  navigate: NavigateFunction,
  route: ROUTE,
  rowKey: keyof T,
}) => (
    record: T,
  ): (Record<string, never> | { style: CSSProperties, onClick: () => void }
) => {
    const id = record[args.rowKey];

    if (assertIsString(id)) {
      return ({
        style: { cursor: 'pointer' },
        onClick: () => args.navigate(`${getRouteTranslated(args.route)}/${uuidToBase64Url(id)}`),
      });
    }

    throw new Error('Implementation error');
  };
