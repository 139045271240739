import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBoarderForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_BOARDERS } from 'src/gql/queries';
import { CreateBoarderInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_BOARDER = gql`
  mutation CreateBoarder($input: CreateBoarderInput!) {
    createBoarder(input: $input) {
      boarderId
    }
  }
`;

export const useCreateBoarder = (
  { form, onSuccess }: UseOnMutationArgs<CreateBoarderForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarder, { loading }] = useMutation<
    unknown, { input: CreateBoarderInput }
  >(CREATE_BOARDER, { refetchQueries: [GET_ALL_BOARDERS] });

  const onCreateBoarder = useCallback(async () => {
    const formValues = await form.validateFields();
    const boarderDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await createBoarder({
        variables: {
          input: boarderDto,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder has been created successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Boarder has not been created successfully. User exists') });
      }

      return notification.error({ message: t('Boarder has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateBoarder, loading];
};
