import { Form, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { RecruitmentQuestionnaireFormFields, RecruitmentQuestionnaireFormItems } from 'src/components';
import { useSetCurrentRecruitmentForm, useSetInitialFormFields } from 'src/hooks';

const { Paragraph, Text } = Typography;

export const QuestionnaireStep = () => {
  const { t } = useTranslation();
  const [form] = useForm<RecruitmentQuestionnaireFormFields>();
  useSetCurrentRecruitmentForm(form);
  useSetInitialFormFields(form, 'questionnaire');

  return (
    <>
      <Paragraph>
        <Text>{t('Questionnaire step')}</Text>
      </Paragraph>
      <Form form={form} layout="vertical">
        <RecruitmentQuestionnaireFormItems />
      </Form>
    </>
  );
};
