import { FileSyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { GqlMutation } from 'src/shared/types/common';
import { ViewHeaderButton } from './ViewHeaderButton';

type BoardersViewHeaderButtonsProps = {
  onGenerateReportClick: () => void;
  onAddBoarderClick: () => void;
}

export const BoardersViewHeaderButtons = ({
  onGenerateReportClick,
  onAddBoarderClick,
}: BoardersViewHeaderButtonsProps) => {
  const { t } = useTranslation();
  const user = useUserContext();

  return user?.isAllowed(GqlMutation.CreateBoarder) ? (
    <>
      <ViewHeaderButton
        PrefixIcon={FileSyncOutlined}
        title={t('Generate Excel report')}
        onClick={onGenerateReportClick}
        type="default"

      />
      <ViewHeaderButton
        PrefixIcon={UserAddOutlined}
        title={t('Add boarder')}
        onClick={onAddBoarderClick}
        type="primary"
      />
    </>
  ) : undefined;
};
