import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const descriptionRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct description'),
    required: true,
    min: 1,
    max: 1024,
  });
};
