import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, notification, Upload } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { STYLE } from 'src/constants';
import { Payment, PaymentType } from 'src/shared/types/common';

/* eslint-disable */ // eslint doesn't handle const enums properly
const enum UploadState {
  Undefined = "undefined",
  Uploading = "uploading",
  Success = "success",
  Fail = "fail"
}

/* eslint-enable */
export const paymentConfirmationColumn = (t: TFunction): ColumnType<Payment> => ({
  title: t('Payment confirmation'),
  key: 'paymentConfirmation',
  render: (_, item) => {
    const [uploadState, setUploadState] = useState<UploadState>(UploadState.Undefined);

    if (item.type !== PaymentType.BOARDER_PAYMENT) {
      return null;
    }

    switch (uploadState) {
      case UploadState.Uploading:
        return <LoadingOutlined style={{ marginLeft: STYLE.BASE_MARGIN }} />;
      case UploadState.Success:
        return <CheckCircleOutlined style={{ marginLeft: STYLE.BASE_MARGIN }} />;
      case UploadState.Fail:
        return <CloseCircleOutlined style={{ marginLeft: STYLE.BASE_MARGIN }} />;
      default:
    }

    return (item.downloadAttachment
      ? (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          href={item.downloadAttachment.url}
        >
          {t('Download')}
        </Button>
      )
      : (
        <Upload
          accept=".pdf"
          customRequest={async ({ file }) => {
            setUploadState(UploadState.Uploading);
            const body = new FormData();
            if (item.uploadAttachment?.body && item.uploadAttachment.url) {
              Object.entries(item.uploadAttachment.body)
                .forEach(([key, value]) => body.append(key, value));
              body.append('file', file);

              try {
                await fetch(item.uploadAttachment.url, {
                  method: 'POST',
                  mode: 'no-cors',
                  body,
                });

                setUploadState(UploadState.Success);
                notification.success({ message: t('Payment confirmation has been uploaded successfully') });
              } catch (error) {
                setUploadState(UploadState.Fail);
                notification.error({ message: t('Payment confirmation has not been uploaded successfully') });
              }
            }
          }}
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: false,
          }}
        >
          <Button type="link" icon={<UploadOutlined />}>
            {t('Upload')}
          </Button>
        </Upload>
      ));
  },
});
