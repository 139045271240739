import { Form } from 'antd';
import { SendPaymentReminderInput } from 'src/shared/types/api';
import { FormProps } from '.';
import { AmountThresholdFormItem } from './items';

export const SendPaymentReminderForm = ({
  form,
}: FormProps<SendPaymentReminderInput>) => (
  <Form layout="vertical" form={form}>
    <AmountThresholdFormItem />
  </Form>
);
