import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'src/components';
import { boarderBirthDateRule } from '../rules';

type BirthDateFormItemProps = {
  disabled: boolean
}

export const BirthDateFormItem = ({ disabled }: BirthDateFormItemProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Birth date')}
      name="birthDate"
      rules={[boarderBirthDateRule()]}
    >
      <DatePicker disabled={disabled} />
    </Form.Item>
  );
};
