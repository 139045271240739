import { Form, Modal } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateBoarder } from 'src/gql/mutations';
import { BoarderModalForm, CreateBoarderForm } from '../forms';

type BoarderModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const BoarderModal = (
  { isModalVisible, setIsModalVisible }: BoarderModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateBoarderForm>();
  const onSuccess = useCallback(() => setIsModalVisible(false), []);

  const [
    createBoarder,
    loading,
  ] = useCreateBoarder({ form, onSuccess });

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={t('Add boarder')}
      visible={isModalVisible}
      onOk={createBoarder}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Add')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <BoarderModalForm form={form} />
    </Modal>
  );
};
