import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validPolishPostCode } from 'src/shared/validatorUtils';
import { RuleArgs } from '.';

export const postCodeRule = ({ required }: RuleArgs): Rule => {
  const { t } = useTranslation();

  return ({
    required,
    message: t('Please enter correct post code'),
    validator: (_, value: string) => {
      if (required && !value) {
        return Promise.reject();
      }

      return (
        !value || validPolishPostCode(value)
          ? Promise.resolve()
          : Promise.reject());
    },
  });
};
