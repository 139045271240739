import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryDinnerFeeRule } from '../rules';

export const DormitoryDinnerFeeFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Dinner fee')}
      name="dinner"
      rules={[dormitoryDinnerFeeRule()]}
    >
      <Input type="number" min="0" max="99" disabled={disabled} />
    </Form.Item>
  );
};
