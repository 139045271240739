import { Form } from 'antd';
import { UpdateCanteenManagerEmailsInput } from 'src/shared/types/api';
import { FormProps } from '.';
import { CanteenManagerEmailsFormItem } from './items';

export const CanteenManagerEmailsForm = ({ form }: FormProps<UpdateCanteenManagerEmailsInput>) => (
  <Form form={form} layout="vertical">
    <CanteenManagerEmailsFormItem />
  </Form>
);
