import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps, nameWithPrefix } from '.';
import { phoneNumberRule } from '../rules';

export const PhoneNumberFormItem = ({ keyPrefix, required, disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Phone number')}
      name={nameWithPrefix({ name: 'phoneNumber', keyPrefix })}
      rules={[phoneNumberRule({ required: Boolean(required) })]}
    >
      <Input type="tel" disabled={disabled} />
    </Form.Item>
  );
};
