import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { BoarderPresenceStateSwitch } from 'src/components/BoarderPresenceStateSwitch';
import { GET_ALL_BOARDERS } from 'src/gql/queries';

export const presenceStateColumn = <T extends { isPresent?: boolean; boarderId: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Presence'),
    key: 'isPresent',
    render: (_, { boarderId, isPresent }) => (
      <BoarderPresenceStateSwitch
        boarderId={boarderId}
        currentState={isPresent}
        refetchQuery={GET_ALL_BOARDERS}
      />
    ),

    sorter: (obj1, obj2) => (Number(obj1.isPresent) - Number(obj2.isPresent)),
  });
