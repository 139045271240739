import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE, getRouteTranslated } from 'src/constants';
import { uuidToBase64Url } from 'src/utils';

type BoarderLinkProps = PropsWithChildren<{
  boarderId: string,
}>

export const BoarderLink = ({ boarderId, children }: BoarderLinkProps) => {
  const boarderPath = `${getRouteTranslated(ROUTE.BOARDERS)}/${uuidToBase64Url(boarderId)}`;

  // todo: use styled-component, add :hover, use tokens
  return (
    <Link to={boarderPath} onClick={(e) => e.stopPropagation()} style={{ color: '#606266' }}>
      {children}
    </Link>
  );
};
