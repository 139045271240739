import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryBreakfastFeeRule } from '../rules';

export const DormitoryBreakfastFeeFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Breakfast fee')}
      name="breakfast"
      rules={[dormitoryBreakfastFeeRule()]}
    >
      <Input type="number" min="0" max="99" disabled={disabled} />
    </Form.Item>
  );
};
