import { gql, useMutation } from '@apollo/client';
import { ChargeMonthlyFeeInput } from 'src/shared/types/api';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CHARGE_MONTHLY_FEE = gql`
  mutation ChargeMonthlyFee($input: ChargeMonthlyFeeInput!) {
    chargeMonthlyFee(input: $input) {
      base
      breakfast
      lunch
      dinner
      academic
      weekendStay
    }
  }
`;

export const useChargeMonthlyFee = (
  { form, onSuccess }: UseOnMutationArgs<ChargeMonthlyFeeInput>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [chargeMonthlyFee, { loading }] = useMutation<
    unknown, { input: ChargeMonthlyFeeInput }
  >(CHARGE_MONTHLY_FEE);

  const onChargeMonthlyFee = useCallback(async () => {
    const formValues = await form.validateFields();
    try {
      await chargeMonthlyFee({
        variables: {
          input: formValues,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Fees have been charged successfully') });
    } catch (error) {
      return notification.error({ message: t('Fees have not been charged successfully') });
    }
  }, [form, onSuccess]);

  return [onChargeMonthlyFee, loading];
};
