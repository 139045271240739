import { DocumentNode, gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPECIFIC_BOARDER_PAYMENTS } from 'src/gql/queries';
import { CreateBoarderPaymentInput } from 'src/shared/types/api';
import { BoarderPaymentInput } from 'src/shared/types/common';
import { NEGATIVE_PAYMENT_TYPES } from 'src/shared/utils';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_BOARDER_PAYMENT = gql`
  mutation CreateBoarderPayment($input: CreateBoarderPaymentInput!) {
    createBoarderPayment(input: $input) {
      boarderId
    }
  }
`;

type UseCreateBoarderPaymentArgs =
  UseOnMutationArgs<BoarderPaymentInput> & {
    boarderId?: string,
    refetchQueries?: DocumentNode[]
  }

export const useCreateBoarderPayment = ({
  form,
  boarderId,
  refetchQueries,
  onSuccess,
}: UseCreateBoarderPaymentArgs): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarderPayment, { loading }] = useMutation<
    unknown, { input: CreateBoarderPaymentInput }
  >(
    CREATE_BOARDER_PAYMENT,
    {
      refetchQueries: refetchQueries || [GET_SPECIFIC_BOARDER_PAYMENTS],
    },
  );

  const onCreateBoarderPayment = useCallback(async () => {
    if (!boarderId) {
      return notification.error({ message: t('Boarder payment has not been created successfully') });
    }
    const { amount, comment, type } = await form.validateFields();
    try {
      await createBoarderPayment({
        variables: {
          input: {
            boarderId,
            payment: {
              type,
              amount: NEGATIVE_PAYMENT_TYPES.includes(type)
                ? -1 * Number(amount)
                : Number(amount),
              comment,
            },
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder payment has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder payment has not been created successfully') });
    }
  }, [form, boarderId, refetchQueries, onSuccess]);

  return [onCreateBoarderPayment, loading];
};
