import {
  createContext, Dispatch, SetStateAction, useContext,
} from 'react';
import { RecruitmentSubFormInstance } from 'src/components';

export type RecruitmentCurrentFormContextValue = {
  currentForm?: RecruitmentSubFormInstance;
  setCurrentForm?: Dispatch<SetStateAction<RecruitmentSubFormInstance | undefined>>
} | undefined

export const RecruitmentCurrentFormContext = createContext<
  RecruitmentCurrentFormContextValue
>(undefined);

export const useRecruitmentCurrentFormContext = () => useContext(RecruitmentCurrentFormContext);
