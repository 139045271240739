import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPECIFIC_BOARDER_PAYMENTS } from 'src/gql/queries';
import { AddBoarderPaymentCommentInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const ADD_BOARDER_PAYMENT_COMMENT = gql`
  mutation AddBoarderPaymentComment($input: AddBoarderPaymentCommentInput!) {
    addBoarderPaymentComment(input: $input) {
      boarderId
    }
  }
`;

type UseAddBoarderPaymentCommentArgs =
  UseOnMutationArgs<{ comment: string}> & Partial<Omit<AddBoarderPaymentCommentInput, 'comment'>>

export const useAddBoarderPaymentComment = ({
  form,
  boarderId,
  paymentId,
  onSuccess,
}: UseAddBoarderPaymentCommentArgs): UseOnMutation => {
  const { t } = useTranslation();
  const [addBoarderPaymentComment, { loading }] = useMutation<
    unknown, { input: AddBoarderPaymentCommentInput }
  >(ADD_BOARDER_PAYMENT_COMMENT, { refetchQueries: [GET_SPECIFIC_BOARDER_PAYMENTS] });

  const onAddBoarderPaymentComment = useCallback(async () => {
    if (!(boarderId && paymentId)) {
      return notification.error({ message: t('Payment comment has not been added successfully') });
    }
    const { comment } = await form.validateFields();
    try {
      await addBoarderPaymentComment({
        variables: {
          input: {
            boarderId,
            paymentId,
            comment,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Payment comment has been added successfully') });
    } catch (error) {
      return notification.error({ message: t('Payment comment has not been added successfully') });
    }
  }, [form, boarderId, paymentId, onSuccess]);

  return [onAddBoarderPaymentComment, loading];
};
