import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_BOARDERS_BRIEF = gql`
  query GetBoarders {
    boarders {
      boarderId
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

export type BoarderBrief = Pick<Boarder, 'boarderId' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>

export type GetAllBoardersBriefResponse = { boarders: BoarderBrief[] };

export const useGetAllBoardersBriefMap = (): UseQueryReturnType<Map<string, BoarderBrief>> => {
  const { t } = useTranslation();
  const [allBoardersBriefMap, setAllBoardersBriefMap] = useState<Map<string, BoarderBrief>>();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllBoardersBriefResponse>(
    GET_ALL_BOARDERS_BRIEF,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch data') });
  }

  useEffect(() => {
    if (data?.boarders) {
      setAllBoardersBriefMap(
        new Map(data.boarders.map((boarder) => [boarder.boarderId, boarder])),
      );
    }
  }, [data]);

  return [allBoardersBriefMap, loading, error];
};
