import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { getFullName } from 'src/shared/utils';
import { BoarderLink } from 'src/components';
import { sorterByFullName } from '../sorters';

export function boarderFullNameColumn<T extends {
  firstName: string;
  lastName: string;
  boarderId: string;
  awaitingRequests?: boolean;
  isSuspended?: boolean;
}>(t: TFunction): ColumnType<T> {
  return {
    title: t('Full name'),
    key: 'fullName',
    sorter: sorterByFullName({ lastNameFirst: true }),
    defaultSortOrder: 'ascend',
    render: (_, boarder) => {
      const fullName = getFullName({ item: boarder, lastNameFirst: true });

      return (
        <BoarderLink boarderId={boarder.boarderId}>
          <Space>
            {fullName}
            {boarder.awaitingRequests && <Tooltip title={t('Requests await')}><QuestionCircleOutlined /></Tooltip>}
            {boarder.isSuspended && <Tooltip title={t('Boarder is suspended')}><ExclamationCircleOutlined /></Tooltip>}
          </Space>
        </BoarderLink>
      );
    },
  };
}
