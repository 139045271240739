import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ModalState,
  ModalAction,
  BreadcrumbItem,
  ViewHeaderButton,
  ViewHeaderSearch,
  ViewHeader,
  BoardersList,
} from 'src/components';
import { ParentModal } from 'src/components/modals/ParentModal';
import { useParentsTableColumns } from 'src/components/tables/parents';
import { ROUTE } from 'src/constants';
import { useUserContext } from 'src/context';
import {
  BoarderBrief,
  ParentWithoutBoarders,
  useGetAllBoardersBriefMap,
  useGetAllParents,
} from 'src/gql/queries';
import { GqlMutation, GqlQuery } from 'src/shared/types/common';
import { defaultRoute } from 'src/utils';
import { filterByFullName } from 'src/shared/utils';
import { UserAddOutlined } from '@ant-design/icons';

export const ParentsView = () => {
  const { t } = useTranslation();
  const [allParents, setAllParents] = useState<ParentWithoutBoarders[]>();
  const [filteredParents, setFilteredParents] = useState<ParentWithoutBoarders[]>();
  const [
    modalState,
    setModalState,
  ] = useState<ModalState<ParentWithoutBoarders>>({ visible: false });
  const navigate = useNavigate();
  const user = useUserContext();
  const [allBoardersBriefMap, getAllBoardersBriefLoading] = useGetAllBoardersBriefMap();

  if (user && !user?.isAllowed(GqlQuery.GetParents)) {
    navigate(defaultRoute(user));
  }

  const [allParentsData, getAllParentsLoading] = useGetAllParents();

  useEffect(() => {
    setAllParents(allParentsData);
    setFilteredParents(allParentsData);
  }, [allParentsData]);

  const onSearch = useCallback((searchText: string) => {
    setFilteredParents(filterByFullName({ array: allParents, searchText }));
  }, [allParents]);

  const onUpdateParent = (parent: ParentWithoutBoarders) => setModalState({
    visible: true,
    action: ModalAction.Update,
    data: parent,
  });

  const parentsTableColumns = useParentsTableColumns(onUpdateParent);

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.PARENTS, title: t('Parents') },
  ];

  const HeaderButton = user?.isAllowed(GqlMutation.CreateParent) ? (
    <ViewHeaderButton
      PrefixIcon={UserAddOutlined}
      title={t('Add parent')}
      onClick={() => setModalState({ visible: true, action: ModalAction.Create })}
      type="primary"
    />
  ) : undefined;

  const HeaderFilter = <ViewHeaderSearch onSearch={onSearch} />;

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
        Filter={HeaderFilter}
        Buttons={HeaderButton}
      />
      <Table
        loading={getAllParentsLoading || getAllBoardersBriefLoading}
        dataSource={filteredParents}
        size="middle"
        columns={parentsTableColumns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
        rowKey="parentId"
        expandable={{
          rowExpandable: (parent) => !!parent.boarderIds.length,
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (parent: ParentWithoutBoarders) => {
            const boarders = parent.boarderIds.reduce((prev, boarderId) => {
              const boarder = allBoardersBriefMap?.get(boarderId);
              if (boarder) {
                prev.push(boarder);
              }

              return prev;
            }, [] as BoarderBrief[]);

            return (<BoardersList boarders={boarders} />);
          },
        }}
      />
      {modalState.visible && (
        <ParentModal
          modalState={modalState}
          setModalState={setModalState}
          allBoardersBriefMap={allBoardersBriefMap}
        />
      )}
    </>
  );
};
