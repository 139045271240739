import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SendMessageForm } from 'src/components';
import { SendMessageInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const SEND_MESSAGE = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input)
  }
`;

export const useSendMessage = (
  { form, onSuccess } : UseOnMutationArgs<SendMessageForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [sendMessage, { loading }] = useMutation<
    unknown, { input: SendMessageInput }
  >(SEND_MESSAGE);

  const onSendMessage = useCallback(async () => {
    const formFields = await form.validateFields();
    try {
      await sendMessage({
        variables: {
          input: formFields,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Message has been sent successfully') });
    } catch (error) {
      return notification.error({ message: t('Message has not been sent successfully') });
    }
  }, [form, onSuccess]);

  return [onSendMessage, loading];
};
