import { Form } from 'antd';
import { FormProps } from '.';
import { AmountFormItem } from './items';

export type InitOnlinePaymentFormType = { amount: string };

export const InitOnlinePaymentForm = ({
  form, onValuesChange,
}: FormProps<InitOnlinePaymentFormType>) => (
  <Form layout="vertical" form={form} onValuesChange={onValuesChange}>
    <AmountFormItem isAmountNegative={false} />
  </Form>
);
