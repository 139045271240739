import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { RecruitmentBoarderRequestDataSource } from 'src/gql/queries/recruitment';
import { sorterByFullName } from '../sorters';
import { boarderFullNameColumn } from './boarderFullName';
import { fullNameColumn } from './fullName';

export function recruitmentFullNameColumn(
  t: TFunction,
): ColumnType<RecruitmentBoarderRequestDataSource> {
  return {
    title: t('Full name'),
    key: 'fullName',
    sorter: sorterByFullName({ lastNameFirst: true }),
    defaultSortOrder: 'ascend',
    render: (_, item, index) => (item.isExistingBoarder && item.boarder.boarderId
      ? boarderFullNameColumn(t).render?.(
        undefined,
        item.boarder as { firstName: string; lastName: string; boarderId: string;}, // checked above
        index,
      )
      : fullNameColumn(t).render?.(undefined, item.boarder, index)
    ),
  };
}
