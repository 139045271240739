import { Descriptions, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

const schoolYearToDisplay = (schoolYear?: number): string => {
  if (!schoolYear) {
    return '';
  }
  const schoolYearString = schoolYear.toString();

  return `${schoolYearString.slice(0, 4)}/${schoolYearString.slice(4, 8)}`;
};

type DormitorySchoolYearProps = {
  schoolYear?: number;
}

export const DormitorySchoolYear = ({ schoolYear }: DormitorySchoolYearProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />

      <Descriptions bordered column={1} layout="vertical">
        <Descriptions.Item label={t('School year')}>{schoolYearToDisplay(schoolYear)}</Descriptions.Item>
      </Descriptions>
    </>
  );
};
