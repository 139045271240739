import { Form } from 'antd';
import { CreateBoarderInput } from 'src/shared/types/api';
import { FormProps } from '.';
import { ContactDetailsFormItems } from './items';

export type CreateBoarderForm = CreateBoarderInput;

export const BoarderModalForm = ({
  form,
}: FormProps<CreateBoarderForm>) => (
  <Form form={form} layout="vertical">
    <ContactDetailsFormItems emailRequired phoneNumberRequired />
  </Form>
);
