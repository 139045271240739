import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS } from 'src/gql/queries';
import { DecideAboutBoarderUpdateGeneralInfoRequestInput } from 'src/shared/types/api';
import { UseOnMutation } from '..';

export const DECLINE_UPDATE_BOARDER_GENERAL_INFO_REQUEST = gql`
  mutation DeclineUpdateBoarderGeneralInfoRequest($input: DecideAboutUpdateBoarderGeneralInfoRequestInput!) {
    declineUpdateBoarderGeneralInfoRequest(input: $input) {
      boarderId
    }
  }
`;

type UseDeclineUpdateBoarderGeneralInfoRequestArgs =
  Omit<DecideAboutBoarderUpdateGeneralInfoRequestInput, 'boarderId'> & {
    boarderId?: string;
    onComplete?: () => void;
  };

export const useDeclineUpdateBoarderGeneralInfoRequest = (
  { boarderId, requestId, onComplete }: UseDeclineUpdateBoarderGeneralInfoRequestArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [declineUpdateBoarderGeneralInfoRequest, { loading }] = useMutation<
    unknown, { input: DecideAboutBoarderUpdateGeneralInfoRequestInput }
  >(
    DECLINE_UPDATE_BOARDER_GENERAL_INFO_REQUEST,
    { refetchQueries: [GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS] },
  );

  const onDeclineUpdateBoarderGeneralInfoRequest = useCallback(async () => {
    if (!boarderId) {
      return notification.error({ message: t('Boarder update boarder general info request has not been declined successfully') });
    }
    try {
      await declineUpdateBoarderGeneralInfoRequest({
        variables: {
          input: {
            boarderId,
            requestId,
          },
        },
      });

      return notification.success({ message: t('Boarder update boarder general info request has been declined successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder update boarder general info request has not been declined successfully') });
    } finally {
      onComplete?.();
    }
  }, [boarderId, requestId, onComplete]);

  return [onDeclineUpdateBoarderGeneralInfoRequest, loading];
};
