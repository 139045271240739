import { Col, Form, Row } from 'antd';
import { Dayjs } from 'dayjs';
import { STYLE } from 'src/constants';
import { FormProps } from '.';
import {
  CardIdFormItem,
  GroupIdFormItem,
  LabelsFormItem,
  PeriodOfStayInDormitoryFormItem,
  RoomIdFormItem,
} from './items';

export type BoarderDormitoryInfoFormFields = {
  labels: string[];
  cardId: string;
  groupId?: string;
  roomId?: string;
  livingStartDate?: Dayjs;
  livingEndDate?: Dayjs;
}

export const BoarderDormitoryInfoForm = (
  { disabled, form }: FormProps<BoarderDormitoryInfoFormFields>,
) => (
  <Form form={form} layout="vertical" className="boarder-tab-content-wrapper">
    <LabelsFormItem disabled={disabled} />
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24} sm={8}>
        <CardIdFormItem disabled={disabled} />
      </Col>
      <Col xs={24} sm={8}>
        <GroupIdFormItem disabled={disabled} />
      </Col>
      <Col xs={24} sm={8}>
        <RoomIdFormItem disabled={disabled} />
      </Col>
    </Row>
    <PeriodOfStayInDormitoryFormItem disabled={disabled} />
  </Form>
);
