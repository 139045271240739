import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ParentForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_PARENTS } from 'src/gql/queries';
import { CreateParentInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_PARENT = gql`
  mutation CreateParent($input: CreateParentInput!) {
    createParent(input: $input) {
      parentId
    }
  }
`;

export const useCreateParent = (
  { form, onSuccess }: UseOnMutationArgs<ParentForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createParent, { loading }] = useMutation<
    unknown, { input: CreateParentInput }
  >(CREATE_PARENT, { refetchQueries: [GET_ALL_PARENTS] });

  const onCreateParent = useCallback(async () => {
    const formValues = await form.validateFields();
    const parentDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await createParent({
        variables: {
          input: parentDto,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Parent has been created successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Parent has not been created successfully. User exists') });
      }

      return notification.error({ message: t('Parent has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateParent, loading];
};
