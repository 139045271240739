import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { numberToPLN } from 'src/utils';

export const amountColumn = <T extends { amount: number }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Amount'),
    key: 'amount',
    render: (_, item) => numberToPLN(item.amount),
  });
