import { useEffect } from 'react';
import { RecruitmentSubFormInstance } from 'src/components';
import { useRecruitmentCurrentFormContext } from 'src/context';

export const useSetCurrentRecruitmentForm = (form: RecruitmentSubFormInstance) => {
  const recruitmentCurrentForm = useRecruitmentCurrentFormContext();
  useEffect(() => {
    recruitmentCurrentForm?.setCurrentForm?.(form);
  }, []);
};
