import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getRouteTranslated, ROUTE } from 'src/constants';
import { BoardersFilter, FilterContext, useUserContext } from 'src/context';
import { defaultRoute } from 'src/utils';
import { AdministratorsView } from 'src/views/AdministratorsView';
import { BoarderArchiveView } from 'src/views/BoarderArchiveView';
import { BoardersArchiveView } from 'src/views/BoardersArchiveView ';
import { BoardersView } from 'src/views/BoardersView';
import { BoarderView } from 'src/views/BoarderView';
import { ContactView } from 'src/views/ContactView';
import { DormitoryView } from 'src/views/DormitoryView';
import { OnlinePaymentConfirmationView } from 'src/views/OnlinePaymentConfirmationView';
import { ParentsView } from 'src/views/ParentsView';
import { TutorsView } from 'src/views/TutorsView';

const RedirectToDefault = () => {
  const navigate = useNavigate();
  const user = useUserContext();
  if (user) {
    navigate(defaultRoute(user));
  }

  return null;
};

export const RouterSwitch = () => {
  const [boardersFilter, setBoardersFilter] = useState<BoardersFilter>({});

  return (
    <Routes>
      <Route path={getRouteTranslated(ROUTE.ADMINISTRATORS)} element={<AdministratorsView />} />
      <Route
        path={getRouteTranslated(ROUTE.BOARDERS)}
        element={(
          <FilterContext.Provider value={boardersFilter}>
            <BoardersView boardersFilter={boardersFilter} setBoardersFilter={setBoardersFilter} />
          </FilterContext.Provider>
        )}
      />
      <Route path={getRouteTranslated(ROUTE.BOARDER)} element={<BoarderView />} />
      <Route path={getRouteTranslated(ROUTE.DORMITORY)} element={<DormitoryView />} />
      <Route path={getRouteTranslated(ROUTE.PARENTS)} element={<ParentsView />} />
      <Route path={getRouteTranslated(ROUTE.TUTORS)} element={<TutorsView />} />
      <Route path={getRouteTranslated(ROUTE.BOARDERS_ARCHIVE)} element={<BoardersArchiveView />} />
      <Route path={getRouteTranslated(ROUTE.BOARDER_ARCHIVE)} element={<BoarderArchiveView />} />
      <Route path={getRouteTranslated(ROUTE.CONTACT)} element={<ContactView />} />
      <Route
        path={getRouteTranslated(ROUTE.ONLINE_PAYMENT_CONFIRMATION)}
        element={<OnlinePaymentConfirmationView />}
      />
      <Route path="*" element={<RedirectToDefault />} />
    </Routes>
  );
};
