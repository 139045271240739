import { MailOutlined } from '@ant-design/icons';
import { Descriptions, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BreadcrumbItem, MainCard, SendMessageModal, ViewHeader, ViewHeaderButton,
} from 'src/components';
import { ROUTE, STYLE } from 'src/constants';
import { useUserContext } from 'src/context';
import { useGetDormitoryDetails } from 'src/gql/queries';
import { useSendMessageRecipients } from 'src/hooks';
import { GqlMutation } from 'src/shared/types/common';
import { phoneNumberToDisplay } from 'src/shared/utils';

const { Paragraph, Text, Title } = Typography;

export const ContactView = () => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dormitoryDetails, loading] = useGetDormitoryDetails();
  const recipients = useSendMessageRecipients({ dormitoryDetails });

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.CONTACT, title: t('Contact') },
  ];

  const HeaderButtons = user?.isAllowed(GqlMutation.SendMessage) && recipients?.length ? (
    <ViewHeaderButton
      PrefixIcon={MailOutlined}
      title={t('Send message')}
      onClick={() => setIsModalVisible(true)}
      type="primary"
    />
  ) : undefined;

  return (
    <>
      <ViewHeader breadcrumbItems={breadcrumbItems} Buttons={HeaderButtons} />
      <MainCard loading={loading} className="center">
        {dormitoryDetails && (
          <>
            <Title level={3} style={{ marginTop: 2 * STYLE.BASE_MARGIN }}>
              {dormitoryDetails.name}
            </Title>
            <Paragraph>
              <Text>
                {dormitoryDetails.address.streetAddress}
              </Text>
            </Paragraph>
            <Paragraph>
              <Text>
                {dormitoryDetails.address.postCode}
              </Text>
              {' '}
              <Text>
                {dormitoryDetails.address.city}
              </Text>
            </Paragraph>
            <Paragraph style={{ marginBottom: 2 * STYLE.BASE_MARGIN }}>
              <Text strong>
                {t('Account number')}
                {': '}
              </Text>
              <Text>
                {dormitoryDetails.accountNumber}
              </Text>
            </Paragraph>

            <Title level={5}>{t('Emails')}</Title>
            <Descriptions bordered className="dormitory-tab-content-wrapper" column={1} style={{ marginBottom: 2 * STYLE.BASE_MARGIN }}>
              {dormitoryDetails.emails.map((email, index) => (
                <Descriptions.Item label={email.label} key={`email_${index.toString()}`}>
                  {email.value}
                </Descriptions.Item>
              ))}
            </Descriptions>

            <Title level={5}>{t('Phone numbers')}</Title>
            <Descriptions bordered className="dormitory-tab-content-wrapper" column={1} style={{ marginBottom: 2 * STYLE.BASE_MARGIN }}>
              {dormitoryDetails.phoneNumbers.map((phoneNumber, index) => (
                <Descriptions.Item label={phoneNumber.label} key={`phoneNumber_${index.toString()}`}>
                  {phoneNumberToDisplay(phoneNumber.value)}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        )}
      </MainCard>

      {isModalVisible && (
        <SendMessageModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          recipients={recipients}
        />
      )}
    </>
  );
};
