import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validGroupId } from 'src/shared/validatorUtils';

export const roomIdsRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct room IDs'),
    validator: (_, value: string[] | undefined) => (
      value?.every((groupId) => validGroupId(groupId))
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
