import { Form, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ClausesFormItem, ExistingBoarderFormItem } from 'src/components/forms/items';
import { RecruitmentStartStepFormFields } from 'src/components/forms';
import { useSetCurrentRecruitmentForm, useSetInitialFormFields } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import { useRecruitmentFormContext } from 'src/context';
import { ClosedQuestionRadioOption } from 'src/components';

const { Paragraph, Text } = Typography;

export const StartStep = () => {
  const { t } = useTranslation();
  const [form] = useForm<RecruitmentStartStepFormFields>();
  const recruitmentForm = useRecruitmentFormContext();
  useSetCurrentRecruitmentForm(form);
  useSetInitialFormFields(form, 'startStep');

  const isExistingInitialValue = recruitmentForm
    ?.recruitmentForm
    .startStep
    ?.existingBoarder === ClosedQuestionRadioOption.Yes;

  return (
    <Form form={form} layout="vertical">
      <Paragraph>
        <Text>{t('Start recruitment process')}</Text>
      </Paragraph>

      <ClausesFormItem />

      <Paragraph>
        <Text>{t('Does child already live in the dormitory?')}</Text>
      </Paragraph>

      <ExistingBoarderFormItem isExistingInitialValue={isExistingInitialValue} />
    </Form>
  );
};
