import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { BreadcrumbItem, MainCard, ViewHeader } from 'src/components';
import { ROUTE } from 'src/constants';
import { Boarder } from 'src/shared/types/api';
import { base64UrlToUuid } from 'src/utils';
import { useGetSpecificBoarder } from 'src/gql/queries';
import { getFullName } from 'src/shared/utils';
import {
  BoarderCalendarTab,
  BoarderGeneralInfoTab,
  BoarderPaymentsTab,
  BoarderNotesTab,
  BoarderActivitiesTab,
  BoarderUpdateGeneralInfoRequestsTab,
} from './BoarderTabs';

export const BoarderView = () => {
  const { t } = useTranslation();
  const { boarderId: encodedBoarderId } = useParams<Pick<Boarder, 'boarderId'>>();
  const boarderId = encodedBoarderId && base64UrlToUuid(encodedBoarderId);

  const [boarder, loading] = useGetSpecificBoarder(boarderId);

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.BOARDERS, title: t('Boarders') },
  ];

  if (boarder) {
    breadcrumbItems.push(
      { title: getFullName({ item: boarder }) },
    );
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
      />
      <MainCard loading={loading}>
        <Tabs centered defaultActiveKey="general-data" destroyInactiveTabPane>
          <Tabs.TabPane tab={t('General data')} key="general-data">
            <BoarderGeneralInfoTab boarder={boarder} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Activities')} key="activities">
            <BoarderActivitiesTab boarderId={boarderId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Calendar')} key="calendar">
            <BoarderCalendarTab boarderId={boarderId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Notes')} key="notes">
            <BoarderNotesTab boarderId={boarderId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Update general info requests')} key="update-general-info-requests">
            <BoarderUpdateGeneralInfoRequestsTab boarderId={boarderId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Payments')} key="payments">
            <BoarderPaymentsTab boarderId={boarderId} />
          </Tabs.TabPane>
        </Tabs>
      </MainCard>
    </>
  );
};
