import { Form } from 'antd';
import { BoarderBrief } from 'src/gql/queries';
import { CreateParentInput } from 'src/shared/types/api';
import { Uuid } from 'src/shared/types/common';
import { FormProps } from '.';
import { BoardersFormItem, ContactDetailsFormItems } from './items';

export type ParentForm = CreateParentInput & { boarderIds: Uuid[] };

type ParentModalFormProps = FormProps<ParentForm> & {
  withBoarders: boolean;
  allBoardersBriefMap?: Map<string, BoarderBrief>
}

export const ParentModalForm = ({
  form, allBoardersBriefMap, withBoarders,
}: ParentModalFormProps) => (
  <Form form={form} layout="vertical">
    <ContactDetailsFormItems emailRequired phoneNumberRequired />
    {withBoarders && <BoardersFormItem allBoardersBriefMap={allBoardersBriefMap} />}
  </Form>
);
