import {
  Alert, Form, Modal,
} from 'antd';
import {
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useChargeMonthlyFee } from 'src/gql/mutations';
import { useGetDormitoryChargedMonths } from 'src/gql/queries';
import { ChargeMonthlyFeeInput } from 'src/shared/types/api';
import { ChargeMonthlyFeeForm } from '../forms';

type ChargeMonthlyFeeModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ChargeMonthlyFeeModal = (
  { isModalVisible, setIsModalVisible }: ChargeMonthlyFeeModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ChargeMonthlyFeeInput>();

  const [
    chargedMonths,
    chargedMonthsLoading,
  ] = useGetDormitoryChargedMonths({ onError: () => setIsModalVisible(false) });

  const [
    chargeMonthlyFee,
    chargeMonthlyFeeLoading,
  ] = useChargeMonthlyFee({
    form,
    onSuccess: () => setIsModalVisible(false),
  });

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={t('Charging monthly fee')}
      visible={isModalVisible}
      onOk={chargeMonthlyFee}
      confirmLoading={chargeMonthlyFeeLoading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Charge monthly fee')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <ChargeMonthlyFeeForm
        form={form}
        loading={chargedMonthsLoading}
        chargedMonths={chargedMonths}
      />
      <Alert
        message={t('Warning')}
        description={t('Charge monthly fee warning')}
        type="warning"
        showIcon
      />
    </Modal>
  );
};
