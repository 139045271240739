import { Form, Row, Col } from 'antd';
import { Dayjs } from 'dayjs';
import { PESEL } from 'polish-utils';
import { useEffect, useState } from 'react';
import { STYLE } from 'src/constants';
import { Boarder } from 'src/shared/types/api';
import { FormProps } from '.';
import {
  BirthDateFormItem, BirthPlaceFormItem, ContactDetailsFormItems, PeselFormItem,
} from './items';

export type BoarderGeneralInfoFormFields = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  personalIdentityNumber?: string;
  birthDate?: Dayjs;
  birthPlace?: string;
  streetAddress?: string;
  postCode?: string;
  city?: string;
}

type BoarderGeneralInfoFormProps = FormProps<BoarderGeneralInfoFormFields> &
  { boarder?: Pick<Boarder, 'address' | 'birthDate' | 'birthPlace' | 'email' | 'firstName' | 'lastName' | 'personalIdentityNumber' | 'phoneNumber'> }

export const BoarderGeneralInfoForm = (
  { boarder, form, disabled }: BoarderGeneralInfoFormProps,
) => {
  const [isBirthDateDisabled, setIsBirthDateDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (boarder?.personalIdentityNumber) {
      setIsBirthDateDisabled(new PESEL(boarder.personalIdentityNumber).isValid);
    }
  }, [boarder?.personalIdentityNumber]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="boarder-tab-content-wrapper"
    >
      <ContactDetailsFormItems
        showAddress
        emailRequired
        phoneNumberRequired
        disabled={disabled}
      />

      <Row gutter={STYLE.BASE_MARGIN}>
        <Col xs={24} sm={8}>
          <PeselFormItem
            form={form}
            setIsBirthDateDisabled={setIsBirthDateDisabled}
            disabled={disabled}
          />
        </Col>
        <Col xs={24} sm={8}>
          <BirthDateFormItem disabled={Boolean(isBirthDateDisabled || disabled)} />
        </Col>
        <Col xs={24} sm={8}>
          <BirthPlaceFormItem disabled={disabled} />
        </Col>
      </Row>

      {/* TODO: avatar */}
    </Form>
  );
};
