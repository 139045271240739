import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryLunchFeeRule } from '../rules';

export const DormitoryLunchFeeFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Lunch fee')}
      name="lunch"
      rules={[dormitoryLunchFeeRule()]}
    >
      <Input type="number" min="0" max="99" disabled={disabled} />
    </Form.Item>
  );
};
