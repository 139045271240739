import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { RuleArgs } from '.';

export const boardersRule = ({ required }: RuleArgs): Rule => {
  const { t } = useTranslation();

  return ({
    required,
    message: t('Please select at least one boarder'),
    validator: (_, value: string[] | undefined) => {
      if (required && !value?.length) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
  });
};
