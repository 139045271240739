import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoomRentalForm } from 'src/components';
import { GET_ROOM_RENTALS } from 'src/gql/queries';
import { CreateRoomRentalInput } from 'src/shared/types/api';
import { toIsoDateString } from 'src/shared/utils';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_ROOM_RENTAL = gql`
  mutation CreateRoomRental($input: CreateRoomRentalInput!) {
    createRoomRental(input: $input) {
      roomRentalId
    }
  }
`;

export const roomRentalFormToMutationInput = (values: RoomRentalForm): CreateRoomRentalInput => ({
  city: values.city,
  contactDetails: values.contactDetails ?? '',
  endsAt: toIsoDateString(values.startsAtEndsAt[1].toDate()),
  groupName: values.groupName,
  otherInfo: values.otherInfo ?? '',
  peopleCount: Number(values.peopleCount),
  roomIds: values.roomIds.sort(),
  startsAt: toIsoDateString(values.startsAtEndsAt[0].toDate()),

});

export const useCreateRoomRental = (
  { form, onSuccess } : UseOnMutationArgs<RoomRentalForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createRoomRental, { loading }] = useMutation<
    unknown, { input: CreateRoomRentalInput }
  >(CREATE_ROOM_RENTAL, { refetchQueries: [GET_ROOM_RENTALS] });

  const onCreateRoomRental = async () => {
    const values = await form.validateFields();
    try {
      await createRoomRental({
        variables: {
          input: roomRentalFormToMutationInput(values),
        },
      });
      notification.success({ message: t('Room rental has been created successfully') });
      onSuccess?.();
    } catch (error) {
      notification.error({ message: t('Room rental has not been created successfully') });
    }
  };

  return [onCreateRoomRental, loading];
};
