import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteTranslated, ROUTE } from 'src/constants';
import { SpecificBoarder } from 'src/gql/queries';
import { BoarderId } from 'src/shared/types/common';
import { UseOnMutation } from '..';

const ARCHIVE_BOARDER = gql`
  mutation ArchiveBoarder($input: ArchiveBoarderInput!) {
    archiveBoarder(input: $input) {
      boarderId
    }
  }
`;

type UseArchiveBoarderArgs = {
  boarder?: SpecificBoarder;
}

export const useArchiveBoarder = ({ boarder }: UseArchiveBoarderArgs): UseOnMutation => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [archiveBoarder, { loading }] = useMutation<
    unknown, { input: BoarderId }
  >(ARCHIVE_BOARDER);

  const onArchiveBoarder = useCallback(async () => {
    if (!boarder?.boarderId) {
      return notification.error({ message: t('Boarder has not been archived successfully') });
    }
    try {
      await archiveBoarder({
        variables: {
          input: {
            boarderId: boarder?.boarderId,
          },
        },
      });
      navigate(getRouteTranslated(ROUTE.BOARDERS));

      return notification.success({ message: t('Boarder has been archived successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder has not been archived successfully') });
    }
  }, [boarder]);

  return [onArchiveBoarder, loading];
};
