import { TutorForm } from 'src/components';
import {
  UseOnMutation,
  UseOnMutationArgs,
  useCreateTutor,
  useUpdateTutor,
} from 'src/gql/mutations';

type UseOnTutorModalSubmitArgs = UseOnMutationArgs<TutorForm> & {
  tutorId?: string;
};

export const useOnTutorModalSubmit = (
  args: UseOnTutorModalSubmitArgs,
): UseOnMutation => {
  const [create, createLoading] = useCreateTutor(args);
  const [update, updateLoading] = useUpdateTutor(args);

  const onSubmit = async () => {
    const action = args.tutorId ? update : create;
    await action();
  };

  return [onSubmit, createLoading || updateLoading];
};
