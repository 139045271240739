import {
  Collapse, Form, FormInstance, Table,
} from 'antd';
import { useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { GqlMutation, RequestStatus, UpdateGeneralInfoRequest } from 'src/shared/types/common';
import { BoarderUpdateGeneralInfoRequestForm } from 'src/views/BoarderTabs';
import { BoarderGeneralInfoForm, BoarderSchoolInfoForm } from '../forms';
import { boarderUpdateGeneralInfoRequestForm } from '../modals';
import { creationInfoColumn, decisionInfoColumn, updateBoarderGeneralInfoRequestDecisionColumn } from './utils';

type BoarderUpdateGeneralInfoRequestArgs = {
  form: FormInstance<BoarderUpdateGeneralInfoRequestForm>;
  t:TFunction
}

const BoarderUpdateGeneralInfoRequest = (
  { form, t }: BoarderUpdateGeneralInfoRequestArgs,
) => useCallback((request: UpdateGeneralInfoRequest) => (
  <Collapse defaultActiveKey={['generalInfo', 'studentData']}>
    <Collapse.Panel header={t('General data')} key="generalInfo">
      <BoarderGeneralInfoForm
        boarder={{ ...request.boarderGeneralInfo }}
        form={form}
        disabled
      />
    </Collapse.Panel>
    <Collapse.Panel header={t('Student data')} key="studentData">
      <BoarderSchoolInfoForm
        boarder={{ ...request.boarderGeneralInfo }}
        form={form}
        disabled
      />
    </Collapse.Panel>
  </Collapse>
), []);

type BoarderUpdateGeneralInfoRequestTableProps = {
  boarderId?: string;
  requests?: UpdateGeneralInfoRequest[];
  requestsStatus: RequestStatus;
}

export const BoarderUpdateGeneralInfoRequestsTable = ({
  boarderId,
  requests,
  requestsStatus,
}: BoarderUpdateGeneralInfoRequestTableProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [form] = Form.useForm<BoarderUpdateGeneralInfoRequestForm>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  // TODO: useBoarderUpdateGeneralInfoRequestsTableColumns (BUR-194)
  const columns = [
    creationInfoColumn<UpdateGeneralInfoRequest>(t),
  ];

  if (requestsStatus === RequestStatus.AWAITING) {
    if (user?.isAllowed(GqlMutation.ApproveUpdateBoarderGeneralInfoRequest)) {
      columns.push(updateBoarderGeneralInfoRequestDecisionColumn(t, boarderId));
    }
  } else {
    columns.push(decisionInfoColumn<UpdateGeneralInfoRequest>(t));
  }

  return (
    <Table
      dataSource={requests}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 10,
      }}
      size="middle"
      columns={columns}
      rowKey="requestId"
      expandable={{
        expandedRowRender: BoarderUpdateGeneralInfoRequest({ form, t }),
        expandedRowKeys,
        expandRowByClick: true,
        onExpand(expanded, record) {
          if (expanded) {
            setExpandedRowKeys([record.requestId]);
            form.setFieldsValue(boarderUpdateGeneralInfoRequestForm({
              ...record.boarderGeneralInfo,
              boarderId: boarderId || '',
              updateGeneralInfoRequests: { approved: [], awaiting: [], declined: [] },
            }));
          } else {
            setExpandedRowKeys([]);
          }
        },
      }}
    />
  );
};
