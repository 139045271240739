import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { DELETE_TUTOR, RESEND_CREDENTIALS_TO_TUTOR } from 'src/gql/mutations';
import { GET_ALL_TUTORS } from 'src/gql/queries';
import { DeleteTutorInput, Tutor } from 'src/shared/types/api';
import { disabledByAccountStatus } from 'src/utils';
import { useUserContext } from 'src/context';
import { GqlMutation } from 'src/shared/types/common';
import {
  accountStatusColumn,
  actionsColumn,
  emailColumn,
  fullNameColumn,
  groupIdsColumn,
  phoneNumberColumn,
} from './utils/columns';

export const useTutorsTableColumns = (
  // eslint-disable-next-line no-unused-vars
  onUpdateTutor: (tutor: Tutor) => void, // eslint bug
) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isMobile = useIsMobile();

  const refetchQueries = [GET_ALL_TUTORS];
  const recordToInput = (tutor: Tutor) => ({ tutorId: tutor.tutorId });

  const columnsMemo = useMemo(() => ({
    fullNameColumn: fullNameColumn(t),
    groupColumn: groupIdsColumn(t),
    emailColumn: emailColumn(t),
    phoneNumberColumn: phoneNumberColumn(t),
    accountStatusColumn: accountStatusColumn(t),
    actionsColumn: actionsColumn<Tutor, DeleteTutorInput>({
      t,
      rendererArgs: {
        update: {
          onUpdate: onUpdateTutor,
          disabledBy: () => !user?.isAllowed(GqlMutation.UpdateTutor),
        },
        remove: {
          recordToInput,
          refetchQueries,
          mutation: DELETE_TUTOR,
          messageOnSuccess: t('Tutor has been removed successfully'),
          messageOnError: t('Tutor has not been removed successfully'),
          disabledBy: () => !user?.isAllowed(GqlMutation.DeleteTutor),
        },
        resendCredentials: {
          recordToInput,
          refetchQueries,
          mutation: RESEND_CREDENTIALS_TO_TUTOR,
          disabledBy: disabledByAccountStatus,
        },
      },
      isMobile,
    }),
  }), [t, onUpdateTutor, isMobile]);

  return isMobile
    ? [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.actionsColumn,
    ]
    : [
      columnsMemo.fullNameColumn,
      columnsMemo.groupColumn,
      columnsMemo.emailColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.accountStatusColumn,
      columnsMemo.actionsColumn,
    ];
};
