/* eslint-disable react/destructuring-assignment */
import { Checkbox } from 'antd';
import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DormitoryDay,
  DormitoryYear,
  MonthDayNumber,
  MonthNumber,
} from 'src/shared/types/common';
import { mutateDormitoryYear } from 'src/views/DormitoryTabs/utils';

type CalendarDormitoryDayArgs = {
  dormitoryYear: DormitoryYear | undefined;
  setDormitoryYear: Dispatch<SetStateAction<DormitoryYear | undefined>>;
  updateForbidden?: boolean;
}

export const CalendarDormitoryDay = ({
  dormitoryYear, setDormitoryYear, updateForbidden,
// eslint-disable-next-line react/function-component-definition
}: CalendarDormitoryDayArgs) => (date: Dayjs) => {
  const { t } = useTranslation();
  const dormitoryDay: DormitoryDay | undefined = dormitoryYear
    ?.[date.month() as MonthNumber]
    ?.[date.date() as MonthDayNumber];

  return (
    <>
      <Checkbox
        checked={dormitoryDay?.breakfast}
        disabled={dormitoryDay?.holiday || updateForbidden}
        onChange={dormitoryYear ? (e) => {
          setDormitoryYear(
            mutateDormitoryYear({
              date,
              newValue: e.target.checked,
              oldDormitoryYear: dormitoryYear,
              updatedKey: 'breakfast',
            }),
          );
        } : undefined}
      >
        {t('Breakfast')}
      </Checkbox>
      <Checkbox
        checked={dormitoryDay?.lunch}
        disabled={dormitoryDay?.holiday || updateForbidden}
        onChange={dormitoryYear ? (e) => {
          setDormitoryYear(
            mutateDormitoryYear({
              date,
              newValue: e.target.checked,
              oldDormitoryYear: dormitoryYear,
              updatedKey: 'lunch',
            }),
          );
        } : undefined}
      >
        {t('Lunch')}
      </Checkbox>
      <Checkbox
        checked={dormitoryDay?.dinner}
        disabled={dormitoryDay?.holiday || updateForbidden}
        onChange={dormitoryYear ? (e) => {
          setDormitoryYear(
            mutateDormitoryYear({
              date,
              newValue: e.target.checked,
              oldDormitoryYear: dormitoryYear,
              updatedKey: 'dinner',
            }),
          );
        } : undefined}
      >
        {t('Dinner')}
      </Checkbox>
      <Checkbox
        checked={dormitoryDay?.holiday}
        disabled={updateForbidden}
        onChange={dormitoryYear ? (e) => {
          setDormitoryYear(
            mutateDormitoryYear({
              date,
              newValue: e.target.checked,
              oldDormitoryYear: dormitoryYear,
              updatedKey: 'holiday',
            }),
          );
        } : undefined}
      >
        {t('Holiday')}
      </Checkbox>
    </>
  );
};
