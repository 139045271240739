import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBoarderAndParentsByRecruitmentIdInput } from 'src/shared/types/api';
import { BoarderRecruitmentDoc } from 'src/shared/types/docs';
import { UseOnMutation } from '..';

export const CREATE_BOARDER_AND_PARENTS_BY_RECRUITMENT_ID = gql`
  mutation CreateBoarderAndParentsByRecruitmentId($input: CreateBoarderAndParentsByRecruitmentIdInput!) {
    createBoarderAndParentsByRecruitmentId(input: $input) {
      boarderId
    }
  }
`;

type useCreateBoarderAndParentsByRecruitmentIdArgs =
  Pick<BoarderRecruitmentDoc, 'boarderRecruitmentId'> & {
    onSuccess?: () => void;
    onComplete?: () => void;
  }

export const useCreateBoarderAndParentsByRecruitmentId = (
  { boarderRecruitmentId, onSuccess, onComplete }: useCreateBoarderAndParentsByRecruitmentIdArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarder, { loading }] = useMutation<
    unknown, { input: CreateBoarderAndParentsByRecruitmentIdInput }
  >(CREATE_BOARDER_AND_PARENTS_BY_RECRUITMENT_ID);

  const onCreateBoarderAndParentsByRecruitmentId = useCallback(async () => {
    try {
      await createBoarder({
        variables: {
          input: {
            boarderRecruitmentId,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder has not been created successfully') });
    } finally {
      onComplete?.();
    }
  }, [boarderRecruitmentId, onSuccess, onComplete]);

  return [onCreateBoarderAndParentsByRecruitmentId, loading];
};
