import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { WHOLE_ADDRESS_FRAGMENT } from 'src/gql/fragments';
import { Dormitory, DormitoryDetailsApi } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_DETAILS = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetDormitoryContact {
    dormitory {
      dormitoryDetails {
        name
        accountNumber
        address { ...WholeAddressFragment }
        emails {
          label
          value
        }
        phoneNumbers {
          label
          value
        }
        groups {
          groupId
          tutors {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export type GetDormitoryDetailsResponse = { dormitory: Pick<Dormitory, 'dormitoryDetails'> }

export const useGetDormitoryDetails = (): UseQueryReturnType<DormitoryDetailsApi> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryDetailsResponse>(
    GET_DORMITORY_DETAILS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory groups') });
  }

  return [data?.dormitory.dormitoryDetails, loading, error];
};
