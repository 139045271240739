import { CSSProperties, PropsWithChildren } from 'react';

const style: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
};

type CenterProps = {
  fullHeight?: true;
}

// todo: use styled-components
export const Center = ({ children, fullHeight }: PropsWithChildren<CenterProps>) => (
  <div style={{ ...style, height: fullHeight ? '100vh' : undefined }}>
    {children}
  </div>
);
