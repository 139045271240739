import {
  Row, Col, Form, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { labelsRule } from '../rules';

export const LabelsFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <Form.Item
          label={t('Labels')}
          name="labels"
          rules={[labelsRule()]}
        >
          <Select
            disabled={disabled}
            mode="tags"
            tokenSeparators={[',', ' ']}
            notFoundContent={null}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
