import { Radio, RadioChangeEvent, Space } from 'antd';
import { useTranslation } from 'react-i18next';

/* eslint-disable */ // eslint doesn't handle const enums properly
export const enum ClosedQuestionRadioOption {
  No = 'no',
  Yes = 'yes'
}
/* eslint-enable */

type ClosedQuestionRadioProps = {
  direction?: 'horizontal' | 'vertical';
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: RadioChangeEvent) => void; // eslint bug
  disabled?: boolean;
}

export const toClosedQuestionRadio = (value?: boolean):ClosedQuestionRadioOption => (value
  ? ClosedQuestionRadioOption.Yes
  : ClosedQuestionRadioOption.No);

export const ClosedQuestionRadio = ({
  direction,
  onChange,
  disabled,
}: ClosedQuestionRadioProps) => {
  const { t } = useTranslation();

  return (
    <Radio.Group onChange={onChange} disabled={disabled}>
      <Space direction={direction} size={0}>
        <Radio value={ClosedQuestionRadioOption.Yes}>
          {t('Yes')}
        </Radio>
        <Radio value={ClosedQuestionRadioOption.No}>
          {t('No')}
        </Radio>
      </Space>
    </Radio.Group>
  );
};
