import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SpecificBoarder } from 'src/gql/queries';
import { BoarderId } from 'src/shared/types/common';
import { UseOnMutation } from '..';

export const RESEND_CREDENTIALS_TO_BOARDER = gql`
  mutation ResendCredentialsToBoarder($input: ResendCredentialsToBoarderInput!) {
    resendCredentialsToBoarder(input: $input) {
      boarderId
    }
  }
`;

type UseResendCredentialsToBoarderArgs = {
  boarder?: SpecificBoarder;
}

export const useResendCredentialsToBoarder = (
  { boarder } : UseResendCredentialsToBoarderArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [resendCredentialsToBoarder, { loading }] = useMutation<
    unknown, { input: BoarderId }
  >(RESEND_CREDENTIALS_TO_BOARDER);

  const onResendCredentialsToBoarder = useCallback(async () => {
    if (!boarder?.boarderId) {
      return notification.error({ message: t('Email with credentials has not been sent successfully') });
    }
    try {
      await resendCredentialsToBoarder({
        variables: {
          input: {
            boarderId: boarder?.boarderId,
          },
        },
      });

      return notification.success({ message: t('Email with credentials has been sent successfully') });
    } catch (error) {
      return notification.error({ message: t('Email with credentials has not been sent successfully') });
    }
  }, [boarder]);

  return [onResendCredentialsToBoarder, loading];
};
