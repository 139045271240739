import dayjs from 'dayjs';
import { RoomRentalBrief } from 'src/gql/queries';

export const getRoomIdsRentedInCurrentWeek = (
  roomRentals: RoomRentalBrief[] | undefined,
): string[] => {
  if (!roomRentals) {
    return [];
  }

  const startOfWeek = dayjs().endOf('week').day(-7); // sunday 23:59:59 of last week
  const endOfWeek = dayjs().endOf('week'); // sunday 23:59:59 of this week

  const roomsRentedInCurrentWeek = roomRentals.filter((roomRental) => {
    const roomRentalStart = dayjs(roomRental.startsAt);
    const roomRentalEnd = dayjs(roomRental.endsAt);

    return roomRentalStart.isBefore(endOfWeek) && roomRentalEnd.isAfter(startOfWeek);
  });

  const roomIdsRentedInCurrentWeek = roomsRentedInCurrentWeek.flatMap(({ roomIds }) => roomIds);
  const roomIdsRentedInCurrentWeekSet = new Set(roomIdsRentedInCurrentWeek);

  return Array.from(roomIdsRentedInCurrentWeekSet);
};
