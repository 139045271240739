import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetAllParentsBriefResponse } from 'src/gql/queries';
import { FormProps } from '.';
import {
  ContactDetailsFormItems,
  ContactDetailsFormValues,
  FormListItem,
  RegisteredParentsFormItem,
} from './items';

export type BoarderParentsFormFields = {
  registeredParentIds: string[]
  unregisteredParents: ContactDetailsFormValues[]
}

export const BoarderParentsForm = ({
  disabled,
  form,
  parents,
}: FormProps<BoarderParentsFormFields> & Partial<GetAllParentsBriefResponse>) => {
  const { t } = useTranslation();

  return (
    <Form form={form} layout="vertical" className="boarder-tab-content-wrapper">
      <RegisteredParentsFormItem parents={parents} disabled={disabled} />

      <FormListItem
        renderItem={ContactDetailsFormItems}
        otherProps={{ showAddress: true }}
        addItemText={t('Add parent')}
        formTitle={t('Unregistered parents')}
        name="unregisteredParents"
        removeItemText={t('Remove parent')}
        disabled={disabled}
      />

    </Form>
  );
};
