import {
  Row,
  Col,
  Form,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { sorterByFullName } from 'src/components/tables';
import { STYLE } from 'src/constants';
import { BoarderBrief } from 'src/gql/queries';
import { getFullName, itemContainsFullName } from 'src/shared/utils';
import { FormItemCommonProps } from '.';
import { boardersRule } from '../rules';

type BoardersFormItemProps = FormItemCommonProps & {
  allBoardersBriefMap: Map<string, BoarderBrief> | undefined
}

export const BoardersFormItem = ({
  allBoardersBriefMap,
  disabled,
  required,
}: BoardersFormItemProps) => {
  const { t } = useTranslation();
  const options = allBoardersBriefMap ? Array.from(allBoardersBriefMap)
    .sort(([, a], [, b]) => sorterByFullName({ lastNameFirst: false })(a, b))
    .map(
      ([boarderId, boarderBrief]) => ({
        value: boarderId,
        label: getFullName({ item: boarderBrief }),
      }),
    ) : [];

  return (
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <Form.Item
          label={t('Boarders')}
          name="boarderIds"
          rules={[boardersRule({ required: Boolean(required) })]}
          required={required}
        >
          <Select
            mode="multiple"
            allowClear
            disabled={disabled}
            filterOption={(inputValue, option) => {
              if (!inputValue) return true;
              if (typeof option?.value !== 'string') return false;
              const item = allBoardersBriefMap?.get(option.value);
              if (!item) return false;

              return itemContainsFullName({
                item,
                searchText: inputValue,
              });
            }}
            options={options}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
