import { FullName, getFullName } from './misc';

const LOCALE = 'pl';

export const itemContainsFullName = <T>({
  item,
  searchText,
}: {
  item: (T & FullName),
  searchText: string,
}): boolean => {
  const firstAndLastName = getFullName({ item })
    .toLocaleLowerCase(LOCALE);
  const lastAndFirstName = getFullName({ item, lastNameFirst: true })
    .toLocaleLowerCase(LOCALE);
  const searchTextLowerCased = searchText.toLocaleLowerCase(LOCALE);

  return firstAndLastName.includes(searchTextLowerCased)
    || lastAndFirstName.includes(searchTextLowerCased);
};

export const filterByFullName = <T>({
  array, searchText,
}: {
  array?: (T & FullName)[],
  searchText: string,
}): (T & FullName)[] => (
    array
      ? array.filter((item) => itemContainsFullName({ item, searchText }))
      : []
  );
