import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { STYLE } from 'src/constants';
import { useGetSpecificReport } from 'src/gql/queries';
import { ReportsTableData } from '../../reports';

export const downloadReportColumn = (t: TFunction): ColumnType<ReportsTableData> => ({
  key: 'downloadReport',
  render: (_, item) => {
    const [loading, setLoading] = useState(false);
    const fetchReport = useGetSpecificReport();

    return loading
      ? <LoadingOutlined style={{ marginLeft: STYLE.BASE_MARGIN }} />
      : (
        <Button
          type="link"
          icon={<FilePdfOutlined />}
          onClick={async () => {
            setLoading(true);
            try {
              const { data } = await fetchReport({ key: item.key });
              window.open(data.report.url);
            } catch (err) {
              notification.error({ message: t('Unable to fetch report') });
            }
            setLoading(false);
          }}
        >
          {t('Download')}
        </Button>
      );
  },
});
