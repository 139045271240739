import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useGetDormitoryNotesHistory } from 'src/gql/queries';
import { FileAddFilled } from '@ant-design/icons';
import {
  ModalAction,
  ModalState,
  NoteModal,
  NoteModalStateData,
  NotesHistoryTable,
  RangePicker,
} from 'src/components';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { ExtendedNote } from 'src/shared/types/api';
import { STYLE } from 'src/constants';

export const DormitoryNotesHistoryTab = () => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<RangeValue<Dayjs>>(
    [dayjs().startOf('week'), dayjs().endOf('week')],
  );

  const [
    modalState,
    setModalState,
  ] = useState<ModalState<NoteModalStateData>>({ visible: false });

  const [notesHistory, loading] = useGetDormitoryNotesHistory(dateRange);

  const onUpdateNote = (note: ExtendedNote) => {
    setModalState({
      visible: true,
      action: ModalAction.Update,
      data: {
        boarderIds: note.boarder?.boarderId ? [note.boarder.boarderId] : [],
        category: note.category,
        description: note.description,
        title: note.title,
        noteId: note.noteId,
      },
    });
  };

  return (
    <>
      <div className="sub-actions-wrapper">
        <Button
          type="primary"
          size="large"
          onClick={() => { setModalState({ visible: true, action: ModalAction.Create }); }}
        >
          <FileAddFilled />
          {t('Add note')}
        </Button>
      </div>
      <div className="dormitory-tab-content-wrapper" style={{ marginBottom: STYLE.BASE_MARGIN }}>
        <RangePicker
          value={dateRange}
          onChange={(values) => setDateRange(values)}
          allowClear={false}
          allowEmpty={[false, false]}
        />
      </div>
      <NotesHistoryTable
        notes={notesHistory}
        loading={loading}
        onUpdateNote={onUpdateNote}
      />
      {modalState.visible && (
        <NoteModal
          modalState={modalState}
          setModalState={setModalState}
          withDormitoryCategory
        />
      )}
    </>
  );
};
