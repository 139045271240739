import { Button, Popconfirm } from 'antd';
import { t } from 'i18next';
import { useUserContext } from 'src/context';
import { useRemoveBoarderFromArchive, useRestoreBoarderFromArchive } from 'src/gql/mutations';
import { SpecificBoarder } from 'src/gql/queries';
import { GqlMutation } from 'src/shared/types/common';

type BoarderGeneralInfoTabHeaderArchiveProps = {
  boarder?: SpecificBoarder;
}

export const BoarderGeneralInfoTabHeaderArchive = (
  { boarder }: BoarderGeneralInfoTabHeaderArchiveProps,
) => {
  const user = useUserContext();

  const [
    restoreBoarderFromArchive,
    restoreBoarderFromArchiveLoading,
  ] = useRestoreBoarderFromArchive({ boarder });

  const [
    removeBoarderFromArchive,
    removeBoarderFromArchiveLoading,
  ] = useRemoveBoarderFromArchive({ boarder });

  return (
    <div className="sub-actions-wrapper">

      {user?.isAllowed(GqlMutation.RestoreBoarderFromArchive) && (
        <Popconfirm
          title={t('Restore from archive tooltip')}
          okText={t('Yes')}
          onConfirm={restoreBoarderFromArchive}
          okButtonProps={{ loading: restoreBoarderFromArchiveLoading }}
        >
          <Button
            type="primary"
            size="small"
            className="header-item"
          >
            {t('Restore from archive')}
          </Button>
        </Popconfirm>
      )}

      {user?.isAllowed(GqlMutation.RemoveBoarderFromArchive) && (
      <Popconfirm
        title={t('Remove boarder from archive tooltip')}
        okText={t('Yes')}
        onConfirm={removeBoarderFromArchive}
        okButtonProps={{ loading: removeBoarderFromArchiveLoading, danger: true }}
      >
        <Button
          type="default"
          size="small"
          className="header-item"
        >
          {t('Remove permanently')}
        </Button>
      </Popconfirm>
      )}

    </div>
  );
};
