import {
  Col, Form, Row, Select,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { groupIdsRule } from '../rules';

type GroupIdsFormItemProps = FormItemCommonProps & ({
  mode: 'tags';
  options?: undefined;
} | {
  mode: 'multiple';
  options: DefaultOptionType[] | undefined;
})

export const GroupIdsFormItem = ({ disabled, mode, options }: GroupIdsFormItemProps) => {
  const { t } = useTranslation();

  return (
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <Form.Item
          label={t('Group IDs')}
          name="groupIds"
          rules={[groupIdsRule()]}
        >
          <Select
            mode={mode}
            tokenSeparators={[',', ' ']}
            notFoundContent={null}
            disabled={disabled}
            options={options}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
