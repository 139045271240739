import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { GroupIdInputFormItem, PersonalIdentityNumberFormItem, RoomIdFormItem } from './forms/items';

type RecruitmentRegisteredBoarderFormItemsProps = {
  disabled?: boolean;
}

export const RecruitmentRegisteredBoarderFormItems = ({
  disabled,
}: RecruitmentRegisteredBoarderFormItemsProps) => (
  <Row gutter={STYLE.BASE_MARGIN}>
    <Col xs={24} sm={8}>
      <PersonalIdentityNumberFormItem disabled={disabled} />
    </Col>
    <Col xs={24} sm={8}>
      <GroupIdInputFormItem disabled={disabled} />
    </Col>
    <Col xs={24} sm={8}>
      <RoomIdFormItem disabled={disabled} />
    </Col>
  </Row>
);
