import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { roomIdRule } from '../rules';

export const RoomIdFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Room ID')}
      name="roomId"
      rules={[roomIdRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
