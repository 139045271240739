/**
 * @deprecated in favor of `tokens
 */
export const STYLE = {
  BASE_MARGIN: 16,
  PRIMARY_FONT_SIZE: 14,
  SECONDARY_FONT_SIZE: 12,
  LARGE_ITEM_HEIGHT: 40,
  HEADER_HEIGHT: 72,
  COMMON_RADIUS: 4,
  ACTIONS_COLUMN_WIDTH_2: 72,
  ACTIONS_COLUMN_WIDTH_2_MOBILE: 48,
  ACTIONS_COLUMN_WIDTH_3: 96,
  ACTIONS_COLUMN_WIDTH_3_MOBILE: 72,
};
