import { Table } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { DELETE_BOARDER_NOTE, DELETE_DORMITORY_NOTE } from 'src/gql/mutations';
import { GET_DORMITORY_NOTES_HISTORY } from 'src/gql/queries';
import { useIsMobile } from 'src/hooks';
import { DeleteBoarderNoteInput, DeleteDormitoryNoteInput, ExtendedNote } from 'src/shared/types/api';
import { GqlMutation } from 'src/shared/types/common';
import {
  actionsColumn,
  boarderDormitoryInfoColumn,
  creationInfoColumn,
  noteCategoryColumn,
  titleColumn,
  updateInfoColumn,
} from './utils';

type NotesHistoryTableProps = {
  notes?: ExtendedNote[];
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  onUpdateNote: (note: ExtendedNote) => void,
}

export const NotesHistoryTable = ({
  loading,
  notes,
  onUpdateNote,
}: NotesHistoryTableProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isMobile = useIsMobile();

  const columnsMemo = useMemo(() => {
    const deleteDisabledBy = () => !user?.isAllowed(GqlMutation.DeleteDormitoryNote)
      || !user.isAllowed(GqlMutation.DeleteBoarderNote);
    const updateDisabledBy = () => !user?.isAllowed(GqlMutation.UpdateDormitoryNote)
      || !user.isAllowed(GqlMutation.UpdateBoarderNote);
    const removeMutationBy = (record: ExtendedNote) => (record.boarder
      ? DELETE_BOARDER_NOTE
      : DELETE_DORMITORY_NOTE);
    const recordToRemoveInput = (record: ExtendedNote) => (record.boarder ? {
      boarderId: record.boarder.boarderId,
      note: { noteId: record.noteId, category: record.category },
    } : { noteId: record.noteId });

    return {
      boarderDormitoryInfoColumn: boarderDormitoryInfoColumn<ExtendedNote>(t),
      titleColumn: titleColumn<ExtendedNote>(t),
      noteCategoryColumn: noteCategoryColumn<ExtendedNote>(t),
      creationInfoColumn: creationInfoColumn<ExtendedNote>(t),
      updateInfoColumn: updateInfoColumn<ExtendedNote>(t),
      actionsColumn: actionsColumn<
        ExtendedNote,
        DeleteBoarderNoteInput | DeleteDormitoryNoteInput
      >({
        t,
        rendererArgs: {
          update: {
            onUpdate: onUpdateNote,
            disabledBy: updateDisabledBy,
          },
          remove: {
            recordToInput: recordToRemoveInput,
            refetchQueries: [GET_DORMITORY_NOTES_HISTORY],
            mutationBy: removeMutationBy,
            messageOnSuccess: t('Note has been removed successfully'),
            messageOnError: t('Note has not been removed successfully'),
            disabledBy: deleteDisabledBy,
          },
        },
        isMobile,
      }),
    };
  }, [t, user, isMobile]);

  // TODO: useNotesHistoryTableColumns (BUR-194)
  const columns = isMobile
    ? [
      columnsMemo.boarderDormitoryInfoColumn,
      columnsMemo.titleColumn,
      columnsMemo.actionsColumn,
    ]
    : [
      columnsMemo.boarderDormitoryInfoColumn,
      columnsMemo.titleColumn,
      columnsMemo.noteCategoryColumn,
      columnsMemo.creationInfoColumn,
      columnsMemo.updateInfoColumn,
      columnsMemo.actionsColumn,
    ];

  return (
    <Table
      dataSource={notes}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 10,
      }}
      size="middle"
      columns={columns}
      rowKey="noteId"
      expandable={{
        rowExpandable: (note) => !!note.description.length,
        expandedRowRender: (note) => (note.description),
      }}
      loading={loading}
    />
  );
};
