import { Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { GqlMutation } from 'src/shared/types/common';
import { FormProps } from '.';
import {
  AccountNumberFormItem,
  AddressFormItems,
  DormitoryNameFormItem,
  FormListItem,
  GroupIdsFormItem,
  LabeledEmailFormItem,
  LabeledPhoneNumberFormItem,
} from './items';

export type DormitoryDetailsFormFields = {
  name: string;
  accountNumber: string;
  postCode: string;
  streetAddress: string;
  city: string;
  emails: { label: string; email: string}[];
  phoneNumbers: { label: string; phoneNumber: string}[];
  groupIds: string[];
}

export const DormitoryDetailsForm = ({ form }: FormProps<DormitoryDetailsFormFields>) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isUpdateForbidden = !user?.isAllowed(GqlMutation.UpdateDormitoryDetails);

  return (
    <Form form={form} layout="vertical">
      <DormitoryNameFormItem disabled={isUpdateForbidden} />

      <AccountNumberFormItem disabled={isUpdateForbidden} />

      <AddressFormItems disabled={isUpdateForbidden} required />

      <FormListItem
        renderItem={LabeledEmailFormItem}
        addItemText={t('Add email')}
        formTitle={t('Emails')}
        name="emails"
        removeItemText={t('Remove email')}
        otherProps={{ disabled: isUpdateForbidden }}
        disabled={isUpdateForbidden}
      />

      <FormListItem
        renderItem={LabeledPhoneNumberFormItem}
        addItemText={t('Add phone number')}
        formTitle={t('Phone numbers')}
        name="phoneNumbers"
        removeItemText={t('Remove phone number')}
        otherProps={{ disabled: isUpdateForbidden }}
        disabled={isUpdateForbidden}
      />

      {!isUpdateForbidden && <Divider plain />}

      <GroupIdsFormItem disabled={isUpdateForbidden} mode="tags" />
    </Form>
  );
};
