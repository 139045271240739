import { RoomRentalForm } from 'src/components';
import {
  UseOnMutation, UseOnMutationArgs, useCreateRoomRental, useUpdateRoomRental,
} from 'src/gql/mutations';

type UseOnRoomRentalModalSubmitArgs = UseOnMutationArgs<RoomRentalForm> & {
  roomRentalId: string | undefined;
}

export const useOnRoomRentalModalSubmit = (
  args: UseOnRoomRentalModalSubmitArgs,
): UseOnMutation => {
  const [create, createLoading] = useCreateRoomRental(args);
  const [update, updateLoading] = useUpdateRoomRental(args);

  const onSubmit = async () => {
    const action = args.roomRentalId ? update : create;
    await action();
  };

  return [onSubmit, createLoading || updateLoading];
};
