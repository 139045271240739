import { ClosedQuestionRadioOption } from '../ClosedQuestionRadio';

/* eslint-disable */ // eslint doesn't handle const enums properly
export const enum ClauseOption {
  RecruitmentClause = 'recruitmentClause',
  DormitoryClause = 'dormitoryClause',
}

export const enum ExistingBoarderConfirmationOption {
  BoarderHasAccess = 'boarderHasAccess',
  BoarderInfoIsUpToDate = 'boarderInfoIsUpToDate',
  ParentsHaveAccess = 'parentsHaveAccess',
  ParentsContactDetailsAreUpToDate = 'parentsContactDetailsAreUpToDate',
}
/* eslint-enable */

export type RecruitmentStartStepFormFields = {
  clauses?: ClauseOption[];
  existingBoarder?: ClosedQuestionRadioOption;
  existingBoarderConfirmationOptions?: ExistingBoarderConfirmationOption[]
}
