import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { useUserContext } from 'src/context';
import { UpdateBoarderSubscriptionInput } from 'src/shared/types/api';
import { GqlMutation, Subscription } from 'src/shared/types/common';
import { numberToPLN } from 'src/utils';
import { ModalAction, ModalState } from './modals';

type BoarderPaymentsInfoProps = {
  boarderId?: string;
  balance?: number;
  subscription?: Subscription
  setModalState: Dispatch<SetStateAction<ModalState<UpdateBoarderSubscriptionInput>>>;
  archive?: boolean;
}

export const BoarderPaymentsInfo = ({
  boarderId,
  balance,
  subscription,
  setModalState,
  archive,
}: BoarderPaymentsInfoProps) => {
  const { t } = useTranslation();
  const user = useUserContext();

  return (
    <Descriptions bordered className="boarder-tab-content-wrapper" column={2} layout="vertical" style={{ marginBottom: STYLE.BASE_MARGIN }}>
      <Descriptions.Item label={t('Balance')}>
        {balance !== undefined && numberToPLN(balance)}
        {balance !== undefined && balance < 0 && <ExclamationCircleOutlined style={{ marginLeft: STYLE.BASE_MARGIN / 2 }} className="red" /> }
      </Descriptions.Item>
      <Descriptions.Item label={t('Subscription')}>
        {subscription && (
        <>
          {t(subscription)}
          {user?.isAllowed(GqlMutation.UpdateBoarderSubscription) && !archive && (
            <Button
              type="default"
              size="small"
              style={{ marginLeft: STYLE.BASE_MARGIN / 2 }}
              onClick={() => boarderId && setModalState({
                visible: true,
                action: ModalAction.Update,
                data: {
                  boarderId,
                  subscription,
                },
              })}
            >
              {t('Edit')}
            </Button>
          )}
        </>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
