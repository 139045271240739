import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { otherInfoRule } from '../rules';

export const OtherInfoFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Other info')}
      name="otherInfo"
      rules={[otherInfoRule()]}
    >
      <Input.TextArea showCount rows={5} maxLength={1024} />
    </Form.Item>
  );
};
