import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDormitoryGroupOptions } from 'src/hooks';
import { FormItemCommonProps } from '.';
import { groupIdRule } from '../rules';

export const GroupIdFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();
  const [options, loading] = useDormitoryGroupOptions();

  return (
    <Form.Item
      label={t('Group ID')}
      name="groupId"
      rules={[groupIdRule()]}
    >
      <Select
        allowClear
        disabled={disabled || loading}
        loading={loading}
        placeholder={t('Group ID')}
        options={options}
      />
    </Form.Item>
  );
};
