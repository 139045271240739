import { List, Table } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { GET_ROOM_RENTALS } from 'src/gql/queries';
import { useIsMobile } from 'src/hooks';
import { DeleteRoomRentalInput } from 'src/shared/types/api';
import { GqlMutation, RoomRental } from 'src/shared/types/common';
import { DELETE_ROOM_RENTAL } from 'src/gql/mutations';
import {
  actionsColumn, dateRangeColumn, groupNameColumn, peopleCountColumn,
} from './utils';

type RoomRentalsTableProps = {
  roomRentals?: RoomRental[];
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  onUpdate: (roomRental: RoomRental) => void,
}

type RoomRentalListInfoProps = {
  roomRental: RoomRental;
}

const RoomRentalListInfo = ({ roomRental }: RoomRentalListInfoProps) => {
  const {
    city,
    roomIds,
    contactDetails,
    otherInfo,
  } = roomRental;
  const { t } = useTranslation();

  return (
    <List
      itemLayout="horizontal"
    >
      <List.Item key="roomIds">
        <List.Item.Meta
          title={t('Room IDs')}
          description={roomIds.join(', ')}
        />
      </List.Item>

      <List.Item key="city">
        <List.Item.Meta
          title={t('City')}
          description={city}
        />
      </List.Item>

      <List.Item key="contactDetails">
        <List.Item.Meta
          title={t('Contact details')}
          description={contactDetails || '---'}
        />
      </List.Item>

      <List.Item key="otherInfo">
        <List.Item.Meta
          title={t('Other info')}
          description={otherInfo || '---'}
        />
      </List.Item>
    </List>
  );
};

const expandedRowRender = (
  roomRental: RoomRental,
) => <RoomRentalListInfo roomRental={roomRental} />;

export const RoomRentalsTable = ({
  loading,
  roomRentals,
  onUpdate,
}: RoomRentalsTableProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isMobile = useIsMobile();

  const columns = useMemo(() => {
    const deleteDisabledBy = () => !user?.isAllowed(GqlMutation.DeleteRoomRental);
    const updateDisabledBy = () => !user?.isAllowed(GqlMutation.UpdateRoomRental);
    const recordToRemoveInput = (record: RoomRental) => ({ roomRentalId: record.roomRentalId });

    return [
      groupNameColumn<RoomRental>(t),
      dateRangeColumn<RoomRental>(t),
      peopleCountColumn<RoomRental>(t),
      actionsColumn<
        RoomRental,
        DeleteRoomRentalInput
      >({
        t,
        rendererArgs: {
          update: {
            onUpdate,
            disabledBy: updateDisabledBy,
          },
          remove: {
            recordToInput: recordToRemoveInput,
            refetchQueries: [GET_ROOM_RENTALS],
            mutation: DELETE_ROOM_RENTAL,
            messageOnSuccess: t('Room rental has been removed successfully'),
            messageOnError: t('Room rental has not been removed successfully'),
            disabledBy: deleteDisabledBy,
          },
        },
        isMobile,
      }),
    ];
  }, [t, user, isMobile]);

  return (
    <Table
      dataSource={roomRentals}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 50,
      }}
      size="middle"
      columns={columns}
      rowKey="roomRentalId"
      expandable={{
        expandedRowRender,
      }}
      loading={loading}
    />
  );
};
