import { Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbItem,
  useBoardersTableColumns,
  ViewHeader,
  ViewHeaderSearch,
} from 'src/components';
import { ROUTE } from 'src/constants';
import { useUserContext } from 'src/context';
import { BoarderArchive, useGetAllBoardersArchive } from 'src/gql/queries';
import { GqlQuery } from 'src/shared/types/common';
import { filterByFullName } from 'src/shared/utils';
import { defaultRoute, navigateToItem } from 'src/utils';

export const BoardersArchiveView = () => {
  const { t } = useTranslation();
  const [allBoarders, setAllBoarders] = useState<BoarderArchive[]>();
  const [filteredBoarders, setFilteredBoarders] = useState<BoarderArchive[]>();
  const navigate = useNavigate();
  const user = useUserContext();

  const [boarderArchives, loading] = useGetAllBoardersArchive();

  useEffect(() => {
    setAllBoarders(boarderArchives);
    setFilteredBoarders(boarderArchives);
  }, [boarderArchives]);

  const boardersTableColumns = useBoardersTableColumns({ archiveView: true });

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.BOARDERS_ARCHIVE, title: t('Boarders archive') },
  ];

  const HeaderFilter = useMemo(() => (
    <ViewHeaderSearch
      onSearch={(searchText: string) => setFilteredBoarders(
        filterByFullName({ searchText, array: allBoarders }),
      )}
    />
  ), [allBoarders]);

  if (user && !user?.isAllowed(GqlQuery.GetBoardersArchive)) {
    navigate(defaultRoute(user));
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
        Filter={HeaderFilter}
        twoRows
      />
      <Table
        loading={loading}
        dataSource={filteredBoarders}
        size="middle"
        columns={boardersTableColumns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
        rowKey="boarderId"
        onRow={navigateToItem<BoarderArchive>({
          navigate,
          route: ROUTE.BOARDERS_ARCHIVE,
          rowKey: 'boarderId',
        })}
      />
    </>
  );
};
