export const isoStringDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export const isoStringDateRegex = /^\d{4}-\d{2}-\d{2}$/;

export const polishSimpleNameRegex = /^[\s\p{L}]{2,31}$/u;

export const polishExtendedNameRegex = /^[/.\d\s\p{L}()-]{2,127}$/u;

export const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const cardIdRegex = /^[a-z0-9_]{0,64}$/i;

export const polishPostCodeRegex = /^\d{2}-\d{3}$/;

export const accountNumberRegex = /^\d{2}[ ]?\d{4}[ ]?\d{4}[ ]?\d{4}[ ]?\d{4}[ ]?\d{4}[ ]?\d{4}$/;

export const phoneNumberRegex = /^\+\d{11,15}$/;
