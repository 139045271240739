import {
  Button,
  Form,
  Spin,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { useUpdateDormitoryFees } from 'src/gql/mutations';
import { useGetDormitoryFees } from 'src/gql/queries';
import { GqlMutation, DormitoryFees as DormitoryFeesType } from 'src/shared/types/common';
import { DormitoryFeesForm } from './forms';

export const DormitoryFees = () => {
  const [form] = Form.useForm<DormitoryFeesType>();
  const { t } = useTranslation();
  const user = useUserContext();

  const [dormitoryFees, getDormitoryFeesLoading] = useGetDormitoryFees();
  const [
    updateDormitoryFees,
    updateDormitoryFeesLoading,
  ] = useUpdateDormitoryFees({ form });

  useEffect(() => {
    if (dormitoryFees) {
      form.setFieldsValue(dormitoryFees);
    }
  }, [dormitoryFees]);

  return (
    <Spin spinning={getDormitoryFeesLoading}>
      <DormitoryFeesForm form={form} />
      {user?.isAllowed(GqlMutation.UpdateDormitoryFees) && (
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={updateDormitoryFeesLoading}
          onClick={updateDormitoryFees}
          className="vertical-base-margin"
          block
        >
          {t('Save')}
        </Button>
      )}
    </Spin>
  );
};
