import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_REPORTS } from 'src/gql/queries';
import { UseOnMutation } from '..';

export const GENERATE_REPORT = gql`
  mutation GenerateReport {
    generateReport
  }
`;

export const useGenerateReport = (): UseOnMutation => {
  const { t } = useTranslation();
  const [generateReport, { loading }] = useMutation(
    GENERATE_REPORT,
    { refetchQueries: [GET_REPORTS] },
  );

  const onGenerateReport = useCallback(async () => {
    try {
      const { data: response } = await generateReport();

      if (!response.generateReport) {
        return notification.error({ message: t('Report has not been generated successfully') });
      }

      return notification.success({ message: t('Report has been generated successfully') });
    } catch (error) {
      return notification.error({ message: t('Report has not been generated successfully') });
    }
  }, []);

  return [onGenerateReport, loading];
};
