import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { amountRule } from '../rules';

type AmountFormItemProps = {
  isAmountNegative: boolean
}

export const AmountFormItem = ({ isAmountNegative }: AmountFormItemProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Amount')}
      name="amount"
      rules={[amountRule()]}
    >
      <Input type="number" prefix={isAmountNegative && '-'} suffix={t('PLN')} min={0} />
    </Form.Item>
  );
};
