import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { descriptionRule } from '../rules';

export const DescriptionFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Description')}
      name="description"
      rules={[descriptionRule()]}
    >
      <Input.TextArea showCount rows={10} maxLength={1000} />
    </Form.Item>
  );
};
