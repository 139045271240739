import { Alert, Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateBoarderPaymentModal,
  CreateBoarderPaymentModalProps,
  RecruitmentEvents,
  RecruitmentParents,
  RecruitmentSummaryQuestionnaire,
  RecruitmentSummaryUnregisteredBoarder,
  useRecruitmentBoarderTableColumns,
} from 'src/components';
import { STYLE } from 'src/constants';
import {
  GET_RECRUITMENT_BOARDERS,
  RecruitmentBoarderRequestDataSource,
  useGetRecruitmentBoarders,
} from 'src/gql/queries/recruitment';
import { boarderRequestToQuestionnaireForm } from 'src/utils';

export const DormitoryRecruitmentTab = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    createPaymentModalProps,
    setCreatePaymentModalProps,
  ] = useState<Pick<CreateBoarderPaymentModalProps, 'boarderId' | 'isRecruitmentBoarder'>>({});

  const [recruitmentBoarders, loading] = useGetRecruitmentBoarders();
  const recruitmentBoarderTableColumns = useRecruitmentBoarderTableColumns({
    setIsModalVisible,
    setPaymentModalProps: setCreatePaymentModalProps,
  });

  return (
    <>
      <Alert
        style={{ marginBottom: STYLE.BASE_MARGIN }}
        message={t('Tip')}
        description={t('Recruitment tip')}
        type="info"
        showIcon
      />
      <Table
        loading={loading}
        dataSource={recruitmentBoarders}
        size="middle"
        columns={recruitmentBoarderTableColumns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
        rowKey="boarderRecruitmentId"
        expandable={{
        // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (boarderRequest: RecruitmentBoarderRequestDataSource) => (
            <>
              <RecruitmentEvents events={boarderRequest.events} />
              <RecruitmentParents parents={boarderRequest.boarder.parents} />
              <RecruitmentSummaryQuestionnaire
                values={boarderRequestToQuestionnaireForm(boarderRequest)}
              />
              <RecruitmentSummaryUnregisteredBoarder />
            </>
          ),
        }}
      />
      <CreateBoarderPaymentModal
        {...createPaymentModalProps}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        refetchQueries={[GET_RECRUITMENT_BOARDERS]}
      />
    </>
  );
};
