import { useState, useEffect } from 'react';

type WindowDimensions = {
  width: number;
  height: number;
}

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const useWindowDimensions = (
  { memoThreshold } = { memoThreshold: 100 },
): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions((prevWindowDimensionsState) => (
        Math.abs(prevWindowDimensionsState.width - getWindowDimensions().width) > memoThreshold
          ? getWindowDimensions()
          : prevWindowDimensionsState
      ));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
