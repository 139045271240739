import { Button, Spin } from 'antd';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Calendar,
  CalendarDormitoryDay,
  CalendarMonthSwitcher,
  CalendarNotAvailable,
  TooSmallScreenWarning,
} from 'src/components';
import { useUserContext } from 'src/context';
import { useUpdateDormitoryYear } from 'src/gql/mutations';
import { useGetDormitoryYear } from 'src/gql/queries';
import { useIsMobile } from 'src/hooks';
import { DormitoryYear, GqlMutation } from 'src/shared/types/common';
import { parseDormitoryYear } from './utils';

export const DormitoryCalendarTab = () => {
  const { t } = useTranslation();
  const [dormitoryYear, setDormitoryYear] = useState<DormitoryYear>();
  const user = useUserContext();
  const isMobile = useIsMobile();
  const today = new Date();
  const isHoliday = today.getMonth() === 6 || (today.getMonth() === 7 && today.getDate() < 20);

  const [
    getDormitoryYearData,
    getDormitoryYearLoading,
  ] = useGetDormitoryYear({ onError: () => setDormitoryYear(undefined) });

  const [
    updateDormitoryYear,
    updateDormitoryYearLoading,
  ] = useUpdateDormitoryYear({
    dormitoryYear,
    onSuccess: () => window.scrollTo(0, 0),
  });

  useEffect(() => {
    if (getDormitoryYearData) {
      setDormitoryYear(parseDormitoryYear(getDormitoryYearData));
    }
  }, [getDormitoryYearData]);

  const CalendarDormitoryCellRender = useCallback(CalendarDormitoryDay({
    dormitoryYear,
    setDormitoryYear,
    updateForbidden: !user?.isAllowed(GqlMutation.UpdateDormitoryYear),
  }), [dormitoryYear]);

  if (isHoliday) {
    return <CalendarNotAvailable />;
  }

  if (isMobile) {
    return <TooSmallScreenWarning />;
  }

  return (
    <Spin spinning={getDormitoryYearLoading}>
      <Calendar
        // eslint-disable-next-line react/no-unstable-nested-components
        headerRender={({
          value, onChange,
        }) => <CalendarMonthSwitcher onChange={onChange} value={value} />}
        dateCellRender={CalendarDormitoryCellRender}
      />
      {user?.isAllowed(GqlMutation.UpdateDormitoryYear) && (
        <div className="calendar-month-switcher-wrapper">
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={updateDormitoryYearLoading}
            onClick={updateDormitoryYear}
            block
          >
            {t('Save')}
          </Button>
        </div>
      )}
    </Spin>

  );
};
