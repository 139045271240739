import { DocumentNode, gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BoarderId } from 'src/shared/types/common';
import { UseOnMutation } from '..';

export const TOGGLE_BOARDER_PRESENCE_STATE = gql`
  mutation ToggleBoarderPresenceState($input: ToggleBoarderPresenceStateInput!) {
    toggleBoarderPresenceState(input: $input) {
      boarderId
    }
  }
`;

type UseToggleBoarderPresenceStateArgs = {
  boarderId?: string;
  refetchQueries?: DocumentNode[];
}

export const useToggleBoarderPresenceState = (
  { boarderId, refetchQueries }: UseToggleBoarderPresenceStateArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [toggleBoarderPresenceState, { loading }] = useMutation<
    unknown, { input: BoarderId }
  >(TOGGLE_BOARDER_PRESENCE_STATE, { refetchQueries });

  const onToggleBoarderPresenceState = useCallback(async () => {
    if (!boarderId) {
      return notification.error({ message: t('Boarder presence state has not been toggled successfully') });
    }
    try {
      await toggleBoarderPresenceState({
        variables: {
          input: {
            boarderId,
          },
        },
      });

      return notification.success({ message: t('Boarder presence state has been toggled successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder presence state has not been toggled successfully') });
    }
  }, [boarderId, refetchQueries]);

  return [onToggleBoarderPresenceState, loading];
};
