import { gql, useMutation } from '@apollo/client';
import { FormInstance, notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InitOnlinePaymentFormType, InitOnlinePaymentSuccessMessage } from 'src/components';
import { GET_SPECIFIC_BOARDER_PAYMENTS } from 'src/gql/queries';
import { GqlMutationArgs, InitOnlinePaymentInput } from 'src/shared/types/api';
import { UseOnMutation } from '..';

const INIT_ONLINE_PAYMENT = gql`
  mutation InitOnlinePayment($input: InitOnlinePaymentInput!) {
    initOnlinePayment(input: $input)
  }
`;

type UseInitOnlinePaymentArgs = {
  boarderId?: string;
  form: FormInstance<InitOnlinePaymentFormType>;
  // eslint-disable-next-line no-unused-vars
  onSuccess: (redirectUri: string) => void;
}

export const useInitOnlinePayment = (
  { boarderId, form, onSuccess }: UseInitOnlinePaymentArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [initOnlinePayment, { loading }] = useMutation<
    { initOnlinePayment: string },
    GqlMutationArgs<InitOnlinePaymentInput>
  >(INIT_ONLINE_PAYMENT, { refetchQueries: [GET_SPECIFIC_BOARDER_PAYMENTS] });

  const onInitOnlinePayment = useCallback(async () => {
    const { amount } = await form.validateFields();
    if (!boarderId || !amount) {
      return notification.error({ message: t('Online payment has not been initiated successfully') });
    }

    try {
      const response = await initOnlinePayment({
        variables: {
          input: {
            amount: Number(amount),
            boarderId,
          },
        },
      });

      if (response.data?.initOnlinePayment) {
        notification.success({
          message: <InitOnlinePaymentSuccessMessage href={response.data.initOnlinePayment} />,
          duration: 600,
        });

        return onSuccess(response.data.initOnlinePayment);
      }

      return notification.error({ message: t('Online payment has not been initiated successfully') });
    } catch (error) {
      return notification.error({ message: t('Online payment has not been initiated successfully') });
    }
  }, [form, boarderId, onSuccess]);

  return [onInitOnlinePayment, loading];
};
