import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Spin,
  Tabs,
} from 'antd';
import { FileAddFilled } from '@ant-design/icons';
import { Boarder } from 'src/shared/types/api';
import { useUserContext } from 'src/context';
import {
  useGetSpecificBoarderUpdateGeneralInfoRequests,
} from 'src/gql/queries';
import { GqlMutation, RequestStatus } from 'src/shared/types/common';
import {
  BoarderGeneralInfoFormFields,
  BoarderSchoolInfoFormFields,
  BoarderUpdateGeneralInfoRequestsTable,
  CreateBoarderUpdateGeneralInfoRequestModal,
  CreateBoarderUpdateGeneralInfoRequestModalState,
} from 'src/components';

// eslint-disable-next-line max-len
export type BoarderUpdateGeneralInfoRequestForm = BoarderGeneralInfoFormFields & BoarderSchoolInfoFormFields;

type BoarderUpdateGeneralInfoRequestsTabProps = {
  boarderId?: Boarder['boarderId']
}

export const BoarderUpdateGeneralInfoRequestsTab = (
  { boarderId }: BoarderUpdateGeneralInfoRequestsTabProps,
) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [
    modalState,
    setModalState,
  ] = useState<CreateBoarderUpdateGeneralInfoRequestModalState>({ visible: false });

  const [boarder, loading] = useGetSpecificBoarderUpdateGeneralInfoRequests(boarderId);

  return (
    <>
      <Spin spinning={loading}>
        {(
          user?.isAllowed(GqlMutation.CreateUpdateBoarderGeneralInfoRequest)
        ) && (
        <div className="sub-actions-wrapper">
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (boarder) {
                setModalState({
                  visible: true,
                  boarder,
                });
              }
            }}
          >
            <FileAddFilled />
            {t('Create update general info request')}
          </Button>
        </div>
        )}
        <Tabs centered defaultActiveKey="awaiting" size="small">
          <Tabs.TabPane tab={t('Awaiting')} key="awaiting">
            <BoarderUpdateGeneralInfoRequestsTable
              boarderId={boarderId}
              requests={boarder?.updateGeneralInfoRequests.awaiting}
              requestsStatus={RequestStatus.AWAITING}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Approved')} key="approved">
            <BoarderUpdateGeneralInfoRequestsTable
              boarderId={boarderId}
              requests={boarder?.updateGeneralInfoRequests.approved}
              requestsStatus={RequestStatus.APPROVED}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Declined')} key="declined">
            <BoarderUpdateGeneralInfoRequestsTable
              boarderId={boarderId}
              requests={boarder?.updateGeneralInfoRequests.declined}
              requestsStatus={RequestStatus.DECLINED}
            />
          </Tabs.TabPane>
        </Tabs>
      </Spin>

      {modalState.visible && (
        <CreateBoarderUpdateGeneralInfoRequestModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
