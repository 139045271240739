import { useNavigate } from 'react-router-dom';
import { useRecruitmentCurrentFormContext, useRecruitmentFormContext } from 'src/context';
import { RecruitmentForm, isRecruitmentUnregisteredBoarderDataFormFields } from 'src/components';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRecruitmentStepPathIndex } from './useRecruitmentStepPathIndex';
import { useRecruitmentStepPaths } from './useRecruitmentStepPaths';

type GetSubFormArgs = {
  nextHref?: string;
  recruitmentPaths: string[]
  isUnregisteredBoarderDataForm: boolean;
}

const getSubForm = ({
  nextHref,
  recruitmentPaths,
  isUnregisteredBoarderDataForm,
}: GetSubFormArgs): keyof RecruitmentForm | undefined => {
  if (nextHref === recruitmentPaths[1]) {
    return 'startStep';
  }

  if (nextHref === recruitmentPaths[2]) {
    if (isUnregisteredBoarderDataForm) {
      return 'unregisteredBoarderData';
    }

    return 'registeredBoarderData';
  }

  if (nextHref === recruitmentPaths[3]) {
    return 'questionnaire';
  }

  if (nextHref === recruitmentPaths[4]) {
    return 'agreements';
  }

  return undefined;
};

type UseRecruitmentNavigation = {
  onPrevClick?: () => void;
  onNextClick?: () => void;
};

const useOnPrevClick = (prevHref?: string) => {
  const navigate = useNavigate();

  if (!prevHref) {
    return undefined;
  }

  return () => {
    navigate(prevHref);
  };
};

const useOnNextClick = (nextHref?: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentFormValue = useRecruitmentCurrentFormContext();
  const recruitmentFormValue = useRecruitmentFormContext();
  const recruitmentPaths = useRecruitmentStepPaths();

  if (!nextHref || !currentFormValue?.currentForm) {
    return undefined;
  }

  return async () => {
    const values = await currentFormValue.currentForm?.validateFields();
    const isUnregisteredBoarderDataForm = isRecruitmentUnregisteredBoarderDataFormFields(values);

    if (isUnregisteredBoarderDataForm && !values.parents?.length) {
      return message.error(t('Please enter at least one parent'));
    }

    const subFormKey = getSubForm({ isUnregisteredBoarderDataForm, recruitmentPaths, nextHref });

    if (subFormKey) {
      recruitmentFormValue?.setRecruitmentForm((curr) => ({
        ...curr,
        [subFormKey]: values,
      }));
    }

    window.scrollTo(0, 0);

    return navigate(nextHref);
  };
};

export const useRecruitmentNavigation = (): UseRecruitmentNavigation => {
  const recruitmentPaths = useRecruitmentStepPaths();
  const currentStepPath = useRecruitmentStepPathIndex();

  const prevHref = currentStepPath !== undefined
    ? recruitmentPaths[currentStepPath - 1]
    : undefined;
  const nextHref = currentStepPath !== undefined
    ? recruitmentPaths[currentStepPath + 1]
    : undefined;

  const onPrevClick = useOnPrevClick(prevHref);
  const onNextClick = useOnNextClick(nextHref);

  return {
    onPrevClick,
    onNextClick,
  };
};
