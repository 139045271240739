import { BoarderGeneralInfoFormFields } from './BoarderGeneralInfoForm';
import { ContactDetailsFormValues } from './items';

export type RecruitmentUnregisteredBoarderDataFormFields = BoarderGeneralInfoFormFields & {
  parents: ContactDetailsFormValues[];
}

export function isRecruitmentUnregisteredBoarderDataFormFields(
  value: any,
): value is RecruitmentUnregisteredBoarderDataFormFields {
  if (!value) return false;
  if (
    typeof value.firstName === 'string'
    && typeof value.lastName === 'string'
    && typeof value.phoneNumber === 'string'
    && typeof value.email === 'string'
  ) {
    return true;
  }

  return false;
}
