import { Form, Modal } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useOnRoomRentalModalSubmit } from 'src/hooks';
import { RoomRental } from 'src/shared/types/common';
import { ModalAction, ModalState } from '.';
import { RoomRentalForm, RoomRentalModalForm, roomRentalToForm } from '../forms';

type RoomRentalModalProps = {
  modalState: ModalState<RoomRental>
  setModalState: Dispatch<SetStateAction<ModalState<RoomRental>>>;
}

export const RoomRentalModal = ({
  modalState,
  setModalState,
}: RoomRentalModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<RoomRentalForm>();
  const [onSubmit, loading] = useOnRoomRentalModalSubmit({
    form,
    onSuccess: () => setModalState({ visible: false }),
    roomRentalId: (modalState.visible && modalState.data?.roomRentalId) || undefined,
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue(roomRentalToForm(modalState.data));
    }
  }, [modalState.action]);

  return (
    <Modal
      title={modalState.action === ModalAction.Create ? t('Creating room rental') : t('Edit room rental')}
      visible={modalState.visible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={modalState.action === ModalAction.Create ? t('Add') : t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <RoomRentalModalForm
        form={form}
      />
    </Modal>
  );
};
