import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { GET_ROOM_RENTALS } from 'src/gql/queries';
import { UpdateRoomRentalInput } from 'src/shared/types/api';
import { RoomRentalForm } from 'src/components';
import { UseOnMutationArgs, UseOnMutation } from '..';
import { roomRentalFormToMutationInput } from './createRoomRental';

export const UPDATE_ROOM_RENTAL = gql`
  mutation UpdateRoomRental($input: UpdateRoomRentalInput!) {
    updateRoomRental(input: $input) {
      roomRentalId
    }
  }
`;

type UseUpdateRoomRentalArgs = UseOnMutationArgs<RoomRentalForm> & { roomRentalId?: string };

export const useUpdateRoomRental = (
  { form, roomRentalId, onSuccess }: UseUpdateRoomRentalArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateRoomRental, { loading }] = useMutation<
    unknown, { input: UpdateRoomRentalInput }
  >(UPDATE_ROOM_RENTAL, { refetchQueries: [GET_ROOM_RENTALS] });

  const onUpdateRoomRental = async () => {
    if (!roomRentalId) {
      return notification.error({ message: t('Room rental has not been updated successfully') });
    }

    const values = await form.validateFields();
    try {
      await updateRoomRental({
        variables: {
          input: {
            roomRentalId,
            ...roomRentalFormToMutationInput(values),
          },
        },
      });

      notification.success(
        { message: t('Room rental has been updated successfully') },
      );

      return onSuccess?.();
    } catch (error) {
      return notification.error(
        { message: t('Room rental has not been updated successfully') },
      );
    }
  };

  return [onUpdateRoomRental, loading];
};
