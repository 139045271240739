import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { DELETE_PARENT, RESEND_CREDENTIALS_TO_PARENT } from 'src/gql/mutations';
import { GET_ALL_PARENTS, ParentWithoutBoarders } from 'src/gql/queries';
import { DeleteParentInput } from 'src/shared/types/api';
import { disabledByAccountStatus } from 'src/utils';
import { useUserContext } from 'src/context';
import { GqlMutation } from 'src/shared/types/common';
import {
  accountStatusColumn,
  actionsColumn,
  emailColumn,
  fullNameColumn,
  phoneNumberColumn,
} from './utils/columns';

export const useParentsTableColumns = (
  // eslint-disable-next-line no-unused-vars
  onUpdateParent: (parent: ParentWithoutBoarders) => void, // eslint bug
) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isMobile = useIsMobile();

  const recordToInput = (parent: ParentWithoutBoarders) => ({ parentId: parent.parentId });
  const refetchQueries = [GET_ALL_PARENTS];

  const columnsMemo = useMemo(() => ({
    fullNameColumn: fullNameColumn<ParentWithoutBoarders>(t),
    emailColumn: emailColumn<ParentWithoutBoarders>(t),
    phoneNumberColumn: phoneNumberColumn<ParentWithoutBoarders>(t),
    accountStatusColumn: accountStatusColumn<ParentWithoutBoarders>(t),
    actionsColumn: actionsColumn<ParentWithoutBoarders, DeleteParentInput>({
      t,
      rendererArgs: {
        update: {
          onUpdate: onUpdateParent,
          disabledBy: () => !user?.isAllowed(GqlMutation.UpdateParent),
        },
        remove: {
          recordToInput,
          refetchQueries,
          mutation: DELETE_PARENT,
          messageOnSuccess: t('Parent has been removed successfully'),
          messageOnError: t('Parent has not been removed successfully'),
          disabledBy: () => !user?.isAllowed(GqlMutation.DeleteParent),
        },
        resendCredentials: {
          recordToInput,
          refetchQueries,
          mutation: RESEND_CREDENTIALS_TO_PARENT,
          disabledBy: disabledByAccountStatus,
        },
      },
      isMobile,
    }),
  }), [t, onUpdateParent, isMobile]);

  return isMobile
    ? [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.actionsColumn,
    ]
    : [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.accountStatusColumn,
      columnsMemo.actionsColumn,
    ];
};
