import { useRecruitmentNavigation } from 'src/hooks';
import { STYLE } from 'src/constants';
import { NextStep } from './NextStep';
import { PreviousStep } from './PreviousStep';

const style = {
  marginTop: 2 * STYLE.BASE_MARGIN,
};

export const RecruitmentNavigation = () => {
  const {
    onPrevClick,
    onNextClick,
  } = useRecruitmentNavigation();

  if (!onPrevClick && onNextClick) {
    return (
      <div className="flex-end" style={style}>
        <NextStep onClick={onNextClick} />
      </div>
    );
  }

  if (onPrevClick && !onNextClick) {
    return (
      <div className="flex-start" style={style}>
        <PreviousStep onClick={onPrevClick} />
      </div>
    );
  }

  if (onPrevClick && onNextClick) {
    return (
      <div className="space-between" style={style}>
        <PreviousStep onClick={onPrevClick} />
        <NextStep onClick={onNextClick} />
      </div>
    );
  }

  return null;
};
