import { Dayjs } from 'dayjs';
import { GqlCalendar } from 'src/shared/types/api';
import {
  DormitoryYear, DormitoryDay, MonthNumber, MonthDayNumber,
} from 'src/shared/types/common';
import { isCurrentSchoolYearLeap, monthsMap } from 'src/shared/utils';
import { emptyDormitoryYear } from 'src/shared/utils/meals';

export const parseDormitoryYear = (gqlCalendar: GqlCalendar): DormitoryYear => {
  const dormitoryYear = emptyDormitoryYear({ isLeapYear: isCurrentSchoolYearLeap() });
  Object.entries(gqlCalendar).forEach(([monthName, value]) => {
    if (monthName in monthsMap) {
      dormitoryYear[monthsMap[monthName as keyof GqlCalendar]] = JSON.parse(value);
    }
  });

  return dormitoryYear;
};

export const mutateDormitoryYear = ({
  date,
  newValue,
  oldDormitoryYear,
  updatedKey,
}: {
  oldDormitoryYear: DormitoryYear,
  date: Dayjs,
  updatedKey: keyof DormitoryDay,
  newValue: boolean
}) => {
  const month = date.month() as MonthNumber;
  const day = date.date() as MonthDayNumber;

  const oldDormitoryDay = oldDormitoryYear[month][day];

  return {
    ...oldDormitoryYear,
    [month]: {
      ...oldDormitoryYear[month],
      [day]: updatedKey === 'holiday' && newValue ? {
        holiday: true,
        breakfast: false,
        lunch: false,
        dinner: false,
      } : {
        ...oldDormitoryDay,
        [updatedKey]: newValue,
      },
    },
  };
};
