import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { STYLE } from 'src/constants';
import { Boarder } from 'src/shared/types/api';
import { SchoolKind } from 'src/shared/types/common';
import { MinMaxRange } from 'src/utils';
import { FormProps } from '.';
import {
  ClassNumberFormItem,
  SchoolKindFormItem,
  schoolKindToClassesRange,
  SchoolNameFormItem,
} from './items';

export type BoarderSchoolInfoFormFields = {
  schoolName?: string;
  schoolKind?: SchoolKind;
  classNumber?: string
}

type BoarderSchoolInfoFormProps = FormProps<BoarderSchoolInfoFormFields> &
  { boarder?: Pick<Boarder, 'schoolDetails'> }

export const BoarderSchoolInfoForm = ({ boarder, form, disabled }: BoarderSchoolInfoFormProps) => {
  const [
    classNumberRange,
    setClassNumberRange,
  ] = useState<MinMaxRange>({ min: 0, max: Number.MAX_SAFE_INTEGER });

  useEffect(() => {
    if (boarder?.schoolDetails.schoolKind) {
      setClassNumberRange(schoolKindToClassesRange(boarder.schoolDetails.schoolKind));
    }
  }, [boarder?.schoolDetails.schoolKind]);

  return (
    <Form form={form} layout="vertical" className="boarder-tab-content-wrapper">
      <SchoolNameFormItem disabled={disabled} />
      <Row gutter={STYLE.BASE_MARGIN}>
        <Col xs={24} sm={12}>
          <SchoolKindFormItem
            setClassNumberRange={setClassNumberRange}
            disabled={disabled}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ClassNumberFormItem range={classNumberRange} disabled={disabled} />
        </Col>
      </Row>
    </Form>
  );
};
