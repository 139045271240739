import { Form } from 'antd';
import { NoteCategory } from 'src/shared/types/common';
import { BoarderBrief } from 'src/gql/queries';
import { useState } from 'react';
import {
  NoteCategoryFormItem,
  DescriptionFormItem,
  TitleFormItem,
  BoardersFormItem,
} from './items';
import { FormProps } from '.';

export type NoteForm = {
  boarderIds: string[];
  category: NoteCategory;
  title: string;
  description: string;
}

type NoteModalFormProps = FormProps<NoteForm> & {
  noteCategoryDisabled: boolean;
  boardersDisabled: boolean;
  allBoardersBriefMap: Map<string, BoarderBrief> | undefined;
  withDormitoryCategory?: boolean;
}

export const NoteModalForm = ({
  form,
  allBoardersBriefMap,
  boardersDisabled,
  noteCategoryDisabled,
  withDormitoryCategory,
}: NoteModalFormProps) => {
  const [isDormitoryNote, setIsDormitoryNote] = useState(false);

  const onDormitoryCategorySelect = () => {
    setIsDormitoryNote(true);
    form.setFieldsValue({
      boarderIds: [],
    });
  };
  const onBoarderCategorySelect = () => {
    setIsDormitoryNote(false);
  };

  return (
    <Form form={form} layout="vertical">
      <NoteCategoryFormItem
        disabled={noteCategoryDisabled}
        withDormitoryCategory={withDormitoryCategory}
        onDormitorySelect={onDormitoryCategorySelect}
        onBoarderSelect={onBoarderCategorySelect}
      />
      <BoardersFormItem
        allBoardersBriefMap={allBoardersBriefMap}
        disabled={boardersDisabled || isDormitoryNote}
        required={!noteCategoryDisabled && !isDormitoryNote}
      />
      <TitleFormItem />
      <DescriptionFormItem />
    </Form>
  );
};
