import { Button, Form } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { useUpdateDormitoryDetails } from 'src/gql/mutations';
import { DormitoryDetailsApi } from 'src/shared/types/api';
import { GqlMutation } from 'src/shared/types/common';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { DormitoryDetailsForm, DormitoryDetailsFormFields } from './forms';

type DormitoryDetailsProps = {
  dormitoryDetails?: DormitoryDetailsApi;
}

export const DormitoryDetails = ({ dormitoryDetails }: DormitoryDetailsProps) => {
  const [form] = Form.useForm<DormitoryDetailsFormFields>();
  const { t } = useTranslation();
  const user = useUserContext();

  const [
    updateDormitoryDetails,
    loading,
  ] = useUpdateDormitoryDetails({
    form,
    onSuccess: () => window.scrollTo(0, 0),
  });

  useEffect(() => {
    if (dormitoryDetails) {
      form.setFieldsValue({
        name: dormitoryDetails.name,
        accountNumber: dormitoryDetails.accountNumber,
        streetAddress: dormitoryDetails.address.streetAddress,
        postCode: dormitoryDetails.address.postCode,
        city: dormitoryDetails.address.city,
        emails: dormitoryDetails.emails.map((email) => ({
          label: email.label,
          email: email.value,
        })),
        phoneNumbers: dormitoryDetails.phoneNumbers.map((phoneNumber) => ({
          label: phoneNumber.label,
          phoneNumber: phoneNumberToDisplay(phoneNumber.value),
        })),
        groupIds: dormitoryDetails.groups.map(({ groupId }) => groupId),
      });
    }
  }, [dormitoryDetails]);

  return (
    <>
      <DormitoryDetailsForm form={form} />
      {user?.isAllowed(GqlMutation.UpdateDormitoryDetails) && (
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          onClick={updateDormitoryDetails}
          className="vertical-base-margin"
          block
        >
          {t('Save')}
        </Button>
      )}
    </>
  );
};
