import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const contactDetailsRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct contact details'),
    max: 1024,
  });
};
