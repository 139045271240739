import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateCanteenManagerEmailsInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_CANTEEN_MANAGER_EMAILS = gql`
  mutation UpdateCanteenManagerEmails($input: UpdateCanteenManagerEmailsInput!) {
    updateCanteenManagerEmails(input: $input) {
      canteenManagerEmails
    }
  }
`;

export const useUpdateCanteenManagerEmails = (
  { form, onSuccess } : UseOnMutationArgs<UpdateCanteenManagerEmailsInput>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateCanteenManagerEmails, { loading }] = useMutation<
    unknown, { input: UpdateCanteenManagerEmailsInput }
  >(UPDATE_CANTEEN_MANAGER_EMAILS);

  const onUpdateCanteenMangerEmails = useCallback(async () => {
    const formFields = await form.validateFields();
    try {
      await updateCanteenManagerEmails({
        variables: {
          input: formFields,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Canteen manager emails have been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Canteen manager emails have not been updated successfully') });
    }
  }, [form, onSuccess]);

  return [onUpdateCanteenMangerEmails, loading];
};
