import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecruitmentStepPaths } from './useRecruitmentStepPaths';

export const useRecruitmentStepPathIndex = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const path = pathname.slice(1); // remove '/'
  const recruitmentStepPaths = useRecruitmentStepPaths();

  if (path === t('recruitment-succeeded-step') || path === t('boarder-already-recruited-step')) {
    return undefined;
  }

  const step = recruitmentStepPaths.findIndex((val) => val === path);

  if (step === -1) {
    return 0;
  }

  return step;
};
