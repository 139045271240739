import {
  GqlMutation, GqlQuery, GqlRequest, UserGroup,
} from './types/common';

type AccessControlMatrix = {
  [role in UserGroup]: {
    [action in Exclude<GqlRequest, 'handler' | 'authorizeBoarderAccess' | 'notifyAboutOnlinePayment'>]: boolean
  }
}

export const accessControlMatrix: AccessControlMatrix = {
  [UserGroup.Administrators]: {
    [GqlMutation.CreateBoarder]: true,
    [GqlMutation.UpdateBoarder]: true,
    [GqlMutation.ResendCredentialsToBoarder]: true,
    [GqlMutation.ToggleBoarderSuspensionStatus]: true,
    [GqlMutation.ToggleBoarderPresenceState]: true,
    [GqlMutation.UpdateBoarderSubscription]: true,
    [GqlMutation.UpdateBoarderAbsences]: true,
    [GqlMutation.UpdateBoarderWeekendStays]: true,
    [GqlMutation.UpdateBoarderMeals]: true,
    [GqlMutation.PickUpMeal]: false,
    [GqlMutation.ArchiveBoarder]: true,
    [GqlMutation.RestoreBoarderFromArchive]: true,
    [GqlMutation.RemoveBoarderFromArchive]: true,

    [GqlMutation.CreateBoarderNote]: true,
    [GqlMutation.UpdateBoarderNote]: true,
    [GqlMutation.DeleteBoarderNote]: true,

    [GqlMutation.CreateBoarderPayment]: true,
    [GqlMutation.AddBoarderPaymentComment]: true,
    [GqlMutation.InitOnlinePayment]: false,

    [GqlMutation.CreateUpdateBoarderGeneralInfoRequest]: false,
    [GqlMutation.ApproveUpdateBoarderGeneralInfoRequest]: true,
    [GqlMutation.DeclineUpdateBoarderGeneralInfoRequest]: true,

    [GqlMutation.CreateTutor]: true,
    [GqlMutation.UpdateTutor]: true,
    [GqlMutation.DeleteTutor]: true,
    [GqlMutation.ResendCredentialsToTutor]: true,

    [GqlMutation.CreateAdministrator]: true,
    [GqlMutation.UpdateAdministrator]: true,
    [GqlMutation.DeleteAdministrator]: true,
    [GqlMutation.ResendCredentialsToAdministrator]: true,

    [GqlMutation.CreateParent]: true,
    [GqlMutation.UpdateParent]: true,
    [GqlMutation.DeleteParent]: true,
    [GqlMutation.ResendCredentialsToParent]: true,

    [GqlMutation.UpdateDormitoryDetails]: true,
    [GqlMutation.UpdateDormitoryYear]: true,
    [GqlMutation.UpdateDormitoryFees]: true,
    [GqlMutation.ChargeMonthlyFee]: true,
    [GqlMutation.UpdateCanteenManagerEmails]: true,
    [GqlMutation.SendPaymentReminder]: true,
    [GqlMutation.GenerateReport]: true,
    [GqlMutation.SendMessage]: true,

    [GqlMutation.CreateDormitoryNote]: true,
    [GqlMutation.UpdateDormitoryNote]: true,
    [GqlMutation.DeleteDormitoryNote]: true,

    [GqlMutation.CreateRoomRental]: true,
    [GqlMutation.UpdateRoomRental]: true,
    [GqlMutation.DeleteRoomRental]: true,

    [GqlMutation.AcceptRecruitmentRequest]: true,
    [GqlMutation.DeclineRecruitmentRequest]: true,
    [GqlMutation.CreateBoarderRecruitmentPayment]: true,
    [GqlMutation.ConfirmRecruitmentRequest]: true,
    [GqlMutation.ArchiveRecruitmentRequest]: true,
    [GqlMutation.CreateBoarderAndParentsByRecruitmentId]: true,
    [GqlMutation.GenerateRecruitmentQuestionnaire]: true,

    [GqlQuery.GetBoarder]: true,
    [GqlQuery.GetBoarderArchive]: true,
    [GqlQuery.GetBoarders]: true,
    [GqlQuery.GetBoardersArchive]: true,

    [GqlQuery.GetTutor]: true,
    [GqlQuery.GetTutors]: true,

    [GqlQuery.GetAdministrator]: true,
    [GqlQuery.GetAdministrators]: true,

    [GqlQuery.GetParent]: true,
    [GqlQuery.GetParents]: true,

    [GqlQuery.GetSchoolYear]: true,
    [GqlQuery.GetDormitoryChargedMonths]: true,
    [GqlQuery.GetCanteenManagerEmails]: true,
    [GqlQuery.GetDormitoryYear]: true,
    [GqlQuery.GetDormitoryDetails]: true,
    [GqlQuery.GetDormitoryFees]: true,
    [GqlQuery.GetFinanceStats]: true,
    [GqlQuery.GetWeeklyOrderedMeals]: true,
    [GqlQuery.GetNotesHistory]: true,
    [GqlQuery.GetReports]: true,
    [GqlQuery.GetReport]: true,
    [GqlQuery.GetDormitoryNotes]: true,
    [GqlQuery.GetRoomRentals]: true,

    [GqlQuery.GetRecruitmentBoarders]: true,

    [GqlQuery.GetMe]: true,
  },

  [UserGroup.Tutors]: {
    [GqlMutation.CreateBoarder]: false,
    [GqlMutation.UpdateBoarder]: true,
    [GqlMutation.ResendCredentialsToBoarder]: true,
    [GqlMutation.ToggleBoarderSuspensionStatus]: false,
    [GqlMutation.ToggleBoarderPresenceState]: true,
    [GqlMutation.UpdateBoarderSubscription]: true,
    [GqlMutation.UpdateBoarderAbsences]: true,
    [GqlMutation.UpdateBoarderWeekendStays]: true,
    [GqlMutation.UpdateBoarderMeals]: true,
    [GqlMutation.PickUpMeal]: false,
    [GqlMutation.ArchiveBoarder]: false,
    [GqlMutation.RestoreBoarderFromArchive]: false,
    [GqlMutation.RemoveBoarderFromArchive]: false,

    [GqlMutation.CreateBoarderNote]: true,
    [GqlMutation.UpdateBoarderNote]: true,
    [GqlMutation.DeleteBoarderNote]: true,

    [GqlMutation.CreateBoarderPayment]: false,
    [GqlMutation.AddBoarderPaymentComment]: true,
    [GqlMutation.InitOnlinePayment]: false,

    [GqlMutation.CreateUpdateBoarderGeneralInfoRequest]: false,
    [GqlMutation.ApproveUpdateBoarderGeneralInfoRequest]: true,
    [GqlMutation.DeclineUpdateBoarderGeneralInfoRequest]: true,

    [GqlMutation.CreateTutor]: false,
    [GqlMutation.UpdateTutor]: false,
    [GqlMutation.DeleteTutor]: false,
    [GqlMutation.ResendCredentialsToTutor]: true,

    [GqlMutation.CreateAdministrator]: false,
    [GqlMutation.UpdateAdministrator]: false,
    [GqlMutation.DeleteAdministrator]: false,
    [GqlMutation.ResendCredentialsToAdministrator]: false,

    [GqlMutation.CreateParent]: true,
    [GqlMutation.UpdateParent]: true,
    [GqlMutation.DeleteParent]: true,
    [GqlMutation.ResendCredentialsToParent]: true,

    [GqlMutation.UpdateDormitoryDetails]: false,
    [GqlMutation.UpdateDormitoryYear]: false,
    [GqlMutation.UpdateDormitoryFees]: false,
    [GqlMutation.ChargeMonthlyFee]: false,
    [GqlMutation.UpdateCanteenManagerEmails]: false,
    [GqlMutation.SendPaymentReminder]: false,
    [GqlMutation.GenerateReport]: true,
    [GqlMutation.SendMessage]: true,

    [GqlMutation.CreateDormitoryNote]: true,
    [GqlMutation.UpdateDormitoryNote]: true,
    [GqlMutation.DeleteDormitoryNote]: true,

    [GqlMutation.CreateRoomRental]: false,
    [GqlMutation.UpdateRoomRental]: false,
    [GqlMutation.DeleteRoomRental]: false,

    [GqlMutation.AcceptRecruitmentRequest]: false,
    [GqlMutation.DeclineRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderRecruitmentPayment]: false,
    [GqlMutation.ConfirmRecruitmentRequest]: false,
    [GqlMutation.ArchiveRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderAndParentsByRecruitmentId]: false,
    [GqlMutation.GenerateRecruitmentQuestionnaire]: false,

    [GqlQuery.GetBoarder]: true,
    [GqlQuery.GetBoarderArchive]: false,
    [GqlQuery.GetBoarders]: true,
    [GqlQuery.GetBoardersArchive]: false,

    [GqlQuery.GetTutor]: false,
    [GqlQuery.GetTutors]: true,

    [GqlQuery.GetAdministrator]: false,
    [GqlQuery.GetAdministrators]: false,

    [GqlQuery.GetParent]: true,
    [GqlQuery.GetParents]: true,

    [GqlQuery.GetSchoolYear]: true,
    [GqlQuery.GetDormitoryChargedMonths]: true,
    [GqlQuery.GetCanteenManagerEmails]: false,
    [GqlQuery.GetDormitoryYear]: true,
    [GqlQuery.GetDormitoryDetails]: true,
    [GqlQuery.GetDormitoryFees]: true,
    [GqlQuery.GetFinanceStats]: false,
    [GqlQuery.GetWeeklyOrderedMeals]: true,
    [GqlQuery.GetNotesHistory]: true,
    [GqlQuery.GetReports]: true,
    [GqlQuery.GetReport]: true,
    [GqlQuery.GetDormitoryNotes]: true,
    [GqlQuery.GetRoomRentals]: true,

    [GqlQuery.GetRecruitmentBoarders]: false,

    [GqlQuery.GetMe]: true,
  },

  [UserGroup.Boarders]: {
    [GqlMutation.CreateBoarder]: false,
    [GqlMutation.UpdateBoarder]: false,
    [GqlMutation.ResendCredentialsToBoarder]: false,
    [GqlMutation.ToggleBoarderSuspensionStatus]: false,
    [GqlMutation.ToggleBoarderPresenceState]: false,
    [GqlMutation.UpdateBoarderSubscription]: false,
    [GqlMutation.UpdateBoarderAbsences]: false,
    [GqlMutation.UpdateBoarderWeekendStays]: false,
    [GqlMutation.UpdateBoarderMeals]: true, // only student (academic), only ourselves
    [GqlMutation.PickUpMeal]: false,
    [GqlMutation.ArchiveBoarder]: false,
    [GqlMutation.RestoreBoarderFromArchive]: false,
    [GqlMutation.RemoveBoarderFromArchive]: false,

    [GqlMutation.CreateBoarderNote]: false,
    [GqlMutation.UpdateBoarderNote]: false,
    [GqlMutation.DeleteBoarderNote]: false,

    [GqlMutation.CreateBoarderPayment]: false,
    [GqlMutation.AddBoarderPaymentComment]: true, // only ourselves
    // hide button until PayU confirms the code works
    [GqlMutation.InitOnlinePayment]: false, // only ourselves

    [GqlMutation.CreateUpdateBoarderGeneralInfoRequest]: true, // only ourselves
    [GqlMutation.ApproveUpdateBoarderGeneralInfoRequest]: false,
    [GqlMutation.DeclineUpdateBoarderGeneralInfoRequest]: false,

    [GqlMutation.CreateTutor]: false,
    [GqlMutation.UpdateTutor]: false,
    [GqlMutation.DeleteTutor]: false,
    [GqlMutation.ResendCredentialsToTutor]: false,

    [GqlMutation.CreateAdministrator]: false,
    [GqlMutation.UpdateAdministrator]: false,
    [GqlMutation.DeleteAdministrator]: false,
    [GqlMutation.ResendCredentialsToAdministrator]: false,

    [GqlMutation.CreateParent]: false,
    [GqlMutation.UpdateParent]: false,
    [GqlMutation.DeleteParent]: false,
    [GqlMutation.ResendCredentialsToParent]: false,

    [GqlMutation.UpdateDormitoryDetails]: false,
    [GqlMutation.UpdateDormitoryYear]: false,
    [GqlMutation.UpdateDormitoryFees]: false,
    [GqlMutation.ChargeMonthlyFee]: false,
    [GqlMutation.UpdateCanteenManagerEmails]: false,
    [GqlMutation.SendPaymentReminder]: false,
    [GqlMutation.GenerateReport]: false,
    [GqlMutation.SendMessage]: true,

    [GqlMutation.CreateDormitoryNote]: false,
    [GqlMutation.UpdateDormitoryNote]: false,
    [GqlMutation.DeleteDormitoryNote]: false,

    [GqlMutation.CreateRoomRental]: false,
    [GqlMutation.UpdateRoomRental]: false,
    [GqlMutation.DeleteRoomRental]: false,

    [GqlMutation.AcceptRecruitmentRequest]: false,
    [GqlMutation.DeclineRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderRecruitmentPayment]: false,
    [GqlMutation.ConfirmRecruitmentRequest]: false,
    [GqlMutation.ArchiveRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderAndParentsByRecruitmentId]: false,
    [GqlMutation.GenerateRecruitmentQuestionnaire]: false,

    [GqlQuery.GetBoarder]: true, // only ourselves
    [GqlQuery.GetBoarderArchive]: false,
    [GqlQuery.GetBoarders]: false,
    [GqlQuery.GetBoardersArchive]: false,

    [GqlQuery.GetSchoolYear]: true,
    [GqlQuery.GetDormitoryChargedMonths]: true,
    [GqlQuery.GetCanteenManagerEmails]: false,
    [GqlQuery.GetDormitoryYear]: true,
    [GqlQuery.GetDormitoryDetails]: true,
    [GqlQuery.GetDormitoryFees]: true,
    [GqlQuery.GetFinanceStats]: false,
    [GqlQuery.GetWeeklyOrderedMeals]: false,
    [GqlQuery.GetNotesHistory]: false,
    [GqlQuery.GetReports]: false,
    [GqlQuery.GetReport]: false,
    [GqlQuery.GetDormitoryNotes]: false,
    [GqlQuery.GetRoomRentals]: false,

    [GqlQuery.GetTutor]: false,
    [GqlQuery.GetTutors]: false,

    [GqlQuery.GetAdministrator]: false,
    [GqlQuery.GetAdministrators]: false,

    [GqlQuery.GetParent]: false,
    [GqlQuery.GetParents]: false,

    [GqlQuery.GetRecruitmentBoarders]: false,

    [GqlQuery.GetMe]: true,
  },

  [UserGroup.Parents]: {
    [GqlMutation.CreateBoarder]: false,
    [GqlMutation.UpdateBoarder]: false,
    [GqlMutation.ResendCredentialsToBoarder]: false,
    [GqlMutation.ToggleBoarderSuspensionStatus]: false,
    [GqlMutation.ToggleBoarderPresenceState]: false,
    [GqlMutation.UpdateBoarderSubscription]: false,
    [GqlMutation.UpdateBoarderAbsences]: true,
    [GqlMutation.UpdateBoarderWeekendStays]: true,
    [GqlMutation.UpdateBoarderMeals]: true,
    [GqlMutation.PickUpMeal]: false,
    [GqlMutation.ArchiveBoarder]: false,
    [GqlMutation.RestoreBoarderFromArchive]: false,
    [GqlMutation.RemoveBoarderFromArchive]: false,

    [GqlMutation.CreateBoarderNote]: false,
    [GqlMutation.UpdateBoarderNote]: false,
    [GqlMutation.DeleteBoarderNote]: false,

    [GqlMutation.CreateBoarderPayment]: false,
    [GqlMutation.AddBoarderPaymentComment]: true, // only children
    // hide button until PayU confirms the code works
    [GqlMutation.InitOnlinePayment]: false, // only children

    [GqlMutation.CreateUpdateBoarderGeneralInfoRequest]: true, // only children
    [GqlMutation.ApproveUpdateBoarderGeneralInfoRequest]: false,
    [GqlMutation.DeclineUpdateBoarderGeneralInfoRequest]: false,

    [GqlMutation.CreateTutor]: false,
    [GqlMutation.UpdateTutor]: false,
    [GqlMutation.DeleteTutor]: false,
    [GqlMutation.ResendCredentialsToTutor]: false,

    [GqlMutation.CreateAdministrator]: false,
    [GqlMutation.UpdateAdministrator]: false,
    [GqlMutation.DeleteAdministrator]: false,
    [GqlMutation.ResendCredentialsToAdministrator]: false,

    [GqlMutation.CreateParent]: false,
    [GqlMutation.UpdateParent]: false,
    [GqlMutation.DeleteParent]: false,
    [GqlMutation.ResendCredentialsToParent]: false,

    [GqlMutation.UpdateDormitoryDetails]: false,
    [GqlMutation.UpdateDormitoryYear]: false,
    [GqlMutation.UpdateDormitoryFees]: false,
    [GqlMutation.ChargeMonthlyFee]: false,
    [GqlMutation.UpdateCanteenManagerEmails]: false,
    [GqlMutation.SendPaymentReminder]: false,
    [GqlMutation.GenerateReport]: false,
    [GqlMutation.SendMessage]: true,

    [GqlMutation.CreateDormitoryNote]: false,
    [GqlMutation.UpdateDormitoryNote]: false,
    [GqlMutation.DeleteDormitoryNote]: false,

    [GqlMutation.CreateRoomRental]: false,
    [GqlMutation.UpdateRoomRental]: false,
    [GqlMutation.DeleteRoomRental]: false,

    [GqlMutation.AcceptRecruitmentRequest]: false,
    [GqlMutation.DeclineRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderRecruitmentPayment]: false,
    [GqlMutation.ConfirmRecruitmentRequest]: false,
    [GqlMutation.ArchiveRecruitmentRequest]: false,
    [GqlMutation.CreateBoarderAndParentsByRecruitmentId]: false,
    [GqlMutation.GenerateRecruitmentQuestionnaire]: false,

    [GqlQuery.GetBoarder]: true, // only children
    [GqlQuery.GetBoarderArchive]: false,
    [GqlQuery.GetBoarders]: true, // only children returned
    [GqlQuery.GetBoardersArchive]: false,

    [GqlQuery.GetSchoolYear]: true,
    [GqlQuery.GetDormitoryChargedMonths]: true,
    [GqlQuery.GetCanteenManagerEmails]: false,
    [GqlQuery.GetDormitoryYear]: true,
    [GqlQuery.GetDormitoryDetails]: true,
    [GqlQuery.GetDormitoryFees]: true,
    [GqlQuery.GetFinanceStats]: false,
    [GqlQuery.GetWeeklyOrderedMeals]: false,
    [GqlQuery.GetNotesHistory]: false,
    [GqlQuery.GetReports]: false,
    [GqlQuery.GetReport]: false,
    [GqlQuery.GetDormitoryNotes]: false,
    [GqlQuery.GetRoomRentals]: false,

    [GqlQuery.GetTutor]: false,
    [GqlQuery.GetTutors]: false,

    [GqlQuery.GetAdministrator]: false,
    [GqlQuery.GetAdministrators]: false,

    [GqlQuery.GetParent]: false,
    [GqlQuery.GetParents]: false,

    [GqlQuery.GetRecruitmentBoarders]: false,

    [GqlQuery.GetMe]: true,
  },
} as const;
