import { gql } from '@apollo/client';
import { WHOLE_ADDRESS_FRAGMENT } from './address';

export const WHOLE_UPDATE_GENERAL_INFO_REQUEST_FRAGMENT = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  fragment WholeUpdateGeneralInfoRequestFragment on UpdateGeneralInfoRequest {
    requestId
    createdAt
    createdBy
    decidedAt
    decidedBy
    boarderGeneralInfo {
      firstName
      lastName
      email
      phoneNumber
      address { ...WholeAddressFragment }
      personalIdentityNumber
      birthDate
      birthPlace
      schoolDetails {
        schoolName
        schoolKind
        classNumber
      }
    }
  }
`;
