import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type InitOnlinePaymentSuccessMessageProps = { href: string }

export const InitOnlinePaymentSuccessMessage = ({ href }: InitOnlinePaymentSuccessMessageProps) => {
  const { t } = useTranslation();

  return (
    <>
      {t('Online payment has been initiated successfully. If not, ')}
      <Button type="link" href={href} style={{ padding: 0, lineHeight: 'inherit' }} target="_blank">{t('click here.')}</Button>
    </>
  );
};
