import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { AddressFormItems } from './address';
import { EmailFormItem } from './email';
import { FullNameFormItems } from './fullName';
import { PhoneNumberFormItem } from './phoneNumber';

export type ContactDetailsFormValues = {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  streetAddress?: string;
  postCode?: string;
  city?: string;
}

export type ContactDetailsFormItemsProps = {
  showAddress?: true;
  phoneNumberRequired?: true;
  emailRequired?: true;
} & FormItemCommonProps

export const ContactDetailsFormItems = ({
  emailRequired,
  keyPrefix,
  phoneNumberRequired,
  showAddress,
  disabled,
}: ContactDetailsFormItemsProps) => (
  <>
    <FullNameFormItems keyPrefix={keyPrefix} disabled={disabled} />

    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24} sm={12}>
        <PhoneNumberFormItem
          keyPrefix={keyPrefix}
          required={phoneNumberRequired}
          disabled={disabled}
        />
      </Col>
      <Col xs={24} sm={12}>
        <EmailFormItem
          keyPrefix={keyPrefix}
          required={emailRequired}
          disabled={disabled}
        />
      </Col>
    </Row>

    {showAddress && <AddressFormItems keyPrefix={keyPrefix} disabled={disabled} />}
  </>
);
