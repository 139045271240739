import { DesktopOutlined } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';

export const TooSmallScreenWarning = () => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" align="center" style={{ width: '100%' }}>
      <DesktopOutlined style={{ fontSize: STYLE.LARGE_ITEM_HEIGHT * 4 }} />
      <Alert
        message={t('Warning')}
        description={t('Too small screen warning')}
        type="warning"
        showIcon
      />
    </Space>
  );
};
