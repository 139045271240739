export * from './ContactDetailsFormItems';
export * from './accountNumber';
export * from './address';
export * from './amount';
export * from './amountThreshold';
export * from './birthDate';
export * from './birthPlace';
export * from './boarders';
export * from './canteenManagerEmails';
export * from './cardId';
export * from './city';
export * from './classNumber';
export * from './clauses';
export * from './contactDetails';
export * from './description';
export * from './dormitoryAcademicFee';
export * from './dormitoryBaseFee';
export * from './dormitoryBreakfastFee';
export * from './dormitoryDinnerFee';
export * from './dormitoryLunchFee';
export * from './dormitoryName';
export * from './dormitoryWeekendStayFee';
export * from './email';
export * from './existingBoarder';
export * from './firstName';
export * from './formList';
export * from './fullName';
export * from './groupId';
export * from './groupIdInput';
export * from './groupIds';
export * from './groupName';
export * from './label';
export * from './labeledEmail';
export * from './labeledPhoneNumber';
export * from './labels';
export * from './lastName';
export * from './livingEndDate';
export * from './livingStartDate';
export * from './message';
export * from './month';
export * from './noteCategory';
export * from './otherInfo';
export * from './paymentComment';
export * from './paymentType';
export * from './peopleCount';
export * from './periodOfStayInDormitory';
export * from './personalIdentityNumber';
export * from './phoneNumber';
export * from './postCode';
export * from './questionnaire';
export * from './recipients';
export * from './registeredParents';
export * from './riskFactors';
export * from './roomId';
export * from './roomIds';
export * from './schoolKind';
export * from './schoolName';
export * from './startsAtEndsAt';
export * from './streetAddress';
export * from './subscription';
export * from './title';

export type FormItemCommonProps = {
  keyPrefix?: string;
  required?: boolean;
  disabled?: boolean;
}

type NameWithPrefixArgs = {
  keyPrefix?: string;
  name: string;
}
export const nameWithPrefix = (
  { keyPrefix, name }: NameWithPrefixArgs,
) => (keyPrefix ? [keyPrefix, name] : name);
