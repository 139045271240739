import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolKind } from 'src/shared/types/common';
import { MinMaxRange } from 'src/utils';
import { FormItemCommonProps } from '.';

export const schoolKindToClassesRange = (schoolKind: SchoolKind): MinMaxRange => {
  switch (schoolKind) {
    case SchoolKind.PRIMARY:
      return ({ min: 0, max: 8 });
    case SchoolKind.SECONDARY:
    case SchoolKind.VOCATIONAL_SECONDARY:
      return ({ min: 1, max: 4 });
    case SchoolKind.TECHNICAL_SECONDARY:
      return ({ min: 1, max: 5 });
    default:
      throw new Error('Implementation error');
  }
};

type SchoolKindFormItemProps = {
  setClassNumberRange: Dispatch<SetStateAction<MinMaxRange>>
} & FormItemCommonProps

export const SchoolKindFormItem = ({ setClassNumberRange, disabled }: SchoolKindFormItemProps) => {
  const { t } = useTranslation();

  const options: DefaultOptionType[] = [
    { value: SchoolKind.PRIMARY, label: t(SchoolKind.PRIMARY) },
    { value: SchoolKind.SECONDARY, label: t(SchoolKind.SECONDARY) },
    { value: SchoolKind.TECHNICAL_SECONDARY, label: t(SchoolKind.TECHNICAL_SECONDARY) },
    { value: SchoolKind.VOCATIONAL_SECONDARY, label: t(SchoolKind.VOCATIONAL_SECONDARY) },
  ];

  return (
    <Form.Item
      label={t('School kind')}
      name="schoolKind"
    >
      <Select
        allowClear
        disabled={disabled}
        options={options}
        onSelect={(schoolKind: SchoolKind) => {
          const range = schoolKindToClassesRange(schoolKind);
          setClassNumberRange(range);
        }}
      />
    </Form.Item>
  );
};
