import { Table } from 'antd';
import { useGetDormitoryWeeklyOrderedMeals } from 'src/gql/queries';
import { useWeeklyOrderedMealsTableColumns } from './tables';

export const DormitoryWeeklyOrderedMeals = () => {
  const [weeklyOrderedMeals, loading] = useGetDormitoryWeeklyOrderedMeals();

  const columns = useWeeklyOrderedMealsTableColumns();

  return (
    <Table
      dataSource={weeklyOrderedMeals}
      size="large"
      pagination={false}
      columns={columns}
      rowKey="date"
      loading={loading}
    />
  );
};
