import { gql, useQuery } from '@apollo/client';
import { ContextUserAsViewed } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_ME = gql`
  query GetMe {
    me {
      firstName
      lastName
      email
      phoneNumber
      group
      administratorId
      tutorId
      boarderId
      parentId
      cardId
      notFound
    }
  }
`;

export const useGetMe = (): UseQueryReturnType<ContextUserAsViewed> => {
  const {
    loading,
    error,
    data,
  } = useQuery<{ me: ContextUserAsViewed }>(
    GET_ME,
  );

  return [data?.me, loading, error];
};
