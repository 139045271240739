import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { contactDetailsRule } from '../rules';

export const ContactDetailsFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Contact details')}
      name="contactDetails"
      rules={[contactDetailsRule()]}
    >
      <Input.TextArea showCount rows={5} maxLength={1024} />
    </Form.Item>
  );
};
