import { useTranslation } from 'react-i18next';
import { QuestionnaireFormItem, RiskFactorsFormItem } from './forms/items';

type RecruitmentQuestionnaireFormItemsProps = {
  disabled?: boolean;
}

export const RecruitmentQuestionnaireFormItems = ({
  disabled,
}: RecruitmentQuestionnaireFormItemsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <QuestionnaireFormItem name="isAcolyte" disabled={disabled} question={t('Is acolyte?')} additionalName="parishName" additionalPlaceholder={t('Parish name')} />
      <QuestionnaireFormItem name="isVolunteer" disabled={disabled} question={t('Is volunteer?')} additionalName="groupName" additionalPlaceholder={t('Group name')} />
      <QuestionnaireFormItem name="isLargeFamily" disabled={disabled} question={t('Is large family?')} />
      <QuestionnaireFormItem name="isSingleParent" disabled={disabled} question={t('Is single parent?')} />
      <QuestionnaireFormItem name="isRefugee" disabled={disabled} question={t('Is refugee?')} />
      <QuestionnaireFormItem name="isFoster" disabled={disabled} question={t('Is foster?')} />
      <QuestionnaireFormItem name="hadReligiousEducation" disabled={disabled} question={t('Had religious education?')} />
      <QuestionnaireFormItem name="religiousEducationPlans" disabled={disabled} question={t('Religious education plans?')} />
      <QuestionnaireFormItem name="isOrWantsToBeConfirmed" disabled={disabled} question={t('Is or wants to be confirmed?')} />
      <QuestionnaireFormItem name="isSportsman" disabled={disabled} question={t('Is sportsman?')} additionalName="clubName" additionalPlaceholder={t('Club name')} />
      <RiskFactorsFormItem disabled={disabled} />
      <QuestionnaireFormItem name="isDemoralized" disabled={disabled} question={t('Is demoralized?')} />
      <QuestionnaireFormItem name="isPsychotic" disabled={disabled} question={t('Is psychotic?')} additionalName="psychotic" additionalPlaceholder={t('Treatment details')} />
      <QuestionnaireFormItem name="isIll" disabled={disabled} question={t('Is ill?')} additionalName="ill" additionalPlaceholder={t('Treatment details')} />
      <QuestionnaireFormItem name="isAllergic" disabled={disabled} question={t('Is allergic?')} additionalName="allergies" additionalPlaceholder={t('Allergies')} />
    </>
  );
};
