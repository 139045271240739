import { gql, useQuery } from '@apollo/client';
import { S3ObjectDownload } from 'src/shared/types/common';

export const GET_SPECIFIC_REPORT = gql`
  query GetSpecificReport($key: String!) {
    report(key: $key) {
      expires
      url
    }
  }
`;

export const useGetSpecificReport = () => {
  const {
    refetch: fetchReport,
  } = useQuery<{ report: S3ObjectDownload }>(
    GET_SPECIFIC_REPORT,
    { skip: true },
  );

  return fetchReport;
};
