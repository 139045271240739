import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory } from 'src/shared/types/api';
import { RoomRental } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_ROOM_RENTALS = gql`
  query GetRoomRentals {
    dormitory {
      roomRentals {
        city
        contactDetails
        createdAt
        createdBy
        endsAt
        groupName
        otherInfo
        peopleCount
        roomRentalId
        roomIds
        startsAt
        updatedAt
      }
    }
  }
`;

type GetRoomRentalsResponse = { dormitory: Pick<Dormitory, 'roomRentals'> };

export const useGetRoomRentals = (): UseQueryReturnType<RoomRental[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetRoomRentalsResponse>(
    GET_ROOM_RENTALS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch room rentals') });
  }

  return [data?.dormitory.roomRentals, loading, error];
};
