import { gql } from '@apollo/client';

export const WHOLE_PAYMENT_FRAGMENT = gql`
  fragment WholePaymentFragment on Payment {
    paymentId
    type
    createdAt
    createdBy
    decidedAt
    decidedBy
    amount
    comments {
      createdBy
      createdAt
      comment
    }
    downloadAttachment {
      expires
      url
    }
    uploadAttachment {
      expires
      body
      url
    }
  }
`;
