import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';
import { useGetSpecificBoarderOtherInfo, UseGetSpecificBoarderOtherInfoArgsCore } from './getSpecificBoarderOtherInfo';

export const GET_SPECIFIC_BOARDER_PAYMENTS = gql`
  query GetSpecificBoarderPayments($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      boarderId
      balance
      subscription
      payments {
        paymentId
        type
        createdAt
        createdBy
        decidedAt
        decidedBy
        amount
        comments {
          createdBy
          createdAt
          comment
        }
        downloadAttachment {
          expires
          url
        }
        uploadAttachment {
          expires
          body
          url
        }
      }
    }
  }
`;

export const GET_SPECIFIC_BOARDER_PAYMENTS_ARCHIVE = gql`
  query GetSpecificBoarderPaymentsArchive($boarderId: ID!) {
    boarderArchive(boarderId: $boarderId) {
      boarderId
      balance
      subscription
      payments {
        paymentId
        type
        createdAt
        createdBy
        decidedAt
        decidedBy
        amount
        comments {
          createdBy
          createdAt
          comment
        }
        downloadAttachment {
          expires
          url
        }
        uploadAttachment {
          expires
          body
          url
        }
      }
    }
  }
`;

export type GetSpecificBoarderPaymentsResponse = Pick<Boarder, 'boarderId' | 'balance' | 'subscription' | 'payments'>

export const useGetSpecificBoarderPayments = (
  args: UseGetSpecificBoarderOtherInfoArgsCore,
): UseQueryReturnType<GetSpecificBoarderPaymentsResponse> => {
  const { t } = useTranslation();

  return useGetSpecificBoarderOtherInfo<GetSpecificBoarderPaymentsResponse>({
    ...args,
    GET_SPECIFIC_BOARDER_OTHER_INFO: GET_SPECIFIC_BOARDER_PAYMENTS,
    GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE: GET_SPECIFIC_BOARDER_PAYMENTS_ARCHIVE,
    errorMessage: t('Unable to fetch boarder payments'),
  });
};
