import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseQueryReturnType } from '..';
import { WHOLE_ADDRESS_FRAGMENT } from '../../fragments';
import { SpecificBoarder } from './getSpecificBoarder';

export const GET_SPECIFIC_BOARDER_ARCHIVE = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetSpecificBoarderArchive($boarderId: ID!) {
    boarderArchive(boarderId: $boarderId) {
      boarderId

      firstName
      lastName
      phoneNumber
      email
      address { ...WholeAddressFragment }
      personalIdentityNumber
      birthDate
      birthPlace

      registeredParentIds
      unregisteredParents {
        firstName
        lastName
        phoneNumber
        email
        address { ...WholeAddressFragment }
      }

      labels
      cardId
      groupId
      roomId
      livingStartDate
      livingEndDate

      schoolDetails {
        schoolName
        schoolKind
        classNumber
      }

      isSuspended
      accountStatus
    }
  }
`;

export const useGetSpecificBoarderArchive = (
  boarderId?: string,
): UseQueryReturnType<SpecificBoarder> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<{ boarderArchive: SpecificBoarder }>(
    GET_SPECIFIC_BOARDER_ARCHIVE,
    { variables: { boarderId } },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarder archive') });
  }

  return [data?.boarderArchive, loading, error];
};
