import { DefaultOptionType } from 'antd/lib/select';
import { useGetDormitoryGroups, UseQueryReturnType } from 'src/gql/queries';
import { getFullName } from 'src/shared/utils';

export const useDormitoryGroupOptions = (): UseQueryReturnType<DefaultOptionType[]> => {
  const [groups, loading, error] = useGetDormitoryGroups();
  const options: DefaultOptionType[] | undefined = groups?.map(({ groupId, tutors }) => {
    const label = tutors.length
      ? `${groupId} (${tutors.map((tutor) => getFullName({ item: tutor })).join(', ')})`
      : groupId;

    return { label, value: groupId };
  });

  return [options, loading, error];
};
