import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useUserContext } from 'src/context';
import { UPDATE_BOARDER_ABSENCES, UPDATE_BOARDER_MEALS, UPDATE_BOARDER_WEEKEND_STAYS } from 'src/gql/mutations';
import { GET_SPECIFIC_BOARDER_CALENDAR } from 'src/gql/queries';
import {
  Boarder, UpdateBoarderAbsencesInput, UpdateBoarderMealsInput, UpdateBoarderWeekendStaysInput,
} from 'src/shared/types/api';
import { MonthNumber, GqlMutation, BoarderYear } from 'src/shared/types/common';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { boarderYearToMonthlyAbsences, boarderYearToMonthlyMeals, boarderYearToMonthlyWeekendStays } from './utils';

type UseOnBoarderCalendarSubmitArgs = {
  boarderId: Boarder['boarderId'] | undefined;
  firstDayOfUpdatedMonth: Dayjs;
  boarderYear: BoarderYear | undefined;
}

export const useOnBoarderCalendarSubmit = ({
  boarderId,
  boarderYear,
  firstDayOfUpdatedMonth,
}: UseOnBoarderCalendarSubmitArgs) => {
  const user = useUserContext();
  const { t } = useTranslation();

  const [updateBoarderAbsences] = useMutation<
    unknown, { input: UpdateBoarderAbsencesInput }
  >(UPDATE_BOARDER_ABSENCES);

  const [updateBoarderWeekendStays] = useMutation<
    unknown, { input: UpdateBoarderWeekendStaysInput }
  >(UPDATE_BOARDER_WEEKEND_STAYS);

  const [updateBoarderMeals] = useMutation<
    unknown, { input: UpdateBoarderMealsInput }
  >(UPDATE_BOARDER_MEALS, { refetchQueries: [GET_SPECIFIC_BOARDER_CALENDAR] });

  const onSubmit = async () => {
    if (!(boarderYear && boarderId)) {
      return notification.error({ message: t('Boarder year has not been updated successfully') });
    }

    const monthNumber = firstDayOfUpdatedMonth.month() as MonthNumber;
    const yearNumber = firstDayOfUpdatedMonth.year();

    const commonArgs = {
      boarderId,
      monthNumber,
      year: yearNumber,

    } as const;

    try {
      if (user?.isAllowed(GqlMutation.UpdateBoarderAbsences)) {
        await updateBoarderAbsences({
          variables: {
            input: {
              ...commonArgs,
              monthlyAbsences: JSON.stringify(boarderYearToMonthlyAbsences({
                boarderYear,
                monthNumber,
              })),
            },
          },
        });
        notification.success({ message: t('Boarder absences have been updated successfully') });
      }

      if (user?.isAllowed(GqlMutation.UpdateBoarderWeekendStays)) {
        await updateBoarderWeekendStays({
          variables: {
            input: {
              ...commonArgs,
              monthlyWeekendStays: JSON.stringify(boarderYearToMonthlyWeekendStays({
                boarderYear,
                monthNumber,
              })),
            },
          },
        });
        notification.success({ message: t('Boarder weekend stays have been updated successfully') });
      }

      await updateBoarderMeals({
        variables: {
          input: {
            ...commonArgs,
            monthlyMeals: JSON.stringify(boarderYearToMonthlyMeals({
              boarderYear,
              monthNumber,
            })),
          },
        },
      });
      notification.success({ message: t('Boarder meals have been updated successfully') });

      return window.scrollTo(0, 0);
    } catch (error) {
      return notification.error({ message: t('Boarder year has not been updated successfully') });
    }
  };

  return onSubmit;
};
