import { Table } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BoarderModal,
  BoardersViewFilter,
  BoardersViewHeaderButtons,
  BreadcrumbItem,
  filterBoarders,
  GenerateExcelReportModal,
  useBoardersTableColumns,
  ViewHeader,
} from 'src/components';
import { ROUTE } from 'src/constants';
import { BoardersFilter, useUserContext } from 'src/context';
import { BoarderListItem, useGetAllBoarders, useGetAllBoardersIconData } from 'src/gql/queries';
import { GqlQuery } from 'src/shared/types/common';
import { uniqByKey, uniq } from 'src/shared/utils';
import { defaultRoute, getRoomIdsRentedInCurrentWeek, navigateToItem } from 'src/utils';

export type BoarderIconsData = {
  isSuspended: boolean;
  awaitingRequests: boolean;
  isRoomRented: boolean;
}

export type BoarderAsViewed = BoarderListItem & Partial<BoarderIconsData>;

type BoarderAsViewedMapArgs = {
  allBoarders?: BoarderListItem[];
  iconsData?: Map<string, BoarderIconsData>;
}

const boarderAsViewedMap = (
  { allBoarders, iconsData }: BoarderAsViewedMapArgs,
): BoarderAsViewed[] => {
  if (!allBoarders) {
    return [];
  }

  if (!iconsData) {
    return allBoarders;
  }

  return allBoarders.map((boarder) => ({ ...boarder, ...iconsData.get(boarder.boarderId) }));
};

type BoardersViewProps = {
  boardersFilter: BoardersFilter;
  setBoardersFilter: Dispatch<SetStateAction<BoardersFilter>>;
}

export const BoardersView = ({ boardersFilter, setBoardersFilter }: BoardersViewProps) => {
  const { t } = useTranslation();
  const [allBoarders, setAllBoarders] = useState<BoarderAsViewed[]>();
  const [filteredBoarders, setFilteredBoarders] = useState<BoarderAsViewed[]>();
  const [isAddBoarderModalVisible, setIsAddBoarderModalVisible] = useState(false);
  const [isGenerateReportModalVisible, setIsGenerateReportModalVisible] = useState(false);
  const navigate = useNavigate();
  const user = useUserContext();

  const [boardersData, loading] = useGetAllBoarders();

  const [boardersIconsData] = useGetAllBoardersIconData();

  useEffect(() => {
    const roomIdsRentedInCurrentWeek = getRoomIdsRentedInCurrentWeek(
      boardersIconsData?.roomRentals,
    );

    const boardersIconsDataMap = boardersIconsData && new Map<string, BoarderIconsData>(
      boardersIconsData.boarders?.map(
        (boarder) => ([boarder.boarderId, {
          isSuspended: !!boarder.isSuspended,
          awaitingRequests: !!boarder.updateGeneralInfoRequests.awaiting.length,
          isRoomRented: Boolean(
            boarder.roomId && roomIdsRentedInCurrentWeek.includes(boarder.roomId),
          ),
        }]
        ),
      ),
    );

    const boardersAsViewed = boarderAsViewedMap({
      allBoarders: boardersData,
      iconsData: boardersIconsDataMap,
    });
    setAllBoarders(boardersAsViewed);
    setFilteredBoarders(boardersAsViewed);
  }, [boardersData, boardersIconsData?.boarders]);

  useEffect(() => {
    setFilteredBoarders((filterBoarders({ allBoarders, ...boardersFilter })));
  }, [allBoarders, boardersFilter]);

  const allGroupIds = uniqByKey({ array: allBoarders, key: 'groupId' });
  const allLabels = uniq(allBoarders?.flatMap((boarder) => boarder.labels));

  const boardersTableColumns = useBoardersTableColumns();

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.BOARDERS, title: t('Boarders') },
  ];

  const HeaderButton = BoardersViewHeaderButtons({
    onAddBoarderClick: () => setIsAddBoarderModalVisible(true),
    onGenerateReportClick: () => setIsGenerateReportModalVisible(true),
  });

  const HeaderFilter = useMemo(() => (
    <BoardersViewFilter
      boardersFilter={boardersFilter}
      setBoardersFilter={setBoardersFilter}
      groupIds={allGroupIds}
      labels={allLabels}
    />
  ), [allBoarders, boardersFilter]);

  if (user && !user?.isAllowed(GqlQuery.GetBoarders)) {
    navigate(defaultRoute(user));
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
        Filter={HeaderFilter}
        Buttons={HeaderButton}
        twoRows
      />
      <Table
        loading={loading}
        dataSource={filteredBoarders}
        size="middle"
        columns={boardersTableColumns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
        rowKey="boarderId"
        onRow={navigateToItem<BoarderListItem>({
          navigate,
          route: ROUTE.BOARDERS,
          rowKey: 'boarderId',
        })}
      />
      {isAddBoarderModalVisible && (
        <BoarderModal
          isModalVisible={isAddBoarderModalVisible}
          setIsModalVisible={setIsAddBoarderModalVisible}
        />
      )}
      {isGenerateReportModalVisible && (
        <GenerateExcelReportModal
          isModalVisible={isGenerateReportModalVisible}
          setIsModalVisible={setIsGenerateReportModalVisible}
          groupIds={allGroupIds}
          labels={allLabels}
        />
      )}
    </>
  );
};
