import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { birthPlaceRule } from '../rules';

export const BirthPlaceFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Birth place')}
      name="birthPlace"
      rules={[birthPlaceRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
