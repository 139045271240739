import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from 'src/shared/types/common';
import { getFullName, phoneNumberToDisplay } from 'src/shared/utils';

type RecruitmentParentsProps = {
  parents: ContactDetails[]
}

export const RecruitmentParents = ({
  parents,
}: RecruitmentParentsProps) => {
  const { t } = useTranslation();

  return (
    <List
      header={t('Parents')}
      bordered
      dataSource={parents}
      renderItem={(item) => (
        <List.Item>
          {getFullName({ item })}
          {item.phoneNumber ? ` · ${phoneNumberToDisplay(item.phoneNumber)}` : null}
          {item.email ? ` · ${item.email}` : null}
        </List.Item>
      )}
    />
  );
};
