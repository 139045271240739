import { Form } from 'antd';
import { FormProps } from '.';
import { PaymentCommentFormItem } from './items';

type BoarderPaymentCommentFormProps = FormProps<{ comment: string; }>

export const BoarderPaymentCommentForm = ({ form }: BoarderPaymentCommentFormProps) => (
  <Form layout="vertical" form={form}>
    <PaymentCommentFormItem required />
  </Form>
);
