import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { DailyOrderedMeals, Dormitory } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_WEEKLY_ORDERED_MEALS = gql`
  query GetDormitoryWeeklyOrderedMeals {
    dormitory {
      weeklyOrderedMeals {
        breakfastsCount
        lunchesCount
        dinnersCount
        weekDay
        date
      }
    }
  }
`;

export type GetDormitoryWeeklyOrderedMealsResponse = { dormitory: Pick<Dormitory, 'weeklyOrderedMeals'>}

export const useGetDormitoryWeeklyOrderedMeals = (): UseQueryReturnType<
  DailyOrderedMeals[]
> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryWeeklyOrderedMealsResponse>(
    GET_DORMITORY_WEEKLY_ORDERED_MEALS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch weekly ordered meals') });
  }

  return [data?.dormitory.weeklyOrderedMeals, loading, error];
};
