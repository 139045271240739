import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { titleRule } from '../rules';

export const TitleFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Title')}
      name="title"
      rules={[titleRule()]}
    >
      <Input />
    </Form.Item>
  );
};
