import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form } from 'antd';
import { ReactNode } from 'react';
import { FormItemCommonProps } from '.';

type FormListItemProps<T = { keyPrefix: string; }> = {
  name: string;
  // eslint-disable-next-line no-unused-vars
  renderItem: (props: T) => ReactNode
  otherProps: T;
  formTitle: string;
  removeItemText: string;
  addItemText: string;
} & FormItemCommonProps

// eslint-disable-next-line react/function-component-definition
export function FormListItem<T = { keyPrefix: string; }>({
  name,
  renderItem,
  otherProps,
  formTitle,
  removeItemText,
  addItemText,
  disabled,
}: FormListItemProps<T>) {
  return (
    <>
      <Divider plain>{formTitle}</Divider>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key }) => (
              <div key={key}>
                {renderItem({ ...otherProps, keyPrefix: key.toString() })}
                {!disabled && (
                  <Button
                    type="default"
                    onClick={() => remove(key)}
                    icon={<MinusCircleOutlined />}
                    block
                    danger
                  >
                    {removeItemText}
                  </Button>
                )}
                <Divider />
              </div>
            ))}
            {!disabled && (
              <Button
                type="default"
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
                block
              >
                {addItemText}
              </Button>
            )}
          </>
        )}
      </Form.List>
    </>
  );
}
