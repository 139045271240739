import { Theme, ThemeProvider } from '@aws-amplify/ui-react';
import { PropsWithChildren } from 'react';
import { tokens } from 'src/constants/tokens';

const theme: Theme = {
  name: 'Default Theme',
  tokens: {
    components: {
      authenticator: {
        router: {
          borderColor: tokens.color.border,
          borderWidth: { value: `${tokens.space.line}px` },
          boxShadow: tokens.shadow.card,
        },
      },
      button: {
        primary: {
          backgroundColor: tokens.color.primary,
          _hover: {
            backgroundColor: tokens.color.primary$hover,
          },
          _active: {
            backgroundColor: tokens.color.primary$active,
          },
          _focus: {
            backgroundColor: tokens.color.primary$active,
            boxShadow: { value: 'none' },
          },
        },
        link: {
          color: tokens.color.primary,
          backgroundColor: tokens.color.white,
          _hover: {
            color: tokens.color.primary$hover,
            backgroundColor: tokens.color.white,
          },
          _active: {
            color: tokens.color.primary$active,
            backgroundColor: tokens.color.white,
          },
          _focus: {
            color: tokens.color.primary$hover,
            backgroundColor: tokens.color.white,
            boxShadow: { value: 'none' },
          },
        },
        backgroundColor: tokens.color.white,
        _hover: {
          color: tokens.color.primary$hover,
          backgroundColor: tokens.color.white,
          borderColor: tokens.color.primary$hover,
        },
        _active: {
          color: tokens.color.primary$active,
          backgroundColor: tokens.color.white,
        },
        _focus: {
          color: tokens.color.primary$hover,
          backgroundColor: tokens.color.white,
          boxShadow: 'none',
        },

      },
      loader: {
        // strokeEmpty: { value: '{colors.neutral.20}' },
        strokeEmpty: { value: tokens.color.border },
        strokeFilled: { value: tokens.color.primary },
      },
    },
  },
};

export const AmplifyThemeProvider = ({ children }: PropsWithChildren<unknown>) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);
