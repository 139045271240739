import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { ClosedQuestionRadioOption } from 'src/components';

export const closedQuestionRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please select an available option'),
    validator: (_, value?: ClosedQuestionRadioOption) => {
      if (value && [ClosedQuestionRadioOption.No, ClosedQuestionRadioOption.Yes].includes(value)) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
  });
};
