import { Button, Tooltip, Popconfirm } from 'antd';
import { t } from 'i18next';
import { useUserContext } from 'src/context';
import { useToggleBoarderSuspensionStatus, useResendCredentialsToBoarder, useArchiveBoarder } from 'src/gql/mutations';
import { SpecificBoarder } from 'src/gql/queries';
import { GqlMutation, AccountStatus } from 'src/shared/types/common';

type BoarderGeneralInfoTabHeaderProps = {
  boarder?: SpecificBoarder;
}

export const BoarderGeneralInfoTabHeader = ({ boarder } : BoarderGeneralInfoTabHeaderProps) => {
  const user = useUserContext();

  const [
    toggleBoarderSuspensionStatus,
    toggleBoarderSuspensionStatusLoading,
  ] = useToggleBoarderSuspensionStatus({ boarder });

  const [
    resendCredentialsToBoarder,
    resendCredentialsLoading,
  ] = useResendCredentialsToBoarder({ boarder });

  const [
    archiveBoarder,
    archiveBoarderLoading,
  ] = useArchiveBoarder({ boarder });

  return (
    <div className="sub-actions-wrapper">

      {user?.isAllowed(GqlMutation.ToggleBoarderSuspensionStatus) && (
        <Button
          type={boarder?.isSuspended ? 'primary' : 'default'}
          size="small"
          loading={toggleBoarderSuspensionStatusLoading}
          onClick={toggleBoarderSuspensionStatus}
          className="header-item"
        >
          {boarder?.isSuspended ? t('Unsuspend') : t('Suspend')}
        </Button>
      )}

      {user?.isAllowed(GqlMutation.ResendCredentialsToBoarder) && (
        <Tooltip title={boarder?.accountStatus !== AccountStatus.FORCE_CHANGE_PASSWORD && t('Resend email with credentials disabled tooltip')}>
          <Button
            type="default"
            size="small"
            loading={resendCredentialsLoading}
            onClick={resendCredentialsToBoarder}
            disabled={boarder?.accountStatus !== AccountStatus.FORCE_CHANGE_PASSWORD}
            className="header-item"
          >
            {t('Send password')}
          </Button>
        </Tooltip>
      )}

      {user?.isAllowed(GqlMutation.ArchiveBoarder) && (
        <Popconfirm
          title={t('Archive boarder tooltip')}
          okText={t('Yes')}
          onConfirm={archiveBoarder}
          okButtonProps={{ loading: archiveBoarderLoading, danger: true }}
        >
          <Button
            type="default"
            size="small"
            className="header-item"
          >
            {t('Archive')}
          </Button>
        </Popconfirm>
      )}

    </div>
  );
};
