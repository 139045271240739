import {
  Alert,
  Form,
  Modal,
} from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendPaymentReminder } from 'src/gql/mutations';
import { SendPaymentReminderInput } from 'src/shared/types/api/inputs';
import { SendPaymentReminderForm } from '../forms';

type SendPaymentReminderModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const SendPaymentReminderModal = (
  { isModalVisible, setIsModalVisible }: SendPaymentReminderModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<SendPaymentReminderInput>();

  const [
    sendPaymentReminder,
    loading,
  ] = useSendPaymentReminder({
    form,
    onSuccess: () => setIsModalVisible(false),
  });

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={t('Sending payment reminder')}
      visible={isModalVisible}
      onOk={sendPaymentReminder}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Send payment reminder')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <SendPaymentReminderForm form={form} />
      <Alert
        message={t('Warning')}
        description={t('Send payment reminder warning')}
        type="warning"
        showIcon
      />
    </Modal>
  );
};
