import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UpdateBoarderGeneralInfoRequestPayload } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';
import { WHOLE_UPDATE_GENERAL_INFO_REQUEST_FRAGMENT } from '../../fragments';

export const GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS = gql`
  ${WHOLE_UPDATE_GENERAL_INFO_REQUEST_FRAGMENT}

  query GetSpecificBoarderPayments($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      boarderId

      firstName
      lastName
      phoneNumber
      email
      address { ...WholeAddressFragment }
      personalIdentityNumber
      birthDate
      birthPlace

      schoolDetails {
        schoolName
        schoolKind
        classNumber
      }

      updateGeneralInfoRequests {
        approved { ...WholeUpdateGeneralInfoRequestFragment }
        declined { ...WholeUpdateGeneralInfoRequestFragment }
        awaiting { ...WholeUpdateGeneralInfoRequestFragment }
      }
    }
  }
`;

export type BoarderWithRequests = UpdateBoarderGeneralInfoRequestPayload & Pick<Boarder, 'boarderId' | 'updateGeneralInfoRequests'>

export type GetSpecificBoarderUpdateGeneralInfoRequestsResponse = { boarder?: BoarderWithRequests };

export const useGetSpecificBoarderUpdateGeneralInfoRequests = (
  boarderId?: string,
): UseQueryReturnType<BoarderWithRequests> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetSpecificBoarderUpdateGeneralInfoRequestsResponse>(
    GET_SPECIFIC_BOARDER_UPDATE_GENERAL_INFO_REQUESTS,
    { variables: { boarderId } },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarder update general info requests') });
  }

  return [data?.boarder, loading, error];
};
