import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { WHOLE_ADDRESS_FRAGMENT } from 'src/gql/fragments';
import { Dormitory } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_GENERAL_INFO = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetDormitoryGeneralInfo {
    dormitory {
      schoolYear
      dormitoryDetails {
        name
        accountNumber
        address { ...WholeAddressFragment }
        emails {
          label
          value
        }
        phoneNumbers {
          label
          value
        }
        groups {
          groupId
          tutors {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

type DormitoryGeneralInfo = Pick<Dormitory, 'schoolYear' | 'dormitoryDetails'>;

export type GetDormitoryGeneralInfoResponse = { dormitory: DormitoryGeneralInfo };

export const useGetDormitoryGeneralInfo = (): UseQueryReturnType< DormitoryGeneralInfo> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryGeneralInfoResponse>(
    GET_DORMITORY_GENERAL_INFO,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory general info') });
  }

  return [data?.dormitory, loading, error];
};
