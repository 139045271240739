import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import {
  ModalAction,
  ModalState,
  RoomRentalModal,
  RoomRentalsTable,
} from 'src/components';
import { useState } from 'react';
import { GqlMutation, RoomRental } from 'src/shared/types/common';
import { useGetRoomRentals } from 'src/gql/queries';
import { useUserContext } from 'src/context';

export const DormitoryRoomRentalsTab = () => {
  const { t } = useTranslation();
  const user = useUserContext();

  const [
    modalState,
    setModalState,
  ] = useState<ModalState<RoomRental>>({ visible: false });

  const [roomRentals, loading] = useGetRoomRentals();

  const onUpdateRoomRental = (roomRental: RoomRental) => {
    setModalState({
      visible: true,
      action: ModalAction.Update,
      data: roomRental,
    });
  };

  return (
    <>
      {user?.isAllowed(GqlMutation.CreateRoomRental) && (
        <div className="sub-actions-wrapper">
          <Button
            type="primary"
            size="large"
            onClick={() => { setModalState({ visible: true, action: ModalAction.Create }); }}
          >
            <UsergroupAddOutlined />
            {t('Add room rental')}
          </Button>
        </div>
      )}
      <RoomRentalsTable
        roomRentals={roomRentals}
        loading={loading}
        onUpdate={onUpdateRoomRental}
      />
      {modalState.visible && (
        <RoomRentalModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
