import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { sorterByString } from '../sorters';

export const datetimeColumn = <T extends { datetime: string }>(t: TFunction): ColumnType<T> => ({
  title: t('Datetime'),
  dataIndex: 'datetime',
  defaultSortOrder: 'descend',
  sorter: (obj1, obj2, order) => sorterByString({
    obj1, obj2, key: 'datetime', order,
  }),
});
