import { gql } from '@apollo/client';

export const WHOLE_ACTIVITY_FRAGMENT = gql`
  fragment WholeActivityFragment on Activity {
    activityId
    activityType
    date
    registeredBy
  }
`;
