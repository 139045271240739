import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_RECRUITMENT_BOARDERS } from 'src/gql/queries/recruitment';
import { ConfirmRecruitmentRequestInput } from 'src/shared/types/api';
import { BoarderRecruitmentDoc } from 'src/shared/types/docs';
import { UseOnMutation } from '..';

export const CONFIRM_RECRUITMENT_REQUEST = gql`
  mutation ConfirmRecruitmentRequest($input: ConfirmRecruitmentRequestInput!) {
    confirmRecruitmentRequest(input: $input) {
      boarderRecruitmentId
    }
  }
`;

type useConfirmRecruitmentRequestArgs =
  Pick<BoarderRecruitmentDoc, 'boarderRecruitmentId'> & {
    onSuccess?: () => void;
    onComplete?: () => void;
  }

export const useConfirmRecruitmentRequest = (
  { boarderRecruitmentId, onSuccess, onComplete }: useConfirmRecruitmentRequestArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [confirmRecruitment, { loading }] = useMutation<
    unknown, { input: ConfirmRecruitmentRequestInput }
  >(CONFIRM_RECRUITMENT_REQUEST, { refetchQueries: [GET_RECRUITMENT_BOARDERS] });

  const onConfirmRecruitmentRequest = useCallback(async () => {
    try {
      await confirmRecruitment({
        variables: {
          input: {
            boarderRecruitmentId,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Recruitment request has been confirmed successfully') });
    } catch (error) {
      return notification.error({ message: t('Recruitment request has not been confirmed successfully') });
    } finally {
      onComplete?.();
    }
  }, [boarderRecruitmentId, onSuccess, onComplete]);

  return [onConfirmRecruitmentRequest, loading];
};
