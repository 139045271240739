import { Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { CONFIG } from 'src/config';
import { STYLE } from 'src/constants';
import { RecruitmentEvent } from 'src/shared/types/docs';

type RecruitmentEventsProps = {
  events: RecruitmentEvent[]
}

export const RecruitmentEvents = ({
  events,
}: RecruitmentEventsProps) => {
  const { t } = useTranslation();

  return (
    <Timeline style={{ padding: STYLE.BASE_MARGIN }}>
      {events.map(({ createdBy, event, timestamp }) => (
        <Timeline.Item key={timestamp}>
          {`${new Date(timestamp).toLocaleString(CONFIG.LOCALE)}: ${t(event)} (${createdBy})`}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};
