import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { polishSimpleNameRegex } from 'src/shared/regex';

export const firstNameRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    required: true,
    message: t('Please enter correct first name'),
    pattern: polishSimpleNameRegex,
  });
};
