import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { useRecruitmentStepPathIndex, useIsMobile } from 'src/hooks';

export const RecruitmentSteps = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const currentStep = useRecruitmentStepPathIndex();

  return currentStep ? (
    <Steps
      current={currentStep}
      style={
        isMobile
          ? { display: 'none' }
          : { marginTop: 2 * STYLE.BASE_MARGIN, marginBottom: 2 * STYLE.BASE_MARGIN }
            }
    >
      <Steps.Step title={t('StartStep')} />
      <Steps.Step title={t('BoarderDataStep')} />
      <Steps.Step title={t('QuestionnaireStep')} />
      <Steps.Step title={t('AgreementsStep')} />
      <Steps.Step title={t('SummaryStep')} />
    </Steps>
  ) : null;
};
