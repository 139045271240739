import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { FirstNameFormItem } from './firstName';
import { LastNameFormItem } from './lastName';

export const FullNameFormItems = ({ keyPrefix, disabled }: FormItemCommonProps) => (
  <Row gutter={STYLE.BASE_MARGIN}>
    <Col xs={24} sm={12}>
      <FirstNameFormItem keyPrefix={keyPrefix} disabled={disabled} />
    </Col>
    <Col xs={24} sm={12}>
      <LastNameFormItem keyPrefix={keyPrefix} disabled={disabled} />
    </Col>
  </Row>
);
