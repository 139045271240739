import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { NoteCategory } from 'src/shared/types/common';
import { FormItemCommonProps } from '.';
import { noteCategoryRule } from '../rules';

type NoteCategoryFormItemProps = FormItemCommonProps & {
  withDormitoryCategory?: boolean;
  onDormitorySelect: () => void;
  onBoarderSelect: () => void;
}

export const NoteCategoryFormItem = ({
  disabled,
  withDormitoryCategory,
  onDormitorySelect,
  onBoarderSelect,
}: NoteCategoryFormItemProps) => {
  const { t } = useTranslation();

  const options: DefaultOptionType[] = [
    { value: NoteCategory.HEALTH, label: t(NoteCategory.HEALTH) },
    { value: NoteCategory.BEHAVIOR, label: t(NoteCategory.BEHAVIOR) },
    { value: NoteCategory.OTHER, label: t(NoteCategory.OTHER) },
  ];

  if (withDormitoryCategory) {
    options.push({ value: NoteCategory.DORMITORY, label: t(NoteCategory.DORMITORY) });
  }

  return (
    <Form.Item
      label={t('Note category')}
      name="category"
      rules={[noteCategoryRule()]}
      tooltip={withDormitoryCategory && t('Dormitory notes tip')}
    >
      <Select
        disabled={disabled}
        options={options}
        onSelect={(category: NoteCategory) => {
          if (category === NoteCategory.DORMITORY) {
            onDormitorySelect();
          } else {
            onBoarderSelect();
          }
        }}
      />
    </Form.Item>
  );
};
