import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_BOARDERS_ARCHIVE = gql`
  query GetAllBoardersArchive {
    boardersArchive {
      boarderId
      firstName
      lastName
      labels
      roomId
      groupId
      lastActivity
      balance
      phoneNumber
      isSuspended
      archivedAt
    }
  }
`;

export type BoarderArchive = Pick<Boarder,
  'boarderId' |
  'firstName' | 'lastName' | 'labels' |
  'roomId' | 'groupId' | 'lastActivity' |
  'balance' | 'phoneNumber' | 'isSuspended' | 'archivedAt'
>;

type GetAllBoardersArchiveResponse = {
  boardersArchive: BoarderArchive[];
}

export const useGetAllBoardersArchive = (): UseQueryReturnType<BoarderArchive[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllBoardersArchiveResponse>(
    GET_ALL_BOARDERS_ARCHIVE,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarders archive') });
  }

  return [data?.boardersArchive, loading, error];
};
