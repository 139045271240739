import { Breadcrumb as AntBreadcrumb } from 'antd';
import { ComponentElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRouteTranslated, ROUTE } from 'src/constants';

export type BreadcrumbItem = {
  route?: ROUTE;
  title: string;
}

type ViewHeaderProps = {
  breadcrumbItems: BreadcrumbItem[];
  Buttons?: ComponentElement<unknown, any>;
  Filter?: ComponentElement<unknown, any>;
  twoRows?: true;
}

export const ViewHeader = ({
  breadcrumbItems,
  Buttons,
  Filter,
  twoRows,
}: ViewHeaderProps) => {
  const navigate = useNavigate();

  const Breadcrumb = (
    <AntBreadcrumb>
      {breadcrumbItems.map(({ title, route }) => (
        <AntBreadcrumb.Item
          key={route || title}
          onClick={() => route && navigate(getRouteTranslated(route))}
        >
          {title}
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  );

  return twoRows ? (
    <>
      <div className="view-header-two-rows-1">
        {Breadcrumb}
        <div>
          {Buttons}
        </div>
      </div>
      <div className="view-header-two-rows-2">
        {Filter}
      </div>
    </>
  ) : (
    <div className="view-header-one-row-wrapper">
      {Breadcrumb}
      <div className="view-header-one-row-control-wrapper">
        {Filter}
        {Buttons}
      </div>
    </div>
  );
};
