import {
  Alert,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { toSelectOptions } from 'src/utils';
import { FormProps } from '.';

/* eslint-disable */ // eslint doesn't handle const enums properly
export const enum BoarderGeneralInfoLabels {
  FullName = 'fullName',
  PersonalIdentityNumber = 'personalIdentityNumber',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  BirthDate = 'birthDate',
  BirthPlace = 'birthPlace',
  Address = 'address',
}

export const enum BoarderDataLabels {
  Labels = 'labels',
  CardId = 'cardId',
  GroupId = 'groupId',
  RoomId = 'roomId',
  LivingStartData = 'livingStartData',
  LivingEndData = 'livingEndData',
  Presence = 'presence',
  Suspension = 'suspension',
  Balance = 'balance',
  Subscription = 'subscription',
}

export const enum StudentDataLabels {
  SchoolName = 'schoolName',
  SchoolKind = 'schoolKind',
  ClassNumber = 'classNumber',
}

export const enum ParentsDataLabels {
  FullName = 'fullName',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}

export const enum OtherSettingsLabels {
  FullNameSeparately = "fullNameSeparately",
  LastNameFirst = "lastNameFirst",
  AddressAsOneCell = "addressAsOneCell",
}

/* eslint-enable */

export type GenerateExcelReportFormType = {
  boarderGeneralInfo?: BoarderGeneralInfoLabels[];
  boarderData?: BoarderDataLabels[];
  studentData?: StudentDataLabels[];
  parentsData?: ParentsDataLabels[];
  groupIds?: string[];
  labels?: string[];
  minBalance?: number;
  maxBalance?: number;
  otherSettings?: OtherSettingsLabels;
}

type GenerateExcelReportFormProps = {
  groupIds: string[];
  labels: string[];
} & FormProps<GenerateExcelReportFormType>

export const GenerateExcelReportForm = ({
  form,
  groupIds,
  labels,
}: GenerateExcelReportFormProps) => {
  const { t } = useTranslation();

  return (
    <Form form={form} layout="vertical">

      <Alert
        style={{ marginBottom: STYLE.BASE_MARGIN }}
        message={t('Boarder properties in report')}
        description={t('Boarder properties in report tip')}
        type="info"
        showIcon
      />

      <Form.Item
        label={t('Boarder general info')}
        name="boarderGeneralInfo"
      >
        <Checkbox.Group className="full-width">
          <Row>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.FullName}>{t('Full name reverted')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.PersonalIdentityNumber}>{t('PESEL')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.PhoneNumber}>{t('Phone number')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.Email}>{t('Email')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.BirthDate}>{t('Birth date')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.BirthPlace}>{t('Birth place')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderGeneralInfoLabels.Address}>{t('Address')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        label={t('Boarder data')}
        name="boarderData"
      >
        <Checkbox.Group className="full-width">
          <Row>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.Labels}>{t('Labels')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.CardId}>{t('Card ID')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.GroupId}>{t('Group ID')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.RoomId}>{t('Room ID')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.LivingStartData}>{t('Living start date')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.LivingEndData}>{t('Living end date')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.Presence}>{t('Presence')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.Suspension}>{t('Suspension')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.Balance}>{t('Balance')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={BoarderDataLabels.Subscription}>{t('Subscription')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        label={t('Student data')}
        name="studentData"
      >
        <Checkbox.Group className="full-width">
          <Row>
            <Col span={12}>
              <Checkbox value={StudentDataLabels.SchoolName}>{t('School name')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={StudentDataLabels.SchoolKind}>{t('School kind')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={StudentDataLabels.ClassNumber}>{t('Class number')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        label={t('Parents')}
        name="parentsData"
      >
        <Checkbox.Group className="full-width">
          <Row>
            <Col span={12}>
              <Checkbox value={ParentsDataLabels.FullName}>{t('Full name reverted')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={ParentsDataLabels.PhoneNumber}>{t('Phone number')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={ParentsDataLabels.Email}>{t('Email')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Alert
        style={{ marginBottom: STYLE.BASE_MARGIN }}
        message={t('Boarder filter in report')}
        description={t('Boarder filter in report tip')}
        type="info"
        showIcon
      />

      <div className="space-between">
        <Form.Item name="groupIds">
          <Select
            mode="tags"
            allowClear
            placeholder={t('Group IDs')}
            style={{ width: 220 }}
            options={toSelectOptions(groupIds)}
          />
        </Form.Item>
        <Form.Item name="labels">
          <Select
            mode="tags"
            allowClear
            placeholder={t('Labels')}
            style={{ width: 220 }}
            options={toSelectOptions(labels)}
          />
        </Form.Item>
      </div>

      <div className="large-items-container">
        <Form.Item name="minBalance">
          <InputNumber
            placeholder={t('Minimum balance')}
            addonAfter={t('PLN')}
            min={-100_000}
            max={100_000}
          />
        </Form.Item>
        <Input style={{ width: STYLE.BASE_MARGIN * 2, height: STYLE.LARGE_ITEM_HEIGHT + 2 }} placeholder="~" disabled />
        <Form.Item name="maxBalance">
          <InputNumber
            placeholder={t('Maximum balance')}
            addonAfter={t('PLN')}
            min={-100_000}
            max={100_000}
          />
        </Form.Item>
      </div>

      <Alert
        style={{ marginBottom: STYLE.BASE_MARGIN }}
        message={t('Generate Excel report other settings')}
        type="info"
        showIcon
      />

      <Form.Item
        name="otherSettings"
      >
        <Checkbox.Group className="full-width">
          <Row>
            <Col span={12}>
              <Checkbox value={OtherSettingsLabels.FullNameSeparately}>{t('Full name separately')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={OtherSettingsLabels.LastNameFirst}>{t('Last name first')}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value={OtherSettingsLabels.AddressAsOneCell}>{t('Address as one cell')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

    </Form>
  );
};
