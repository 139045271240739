import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Administrator } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_ADMINISTRATORS = gql`
  query GetAdministrators {
    administrators {
      administratorId
      firstName
      lastName
      email
      phoneNumber
      accountStatus
    }
  }
`;

export type GetAllAdministratorsResponse = { administrators: Administrator[] };

export const useGetAllAdministrators = (): UseQueryReturnType<Administrator[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllAdministratorsResponse>(
    GET_ALL_ADMINISTRATORS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch administrators') });
  }

  return [data?.administrators, loading, error];
};
