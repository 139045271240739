import { gql, useMutation } from '@apollo/client';
import { FormInstance, notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SpecificBoarder, GET_SPECIFIC_BOARDER } from 'src/gql/queries';
import { UpdateBoarderGeneralInfoInput } from 'src/shared/types/api';
import { UpdateBoarderForm } from 'src/views/BoarderTabs';
import { updateBoarderFormToMutationInput } from 'src/views/BoarderTabs/utils';
import { UseOnMutation } from '..';

const UPDATE_BOARDER_GENERAL_INFO = gql`
  mutation UpdateBoarderGeneralInfo($input: UpdateBoarderGeneralInfoInput!) {
    updateBoarderGeneralInfo(input: $input) {
      boarderId
    }
  }
`;

type UseUpdateBoarderGeneralInfoArgs = {
  boarder?: SpecificBoarder;
  form: FormInstance<UpdateBoarderForm>;
}

export const useUpdateBoarderGeneralInfo = (
  { boarder, form }: UseUpdateBoarderGeneralInfoArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateBoarderGeneralInfo, { loading }] = useMutation<
    unknown, { input: UpdateBoarderGeneralInfoInput }
  >(UPDATE_BOARDER_GENERAL_INFO, { refetchQueries: [GET_SPECIFIC_BOARDER] });

  const onUpdateBoarderGeneralInfo = useCallback(async () => {
    const formFields = await form.validateFields();
    if (boarder?.boarderId) {
      try {
        await updateBoarderGeneralInfo({
          variables: {
            input: {
              boarderId: boarder?.boarderId,
              generalInfo: updateBoarderFormToMutationInput(formFields),
            },
          },
        });
        window.scrollTo(0, 0);

        return notification.success({ message: t('Boarder has been updated successfully') });
      } catch (error) {
        if ((error as GraphQLError)?.message === 'UserExists') {
          return notification.error({ message: t('Boarder has not been updated successfully. User exists') });
        }

        return notification.error({ message: t('Boarder has not been updated successfully') });
      }
    } else {
      return notification.error({ message: t('Boarder has not been updated successfully') });
    }
  }, [boarder, form]);

  return [onUpdateBoarderGeneralInfo, loading];
};
