import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { MinMaxRange, toSelectOptions } from 'src/utils';
import { FormItemCommonProps } from '.';
import { classNumberRule } from '../rules';

type ClassNumberFormItemProps = { range: MinMaxRange } & FormItemCommonProps;

export const ClassNumberFormItem = ({ range, disabled }: ClassNumberFormItemProps) => {
  const { t } = useTranslation();
  const validClassNumbers: number[] = [];

  if (
    range.min !== undefined
    && range.max
    && range.max !== Number.MAX_SAFE_INTEGER
    && range.min < range.max
  ) {
    for (let i = range.min; i <= range.max; i += 1) {
      validClassNumbers.push(i);
    }
  }

  return (
    <Form.Item
      label={t('Class number')}
      name="classNumber"
      rules={[classNumberRule(range)]}
    >
      <Select
        allowClear
        notFoundContent={t('Please select school kind')}
        disabled={disabled}
        options={toSelectOptions(validClassNumbers)}
      />
    </Form.Item>
  );
};
