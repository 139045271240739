import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_BOARDERS = gql`
  query GetAllBoarders {
    boarders {
      boarderId
      firstName
      lastName
      labels
      roomId
      groupId
      isPresent
      lastActivity
      balance
      phoneNumber
    }
  }
`;

export type BoarderListItem = Pick<Boarder,
  'boarderId' |
  'firstName' | 'lastName' | 'labels' |
  'roomId' | 'groupId' |
  'isPresent' | 'lastActivity' |
  'balance' | 'phoneNumber'
>

export type GetAllBoardersResponse = {
  boarders: BoarderListItem[];
}

export const useGetAllBoarders = (): UseQueryReturnType<BoarderListItem[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllBoardersResponse>(
    GET_ALL_BOARDERS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarders') });
  }

  return [data?.boarders, loading, error];
};
