import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { datetimeColumn, downloadReportColumn } from './utils';

export type ReportsTableData = {
  datetime: string,
  key: string
}

type ReportsTableProps = {
  reports?: ReportsTableData[];
  loading: boolean;
}

export const ReportsTable = ({ loading, reports }: ReportsTableProps) => {
  const { t } = useTranslation();

  const columns = [
    datetimeColumn<ReportsTableData>(t),
    downloadReportColumn(t),
  ];

  return (
    <Table
      className="dormitory-tab-content-wrapper"
      dataSource={reports}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 50,
      }}
      size="small"
      columns={columns}
      rowKey="key"
      loading={loading}
    />
  );
};
