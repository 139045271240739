import { Button, Typography, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ClosedQuestionRadioOption,
  RecruitmentForm,
  RecruitmentSummaryQuestionnaire,
  RecruitmentSummaryRegisteredBoarder,
  RecruitmentSummaryUnregisteredBoarder,
} from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { useRecruitmentFormContext } from 'src/context';
import { BoarderRecruitmentRequest } from 'src/shared/types/api';
import { toIsoDateString } from 'src/shared/utils';
import { formValuesToAddress, formValuesToContactDetails } from 'src/utils';

const { Paragraph } = Typography;

// TODO: CAPTCHA

const recruitmentFormToRequest = (
  form: RecruitmentForm,
  clausesAndAgreements: string[],
): BoarderRecruitmentRequest => {
  // TODO: for some reason it is not defined
  // Should be fixed
  // if (!form.agreements) {
  //   throw Error('Missing agreements');
  // }

  if (!form.questionnaire) {
    throw Error('Missing questionnaire');
  }

  if (!form.startStep) {
    throw Error('Missing startStep');
  }

  if (!(form.registeredBoarderData || form.unregisteredBoarderData)) {
    throw Error('Missing boarder data');
  }

  return {
    acceptedClausesAndAgreements: clausesAndAgreements, // TODO: add only accepted
    questionnaire: Object.entries(form.questionnaire).reduce(
      (prev, [currKey, currValue]) => {
        let value: string | boolean;

        if (currValue === ClosedQuestionRadioOption.Yes) {
          value = true;
        } else if (currValue === ClosedQuestionRadioOption.No) {
          value = false;
        } else {
          value = currValue;
        }

        return {
          ...prev,
          [currKey]: value,
        };
      },
      {} as BoarderRecruitmentRequest['questionnaire'],
    ),
    registeredBoarder: form.registeredBoarderData,
    unregisteredBoarder: form.unregisteredBoarderData ? {
      firstName: form.unregisteredBoarderData.firstName,
      lastName: form.unregisteredBoarderData.lastName,
      email: form.unregisteredBoarderData.email,
      phoneNumber: phoneNumberToDto(form.unregisteredBoarderData.phoneNumber),
      personalIdentityNumber: form.unregisteredBoarderData.personalIdentityNumber,
      birthDate: form.unregisteredBoarderData.birthDate
        ? toIsoDateString(form.unregisteredBoarderData.birthDate.toDate())
        : undefined,
      birthPlace: form.unregisteredBoarderData.birthPlace,
      address: formValuesToAddress(form.unregisteredBoarderData),
      parents: form.unregisteredBoarderData.parents.map(formValuesToContactDetails),
    } : undefined,
  };
};

export const SummaryStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const recruitmentFormContext = useRecruitmentFormContext();
  const recruitmentForm = recruitmentFormContext?.recruitmentForm;
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (!recruitmentForm) {
      return message.error(t('Unknown error occurred'));
    }

    setLoading(true);
    const requestBody = recruitmentFormToRequest(recruitmentForm, [
      'klauzula informacyjna dla wychowanków i rodziców – proces rekrutacji',
      'klauzula informacyjna dla wychowanków i rodziców – po przyjęciu',
      t('Agreement1Title'),
      t('Agreement2Title'),
      t('Agreement3Title'),
      t('Agreement4Title'),
    ]);

    try {
      const response = await fetch(new Request(
        `${process.env.REACT_APP_E_BURSA_API_ENDPOINT}/requestBoarderRecruitment`,
        {
          body: JSON.stringify(requestBody),
          method: 'POST',
        },
      ));
      const jsonData = await response.json();
      setLoading(false);

      if (response.status === 200) {
        return navigate(`/${t('recruitment-succeeded-step')}`, { replace: true });
      }

      if (response.status === 400) {
        if (jsonData.message === 'BOARDER_ALREADY_RECRUITED') {
          return navigate(`/${t('boarder-already-recruited-step')}`, { replace: true });
        }

        return message.error(t('Unknown error occurred. Please contact with administration'));
      }

      if (response.status === 403) {
        return message.error(t('Wrong recruitment authorization data', { count: 20_000 }));
      }

      return message.error(t('Unknown error occurred. Please contact with administration'));
    } catch (error) {
      setLoading(false);

      return message.error(t('Unknown error occurred. Please contact with administration'));
    }
  };

  return (
    <>
      <Paragraph>{t('Summary step')}</Paragraph>
      <Paragraph>
        {recruitmentForm?.registeredBoarderData
          ? t('Registered boarder recruitment information')
          : t('Unregistered boarder recruitment information')}
      </Paragraph>
      <RecruitmentSummaryUnregisteredBoarder />
      <RecruitmentSummaryRegisteredBoarder />
      <RecruitmentSummaryQuestionnaire values={recruitmentForm?.questionnaire} />
      <div className="boarder-tab-content-wrapper">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          onClick={onClick}
          className="vertical-base-margin "
          block
        >
          {t('Save recruitment request')}
        </Button>
      </div>
    </>
  );
};
