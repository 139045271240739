import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validSchoolName } from 'src/shared/validatorUtils';

export const schoolNameRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct school name'),
    validator: (_, value: string) => (
      !value || validSchoolName(value)
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
