import { RecruitmentQuestionnaireFormFields, toClosedQuestionRadio } from 'src/components';
import { RecruitmentBoarderRequestDataSource } from 'src/gql/queries/recruitment';

export const boarderRequestToQuestionnaireForm = (
  boarderRequest: RecruitmentBoarderRequestDataSource,
): RecruitmentQuestionnaireFormFields => ({
  isAcolyte: toClosedQuestionRadio(boarderRequest.questionnaire.isAcolyte),
  parishName: boarderRequest.questionnaire.parishName,
  isVolunteer: toClosedQuestionRadio(boarderRequest.questionnaire.isVolunteer),
  groupName: boarderRequest.questionnaire.groupName,
  isLargeFamily: toClosedQuestionRadio(boarderRequest.questionnaire.isLargeFamily),
  isSingleParent: toClosedQuestionRadio(boarderRequest.questionnaire.isSingleParent),
  isRefugee: toClosedQuestionRadio(boarderRequest.questionnaire.isRefugee),
  isFoster: toClosedQuestionRadio(boarderRequest.questionnaire.isFoster),
  hadReligiousEducation: toClosedQuestionRadio(boarderRequest.questionnaire.hadReligiousEducation),
  religiousEducationPlans: toClosedQuestionRadio(
    boarderRequest.questionnaire.religiousEducationPlans,
  ),
  isOrWantsToBeConfirmed: toClosedQuestionRadio(
    boarderRequest.questionnaire.isOrWantsToBeConfirmed,
  ),
  isSportsman: toClosedQuestionRadio(boarderRequest.questionnaire.isSportsman),
  clubName: boarderRequest.questionnaire.clubName,
  alcohol: toClosedQuestionRadio(boarderRequest.questionnaire.alcohol),
  cigarettes: toClosedQuestionRadio(boarderRequest.questionnaire.cigarettes),
  narcotics: toClosedQuestionRadio(boarderRequest.questionnaire.narcotics),
  gambling: toClosedQuestionRadio(boarderRequest.questionnaire.gambling),
  addiction: toClosedQuestionRadio(boarderRequest.questionnaire.addiction),
  isDemoralized: toClosedQuestionRadio(boarderRequest.questionnaire.isDemoralized),
  isPsychotic: toClosedQuestionRadio(boarderRequest.questionnaire.isPsychotic),
  psychotic: boarderRequest.questionnaire.psychotic,
  isIll: toClosedQuestionRadio(boarderRequest.questionnaire.isIll),
  ill: boarderRequest.questionnaire.ill,
  isAllergic: toClosedQuestionRadio(boarderRequest.questionnaire.isAllergic),
  allergies: boarderRequest.questionnaire.allergies,
});
