import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';
import { RecruitmentBoarderRequestDataSource } from 'src/gql/queries/recruitment';
import { RecruitmentEventEnum } from 'src/shared/types/docs';

export const recruitmentCreatedAtColumn = (
  t: TFunction,
): ColumnType<RecruitmentBoarderRequestDataSource> => ({
  title: t('Created'),
  dataIndex: 'createdAt',
  sorter: (a, b) => {
    const aCreatedAt = a.events.find(
      ({ event }) => event === RecruitmentEventEnum.REQUEST_CREATED,
    )?.timestamp;
    const bCreatedAt = b.events.find(
      ({ event }) => event === RecruitmentEventEnum.REQUEST_CREATED,
    )?.timestamp;

    if (!(aCreatedAt && bCreatedAt)) {
      return 0;
    }

    return new Date(aCreatedAt).getTime() - new Date(bCreatedAt).getTime();
  },
  width: 120,
  render: (_, item) => {
    const createdAtEvent = item.events.find(
      ({ event }) => event === RecruitmentEventEnum.REQUEST_CREATED,
    );

    return createdAtEvent?.timestamp ? new Date(createdAtEvent?.timestamp).toLocaleDateString(CONFIG.LOCALE) : '';
  }

  ,
});
