import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import { ConfigProvider, notification } from 'antd';
import plLocaleAnt from 'antd/es/locale/pl_PL';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import plLocaleDayjs from 'dayjs/locale/pl';

import { Buffer } from 'buffer';

import { apolloClient, CONFIG } from './config';
import { AmplifyThemeProvider } from './amplify';

import '@aws-amplify/ui-react/styles.css';
import './App.less';
import './i18n';

global.Buffer = Buffer;

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale(plLocaleDayjs);

notification.config({
  duration: 8,
  placement: 'bottomRight',
});

function importBuildTarget() {
  if (CONFIG.APP_TYPE === 'APP') {
    return import('./App');
  }

  if (CONFIG.APP_TYPE === 'RECRUITMENT') {
    return import('./RecruitmentApp');
  }

  return Promise.reject(
    new Error(`No such build target: ${CONFIG.APP_TYPE}`),
  );
}

// Strict mode disabled because of findDOMNode warning
// https://github.com/ant-design/ant-design/issues/22493
importBuildTarget().then(({ App }) => {
  // eslint-disable-next-line global-require
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider locale={plLocaleAnt}>
          <AmplifyThemeProvider>
            <App />
          </AmplifyThemeProvider>
        </ConfigProvider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
});
