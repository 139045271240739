import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

type ViewHeaderSearchProps = {
  defaultValue?: string;
  // eslint-disable-next-line no-unused-vars
  onSearch: (searchText: string) => void; // eslint bug
}

export const ViewHeaderSearch = ({ defaultValue, onSearch }: ViewHeaderSearchProps) => {
  const { t } = useTranslation();

  return (
    <Input
      className="header-search-input header-item-medium"
      placeholder={t('Search placeholder')}
      defaultValue={defaultValue}
      allowClear
      onChange={(event) => onSearch(event.target.value)}
      prefix={<SearchOutlined />}
    />
  );
};
