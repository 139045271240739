import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const weekDayColumn = <T extends { weekDay: string; }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Week day'),
    key: 'weekDay',
    render: (_, item) => (t(item.weekDay)),
  });
