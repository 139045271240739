import logo from 'src/assets/bursa-logo-no-margin-alpha.png';
import {
  Layout,
} from 'antd';
import { CONFIG } from 'src/config';

export const RecruitmentHeader = () => (
  <Layout.Header className="empty-header">
    <div className="layout-header-wrapper">
      <a
        href={CONFIG.DORMITORY_WEB_PAGE}
        target="blank"
      >
        <img src={logo} className="header-logo" alt="logo" />
      </a>
    </div>
  </Layout.Header>

);
