import {
  Col, Form, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { schoolNameRule } from '../rules';

export const SchoolNameFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <Form.Item
          label={t('School name')}
          name="schoolName"
          rules={[schoolNameRule()]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  );
};
