import writeXlsxFile from 'write-excel-file';
import { Form, Modal, notification } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllBoardersReportData } from 'src/gql/queries';
import { getExcelReportFileName, getExcelSchema, getExcelReportData } from 'src/utils';
import { GenerateExcelReportForm, GenerateExcelReportFormType } from '../forms';

type GenerateExcelReportModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  groupIds: string[];
  labels: string[];
}

export const GenerateExcelReportModal = (
  {
    isModalVisible,
    setIsModalVisible,
    groupIds,
    labels,
  }: GenerateExcelReportModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<GenerateExcelReportFormType>();
  const [loading, setLoading] = useState(false);
  const fetchAllBoardersReportData = useGetAllBoardersReportData();

  if (!isModalVisible) {
    return null;
  }

  const handleSubmit = async () => {
    const formValues = await form.validateFields();
    setLoading(true);

    try {
      const allBoardersAndParents = await fetchAllBoardersReportData();
      const reportData = getExcelReportData({
        boarders: allBoardersAndParents.data.boarders,
        parents: allBoardersAndParents.data.parents,
        filters: {
          groupIds: formValues.groupIds,
          labels: formValues.labels,
          minBalance: formValues.minBalance,
          maxBalance: formValues.maxBalance,
        },
      });

      await writeXlsxFile(reportData.boarders, {
        schema: getExcelSchema({
          config: formValues,
          t,
          maxParentCount: reportData.maxParentCount,
        }),
        fileName: getExcelReportFileName(),
        headerStyle: {
          fontWeight: 'bold',
          fontSize: 12,
          height: 16,
        },
        stickyRowsCount: 1,
      });

      notification.success({ message: t('Excel report has been generated successfully') });
    } catch (error) {
      notification.error({ message: t('Excel report has not been generated successfully') });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('Generating Excel report')}
      visible={isModalVisible}
      onOk={handleSubmit}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Generate')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <GenerateExcelReportForm form={form} groupIds={groupIds} labels={labels} />
    </Modal>
  );
};
