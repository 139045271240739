import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const recipientsRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please select recipients'),
    required: true,
    validator: (_, value?: string[]) => (
      value?.length
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
