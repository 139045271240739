import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { ClauseOption } from '../RecruitmentStartStepForm';

export const clausesRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please accept all clauses'),
    validator: (_, value?: string[]) => {
      if (
        value?.length === 2
        && value.includes(ClauseOption.RecruitmentClause)
        && value.includes(ClauseOption.DormitoryClause)
      ) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
  });
};
