/* eslint-disable no-unused-vars */
import { Button, ButtonProps } from 'antd';
import { useState } from 'react';

export type AsyncButtonProps<ResultType = unknown> = {
  onClick?: (() => Promise<void>) | (() => void);
  onError?: ((error: unknown) => Promise<void>) | ((error: unknown) => void);
  onSuccess?:
    | ((result: ResultType) => Promise<void>)
    | ((result: ResultType) => void);
  loading?: boolean;
} & ButtonProps;

export const AsyncButton = ({
  onClick,
  onError,
  onSuccess,
  loading,
  children,
  ...buttonProps
}: AsyncButtonProps) => {
  const [internalLoading, setInternalLoading] = useState(false);

  const internalOnClick = async () => {
    setInternalLoading(true);

    try {
      const result = onClick && (await onClick());
      if (onSuccess) {
        await onSuccess(result);
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    } finally {
      setInternalLoading(false);
    }
  };

  const isLoading = loading || internalLoading;
  const disabled = buttonProps?.disabled || isLoading;

  return (
    <Button
      {...buttonProps}
      onClick={internalOnClick}
      loading={isLoading}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
