import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { sorterByString } from '../sorters';

export const groupColumn = <T extends { groupId?: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Group ID'),
    dataIndex: 'groupId',
    sorter: (obj1, obj2, order) => sorterByString({
      obj1, obj2, key: 'groupId', order, numeric: true,
    }),
  });
