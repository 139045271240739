import { Col, Row } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps, LivingEndDateFormItem, LivingStartDateFormItem } from '.';

export const PeriodOfStayInDormitoryFormItem = ({ disabled }: FormItemCommonProps) => (
  <Row gutter={STYLE.BASE_MARGIN}>
    <Col xs={12}>
      <LivingStartDateFormItem disabled={disabled} />
    </Col>
    <Col xs={12}>
      <LivingEndDateFormItem disabled={disabled} />
    </Col>
  </Row>
);
