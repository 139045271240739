import { Form, Modal, Spin } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllBoardersBriefMap } from 'src/gql/queries';
import { useOnNoteModalSubmit } from 'src/hooks';
import { ModalAction, ModalState } from '.';
import { NoteForm, NoteModalForm } from '../forms';

export type NoteModalStateData = NoteForm & { noteId?: string };

type NoteModalProps = {
  modalState: ModalState<NoteModalStateData>
  setModalState: Dispatch<SetStateAction<ModalState<NoteModalStateData>>>;
  withDormitoryCategory?: boolean;
}

export const NoteModal = ({
  modalState,
  setModalState,
  withDormitoryCategory,
}: NoteModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<NoteForm>();
  const [allBoardersBriefMap, allBoardersBriefMapLoading] = useGetAllBoardersBriefMap();
  const [onSubmit, loading] = useOnNoteModalSubmit({
    form,
    modalStateData: modalState.visible && modalState.action === ModalAction.Update
      ? modalState.data
      : undefined,
    onSuccess: () => setModalState({ visible: false }),
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue({
        category: modalState.data.category,
        description: modalState.data.description,
        title: modalState.data.title,
        // `boarderIds` is set in the `useEffect` below
      });
    }
  }, []);

  useEffect(() => {
    if (allBoardersBriefMap) {
      form.setFieldsValue({
        boarderIds: modalState.data?.boarderIds,
      });
    }
  }, [allBoardersBriefMap]);

  return (
    <Modal
      title={modalState.action === ModalAction.Create ? t('Creating note') : t('Edit note')}
      visible={modalState.visible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={modalState.action === ModalAction.Create ? t('Add') : t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <Spin spinning={allBoardersBriefMapLoading}>
        <NoteModalForm
          form={form}
          allBoardersBriefMap={allBoardersBriefMap}
          noteCategoryDisabled={modalState.action === ModalAction.Update}
          boardersDisabled={modalState.action === ModalAction.Update}
          withDormitoryCategory={withDormitoryCategory}
        />
      </Spin>
    </Modal>
  );
};
