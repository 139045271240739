import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { Parent } from 'src/shared/types/api';
import { GqlQuery } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_ALL_PARENTS_BRIEF = gql`
  query GetParents {
    parents {
      firstName
      lastName
      parentId
    }
  }
`;

export type GetAllParentsBriefResponse = { parents: Pick<Parent, 'parentId' | 'firstName' | 'lastName'>[] }

export const useGetAllParentsBrief = (): UseQueryReturnType<GetAllParentsBriefResponse> => {
  const { t } = useTranslation();
  const user = useUserContext();

  const { loading, error, data } = useQuery<GetAllParentsBriefResponse>(
    GET_ALL_PARENTS_BRIEF,
    { skip: !user?.isAllowed(GqlQuery.GetParents) },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch parents') });
  }

  return [data, loading, error];
};
