import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { RecruitmentBoarderRequestDataSource } from 'src/gql/queries/recruitment';
import { Popover, Space } from 'antd';
import { numberToPLN } from 'src/utils';
import { PaymentsList, balanceColumn } from './balance';

export const recruitmentBalanceColumn = (
  t: TFunction,
): ColumnType<RecruitmentBoarderRequestDataSource> => ({
  title: t('Account balance'),
  key: 'balance',
  render: (_, item, index) => {
    if (item.isExistingBoarder && item.boarder.boarderId) {
      return balanceColumn(t, false).render?.(
        undefined,
        { balance: item.boarder.balance, boarderId: item.boarder.boarderId },
        index,
      );
    }

    return (
      <Popover content={<PaymentsList payments={item.boarder.payments.slice(0, 3)} t={t} />} title={t('Last 3 payments')}>
        <Space>
          {numberToPLN(item.boarder.balance)}
        </Space>
      </Popover>
    );
  },
  sorter: (obj1, obj2) => (obj1.boarder.balance - obj2.boarder.balance),
});
