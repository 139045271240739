import { Col, Form, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { STYLE } from 'src/constants';
import { RoomRental } from 'src/shared/types/common';
import {
  CityFormItem,
  ContactDetailsFormItem,
  GroupNameFormItem,
  OtherInfoFormItem,
  PeopleCountFormItem,
  RoomIdsFormItem,
  StartsAtEndsAtFormItem,
} from './items';
import { FormProps } from '.';

export type RoomRentalForm = {
  groupName: string;
  city: string;
  peopleCount: string;
  startsAtEndsAt: [Dayjs, Dayjs],
  roomIds: string[];
  contactDetails: string | undefined;
  otherInfo: string | undefined;
}

export const roomRentalToForm = (roomRental: RoomRental): RoomRentalForm => ({
  city: roomRental.city,
  contactDetails: roomRental.contactDetails,
  groupName: roomRental.groupName,
  otherInfo: roomRental.otherInfo,
  peopleCount: roomRental.peopleCount.toString(),
  roomIds: roomRental.roomIds,
  startsAtEndsAt: [dayjs(roomRental.startsAt), dayjs(roomRental.endsAt)],
});

type RoomRentalModalFormProps = FormProps<RoomRentalForm>

export const RoomRentalModalForm = ({
  form,
}: RoomRentalModalFormProps) => (
  <Form form={form} layout="vertical">
    <GroupNameFormItem />

    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24} sm={16}>
        <CityFormItem />
      </Col>
      <Col xs={24} sm={8}>
        <PeopleCountFormItem />
      </Col>
    </Row>

    <StartsAtEndsAtFormItem />
    <RoomIdsFormItem />
    <ContactDetailsFormItem />
    <OtherInfoFormItem />
  </Form>
);
