import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { DormitoryDetailsApi, DormitoryGroup } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_GROUPS = gql`
  query GetDormitoryGroups {
    dormitory {
      dormitoryDetails {
        groups {
          groupId
          tutors {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export type GetDormitoryGroupsResponse = { dormitory: { dormitoryDetails: Pick<DormitoryDetailsApi, 'groups'> } };

export const useGetDormitoryGroups = (): UseQueryReturnType<DormitoryGroup[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryGroupsResponse>(
    GET_DORMITORY_GROUPS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory groups') });
  }

  return [data?.dormitory.dormitoryDetails.groups, loading, error];
};
