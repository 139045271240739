import { CreateAdministratorForm } from 'src/components';
import {
  UseOnMutation,
  UseOnMutationArgs,
  useCreateAdministrator,
  useUpdateAdministrator,
} from 'src/gql/mutations';

type UseOnAdministratorModalSubmitArgs = UseOnMutationArgs<CreateAdministratorForm> & {
    administratorId?: string;
  };

export const useOnAdministratorModalSubmit = (
  args: UseOnAdministratorModalSubmitArgs,
): UseOnMutation => {
  const [create, createLoading] = useCreateAdministrator(args);
  const [update, updateLoading] = useUpdateAdministrator(args);

  const onSubmit = async () => {
    const action = args.administratorId ? update : create;
    await action();
  };

  return [onSubmit, createLoading || updateLoading];
};
