import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbItem, MainCard, ViewHeader } from 'src/components';
import { ROUTE } from 'src/constants';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';
import { defaultRoute } from 'src/utils';
import {
  DormitoryCalendarTab,
  DormitoryPaymentsTab,
  DormitoryMealsTab,
  DormitoryGeneralInfoTab,
  DormitoryReportsTab,
  DormitoryNotesHistoryTab,
  DormitoryRecruitmentTab,
  DormitoryRoomRentalsTab,
} from './DormitoryTabs';

export const DormitoryView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUserContext();

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.DORMITORY, title: t('Dormitory') },
  ];

  if (user && !user?.isAllowed(GqlQuery.GetReports)) {
    navigate(defaultRoute(user));
  }

  return (
    <>
      <ViewHeader breadcrumbItems={breadcrumbItems} />
      <MainCard>
        <Tabs centered defaultActiveKey="general-data" destroyInactiveTabPane>
          <Tabs.TabPane tab={t('General data')} key="general-data">
            <DormitoryGeneralInfoTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Payments')} key="payments">
            <DormitoryPaymentsTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Calendar')} key="calendar">
            <DormitoryCalendarTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Reports')} key="reports">
            <DormitoryReportsTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Meals')} key="meals">
            <DormitoryMealsTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Notes')} key="notes">
            <DormitoryNotesHistoryTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Room rentals')} key="roomRentals">
            <DormitoryRoomRentalsTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Recruitment')} key="recruitment">
            <DormitoryRecruitmentTab />
          </Tabs.TabPane>
        </Tabs>
      </MainCard>
    </>
  );
};
