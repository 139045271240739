import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { paymentCommentRule } from '../rules';

export const PaymentCommentFormItem = ({ required }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Comment')}
      name="comment"
      rules={[paymentCommentRule({ required: Boolean(required) })]}
    >
      <Input.TextArea showCount rows={10} maxLength={1000} />
    </Form.Item>
  );
};
