import { Form, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BoarderBrief, ParentWithoutBoarders } from 'src/gql/queries';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { useOnParentModalSubmit } from 'src/hooks';
import { ModalAction, ModalState } from '.';
import { ParentForm, ParentModalForm } from '../forms';

type ParentModalProps = {
  modalState: ModalState<ParentWithoutBoarders>;
  setModalState: Dispatch<SetStateAction<ModalState<ParentWithoutBoarders>>>;
  allBoardersBriefMap?: Map<string, BoarderBrief>;
}

export const ParentModal = ({
  modalState,
  setModalState,
  allBoardersBriefMap,
}: ParentModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ParentForm>();

  const [onSubmit, loading] = useOnParentModalSubmit({
    form,
    onSuccess: () => setModalState({ visible: false }),
    parentId: (modalState.visible && modalState.data?.parentId) || undefined,
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue({
        ...modalState.data,
        phoneNumber: phoneNumberToDisplay(modalState.data.phoneNumber),
      });
    }

    if (modalState.action === ModalAction.Create) {
      form.setFieldsValue({
        boarderIds: [],
      });
    }
  }, [modalState.action]);

  return (
    <Modal
      title={modalState.action === ModalAction.Create ? t('Add parent') : t('Edit parent')}
      visible={modalState.visible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={modalState.action === ModalAction.Create ? t('Add') : t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <ParentModalForm
        form={form}
        allBoardersBriefMap={allBoardersBriefMap}
        withBoarders={modalState.action === ModalAction.Update}
      />
    </Modal>
  );
};
