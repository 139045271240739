import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { polishExtendedNameRegex } from 'src/shared/regex';

export const roomIdRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct room ID'),
    pattern: polishExtendedNameRegex,
  });
};
