import { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import {
  RecruitmentBoarderRequest,
} from 'src/shared/types/api';
import { CreateBoarderPaymentModalProps } from 'src/components/modals';
import { AcceptRecruitmentButton } from './AcceptRecruitmentButton';
import { DeclineRecruitmentButton } from './DeclineRecruitmentButton';
import { AddRecruitmentPaymentButton } from './AddRecruitmentPaymentButton';
import { ConfirmRecruitmentButton } from './ConfirmRecruitmentButton';
import { CreateBoarderButton } from './CreateBoarderButton';
import { ArchiveRecruitmentButton } from './ArchiveRecruitmentButton';
import { GenerateRecruitmentQuestionnaireButton } from './GenerateRecruitmentQuestionnaireButton';

type RecruitmentActionsRendererProps = {
  record: RecruitmentBoarderRequest,
  setPaymentModalProps: Dispatch<SetStateAction<Pick<CreateBoarderPaymentModalProps, 'boarderId' | 'isRecruitmentBoarder'>>>
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

const recruitmentActionsRenderer = ({
  record,
  setPaymentModalProps,
  setIsModalVisible,
}: RecruitmentActionsRendererProps) => (
  <div className="space-between">
    <AcceptRecruitmentButton record={record} />
    <DeclineRecruitmentButton record={record} />
    <AddRecruitmentPaymentButton
      record={record}
      setIsModalVisible={setIsModalVisible}
      setPaymentModalProps={setPaymentModalProps}
    />
    <ConfirmRecruitmentButton record={record} />
    <GenerateRecruitmentQuestionnaireButton record={record} />
    <CreateBoarderButton record={record} />
    <ArchiveRecruitmentButton record={record} />
  </div>
);

type RecruitmentActionsColumnProps = {
  t: TFunction;
  isMobile: boolean;
  setPaymentModalProps: Dispatch<SetStateAction<Pick<CreateBoarderPaymentModalProps, 'boarderId' | 'isRecruitmentBoarder'>>>;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

export function recruitmentActionsColumn({
  isMobile, t, setPaymentModalProps, setIsModalVisible,
}: RecruitmentActionsColumnProps): ColumnType<any> {
  return ({
    title: t('Actions'),
    key: 'actions',
    render: (_, record) => recruitmentActionsRenderer({
      record, setPaymentModalProps, setIsModalVisible,
    }),
    width: isMobile ? 128 : 156,
  });
}
