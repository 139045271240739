import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryNameRule } from '../rules';

export const DormitoryNameFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Dormitory name')}
      name="name"
      rules={[dormitoryNameRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
