import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const peopleCountRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct people count'),
    required: true,
    validator: async (_, value: string) => {
      const valueNumber = Number(value);
      if (valueNumber >= 0 && valueNumber < 512) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
  });
};
