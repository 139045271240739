import { GqlCalendar } from 'src/shared/types/api';
import { BoarderYear } from 'src/shared/types/common';
import { emptyBoarderYear, isCurrentSchoolYearLeap, monthsMap } from 'src/shared/utils';

export const parseBoarderYear = (gqlCalendar: GqlCalendar): BoarderYear => {
  const boarderYear = emptyBoarderYear({ isLeapYear: isCurrentSchoolYearLeap() });
  Object.entries(gqlCalendar).forEach(([monthName, value]) => {
    if (monthName in monthsMap) {
      boarderYear[monthsMap[monthName as keyof GqlCalendar]] = JSON.parse(value);
    }
  });

  return boarderYear;
};
