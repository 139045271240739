import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import {
  BoarderPaymentsInfo,
  BoarderPaymentsTable,
  CreateBoarderPaymentModal,
  InitOnlinePaymentModal,
  ModalState,
  UpdateBoarderSubscriptionModal,
} from 'src/components';
import { useGetSpecificBoarderPayments } from 'src/gql/queries';
import { Boarder, UpdateBoarderSubscriptionInput } from 'src/shared/types/api';
import { DollarOutlined, FileAddFilled } from '@ant-design/icons';
import { GqlMutation } from 'src/shared/types/common';
import { useUserContext } from 'src/context';

type BoarderPaymentsTabProps = {
  boarderId?: Boarder['boarderId']
  archive?: true;
 }

export const BoarderPaymentsTab = ({ boarderId, archive }: BoarderPaymentsTabProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [
    updateBoarderSubscriptionModalState,
    setUpdateBoarderSubscriptionModalState,
  ] = useState<ModalState<UpdateBoarderSubscriptionInput>>({ visible: false });
  const [
    isCreateBoarderPaymentModalVisible,
    setIsCreateBoarderPaymentModalVisible,
  ] = useState(false);
  const [
    isInitOnlinePaymentModalVisible,
    setIsInitOnlinePaymentModalVisible,
  ] = useState(false);
  const [boarder, loading] = useGetSpecificBoarderPayments({ boarderId, archive });

  return (
    <>
      <Spin spinning={loading}>
        <div className="sub-actions-wrapper">
          {user?.isAllowed(GqlMutation.InitOnlinePayment) && !archive && (
            <Button
              type="default"
              size="large"
              onClick={() => setIsInitOnlinePaymentModalVisible(true)}
              className="header-item"
            >
              <DollarOutlined />
              {t('Pay online')}
            </Button>
          )}
          {user?.isAllowed(GqlMutation.CreateBoarderPayment) && !archive && (
            <Button
              type="primary"
              size="large"
              onClick={() => { setIsCreateBoarderPaymentModalVisible(true); }}
              className="header-item"
            >
              <FileAddFilled />
              {t('Create payment')}
            </Button>
          )}
        </div>
        <BoarderPaymentsInfo
          boarderId={boarderId}
          balance={boarder?.balance}
          subscription={boarder?.subscription}
          setModalState={setUpdateBoarderSubscriptionModalState}
          archive={archive}
        />
        <BoarderPaymentsTable
          boarderId={boarderId}
          payments={boarder?.payments}
        />
      </Spin>

      {updateBoarderSubscriptionModalState.visible && (
        <UpdateBoarderSubscriptionModal
          modalState={updateBoarderSubscriptionModalState}
          setModalState={setUpdateBoarderSubscriptionModalState}
        />
      )}

      {isCreateBoarderPaymentModalVisible && (
        <CreateBoarderPaymentModal
          isModalVisible={isCreateBoarderPaymentModalVisible}
          setIsModalVisible={setIsCreateBoarderPaymentModalVisible}
          boarderId={boarderId}
        />
      )}

      {isInitOnlinePaymentModalVisible && (
        <InitOnlinePaymentModal
          isModalVisible={isInitOnlinePaymentModalVisible}
          setIsModalVisible={setIsInitOnlinePaymentModalVisible}
          balance={boarder?.balance}
          boarderId={boarder?.boarderId}
        />
      )}
    </>
  );
};
