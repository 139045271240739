import { range } from './misc';
import {
  IsoStringDate,
  IsoStringDateTime,
  MonthNumber,
} from '../types/common';

export const isoStringDatesComparator = (
  dateTime1: IsoStringDate | IsoStringDateTime,
  dateTime2: IsoStringDate | IsoStringDateTime,
): number => new Date(dateTime1).getTime() - new Date(dateTime2).getTime();

export const createdAtSorter = <T extends { createdAt: string; }>(
  item1: T, item2: T,
): number => isoStringDatesComparator(item2.createdAt, item1.createdAt);

export const decidedAtSorter = <T extends { decidedAt: string; }>(
  item1: T, item2: T,
): number => isoStringDatesComparator(item2.decidedAt, item1.decidedAt);

export const startsAtSorter = <T extends { startsAt: string; }>(
  item1: T, item2: T,
): number => isoStringDatesComparator(item2.startsAt, item1.startsAt);

export const decidedAtOrCreatedAtSorter = <T extends { decidedAt?: string; createdAt: string; }>(
  item1: T, item2: T,
): number => (
    item2.decidedAt && item1.decidedAt
      ? isoStringDatesComparator(item2.decidedAt, item1.decidedAt)
      : isoStringDatesComparator(item2.createdAt, item1.createdAt)
  );

export const monthsMap = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
} as const;

export const monthsMapReverted = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
} as const;

export const weekDays = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as const;

export const weekDaysReverted = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
} as const;

export const weekDaysRevertedPl = {
  0: 'niedziela',
  1: 'poniedziałek',
  2: 'wtorek',
  3: 'środa',
  4: 'czwartek',
  5: 'piątek',
  6: 'sobota',
} as const;

export const thirtyOneDayMonths: Readonly<MonthNumber[]> = [0, 2, 4, 6, 7, 9, 11] as const;
export const isYearLeap = (
  year: number,
): boolean => ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);

export const isCurrentSchoolYearLeap = (): boolean => {
  const now = new Date();
  const yearWithFebruary = now.getMonth() > monthsMap.july
    ? now.getFullYear() + 1
    : now.getFullYear();

  return isYearLeap(yearWithFebruary);
};

const trailingZeroIfNeeded = (n: number): string => (n < 10 ? '0' : '');

export const toIsoDateString = (date: Date): IsoStringDate => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // months are numerated from 0
  const day = date.getDate();

  return `${year}-${trailingZeroIfNeeded(month)}${month}-${trailingZeroIfNeeded(day)}${day}`; // 2000-01-01'
};

export const isWeekend = (date: Date): boolean => (
  [weekDays.saturday, weekDays.sunday] as number[]
).includes(date.getDay());

export const getMondayInCurrentWeek = (): Date => {
  const today = new Date();
  today.setUTCHours(0);
  today.setMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);
  return new Date(today.setDate(today.getDate() - today.getDay() + 1));
};

export const getTwoWeeksDays = (): Date[] => {
  const monday = getMondayInCurrentWeek();

  return [
    monday,
    ...range(1, 13).map((offset) => new Date(
      monday.getUTCFullYear(), monday.getUTCMonth(), monday.getUTCDate() + offset,
    )),
  ];
};
