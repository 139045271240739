import { SpecificBoarder } from 'src/gql/queries';
import { dayjsOrUndefined } from 'src/utils';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { UpdateBoarderForm } from '../BoarderGeneralInfoTab';

export const boarderToBoarderGeneralInfoForm = (
  boarder?: SpecificBoarder,
): Partial<UpdateBoarderForm> => (boarder ? {
  birthDate: dayjsOrUndefined(boarder.birthDate),
  birthPlace: boarder.birthPlace,
  cardId: boarder.cardId,
  city: boarder.address.city,
  classNumber: boarder.schoolDetails.classNumber
    ? String(boarder.schoolDetails.classNumber)
    : undefined,
  email: boarder.email,
  firstName: boarder.firstName,
  groupId: boarder.groupId,
  labels: boarder.labels,
  lastName: boarder.lastName,
  personalIdentityNumber: boarder.personalIdentityNumber,
  livingStartDate: dayjsOrUndefined(boarder.livingStartDate),
  livingEndDate: dayjsOrUndefined(boarder.livingEndDate),
  phoneNumber: phoneNumberToDisplay(boarder.phoneNumber),
  postCode: boarder.address.postCode,
  registeredParentIds: boarder.registeredParentIds,
  roomId: boarder.roomId,
  schoolKind: boarder.schoolDetails.schoolKind,
  schoolName: boarder.schoolDetails.schoolName,
  streetAddress: boarder.address.streetAddress,
  unregisteredParents: boarder.unregisteredParents.map((parent) => ({
    firstName: parent.firstName,
    lastName: parent.lastName,
    city: parent.address.city,
    email: parent.email,
    phoneNumber: phoneNumberToDisplay(parent.phoneNumber || ''),
    postCode: parent.address.postCode,
    streetAddress: parent.address.streetAddress,
  })),
} : {});
