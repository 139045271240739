import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Tutor } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_ALL_TUTORS = gql`
  query GetTutors {
    tutors{
      tutorId
      firstName
      lastName
      email
      phoneNumber
      accountStatus
      groupIds
    }
  }
`;

export const useGetAllTutors = (): UseQueryReturnType<Tutor[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<{ tutors: Tutor[] }>(
    GET_ALL_TUTORS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch tutors') });
  }

  return [data?.tutors, loading, error];
};
