import { FormInstance, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { RecruitmentUnregisteredBoarderDataFormFields, BoarderGeneralInfoForm } from './forms';
import { FormListItem, ContactDetailsFormItems } from './forms/items';

type RecruitmentUnregisteredBoarderFormProps = {
  form: FormInstance<RecruitmentUnregisteredBoarderDataFormFields>
  disabled?: boolean;
}

export const RecruitmentUnregisteredBoarderForm = ({
  form,
  disabled,
}: RecruitmentUnregisteredBoarderFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BoarderGeneralInfoForm form={form} disabled={disabled} />

      <Form
        form={form}
        layout="vertical"
        className="boarder-tab-content-wrapper"
      >
        <FormListItem
          renderItem={ContactDetailsFormItems}
          otherProps={{
            showAddress: true, emailRequired: true, phoneNumberRequired: true, disabled,
          }}
          addItemText={t('Add parent')}
          formTitle={t('Parents')}
          name="parents"
          removeItemText={t('Remove parent')}
          disabled={disabled}
        />
      </Form>
    </>
  );
};
