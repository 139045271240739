import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteTranslated, ROUTE } from 'src/constants';
import { SpecificBoarder } from 'src/gql/queries';
import { BoarderId } from 'src/shared/types/common';
import { UseOnMutation } from '..';

const REMOVE_BOARDER_FROM_ARCHIVE = gql`
  mutation RemoveBoarderFromArchive($input: RemoveBoarderFromArchiveInput!) {
    removeBoarderFromArchive(input: $input) {
      boarderId
    }
  }
`;

type UseRemoveBoarderFromArchiveArgs = {
  boarder?: SpecificBoarder;
}

export const useRemoveBoarderFromArchive = (
  { boarder }: UseRemoveBoarderFromArchiveArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [removeBoarderFromArchive, { loading }] = useMutation<
    unknown, { input: BoarderId }
  >(REMOVE_BOARDER_FROM_ARCHIVE);

  const onRemoveBoarderFromArchive = useCallback(async () => {
    if (!boarder?.boarderId) {
      return notification.error({ message: t('Boarder has not been removed from archive successfully') });
    }
    try {
      await removeBoarderFromArchive({
        variables: {
          input: {
            boarderId: boarder?.boarderId,
          },
        },
      });
      navigate(getRouteTranslated(ROUTE.BOARDERS_ARCHIVE));

      return notification.success({ message: t('Boarder has been removed from archive successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder has not been removed from archive successfully') });
    }
  }, [boarder]);

  return [onRemoveBoarderFromArchive, loading];
};
