import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { STYLE } from 'src/constants';
import { useIsMobile, useNavigateBackIfEmptyForm } from 'src/hooks';
import {
  AgreementsStep,
  BoarderAlreadyRecruitedStep,
  BoarderDataStep,
  QuestionnaireStep,
  RecruitmentSucceededStep,
  StartStep,
  SummaryStep,
} from 'src/views/RecruitmentSteps';

export const RecruitmentRouterSwitch = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const padding = isMobile ? 0 : STYLE.BASE_MARGIN * 4;
  useNavigateBackIfEmptyForm();

  return (
    <div style={{ paddingLeft: padding, paddingRight: padding }}>
      <Routes>
        <Route path={t('start-step')} element={<StartStep />} />
        <Route path={t('boarder-data-step')} element={<BoarderDataStep />} />
        <Route path={t('questionnaire-step')} element={<QuestionnaireStep />} />
        <Route path={t('agreements-step')} element={<AgreementsStep />} />
        <Route path={t('summary-step')} element={<SummaryStep />} />
        <Route path={t('recruitment-succeeded-step')} element={<RecruitmentSucceededStep />} />
        <Route path={t('boarder-already-recruited-step')} element={<BoarderAlreadyRecruitedStep />} />
        <Route path="*" element={<StartStep />} />
      </Routes>
    </div>
  );
};
