import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryWeekendStayFeeRule } from '../rules';

export const DormitoryWeekendStayFeeFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Weekend stay fee')}
      name="weekendStay"
      rules={[dormitoryWeekendStayFeeRule()]}
    >
      <Input type="number" disabled={disabled} />
    </Form.Item>
  );
};
