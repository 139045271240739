import type { Rule } from 'antd/lib/form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { validPeriodOfStayInDormitory } from 'src/shared/validatorUtils';

export const startsAtEndsAtRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct starting and ending date'),
    validator: (_, value: [Dayjs, Dayjs] | undefined) => (validPeriodOfStayInDormitory({
      livingStartDate: value?.[0]?.toDate(),
      livingEndDate: value?.[1]?.toDate(),
    }) ? Promise.resolve() : Promise.reject()),
  });
};
