import { Dayjs } from 'dayjs';
import { UserInContext } from 'src/context';
import { UserGroup } from 'src/shared/types/common';
import { isWeekend } from 'src/shared/utils';
import { isBoarderDayUpdateForbiddenByDate } from './isBoarderDayUpdateForbiddenByDate';

type AbsenceUpdateForbiddenArgs = {
  user: UserInContext | null;
  date: Dayjs;
};
export const absenceUpdateForbidden = ({ user, date }: AbsenceUpdateForbiddenArgs): boolean => {
  if (UserGroup.Boarders === user?.group) {
    return true;
  }

  if (isWeekend(date.toDate())) {
    return true;
  }

  if (([
    UserGroup.Administrators,
    UserGroup.Tutors,
  ] as Array<string | undefined>).includes(user?.group)) {
    return false;
  }

  if (UserGroup.Parents === user?.group) {
    return isBoarderDayUpdateForbiddenByDate(date);
  }

  console.error('CalendarBoarderDay: Implementation error');

  return true;
};
