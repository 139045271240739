import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DormitoryDetailsFormFields } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_DORMITORY_GENERAL_INFO } from 'src/gql/queries';
import { DormitoryDetailsDocValue } from 'src/shared/types/docs';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_DORMITORY_DETAILS = gql`
  mutation UpdateDormitoryDetails($input: UpdateDormitoryDetailsInput!) {
    updateDormitoryDetails(input: $input) {
      name
    }
  }
`;

export const useUpdateDormitoryDetails = (
  { form, onSuccess } : UseOnMutationArgs<DormitoryDetailsFormFields>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateDormitoryDetails, { loading }] = useMutation<
    unknown, { input: DormitoryDetailsDocValue }
  >(
    UPDATE_DORMITORY_DETAILS,
    { refetchQueries: [GET_DORMITORY_GENERAL_INFO] },
  );

  const onUpdateDormitoryDetails = useCallback(async () => {
    const formFields = await form.validateFields();

    try {
      await updateDormitoryDetails({
        variables: {
          input: {
            name: formFields.name,
            accountNumber: formFields.accountNumber,
            address: {
              streetAddress: formFields.streetAddress,
              postCode: formFields.postCode,
              city: formFields.city,
            },
            emails: formFields.emails.map(
              (email) => ({ label: email.label, value: email.email }),
            ),
            phoneNumbers: formFields.phoneNumbers.map(
              (phoneNumber) => ({
                label: phoneNumber.label,
                value: phoneNumberToDto(phoneNumber.phoneNumber),
              }),
            ),
            groupIds: formFields.groupIds,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Dormitory details have been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Dormitory details have not been updated successfully') });
    }
  }, [form, onSuccess]);

  return [onUpdateDormitoryDetails, loading];
};
