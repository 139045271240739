import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const subscriptionRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct subscription'),
    required: true,
  });
};
