import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useRecruitmentFormContext } from 'src/context';
import { RecruitmentRegisteredBoarderFormItems } from './RecruitmentRegisteredBoarderFormItems';
import { RecruitmentRegisteredBoarderDataFormFields } from './forms';

export const RecruitmentSummaryRegisteredBoarder = () => {
  const recruitmentFormContext = useRecruitmentFormContext();
  const recruitmentForm = recruitmentFormContext?.recruitmentForm;
  const [registeredBoarderForm] = useForm<RecruitmentRegisteredBoarderDataFormFields>();
  if (recruitmentForm?.registeredBoarderData) {
    registeredBoarderForm.setFieldsValue(recruitmentForm?.registeredBoarderData);
  }

  if (!recruitmentForm?.registeredBoarderData) {
    return null;
  }

  return (
    <Form form={registeredBoarderForm} layout="vertical">
      <RecruitmentRegisteredBoarderFormItems disabled />
    </Form>
  );
};
