import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileAddFilled } from '@ant-design/icons';
import {
  Button,
  notification,
  Spin,
  Tabs,
} from 'antd';
import { BoarderNotesTable, ModalState } from 'src/components';
import { ModalAction, NoteModal, NoteModalStateData } from 'src/components/modals';
import { useGetSpecificBoarderNotes } from 'src/gql/queries';
import { Boarder } from 'src/shared/types/api';
import { GqlMutation, Note, NoteCategory } from 'src/shared/types/common';
import { useUserContext } from 'src/context';

type BoarderNotesTabProps = {
  boarderId?: Boarder['boarderId']
  archive?: true;
}

export const BoarderNotesTab = ({ boarderId, archive }: BoarderNotesTabProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [
    modalState,
    setModalState,
  ] = useState<ModalState<NoteModalStateData>>({ visible: false });
  const [boarder, loading] = useGetSpecificBoarderNotes({ boarderId, archive });

  const onNoteUpdate = (noteCategory: NoteCategory) => (note: Note) => {
    if (!boarderId || !note.noteId) {
      return notification.error({ message: t('Unknown error') });
    }

    return setModalState({
      visible: true,
      action: ModalAction.Update,
      data: {
        boarderIds: [boarderId],
        category: noteCategory,
        description: note.description,
        title: note.title,
        noteId: note.noteId,
      },
    });
  };

  const commonBoarderNotesTableProps = {
    boarderId,
    onNoteUpdate,
    archive,
  };

  return (
    <>
      <Spin spinning={loading}>
        {user?.isAllowed(GqlMutation.CreateBoarderNote) && !archive && (
          <div className="sub-actions-wrapper">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setModalState({
                  visible: true,
                  action: ModalAction.Create,
                  data: boarderId ? {
                    boarderIds: [boarderId],
                  } : undefined,
                });
              }}
            >
              <FileAddFilled />
              {t('Add note')}
            </Button>
          </div>
        )}
        <Tabs
          centered
          defaultActiveKey="health"
          size="small"
        >
          <Tabs.TabPane tab={t('Health')} key="health">
            <BoarderNotesTable
              {...commonBoarderNotesTableProps}
              notes={boarder?.notes.health}
              noteCategory={NoteCategory.HEALTH}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Behavior')} key="behavior">
            <BoarderNotesTable
              {...commonBoarderNotesTableProps}
              notes={boarder?.notes.behavior}
              noteCategory={NoteCategory.BEHAVIOR}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Other')} key="other">
            <BoarderNotesTable
              {...commonBoarderNotesTableProps}
              notes={boarder?.notes.other}
              noteCategory={NoteCategory.OTHER}
            />
          </Tabs.TabPane>
        </Tabs>
      </Spin>
      {modalState.visible && (
        <NoteModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
