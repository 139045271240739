import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { messageRule } from '../rules';

export const MessageFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Message')}
      name="message"
      rules={[messageRule()]}
    >
      <Input.TextArea showCount rows={10} maxLength={1000} />
    </Form.Item>
  );
};
