import { phoneNumberToDto } from 'src/components/forms/rules';
import { UpdateBoarderGeneralInfoInput } from 'src/shared/types/api';
import { toIsoDateString } from 'src/shared/utils';
import { UpdateBoarderForm } from '../BoarderGeneralInfoTab';

export const updateBoarderFormToMutationInput = (formValues: UpdateBoarderForm): UpdateBoarderGeneralInfoInput['generalInfo'] => ({
  address: {
    city: formValues.city,
    postCode: formValues.postCode,
    streetAddress: formValues.streetAddress,
  },
  birthDate: formValues.birthDate ? toIsoDateString(formValues.birthDate.toDate()) : undefined,
  birthPlace: formValues.birthPlace,
  cardId: formValues.cardId,
  email: formValues.email,
  firstName: formValues.firstName,
  groupId: formValues.groupId,
  labels: formValues.labels,
  lastName: formValues.lastName,
  livingStartDate: formValues.livingStartDate
    ? toIsoDateString(formValues.livingStartDate.toDate())
    : undefined,
  livingEndDate: formValues.livingEndDate
    ? toIsoDateString(formValues.livingEndDate.toDate())
    : undefined,
  personalIdentityNumber: formValues.personalIdentityNumber,
  phoneNumber: phoneNumberToDto(formValues.phoneNumber),
  registeredParentIds: formValues.registeredParentIds,
  roomId: formValues.roomId,
  schoolDetails: {
    classNumber: Number(formValues.classNumber) ?? undefined,
    schoolKind: formValues.schoolKind,
    schoolName: formValues.schoolName,
  },
  unregisteredParents: formValues.unregisteredParents.map((parent) => ({
    address: {
      city: parent.city,
      postCode: parent.postCode,
      streetAddress: parent.streetAddress,
    },
    email: parent.email,
    firstName: parent.firstName,
    lastName: parent.lastName,
    phoneNumber: phoneNumberToDto(parent.phoneNumber) || undefined,
  })),
});
