/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmail from 'validator/es/lib/isEmail';
import { PESEL } from 'polish-utils';
import { SchoolKinds, UserGroups, Year } from './types/common';
import {
  accountNumberRegex,
  cardIdRegex,
  isoStringDateRegex,
  isoStringDateTimeRegex,
  phoneNumberRegex,
  polishExtendedNameRegex,
  polishPostCodeRegex,
  polishSimpleNameRegex,
  uuidRegex,
} from './regex';
import { range, toIsoDateString } from './utils';

export const isIsoStringDateTime = (date: unknown): boolean => {
  if (typeof date !== 'string') {
    return false;
  }

  if (!isoStringDateTimeRegex.test(date)) {
    return false;
  }

  return new Date(date).toISOString() === date;
};

export const isIsoStringDate = (date: unknown): boolean => {
  if (typeof date !== 'string') {
    return false;
  }

  if (!isoStringDateRegex.test(date)) {
    return false;
  }

  return toIsoDateString(new Date(date)) === date;
};

export const validPolishSimpleName = (name: unknown): boolean => (typeof name === 'string' && polishSimpleNameRegex.test(name));
export const validPolishExtendedName = (name: unknown): boolean => (typeof name === 'string' && polishExtendedNameRegex.test(name));

export const validFirstName = (firstName: unknown): boolean => validPolishSimpleName(firstName);
export const validLastName = (lastName: unknown): boolean => validPolishExtendedName(lastName);
export const validEmail = (email: unknown): boolean => (typeof email === 'string' && isEmail(email));
export const validGroupId = (groupId: unknown): boolean => (typeof groupId === 'string' && groupId.length > 0 && groupId.length < 16);
export const validLabels = (labels: unknown): boolean => Array.isArray(labels) && labels.every((label) => /[A-ZĄĆĘŁŃÓŚŹŻ_]+/.test(label));
export const validAccountNumber = (accountNumber: unknown): boolean => typeof accountNumber === 'string' && accountNumberRegex.test(accountNumber);

// `validator/es/lib/isEmail` rejects landline phone numbers
export const validPhoneNumber = (phoneNumber: unknown): boolean => (
  typeof phoneNumber === 'string'
  && phoneNumberRegex.test(phoneNumber)
  && phoneNumber.length >= 9 && phoneNumber.length <= 15
);

export const validUserGroup = (userGroup: unknown): boolean => (
  typeof userGroup === 'string'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  && UserGroups.includes(userGroup as any)
);

export const validPersonalIdentityNumber = (
  personalIdentityNumber: unknown,
): boolean => typeof personalIdentityNumber === 'string' && new PESEL(personalIdentityNumber).isValid;

export const validUuid = (uuid: unknown): boolean => (typeof uuid === 'string' && uuidRegex.test(uuid));
export const validUuids = (
  uuids: unknown,
): boolean => (Array.isArray(uuids) && uuids.every((uuid) => validUuid(uuid)));

export const validCardId = (cardId: unknown): boolean => (typeof cardId === 'string' && cardIdRegex.test(cardId));

export const validPolishStreetAddress = (
  streetAddress: unknown,
): boolean => (typeof streetAddress === 'string' && polishExtendedNameRegex.test(streetAddress));
export const validSchoolName = (
  schoolName: unknown,
): boolean => validPolishStreetAddress(schoolName);

export const validBirthPlace = (
  birthPlace: unknown,
): boolean => validPolishStreetAddress(birthPlace);

export const validPolishPostCode = (postCode: unknown): boolean => (typeof postCode === 'string' && polishPostCodeRegex.test(postCode));

export const validAddress = (address: any): boolean => {
  if (typeof address !== 'object') {
    return false;
  }

  const { streetAddress, city, postCode } = address;

  if (streetAddress && !validPolishStreetAddress(streetAddress)) {
    return false;
  }

  if (city && !validPolishExtendedName(city)) {
    return false;
  }

  if (postCode && !validPolishPostCode(postCode)) {
    return false;
  }

  return true;
};

export const validBoarderBirthDate = (birthDate: unknown): boolean => {
  if (typeof birthDate !== 'string') {
    return false;
  }

  if (birthDate.length !== '2000-01-01'.length) {
    return false;
  }

  const date = new Date(birthDate);

  if (date.getFullYear() < 1990) {
    return false;
  }

  if (date.getTime() > new Date().getTime()) {
    return false;
  }

  return true;
};

export const validSchoolDetails = (schoolDetails: any): boolean => {
  if (typeof schoolDetails !== 'object') {
    return false;
  }

  const {
    classNumber, schoolName, schoolKind,
  } = schoolDetails;

  if (classNumber && !(typeof classNumber === 'number' && classNumber > 0 && classNumber < 9)) {
    return false;
  }

  if (schoolName && !(validSchoolName(schoolName))) {
    return false;
  }

  if (schoolKind && !(SchoolKinds.includes(schoolKind))) {
    return false;
  }

  return true;
};

export const validLivingDate = (livingDate: unknown): boolean => {
  if (typeof livingDate !== 'string') {
    return false;
  }

  if (livingDate.length !== '2000-01-01'.length) {
    return false;
  }

  const date = new Date(livingDate);

  const year = date.getFullYear();
  if (year < 2010 || year > 2040) {
    return false;
  }

  return true;
};

type ValidPeriodOfStayInDormitoryArgs = { livingStartDate: any, livingEndDate: any };
export const validPeriodOfStayInDormitory = ({
  livingEndDate,
  livingStartDate,
}: ValidPeriodOfStayInDormitoryArgs): boolean => {
  if (typeof livingStartDate === 'string') {
    if (!validLivingDate(livingStartDate)) {
      return false;
    }
  }

  if (typeof livingEndDate === 'string') {
    if (!validLivingDate(livingEndDate)) {
      return false;
    }
  }

  if (typeof livingStartDate === 'string' && typeof livingEndDate === 'string') {
    if (new Date(livingStartDate).getTime() > new Date(livingEndDate).getTime()) {
      return false;
    }
  }

  return true;
};

export const validContactDetails = (contactDetails: any): boolean => {
  if (typeof contactDetails !== 'object') {
    return false;
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
  } = contactDetails;

  if (firstName && !validFirstName(firstName)) {
    return false;
  }

  if (lastName && !validLastName(lastName)) {
    return false;
  }

  if (phoneNumber && !validPhoneNumber(phoneNumber)) {
    return false;
  }

  if (email && !validEmail(email)) {
    return false;
  }

  if (!validAddress(address)) {
    return false;
  }

  return true;
};

export function assertIsObject(value: unknown): value is Record<string, unknown> {
  if (typeof value === 'object') {
    return true;
  }

  return false;
}

export function validMonthNumber(value: number): value is keyof Year<unknown> {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(value);
}

export const monthDays28 = JSON.stringify(range(1, 28));
export const monthDays29 = JSON.stringify(range(1, 29));
export const monthDays30 = JSON.stringify(range(1, 30));
export const monthDays31 = JSON.stringify(range(1, 31));

export const isValidAmount = (
  amount: number,
): boolean => (amount - Math.floor(amount)).toLocaleString().length <= 4; // 0.345
