import { Form, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Administrator } from 'src/shared/types/api';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { useOnAdministratorModalSubmit } from 'src/hooks';
import { ModalAction, ModalState } from '.';
import { AdministratorModalForm, CreateAdministratorForm } from '../forms';

type AdministratorModalProps = {
  modalState: ModalState<Administrator>
  setModalState: Dispatch<SetStateAction<ModalState<Administrator>>>;
}

export const AdministratorModal = (
  { modalState, setModalState }: AdministratorModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateAdministratorForm>();
  const [onSubmit, loading] = useOnAdministratorModalSubmit({
    form,
    onSuccess: () => setModalState({ visible: false }),
    administratorId: (modalState.visible && modalState.data?.administratorId) || undefined,
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue({
        ...modalState.data,
        phoneNumber: phoneNumberToDisplay(modalState.data.phoneNumber),
      });
    }
  }, [modalState.action]);

  return (
    <Modal
      title={modalState.action === ModalAction.Create ? t('Add administrator') : t('Edit administrator')}
      visible={modalState.visible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={modalState.action === ModalAction.Create ? t('Add') : t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <AdministratorModalForm form={form} />
    </Modal>
  );
};
