import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SpecificBoarder, GET_SPECIFIC_BOARDER } from 'src/gql/queries';
import { ToggleBoarderSuspensionStatusInput } from 'src/shared/types/api';
import { UseOnMutation } from '..';

const TOGGLE_BOARDER_SUSPENSION_STATUS = gql`
  mutation ToggleBoarderSuspensionStatus($input: ToggleBoarderSuspensionStatusInput!) {
    toggleBoarderSuspensionStatus(input: $input) {
      boarderId
    }
  }
`;

type UseToggleBoarderSuspensionStatusArgs = {
  boarder?: SpecificBoarder;
}

export const useToggleBoarderSuspensionStatus = (
  { boarder }: UseToggleBoarderSuspensionStatusArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [toggleBoarderSuspensionStatus, { loading }] = useMutation<
    unknown, { input: ToggleBoarderSuspensionStatusInput }
  >(TOGGLE_BOARDER_SUSPENSION_STATUS, { refetchQueries: [GET_SPECIFIC_BOARDER] });

  const onToggleBoarderSuspensionStatus = useCallback(async () => {
    if (!boarder?.boarderId) {
      return notification.error({ message: t('Boarder suspension status has not been updated successfully') });
    }
    try {
      await toggleBoarderSuspensionStatus({
        variables: {
          input: {
            boarderId: boarder?.boarderId,
            isSuspended: !boarder.isSuspended,
          },
        },
      });

      return notification.success({ message: t('Boarder suspension status has been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder suspension status has not been updated successfully') });
    }
  }, [boarder]);

  return [onToggleBoarderSuspensionStatus, loading];
};
