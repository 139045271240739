import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { CityFormItem } from './city';
import { PostCodeFormItem } from './postCode';
import { StreetAddressFormItem } from './streetAddress';

export const AddressFormItems = ({ keyPrefix, disabled, required }: FormItemCommonProps) => (
  <>
    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24}>
        <StreetAddressFormItem keyPrefix={keyPrefix} disabled={disabled} required={required} />
      </Col>
    </Row>

    <Row gutter={STYLE.BASE_MARGIN}>
      <Col xs={24} sm={8}>
        <PostCodeFormItem keyPrefix={keyPrefix} disabled={disabled} required={required} />
      </Col>
      <Col xs={24} sm={16}>
        <CityFormItem keyPrefix={keyPrefix} disabled={disabled} required={required} />
      </Col>
    </Row>
  </>
);
