import {
  Collapse,
  Form,
  Modal,
} from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateBoarderUpdateGeneralInfoRequest } from 'src/gql/mutations';
import { BoarderWithRequests } from 'src/gql/queries';
import { phoneNumberToDisplay } from 'src/shared/utils';
import { dayjsOrUndefined } from 'src/utils';
import { BoarderUpdateGeneralInfoRequestForm } from 'src/views/BoarderTabs';
import { BoarderGeneralInfoForm, BoarderSchoolInfoForm } from '../forms';

export const boarderUpdateGeneralInfoRequestForm = (
  boarder?: BoarderWithRequests,
): Partial<BoarderUpdateGeneralInfoRequestForm> => (boarder ? {
  birthDate: dayjsOrUndefined(boarder.birthDate),
  birthPlace: boarder.birthPlace,
  city: boarder.address.city,
  classNumber: boarder.schoolDetails.classNumber
    ? String(boarder.schoolDetails.classNumber)
    : undefined,
  email: boarder.email,
  firstName: boarder.firstName,
  lastName: boarder.lastName,
  personalIdentityNumber: boarder.personalIdentityNumber,
  phoneNumber: phoneNumberToDisplay(boarder.phoneNumber),
  postCode: boarder.address.postCode,
  schoolKind: boarder.schoolDetails.schoolKind,
  schoolName: boarder.schoolDetails.schoolName,
  streetAddress: boarder.address.streetAddress,
} : {});

export type CreateBoarderUpdateGeneralInfoRequestModalState = {
  visible: boolean;
  boarder?: BoarderWithRequests;
}

export type CreateBoarderUpdateGeneralInfoRequestModalProps = {
  modalState: CreateBoarderUpdateGeneralInfoRequestModalState
  setModalState: Dispatch<SetStateAction<CreateBoarderUpdateGeneralInfoRequestModalState>>;
}

export const CreateBoarderUpdateGeneralInfoRequestModal = (
  { modalState, setModalState }: CreateBoarderUpdateGeneralInfoRequestModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<BoarderUpdateGeneralInfoRequestForm>();

  const [
    createBoarderUpdateGeneralInfoRequest,
    loading,
  ] = useCreateBoarderUpdateGeneralInfoRequest({
    form,
    boarderId: modalState.boarder?.boarderId,
    onSuccess: () => setModalState({ visible: false }),
  });

  useEffect(() => {
    form.setFieldsValue(boarderUpdateGeneralInfoRequestForm(modalState.boarder));
  }, [modalState.boarder]);

  if (!modalState.visible) {
    return null;
  }

  return (
    <Modal
      title={t('Creating boarder update general info request')}
      visible={modalState.visible}
      onOk={createBoarderUpdateGeneralInfoRequest}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <Collapse defaultActiveKey={['generalInfo', 'studentData']}>
        <Collapse.Panel header={t('General data')} key="generalInfo">
          <BoarderGeneralInfoForm
            boarder={modalState.boarder}
            form={form}
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('Student data')} key="studentData">
          <BoarderSchoolInfoForm boarder={modalState.boarder} form={form} />
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};
