import { Col, Form, Row } from 'antd';
import { STYLE } from 'src/constants';
import { useUserContext } from 'src/context';
import { DormitoryFees, GqlMutation } from 'src/shared/types/common';
import { FormProps } from '.';
import {
  DormitoryAcademicFeeFormItem,
  DormitoryBaseFeeFormItem,
  DormitoryBreakfastFeeFormItem,
  DormitoryDinnerFeeFormItem,
  DormitoryLunchFeeFormItem,
  DormitoryWeekendStayFeeFormItem,
} from './items';

export const DormitoryFeesForm = ({ form }: FormProps<DormitoryFees>) => {
  const user = useUserContext();
  const isUpdateForbidden = !user?.isAllowed(GqlMutation.UpdateDormitoryFees);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={STYLE.BASE_MARGIN}>
        <Col xs={24} sm={8}>
          <DormitoryBaseFeeFormItem disabled={isUpdateForbidden} />
        </Col>
        <Col xs={24} sm={8}>
          <DormitoryAcademicFeeFormItem disabled={isUpdateForbidden} />
        </Col>
        <Col xs={24} sm={8}>
          <DormitoryWeekendStayFeeFormItem disabled={isUpdateForbidden} />
        </Col>
      </Row>
      <Row gutter={STYLE.BASE_MARGIN}>
        <Col xs={24} sm={8}>
          <DormitoryBreakfastFeeFormItem disabled={isUpdateForbidden} />
        </Col>
        <Col xs={24} sm={8}>
          <DormitoryLunchFeeFormItem disabled={isUpdateForbidden} />
        </Col>
        <Col xs={24} sm={8}>
          <DormitoryDinnerFeeFormItem disabled={isUpdateForbidden} />
        </Col>
      </Row>
    </Form>
  );
};
