import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { STYLE } from 'src/constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export const BoarderAlreadyRecruitedStep = () => {
  const { t } = useTranslation();

  return (
    <div className="center">
      <Title style={{ fontSize: 6 * STYLE.BASE_MARGIN }}>
        <ExclamationCircleOutlined />
      </Title>
      <Text>
        {t('Recruitment request boarder already recruited')}
      </Text>
    </div>
  );
};
