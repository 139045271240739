import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteForm } from 'src/components';
import { GET_DORMITORY_NOTES_HISTORY } from 'src/gql/queries';
import { CreateDormitoryNoteInput } from 'src/shared/types/common';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_DORMITORY_NOTE = gql`
  mutation CreateDormitoryNote($input: CreateDormitoryNoteInput!) {
    createDormitoryNote(input: $input) {
      noteId
    }
  }
`;

export const useCreateDormitoryNote = (
  { form, onSuccess } : UseOnMutationArgs<NoteForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createDormitoryNote, { loading }] = useMutation<
    unknown, { input: CreateDormitoryNoteInput }
  >(CREATE_DORMITORY_NOTE, { refetchQueries: [GET_DORMITORY_NOTES_HISTORY] });

  const onCreateDormitoryNote = useCallback(async () => {
    const formFields = await form.validateFields();
    try {
      await createDormitoryNote({
        variables: {
          input: {
            title: formFields.title,
            description: formFields.description,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Note has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Note has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateDormitoryNote, loading];
};
