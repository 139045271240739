import { Divider, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SendPaymentReminderModal } from './modals';

export const DormitoryPaymentReminder = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Divider />

      <Button
        type="default"
        size="large"
        className="vertical-base-margin"
        onClick={() => setIsModalVisible(true)}
        block
      >
        {t('Send payment reminder')}
      </Button>

      {isModalVisible && (
        <SendPaymentReminderModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};
