import { gql, useQuery } from '@apollo/client';
import { WHOLE_ADDRESS_FRAGMENT } from 'src/gql/fragments';
import { Boarder } from 'src/shared/types/api';
import { ParentWithoutBoarders } from '../parents';

export const GET_ALL_BOARDERS_REPORT_DATA = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetAllBoarders {
    boarders {
      boarderId

      firstName
      lastName
      phoneNumber
      email
      address { ...WholeAddressFragment }
      personalIdentityNumber
      birthDate
      birthPlace

      registeredParentIds
      unregisteredParents {
        firstName
        lastName
        phoneNumber
        email
        address { ...WholeAddressFragment }
      }

      labels
      cardId
      groupId
      roomId
      livingStartDate
      livingEndDate
      isSuspended
      isPresent
      accountStatus
      balance
      subscription

      schoolDetails {
        schoolName
        schoolKind
        classNumber
      }
    }
    parents {
      parentId
      firstName
      lastName
      email
      phoneNumber
      accountStatus
      boarderIds
    }
  }
`;

export type GetAllBoardersReportDataBoarder = Pick<Boarder,
  'boarderId' |
  'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'address' | 'personalIdentityNumber' | 'birthDate' | 'birthPlace' |
  'registeredParentIds' | 'unregisteredParents' |
  'labels' | 'cardId' | 'groupId' | 'roomId' | 'livingStartDate' | 'livingEndDate' | 'isSuspended' | 'isPresent' | 'accountStatus' | 'balance' | 'subscription' |
  'schoolDetails'
>

export type GetAllBoardersReportDataResponse = {
  boarders: GetAllBoardersReportDataBoarder[];
  parents: ParentWithoutBoarders[];
}

export const useGetAllBoardersReportData = () => {
  const {
    refetch: fetchAllBoardersReportData,
  } = useQuery<GetAllBoardersReportDataResponse>(
    GET_ALL_BOARDERS_REPORT_DATA,
    { skip: true },
  );

  return fetchAllBoardersReportData;
};
