import dayjs, { Dayjs } from 'dayjs';
import { isTomorrow } from '../isTomorrow';

export const isBoarderDayUpdateForbiddenByDate = (date:Dayjs): boolean => {
  const now = dayjs();
  if (date < now) {
    return true;
  }

  if (isTomorrow(date.toDate()) && now.hour() >= 15) {
    return true;
  }

  return false;
};
