/* eslint-disable react/destructuring-assignment */
import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import {
  BoarderDay,
  BoarderYear,
  MealType,
  MonthDayNumber,
  MonthNumber,
  Subscription,
} from 'src/shared/types/common';
import { isWeekend } from 'src/shared/utils';
import { mutateBoarderYear } from 'src/views/BoarderTabs/utils';
import { absenceUpdateForbidden, mealUpdateForbidden } from 'src/utils';
import { CalendarBoarderWeekendDay } from './CalendarBoarderWeekendDay';

type CalendarBoarderDayArgs = {
  boarderYear: BoarderYear | undefined;
  setBoarderYear: Dispatch<SetStateAction<BoarderYear | undefined>>;
  boarderSubscription?: Subscription;
}

export const CalendarBoarderDay = ({
  boarderYear,
  setBoarderYear,
  boarderSubscription,
// eslint-disable-next-line react/function-component-definition
}: CalendarBoarderDayArgs) => (date: Dayjs) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const boarderDay: BoarderDay | undefined = boarderYear
    ?.[date.month() as MonthNumber]
    ?.[date.date() as MonthDayNumber];

  if (isWeekend(date.toDate())) {
    return (
      <CalendarBoarderWeekendDay
        boarderYear={boarderYear}
        setBoarderYear={setBoarderYear}
        date={date}
      />
    );
  }

  return (
    <>
      <Checkbox
        checked={boarderDay?.breakfast.isPaid}
        disabled={boarderDay?.isAbsenceReported || mealUpdateForbidden({
          boarderSubscription,
          user,
          meal: MealType.BREAKFAST,
          date,
        })}
        onChange={boarderYear ? (e) => {
          setBoarderYear(
            mutateBoarderYear({
              date,
              newValue: e.target.checked,
              oldBoarderYear: boarderYear,
              updatedKey: 'breakfast',
            }),
          );
        } : undefined}
      >
        <Space>
          {t('Breakfast')}
          {boarderDay?.breakfast.pickedUpAt && <CheckOutlined />}
        </Space>
      </Checkbox>
      <Checkbox
        checked={boarderDay?.lunch.isPaid}
        disabled={boarderDay?.isAbsenceReported || mealUpdateForbidden({
          boarderSubscription,
          user,
          meal: MealType.LUNCH,
          date,
        })}
        onChange={boarderYear ? (e) => {
          setBoarderYear(
            mutateBoarderYear({
              date,
              newValue: e.target.checked,
              oldBoarderYear: boarderYear,
              updatedKey: 'lunch',
            }),
          );
        } : undefined}
      >
        <Space>
          {t('Lunch')}
          {boarderDay?.lunch.pickedUpAt && <CheckOutlined />}
        </Space>
      </Checkbox>
      <Checkbox
        checked={boarderDay?.dinner.isPaid}
        disabled={boarderDay?.isAbsenceReported || mealUpdateForbidden({
          boarderSubscription,
          user,
          meal: MealType.DINNER,
          date,
        })}
        onChange={boarderYear ? (e) => {
          setBoarderYear(
            mutateBoarderYear({
              date,
              newValue: e.target.checked,
              oldBoarderYear: boarderYear,
              updatedKey: 'dinner',
            }),
          );
        } : undefined}
      >
        <Space>
          {t('Dinner')}
          {boarderDay?.dinner.pickedUpAt && <CheckOutlined />}
        </Space>
      </Checkbox>
      <Divider style={{ margin: 0 }} />
      <Checkbox
        checked={boarderDay?.isAbsenceReported}
        disabled={absenceUpdateForbidden({ date, user })}
        onChange={boarderYear ? (e) => {
          setBoarderYear(
            mutateBoarderYear({
              date,
              newValue: e.target.checked,
              oldBoarderYear: boarderYear,
              updatedKey: 'isAbsenceReported',
            }),
          );
        } : undefined}
      >
        {t('Is absence reported')}
      </Checkbox>
    </>
  );
};
