import { Tag } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const labelsColumn = <T extends { labels: string[] }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Labels'),
    key: 'labels',
    render: (_, { labels }) => (labels?.length ? labels.map(
      (label) => (
        <Tag key={label}>{label}</Tag>
      ),
    ) : null),
  });
