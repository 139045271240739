import { DocumentNode } from '@apollo/client';
import { Form, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BoarderPaymentInput } from 'src/shared/types/common';
import { useOnBoarderPaymentModalSubmit } from 'src/hooks';
import { BoarderPaymentForm } from '../forms';

export type CreateBoarderPaymentModalProps = {
  boarderId?: string;
  isRecruitmentBoarder?: boolean;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  refetchQueries?: DocumentNode[];
}

export const CreateBoarderPaymentModal = (
  {
    boarderId,
    isRecruitmentBoarder,
    isModalVisible,
    setIsModalVisible,
    refetchQueries,
  }: CreateBoarderPaymentModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<BoarderPaymentInput>();

  const [onSubmit, loading] = useOnBoarderPaymentModalSubmit({
    form,
    boarderId,
    refetchQueries,
    isRecruitmentBoarder,
    onSuccess: () => setIsModalVisible(false),
  });

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={t('Creating boarder payment')}
      visible={isModalVisible}
      onOk={onSubmit}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <BoarderPaymentForm form={form} isRecruitmentBoarder={isRecruitmentBoarder} />
    </Modal>
  );
};
