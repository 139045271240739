import {
  Alert,
  Button,
  Divider, Form, Spin,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { useUpdateCanteenManagerEmails } from 'src/gql/mutations';
import { useGetCanteenManagerEmails } from 'src/gql/queries';
import { UpdateCanteenManagerEmailsInput } from 'src/shared/types/api';
import { CanteenManagerEmailsForm } from './forms';

export const CanteenManagerEmails = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<UpdateCanteenManagerEmailsInput>();

  const [canteenManagerEmails, getCanteenManagerEmailsLoading] = useGetCanteenManagerEmails();

  const [
    updateCanteenManagerEmails,
    updateCanteenManagerEmailsLoading,
  ] = useUpdateCanteenManagerEmails({ form });

  useEffect(() => {
    if (canteenManagerEmails) {
      form.setFieldsValue({ canteenManagerEmails });
    }
  }, [canteenManagerEmails]);

  return (
    <Spin spinning={getCanteenManagerEmailsLoading}>
      <Divider />

      <Alert
        style={{ marginBottom: STYLE.BASE_MARGIN }}
        message={t('Tip')}
        description={t('Canteen manager emails fees tip')}
        type="info"
        showIcon
      />

      <CanteenManagerEmailsForm form={form} />

      <Button
        type="primary"
        size="large"
        loading={updateCanteenManagerEmailsLoading}
        className="vertical-base-margin"
        style={{ marginTop: 0 }}
        onClick={updateCanteenManagerEmails}
        block
      >
        {t('Save')}
      </Button>
    </Spin>
  );
};
