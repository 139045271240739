import { Dayjs } from 'dayjs';
import {
  BoarderDay, BoarderYear, MonthDayNumber, MonthNumber,
} from 'src/shared/types/common';
import { isWeekend } from 'src/shared/utils';

export const mutateBoarderYear = ({
  date,
  newValue,
  oldBoarderYear,
  updatedKey,
}: {
  oldBoarderYear: BoarderYear,
  date: Dayjs,
  updatedKey: keyof BoarderDay,
  newValue: boolean
}): BoarderYear => {
  const month = date.month() as MonthNumber;
  const day = date.date() as MonthDayNumber;

  const oldBoarderDay = oldBoarderYear[month][day];

  if (updatedKey === 'isWeekendStayReported') {
    if (!isWeekend(date.toDate())) {
      console.error('`isWeekendStayReported` should not be updated for week days');

      return oldBoarderYear;
    }

    return {
      ...oldBoarderYear,
      [month]: {
        ...oldBoarderYear[month],
        [day]: {
          ...oldBoarderDay,
          isWeekendStayReported: newValue,
        },
      },
    };
  }

  if (updatedKey === 'isAbsenceReported') {
    return {
      ...oldBoarderYear,
      [month]: {
        ...oldBoarderYear[month],
        [day]: newValue ? {
          isAbsenceReported: true,
          isWeekendStayReported: false,
          breakfast: { isPaid: false },
          lunch: { isPaid: false },
          dinner: { isPaid: false },
        } : {
          isAbsenceReported: false,
          isWeekendStayReported: false,
          breakfast: { isPaid: true },
          lunch: { isPaid: true },
          dinner: { isPaid: true },
        },
      },
    };
  }

  return {
    ...oldBoarderYear,
    [month]: {
      ...oldBoarderYear[month],
      [day]: {
        ...oldBoarderDay,
        [updatedKey]: { isPaid: newValue },
      },
    },
  };
};
