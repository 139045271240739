import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ParentForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_PARENTS } from 'src/gql/queries';
import { UpdateParentInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_PARENT = gql`
  mutation UpdateParent($input: UpdateParentInput!) {
    updateParent(input: $input) {
      parentId
    }
  }
`;

type UseUpdateParentArgs =
  UseOnMutationArgs<ParentForm> & {parentId?: string}

export const useUpdateParent = (
  { form, parentId, onSuccess }: UseUpdateParentArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateParent, { loading }] = useMutation<
    unknown, { input: UpdateParentInput }
  >(UPDATE_PARENT, { refetchQueries: [GET_ALL_PARENTS] });

  const onUpdateParent = useCallback(async () => {
    if (!parentId) {
      return notification.error({ message: t('Parent has not been updated successfully') });
    }

    const formValues = await form.validateFields();
    const parentDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await updateParent({
        variables: {
          input: {
            parentId,
            parent: parentDto,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Parent has been updated successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Parent has not been updated successfully. User exists') });
      }

      return notification.error({ message: t('Parent has not been updated successfully') });
    }
  }, [form, parentId, onSuccess]);

  return [onUpdateParent, loading];
};
