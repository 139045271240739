import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { Dormitory } from 'src/shared/types/api';
import { monthRule } from '../rules';

type MonthFormItemProps = {
  loading: boolean;
  chargedMonths?: Dormitory['chargedMonths']
}

export const MonthFormItem = ({ loading, chargedMonths }: MonthFormItemProps) => {
  const { t } = useTranslation();

  const options: DefaultOptionType[] = [
    { value: 'september', disabled: !!chargedMonths?.september, label: t('September') },
    { value: 'october', disabled: !!chargedMonths?.october, label: t('October') },
    { value: 'november', disabled: !!chargedMonths?.november, label: t('November') },
    { value: 'december', disabled: !!chargedMonths?.december, label: t('December') },
    { value: 'january', disabled: !!chargedMonths?.january, label: t('January') },
    { value: 'february', disabled: !!chargedMonths?.february, label: t('February') },
    { value: 'march', disabled: !!chargedMonths?.march, label: t('March') },
    { value: 'april', disabled: !!chargedMonths?.april, label: t('April') },
    { value: 'may', disabled: !!chargedMonths?.may, label: t('May') },
    { value: 'june', disabled: !!chargedMonths?.june, label: t('June') },
  ];

  return (
    <Form.Item
      label={t('Month')}
      name="month"
      rules={[monthRule()]}
    >
      <Select loading={loading} options={options} />
    </Form.Item>
  );
};
