/* eslint-disable react/destructuring-assignment */
import { Checkbox, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  BoarderDay,
  BoarderYear,
  MonthDayNumber,
  MonthNumber,
} from 'src/shared/types/common';
import { mutateBoarderYear } from 'src/views/BoarderTabs/utils';
import { useUserContext } from 'src/context';
import { weekendStayForbidden } from 'src/utils';
import { Dispatch, SetStateAction } from 'react';

type CalendarBoarderWeekendDayProps = {
  boarderYear: BoarderYear | undefined;
  setBoarderYear: Dispatch<SetStateAction<BoarderYear | undefined>>;
  date: Dayjs
}

export const CalendarBoarderWeekendDay = ({
  boarderYear,
  setBoarderYear,
  date,
}: CalendarBoarderWeekendDayProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const boarderDay: BoarderDay | undefined = boarderYear
    ?.[date.month() as MonthNumber]
    ?.[date.date() as MonthDayNumber];

  return (
    <Checkbox
      checked={boarderDay?.isWeekendStayReported}
      disabled={weekendStayForbidden({
        user,
        date,
      })}
      onChange={(e) => boarderYear && setBoarderYear(
        mutateBoarderYear({
          date,
          newValue: e.target.checked,
          oldBoarderYear: boarderYear,
          updatedKey: 'isWeekendStayReported',
        }),
      )}
    >
      <Space>
        {t('Weekend stay')}
      </Space>
    </Checkbox>
  );
};
