import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps } from '.';
import { EmailFormItem } from './email';
import { LabelFormItem } from './label';

export const LabeledEmailFormItem = ({
  keyPrefix,
  disabled,
}: FormItemCommonProps) => (
  <Row gutter={STYLE.BASE_MARGIN}>
    <Col xs={24} sm={12}>
      <LabelFormItem
        keyPrefix={keyPrefix}
        disabled={disabled}
      />
    </Col>
    <Col xs={24} sm={12}>
      <EmailFormItem
        keyPrefix={keyPrefix}
        required
        disabled={disabled}
      />
    </Col>
  </Row>
);
