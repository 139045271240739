import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Note } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_NOTES = gql`
  query GetDormitoryNotes {
    dormitory{
      dormitoryNotes{
        noteId
        title
        description
        createdAt
        createdBy
        updatedAt
      }
    }
  }
`;

type GetDormitoryNotesResponse = { dormitory: { dormitoryNotes: Note[] } };

export const useGetDormitoryNotes = (): UseQueryReturnType<Note[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryNotesResponse>(
    GET_DORMITORY_NOTES,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory notes') });
  }

  return [data?.dormitory.dormitoryNotes, loading, error];
};
