import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_TUTORS } from 'src/gql/queries';
import { CreateTutorInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_TUTOR = gql`
  mutation CreateTutor($input: CreateTutorInput!) {
    createTutor(input: $input) {
      tutorId
    }
  }
`;

export const useCreateTutor = (
  { form, onSuccess }: UseOnMutationArgs<TutorForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createTutor, { loading }] = useMutation<
  unknown, { input: CreateTutorInput }
>(CREATE_TUTOR, { refetchQueries: [GET_ALL_TUTORS] });

  const onCreateTutor = useCallback(async () => {
    const formValues = await form.validateFields();
    const tutorDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await createTutor({
        variables: {
          input: tutorDto,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Tutor has been created successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Tutor has not been created successfully. User exists') });
      }

      return notification.error({ message: t('Tutor has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateTutor, loading];
};
