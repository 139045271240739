import { useQuery } from '@apollo/client';
import { notification } from 'antd';
import { DocumentNode } from 'graphql';
import { UseQueryReturnType } from '..';

export type UseGetSpecificBoarderOtherInfoArgsCore = {
  boarderId?: string;
  archive?: boolean;
}

type UseGetSpecificBoarderOtherInfoArgs = UseGetSpecificBoarderOtherInfoArgsCore & {
  GET_SPECIFIC_BOARDER_OTHER_INFO: DocumentNode;
  GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE: DocumentNode;
  errorMessage: string;
}

export const useGetSpecificBoarderOtherInfo = <QueryResponse> ({
  boarderId,
  archive,
  GET_SPECIFIC_BOARDER_OTHER_INFO,
  GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE,
  errorMessage,
}: UseGetSpecificBoarderOtherInfoArgs): UseQueryReturnType<QueryResponse> => {
  const {
    loading,
    error,
    data,
  } = useQuery<{
      boarder?: QueryResponse;
      boarderArchive?: QueryResponse;
    }>(
      archive ? GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE : GET_SPECIFIC_BOARDER_OTHER_INFO,
      { variables: { boarderId } },
    );

  if (error) {
    notification.error({ message: errorMessage });
  }

  return [archive ? data?.boarderArchive : data?.boarder, loading, error];
};
