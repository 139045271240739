import { DocumentNode, gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPECIFIC_BOARDER_PAYMENTS } from 'src/gql/queries';
import { CreateBoarderRecruitmentPaymentInput } from 'src/shared/types/api';
import { BoarderPaymentInput } from 'src/shared/types/common';
import { NEGATIVE_PAYMENT_TYPES } from 'src/shared/utils';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_BOARDER_RECRUITMENT_PAYMENT = gql`
  mutation CreateBoarderRecruitmentPayment($input: CreateBoarderRecruitmentPaymentInput!) {
    createBoarderRecruitmentPayment(input: $input) {
      boarderRecruitmentId
    }
  }
`;

type UseCreateBoarderRecruitmentPaymentArgs =
  UseOnMutationArgs<BoarderPaymentInput>
  & {
    boarderRecruitmentId?: string,
    refetchQueries?: DocumentNode[],
  }

export const useCreateBoarderRecruitmentPayment = ({
  form,
  boarderRecruitmentId,
  refetchQueries,
  onSuccess,
}: UseCreateBoarderRecruitmentPaymentArgs): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarderRecruitmentPayment, { loading }] = useMutation<
    unknown, { input: CreateBoarderRecruitmentPaymentInput }
  >(
    CREATE_BOARDER_RECRUITMENT_PAYMENT,
    {
      refetchQueries: refetchQueries || [GET_SPECIFIC_BOARDER_PAYMENTS],
    },
  );

  const onCreateBoarderRecruitmentPayment = useCallback(async () => {
    if (!boarderRecruitmentId) {
      return notification.error({ message: t('Boarder payment has not been created successfully') });
    }
    const { amount, comment, type } = await form.validateFields();
    try {
      await createBoarderRecruitmentPayment({
        variables: {
          input: {
            boarderRecruitmentId,
            payment: {
              type,
              amount: NEGATIVE_PAYMENT_TYPES.includes(type)
                ? -1 * Number(amount)
                : Number(amount),
              comment,
            },
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder payment has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder payment has not been created successfully') });
    }
  }, [form, boarderRecruitmentId, refetchQueries, onSuccess]);

  return [onCreateBoarderRecruitmentPayment, loading];
};
