import { Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClosedQuestionRadio } from 'src/components';
import { STYLE } from 'src/constants';
import { questionnaireRule } from '../rules';

const { Paragraph, Text } = Typography;

type RiskFactorsFormItemProps ={
  disabled?: boolean;
}

export const RiskFactorsFormItem = ({ disabled }: RiskFactorsFormItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Paragraph>
        <Text strong>
          {t('Risk factors?')}
        </Text>
      </Paragraph>
      <div style={{ marginLeft: 2 * STYLE.BASE_MARGIN }}>
        <Form.Item name="alcohol" label={t('Alcohol')} rules={[questionnaireRule()]}>
          <ClosedQuestionRadio disabled={disabled} />
        </Form.Item>
        <Form.Item name="cigarettes" label={t('Cigarettes/e-cigarettes')} rules={[questionnaireRule()]}>
          <ClosedQuestionRadio disabled={disabled} />
        </Form.Item>
        <Form.Item name="narcotics" label={t('Narcotics/drugs')} rules={[questionnaireRule()]}>
          <ClosedQuestionRadio disabled={disabled} />
        </Form.Item>
        <Form.Item name="gambling" label={t('Gambling')} rules={[questionnaireRule()]}>
          <ClosedQuestionRadio disabled={disabled} />
        </Form.Item>
        <Form.Item name="addiction" label={t('Addiction to computer/Internet')} rules={[questionnaireRule()]}>
          <ClosedQuestionRadio disabled={disabled} />
        </Form.Item>
      </div>
    </>
  );
};
