import {
  BoarderYear,
  MonthNumber,
  Month,
  IsAbsenceReported,
  DailyMeals,
  IsWeekendStayReported,
} from 'src/shared/types/common';

type BoarderYearToStringifiedMonthArgs = {
  boarderYear: BoarderYear,
  monthNumber: MonthNumber
}

export const boarderYearToMonthlyAbsences = (
  { boarderYear, monthNumber }: BoarderYearToStringifiedMonthArgs,
): Month<IsAbsenceReported> => Object.entries(
  boarderYear[monthNumber],
).reduce((prev, [dayNumber, { isAbsenceReported }]) => ({
  ...prev,
  [dayNumber]: { isAbsenceReported },
}), {} as Month<IsAbsenceReported>);

export const boarderYearToMonthlyWeekendStays = (
  { boarderYear, monthNumber }: BoarderYearToStringifiedMonthArgs,
): Month<IsWeekendStayReported> => Object.entries(
  boarderYear[monthNumber],
).reduce((prev, [dayNumber, { isWeekendStayReported }]) => ({
  ...prev,
  [dayNumber]: { isWeekendStayReported: isWeekendStayReported ?? false },
}), {} as Month<IsWeekendStayReported>);

export const boarderYearToMonthlyMeals = (
  { boarderYear, monthNumber }: BoarderYearToStringifiedMonthArgs,
): Month<DailyMeals> => Object.entries(
  boarderYear[monthNumber],
).reduce((prev, [dayNumber, day]) => ({
  ...prev,
  [dayNumber]: {
    breakfast: Boolean(day.breakfast.isPaid), // HOTFIX
    lunch: Boolean(day.lunch.isPaid), // HOTFIX
    dinner: Boolean(day.dinner.isPaid), // HOTFIX
  },
}), {} as Month<DailyMeals>);
