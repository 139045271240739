import { Dispatch, SetStateAction } from 'react';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import 'antd/es/calendar/style';
import plDayjs from 'dayjs/locale/pl';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { monthsMap } from 'src/shared/utils';

export const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig as any);

type CalendarMonthSwitcherProps = {
  value: Dayjs;
  // eslint-disable-next-line no-unused-vars
  onChange: (date: Dayjs) => void; // eslint bug
  setFirstDayOfUpdatedMonth?: Dispatch<SetStateAction<Dayjs>>;
}

export const CalendarMonthSwitcher = (
  { onChange, value, setFirstDayOfUpdatedMonth }: CalendarMonthSwitcherProps,
) => (
  <div className="calendar-month-switcher-wrapper">
    <MinusCircleOutlined
      className={value.month() !== monthsMap.september ? 'action-icon' : 'action-icon-disabled'}
      onClick={() => {
        if (value.month() !== monthsMap.september) {
          const newDate = value.subtract(1, 'month');
          if (setFirstDayOfUpdatedMonth) {
            setFirstDayOfUpdatedMonth(newDate);
          }
          onChange(newDate);
        }
      }}
    />
    {value.locale(plDayjs).format('MMMM YYYY')}
    <PlusCircleOutlined
      className={value.month() !== monthsMap.june ? 'action-icon' : 'action-icon-disabled'}
      onClick={() => {
        if (value.month() !== monthsMap.june) {
          const newDate = value.add(1, 'month');
          if (setFirstDayOfUpdatedMonth) {
            setFirstDayOfUpdatedMonth(newDate);
          }
          onChange(newDate);
        }
      }}
    />
  </div>
);
