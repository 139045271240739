import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { amountThresholdRule } from '../rules';

export const AmountThresholdFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Amount threshold')}
      name="amountThreshold"
      rules={[amountThresholdRule()]}
      initialValue={0}
    >
      <Input type="number" suffix={t('PLN')} max={0} />
    </Form.Item>
  );
};
