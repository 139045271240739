import { accessControlMatrix } from 'src/shared/accessControlMatrix';
import { ContextUserAsViewed, GqlRequest, UserGroup } from 'src/shared/types/common';
import { getFullName } from 'src/shared/utils';

export class UserInContext {
  public group: UserGroup;

  public fullName: string;

  public boarderId?: string;

  public administratorId?: string;

  public tutorId?: string;

  public parentId?: string;

  constructor(args: ContextUserAsViewed) {
    this.group = args.group;
    this.boarderId = (args as { boarderId?: string }).boarderId;
    this.administratorId = (args as { administratorId?: string }).administratorId;
    this.tutorId = (args as { tutorId?: string }).tutorId;
    this.parentId = (args as { parentId?: string }).parentId;
    this.fullName = getFullName({ item: args });
  }

  public isAllowed(action: Exclude<GqlRequest, 'handler' | 'authorizeBoarderAccess' | 'notifyAboutOnlinePayment'>): boolean {
    return accessControlMatrix[this.group][action];
  }
}
