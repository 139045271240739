import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { Activity } from 'src/shared/types/common';
import { activityDateColumn, activityTypeColumn, activityRegisterColumn } from './utils';

type BoarderActivitiesTableProps = {
  activities?: Activity[]
}

export const BoarderActivitiesTable = ({
  activities,
}: BoarderActivitiesTableProps) => {
  const { t } = useTranslation();
  const isMobileTable = useIsMobile(800);

  // TODO: useBoarderActivitiesTableColumns (BUR-194)
  const columns = isMobileTable ? [
    activityDateColumn(t),
    activityTypeColumn(t),
  ] : [
    activityDateColumn(t),
    activityTypeColumn(t),
    activityRegisterColumn(t),
  ];

  return (
    <Table
      className="boarder-tab-content-wrapper"
      dataSource={activities}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 100,
      }}
      size="small"
      columns={columns}
      rowKey="activityId"
    />
  );
};
