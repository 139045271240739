import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateAdministratorForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_ADMINISTRATORS } from 'src/gql/queries';
import { CreateAdministratorInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_ADMINISTRATOR = gql`
  mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      administratorId
    }
  }
`;

export const useCreateAdministrator = (
  { form, onSuccess }: UseOnMutationArgs<CreateAdministratorForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createAdministrator, { loading }] = useMutation<
    unknown, { input: CreateAdministratorInput }
  >(CREATE_ADMINISTRATOR, { refetchQueries: [GET_ALL_ADMINISTRATORS] });

  const onCreateAdministrator = useCallback(async () => {
    const formValues = await form.validateFields();
    const administratorDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await createAdministrator({
        variables: {
          input: administratorDto,
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Administrator has been created successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Administrator has not been created successfully. User exists') });
      }

      return notification.error({ message: t('Administrator has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateAdministrator, loading];
};
