import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BoarderArchive, BoarderListItem } from 'src/gql/queries';
import { useWindowDimensions } from 'src/hooks';
import { BoarderAsViewed } from 'src/views/BoardersView';
import {
  emailColumn,
  phoneNumberColumn,
  roomColumn,
  groupColumn,
  presenceStateColumn,
  balanceColumn,
  lastActivityColumn,
  labelsColumn,
  boarderFullNameColumn,
  archivedAtColumn,
} from './utils/columns';

export const useBoardersTableColumns = ({ archiveView } = { archiveView: false }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const columnsMemo = useMemo(() => ({
    fullNameColumn: boarderFullNameColumn<BoarderAsViewed>(t),
    emailColumn: emailColumn(t),
    phoneNumberColumn: phoneNumberColumn<BoarderListItem>(t),
    labelsColumn: labelsColumn<BoarderListItem>(t),
    roomColumn: roomColumn<BoarderAsViewed>(t),
    groupColumn: groupColumn<BoarderListItem>(t),
    presenceStateColumn: presenceStateColumn<BoarderListItem>(t),
    balanceColumn: balanceColumn<BoarderListItem>(t),
    lastActivityColumn: lastActivityColumn<BoarderListItem>(t),
    archivedAtColumn: archivedAtColumn<BoarderArchive>(t),
  }), [t]);

  if (archiveView) {
    if (width < 360) {
      return [columnsMemo.fullNameColumn];
    }

    if (width < 600) {
      return [
        columnsMemo.fullNameColumn,
        columnsMemo.archivedAtColumn,
      ];
    }

    if (width < 1024) {
      return [
        columnsMemo.fullNameColumn,
        columnsMemo.roomColumn,
        columnsMemo.groupColumn,
        columnsMemo.balanceColumn,
        columnsMemo.archivedAtColumn,
      ];
    }

    return [
      columnsMemo.fullNameColumn,
      columnsMemo.labelsColumn,
      columnsMemo.roomColumn,
      columnsMemo.groupColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.balanceColumn,
      columnsMemo.archivedAtColumn,
    ];
  }

  if (width < 400) {
    return [
      columnsMemo.fullNameColumn,
      columnsMemo.roomColumn,
      columnsMemo.presenceStateColumn,
    ];
  }

  if (width < 600) {
    return [
      columnsMemo.fullNameColumn,
      columnsMemo.roomColumn,
      columnsMemo.groupColumn,
      columnsMemo.presenceStateColumn,
    ];
  }

  if (width < 800) {
    return [
      columnsMemo.fullNameColumn,
      columnsMemo.roomColumn,
      columnsMemo.groupColumn,
      columnsMemo.lastActivityColumn,
      columnsMemo.presenceStateColumn,
    ];
  }

  if (width < 1024) {
    return [
      columnsMemo.fullNameColumn,
      columnsMemo.roomColumn,
      columnsMemo.groupColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.lastActivityColumn,
      columnsMemo.presenceStateColumn,
    ];
  }

  return [
    columnsMemo.fullNameColumn,
    columnsMemo.labelsColumn,
    columnsMemo.roomColumn,
    columnsMemo.groupColumn,
    columnsMemo.phoneNumberColumn,
    columnsMemo.balanceColumn,
    columnsMemo.lastActivityColumn,
    columnsMemo.presenceStateColumn,
  ];
};
