import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_CANTEEN_MANAGER_EMAILS = gql`
  query GetCanteenManagerEmails {
    dormitory {
      canteenManagerEmails
    }
  }
`;

export type GetCanteenManagerEmailsResponse = { dormitory: Pick<Dormitory, 'canteenManagerEmails'>}

export const useGetCanteenManagerEmails = (): UseQueryReturnType<
  string[]
> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetCanteenManagerEmailsResponse>(
    GET_CANTEEN_MANAGER_EMAILS,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch canteen manager emails') });
  }

  return [data?.dormitory.canteenManagerEmails, loading, error];
};
