export const enum SchoolKind {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TECHNICAL_SECONDARY = 'TECHNICAL_SECONDARY',
  VOCATIONAL_SECONDARY = 'VOCATIONAL_SECONDARY',
}

export const SchoolKinds = [
  SchoolKind.PRIMARY,
  SchoolKind.SECONDARY,
  SchoolKind.TECHNICAL_SECONDARY,
  SchoolKind.VOCATIONAL_SECONDARY,
] as const;

export const enum NoteCategory {
  DORMITORY = 'DORMITORY',
  HEALTH = 'HEALTH',
  BEHAVIOR = 'BEHAVIOR',
  OTHER = 'OTHER',
}

export const BoarderNoteCategories = [
  NoteCategory.HEALTH,
  NoteCategory.BEHAVIOR,
  NoteCategory.OTHER,
] as const;

export const enum MealType {
  BREAKFAST = 'BREAKFAST',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
}

export const enum PaymentType {
  AUTO_FEE = 'AUTO_FEE', // Opłata stała (auto)
  AUTO_FEE_ACADEMIC =' AUTO_FEE_ACADEMIC', // Opłata stała: student (auto)
  AUTO_FEE_NO_MEALS = 'AUTO_FEE_NO_MEALS', // Opłata stała + brak posiłków (auto)
  AUTO_FEE_BREAKFAST_DINNER = 'AUTO_FEE_BREAKFAST_DINNER', // Opłata stała + śniadania i kolacje (auto)
  AUTO_FEE_BREAKFAST_LUNCH_DINNER = 'AUTO_FEE_BREAKFAST_LUNCH_DINNER', // Opłata stała + śniadania, obiady i kolacje (auto)
  FEE = 'FEE', // Opłata stała
  ABSENCE = 'ABSENCE', // Nieobecność
  MEALS = 'MEALS', // Posiłki
  ADVANCE = 'ADVANCE', // Zaliczka
  DAMAGE = 'DAMAGE', // Pokrycie szkód
  CARD_DUPLICATE = 'CARD_DUPLICATE', // Duplikat karty
  WEEKEND_STAY = 'WEEKEND_STAY', // Pobyt weekendowy
  OTHER_FEE = 'OTHER_FEE', // Inna opłata
  BOARDER_PAYMENT = 'BOARDER_PAYMENT', // Potwierdzenie przelewu
  RECRUITMENT_PAYMENT = 'RECRUITMENT_PAYMENT', // Potwierdzenie opłaty rekrutacyjnej
  CORRECTION = 'CORRECTION', // Korekta
  OTHER_REFUND = 'OTHER_REFUND', // Inny zwrot
  ONLINE = 'ONLINE', // Płatność online
}

export const enum Subscription {
  BREAKFAST_DINNER = 'BREAKFAST_DINNER',
  BREAKFAST_LUNCH_DINNER = 'BREAKFAST_LUNCH_DINNER',
  NO_MEALS = 'NO_MEALS',
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  ACADEMIC = 'ACADEMIC'
}

export const enum BoarderActivityType {
  ENTER = 'ENTER',
  LEAVE = 'LEAVE',
  PICK_UP_BREAKFAST = 'PICK_UP_BREAKFAST',
  PICK_UP_LUNCH = 'PICK_UP_LUNCH',
  PICK_UP_DINNER = 'PICK_UP_DINNER',
}

export const enum RequestStatus {
  AWAITING = 'AWAITING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export const enum AccountStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  UNKNOWN = 'UNKNOWN',
  RESET_REQUIRED = 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export const enum WeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}
