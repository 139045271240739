import { Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { RecruitmentUnregisteredBoarderDataFormFields, RecruitmentUnregisteredBoarderForm } from 'src/components';
import { useSetCurrentRecruitmentForm, useSetInitialFormFields } from 'src/hooks';

const { Paragraph, Text } = Typography;

export const UnregisteredBoarderDataStep = () => {
  const { t } = useTranslation();
  const [form] = useForm<RecruitmentUnregisteredBoarderDataFormFields>();
  useSetCurrentRecruitmentForm(form);
  useSetInitialFormFields(form, 'unregisteredBoarderData');

  return (
    <>
      <Paragraph>
        <Text>{t('Unregistered boarder data recruitment step')}</Text>
      </Paragraph>
      <RecruitmentUnregisteredBoarderForm form={form} />

    </>

  );
};
