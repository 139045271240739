import { DocumentNode } from 'graphql';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { GqlMutation } from 'src/shared/types/common';
import { useToggleBoarderPresenceState } from 'src/gql/mutations';
import { useUserContext } from 'src/context';

type BoarderPresenceStateSwitchProps = {
  boarderId?: string;
  currentState?: boolean;
  refetchQuery: DocumentNode
}

export const BoarderPresenceStateSwitch = ({
  boarderId,
  currentState,
  refetchQuery,
}: BoarderPresenceStateSwitchProps) => {
  const user = useUserContext();

  const [
    toggleBoarderPresenceState,
    loading,
  ] = useToggleBoarderPresenceState({ boarderId, refetchQueries: [refetchQuery] });

  return (
    <Switch
      loading={loading}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={currentState}
      disabled={!user?.isAllowed(GqlMutation.ToggleBoarderPresenceState)}
      onChange={toggleBoarderPresenceState}
      onClick={(_, e) => e.stopPropagation()}
    />
  );
};
