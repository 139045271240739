import { UserAddOutlined } from '@ant-design/icons';
import {
  Popconfirm, Popover, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateBoarderAndParentsByRecruitmentId } from 'src/gql/mutations/recruitment';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';
import { RecruitmentEventEnum } from 'src/shared/types/docs';

type CreateBoarderButtonProps = {
  record: RecruitmentBoarderRequest;
}

export const CreateBoarderButton = ({ record }: CreateBoarderButtonProps) => {
  const { t } = useTranslation();

  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(record.events.some(
    ({ event }) => event === RecruitmentEventEnum.BOARDER_CREATED,
  ) || record.boarder.isExistingBoarder);

  const [
    createBoarderAndParentsByRecruitmentId,
    loading,
  ] = useCreateBoarderAndParentsByRecruitmentId({
    boarderRecruitmentId: record.boarderRecruitmentId,
    onSuccess: () => setDisabled(true),
    onComplete: () => setPopConfirmVisible(false),
  });

  return (
    <Popconfirm
      title={t('Confirm create boarder from recruitment request')}
      okText={t('Yes')}
      visible={popConfirmVisible}
      onConfirm={createBoarderAndParentsByRecruitmentId}
      okButtonProps={{ loading }}
      onCancel={() => setPopConfirmVisible(false)}
    >
      <Popover content={t('Create boarder account')}>
        <Typography.Link disabled={disabled}>
          <UserAddOutlined onClick={() => setPopConfirmVisible(true)} />
        </Typography.Link>
      </Popover>
    </Popconfirm>
  );
};
