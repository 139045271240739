import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { ExistingBoarderConfirmationOption } from '../RecruitmentStartStepForm';

export const existingBoarderConfirmationOptionsRule = (isExistingBoarder: boolean): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please update data in system to proceed with recruitment'),
    validator: (_, value?: ExistingBoarderConfirmationOption[]) => {
      if (!isExistingBoarder) {
        return Promise.resolve();
      }

      if (
        value?.length === 4 && value.includes(ExistingBoarderConfirmationOption.BoarderHasAccess)
        && value.includes(ExistingBoarderConfirmationOption.BoarderInfoIsUpToDate)
        && value.includes(ExistingBoarderConfirmationOption.ParentsHaveAccess)
        && value.includes(ExistingBoarderConfirmationOption.ParentsContactDetailsAreUpToDate)
      ) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
  });
};
