import { Checkbox, CheckboxOptionType, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { recipientsRule } from '../rules';

type RecipientsFormItemProps = {
  recipients: CheckboxOptionType[];
};

export const RecipientsFormItem = ({ recipients }: RecipientsFormItemProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Recipients')}
      name="recipients"
      rules={[recipientsRule()]}
    >
      <Checkbox.Group options={recipients} className="flex-column" />
    </Form.Item>
  );
};
