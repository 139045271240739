import { Divider, Space } from 'antd';
import { useMemo } from 'react';
import { STYLE } from 'src/constants';
import { useIsMobile } from 'src/hooks';

export const Footer = () => {
  const isMobile = useIsMobile(600);

  return useMemo(
    () => (
      <div className="content-wrapper">
        <Divider style={{ fontSize: STYLE.SECONDARY_FONT_SIZE }}>
          <Space direction="horizontal" size="middle">
            <a href="https://www.e-bursa.pl/" target="blank">e-bursa.pl</a>
            {!isMobile && (
            <>
              <span style={{ fontWeight: 'bold' }}>•</span>
              Developed with ❤️ in Szczecin
            </>
            )}
            <span style={{ fontWeight: 'bold' }}>•</span>
            {new Date().getFullYear()}
          </Space>
        </Divider>
      </div>
    ),
    [isMobile],
  );
};
