/* eslint-disable */ // eslint doesn't handle const enums properly

import { t } from "i18next";

export const enum ROUTE {
  DORMITORY,
  BOARDER,
  BOARDER_ARCHIVE,
  BOARDERS,
  BOARDERS_ARCHIVE,
  PARENTS,
  TUTORS,
  ADMINISTRATORS,
  CONTACT,
  ONLINE_PAYMENT_CONFIRMATION,
}

export const getRouteTranslated = (route: ROUTE) => ({
  [ROUTE.DORMITORY]: `/${t('dormitory')}`,
  [ROUTE.PARENTS]: `/${t('parents')}`,
  [ROUTE.BOARDER]: `/${t('boarders')}/:boarderId`,
  [ROUTE.BOARDER_ARCHIVE]: `/${t('boarders-archive')}/:boarderId`,
  [ROUTE.BOARDERS]: `/${t('boarders')}`,
  [ROUTE.BOARDERS_ARCHIVE]: `/${t('boarders-archive')}`,
  [ROUTE.TUTORS]: `/${t('tutors')}`,
  [ROUTE.ADMINISTRATORS]: `/${t('administrators')}`,
  [ROUTE.CONTACT]: `/${t('contact')}`,
  [ROUTE.ONLINE_PAYMENT_CONFIRMATION]: `/${t('online-payment-confirmation')}`,
})[route];