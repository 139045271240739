import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps, nameWithPrefix } from '.';
import { postCodeRule } from '../rules';

export const PostCodeFormItem = ({ keyPrefix, disabled, required }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Post code')}
      name={nameWithPrefix({ name: 'postCode', keyPrefix })}
      rules={[postCodeRule({ required: Boolean(required) })]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
