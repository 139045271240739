import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { GqlQuery, RoomRental } from 'src/shared/types/common';
import { useUserContext } from 'src/context';
import { UseQueryReturnType } from '..';

export const GET_ALL_BOARDERS_ICONS_DATA = gql`
  query GetAllBoardersIconsData {
    boarders {
      boarderId
      roomId
      isSuspended
      updateGeneralInfoRequests {
        awaiting {
          requestId
        }
      }
    }
    dormitory {
      roomRentals {
        endsAt
        roomIds
        startsAt
      }
    }
  }
`;

export type RoomRentalBrief = Pick<RoomRental, 'startsAt' | 'endsAt' | 'roomIds'>

type BoardersViewIconData = {
  boarders: Array<Pick<Boarder, 'boarderId'| 'isSuspended' | 'roomId'> & { updateGeneralInfoRequests: { awaiting: { requestId: string }[] } }> | undefined;
  roomRentals: RoomRentalBrief[] | undefined;
}

type GetAllBoardersIconDataResponse = {
  boarders: BoardersViewIconData['boarders'],
  dormitory: { roomRentals: RoomRentalBrief[] }
};

export const useGetAllBoardersIconData = (): UseQueryReturnType<BoardersViewIconData> => {
  const { t } = useTranslation();
  const user = useUserContext();
  const {
    loading,
    error,
    data,
  } = useQuery<GetAllBoardersIconDataResponse>(
    GET_ALL_BOARDERS_ICONS_DATA,
    { skip: !(user?.isAllowed(GqlQuery.GetBoarders) && user.isAllowed(GqlQuery.GetRoomRentals)) },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarders') });
  }

  return [{ boarders: data?.boarders, roomRentals: data?.dormitory.roomRentals }, loading, error];
};
