import { PaymentType } from '../types/common';

export const POSITIVE_PAYMENT_TYPES: Readonly<PaymentType[]> = [
  PaymentType.CORRECTION,
  PaymentType.OTHER_REFUND,
  PaymentType.BOARDER_PAYMENT,
  PaymentType.RECRUITMENT_PAYMENT,
] as const;

export const NEGATIVE_PAYMENT_TYPES: Readonly<PaymentType[]> = [
  PaymentType.ADVANCE,
  PaymentType.CARD_DUPLICATE,
  PaymentType.DAMAGE,
  PaymentType.FEE,
  PaymentType.MEALS,
  PaymentType.OTHER_FEE,
  PaymentType.WEEKEND_STAY,
] as const;
