const PHONE_AREA_CODES = [
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  22,
  23,
  24,
  25,
  29,
  32,
  33,
  34,
  41,
  42,
  43,
  44,
  46,
  48,
  52,
  54,
  55,
  56,
  58,
  59,
  61,
  62,
  63,
  65,
  67,
  68,
  71,
  74,
  75,
  76,
  77,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  89,
  91,
  94,
  95,
];

export const phoneNumberToDisplay = (phoneNumber: string): string => {
  if (phoneNumber?.length !== 12) {
    return phoneNumber;
  }

  if (PHONE_AREA_CODES.includes(Number(phoneNumber.slice(3, 5)))) {
    return phoneNumber.replace(/(.{3})(.{2})(.{3})(.{2})(.{2})/, '$2 $3 $4 $5');
  }

  if (phoneNumber.substring(0, 3) === '+48') {
    return phoneNumber.replace(/(.{3})(.{3})(.{3})(.{3})/, '$2 $3 $4');
  }

  return phoneNumber.replace(/(.{3})(.{3})(.{3})(.{3})/, '$1 $2 $3 $4');
};
