import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory, GqlCalendar } from 'src/shared/types/api';
import { UseOnQueryArgs, UseQueryReturnType } from '..';
import { ALL_MONTHS_FRAGMENT } from '../../fragments';

export const GET_DORMITORY_YEAR = gql`
  ${ALL_MONTHS_FRAGMENT}

  query GetDormitoryGeneralInfo {
    dormitory {
      dormitoryYear {
        ...AllMonthsFragment
      }
    }
  }
`;

export type GetDormitoryYearResponse = {
  dormitory: Pick<Dormitory, 'dormitoryYear'>
}

export const useGetDormitoryYear = (
  { onError }: UseOnQueryArgs,
): UseQueryReturnType<GqlCalendar> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryYearResponse>(
    GET_DORMITORY_YEAR,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory year') });
    onError?.();
  }

  return [data?.dormitory.dormitoryYear, loading, error];
};
