import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

export type AntdIcon = ForwardRefExoticComponent<
  Partial<AntdIconProps> & RefAttributes<HTMLSpanElement>
>

type ViewHeaderButtonProps = {
  title: string;
  onClick: () => void;
  type?: ButtonType;
  PrefixIcon?: AntdIcon;
}

export const ViewHeaderButton = ({
  onClick,
  title,
  type,
  PrefixIcon,
}: ViewHeaderButtonProps) => (
  <Button
    className="header-item"
    key={title}
    type={type}
    size="large"
    onClick={onClick}
  >
    {PrefixIcon && <PrefixIcon />}
    {title}
  </Button>
);
