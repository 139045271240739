import { ArrowRightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';

type NextStepProps = {
  onClick: () => void;
}

export const NextStep = ({ onClick }: NextStepProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Link onClick={onClick}>
      {t('Next step')}
      <ArrowRightOutlined style={{ marginLeft: STYLE.BASE_MARGIN }} />
    </Typography.Link>
  );
};
