import { Alert, Form, Modal } from 'antd';
import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CONFIG } from 'src/config';
import { useInitOnlinePayment } from 'src/gql/mutations';
import { numberToPLN } from 'src/utils';
import { InitOnlinePaymentForm, InitOnlinePaymentFormType } from '../forms';

type InitOnlinePaymentModalProps = {
  boarderId?: string;
  balance?: number;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const InitOnlinePaymentModal = ({
  boarderId, balance, isModalVisible, setIsModalVisible,
}: InitOnlinePaymentModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<InitOnlinePaymentFormType>();
  const [amountWithProvision, setAmountWithProvision] = useState<number>();
  const onSuccess = useCallback(async (redirectUri: string) => {
    setIsModalVisible(false);
    setTimeout(() => window.open(redirectUri, '_blank'), 3000);
  }, []);

  const onFormValuesChange = useCallback((
    values: Partial<InitOnlinePaymentFormType>,
  ) => {
    const amount = Number(values.amount);
    if (amount !== undefined && !Number.isNaN(amount)) {
      setAmountWithProvision(amount + CONFIG.PAY_U_PROVISION * amount);
    }
  }, []);

  const [
    initOnlinePayment,
    loading,
  ] = useInitOnlinePayment({ form, onSuccess, boarderId });

  useEffect(() => {
    const amountNumber = balance && balance < 0 ? -balance : 1000;
    const amount = amountNumber.toString();
    form.setFieldsValue({ amount });
    onFormValuesChange({ amount });
  }, []);

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={t('Initiating online payment')}
      visible={isModalVisible}
      onOk={initOnlinePayment}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Init online payment')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <InitOnlinePaymentForm form={form} onValuesChange={onFormValuesChange} />

      {amountWithProvision && amountWithProvision > 0 ? (
        <Alert
          message={t('Warning')}
          description={t('Init online payment tip', { provision: CONFIG.PAY_U_PROVISION * 100, amountWithProvision: numberToPLN(amountWithProvision || 0) })}
          type="info"
          showIcon
        />
      ) : null}
    </Modal>
  );
};
