import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { RecruitmentEventEnum } from 'src/shared/types/docs';

export const recruitmentStatusColumn = <T extends { lastEvent?: RecruitmentEventEnum }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Status'),
    key: 'status',
    width: 140,
    sorter: (a, b) => t(a.lastEvent || '')?.localeCompare(t(b.lastEvent || '')),
    render: (_, { lastEvent }) => (lastEvent ? t(lastEvent) : ''),
  });
