import { useEffect } from 'react';
import { RecruitmentForm, RecruitmentSubFormInstance } from 'src/components';
import { useRecruitmentFormContext } from 'src/context';

export const useSetInitialFormFields = (
  form: RecruitmentSubFormInstance,
  subFormKey: keyof RecruitmentForm,
) => {
  const recruitmentForm = useRecruitmentFormContext();
  useEffect(() => {
    const subForm = recruitmentForm?.recruitmentForm?.[subFormKey];
    if (subForm) {
      form.setFieldsValue(subForm);
    }
  }, [recruitmentForm?.recruitmentForm?.startStep]);
};
