import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  List, Popover, Space, Spin, message,
} from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { STYLE } from 'src/constants';
import { useGetSpecificBoarderPayments } from 'src/gql/queries';
import { numberToPLN } from 'src/utils';
import { Payments } from 'src/shared/types/api';
import { creationInfo } from './creationInfo';

type PaymentsListProps = {
  payments?: Payments;
  t: TFunction;
}

export const PaymentsList = ({ payments, t }: PaymentsListProps) => (
  <List
    dataSource={payments}
    renderItem={(payment) => (
      <List.Item>
        {numberToPLN(payment.amount)}
        {' '}
        ·
        {' '}
        {t(payment.type)}
        {' '}
        ·
        {' '}
        {creationInfo({ createdAt: payment.createdAt, createdBy: payment.createdBy })}
      </List.Item>
    )}
  />
);

type PopoverContentProps = {
  boarderId: string;
  t: TFunction;
}

const PopoverContent = ({ boarderId, t }: PopoverContentProps) => {
  const [response, loading, error] = useGetSpecificBoarderPayments({
    boarderId,
  });

  if (error) {
    message.error(t('Boarder payments have not been fetched successfully'));
  }

  return (
    <Spin spinning={loading}>
      <PaymentsList payments={response?.payments.slice(0, 3)} t={t} />
    </Spin>
  );
};

export const balanceColumn = <T extends { balance: number, boarderId: string }>(
  t: TFunction, showWarning = true,
): ColumnType<T> => ({
    title: t('Account balance'),
    key: 'balance',
    render: (_, { balance, boarderId }) => (
      <Popover content={<PopoverContent boarderId={boarderId} t={t} />} title={t('Last 3 payments')}>
        <Space>
          {numberToPLN(balance)}
          {balance < 0 && showWarning && <ExclamationCircleOutlined style={{ marginLeft: STYLE.BASE_MARGIN / 2 }} className="red" />}
        </Space>
      </Popover>

    ),
    sorter: (obj1, obj2) => (obj1.balance - obj2.balance),
  });
