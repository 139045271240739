import {
  Button, Collapse, Form, Spin,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BoarderDormitoryInfoForm,
  BoarderDormitoryInfoFormFields,
  BoarderGeneralInfoForm,
  BoarderGeneralInfoFormFields,
  BoarderGeneralInfoTabHeader,
  BoarderGeneralInfoTabHeaderArchive,
  BoarderParentsForm,
  BoarderParentsFormFields,
  BoarderSchoolInfoForm,
  BoarderSchoolInfoFormFields,
} from 'src/components';
import { useUserContext } from 'src/context';
import { useUpdateBoarderGeneralInfo } from 'src/gql/mutations';
import { SpecificBoarder, useGetAllParentsBrief } from 'src/gql/queries';
import { GqlMutation, GqlQuery } from 'src/shared/types/common';
import { boarderToBoarderGeneralInfoForm } from './utils';

export type UpdateBoarderForm = BoarderGeneralInfoFormFields &
  BoarderParentsFormFields &
  BoarderDormitoryInfoFormFields &
  BoarderSchoolInfoFormFields;

type BoarderGeneralInfoTabProps = {
  boarder?: SpecificBoarder;
  archive?: true;
}

export const BoarderGeneralInfoTab = ({ archive, boarder }: BoarderGeneralInfoTabProps) => {
  const [form] = Form.useForm<UpdateBoarderForm>();
  const { t } = useTranslation();
  const user = useUserContext();
  const [fetchParentsData, fetchParentsLoading] = useGetAllParentsBrief();

  useEffect(() => {
    form.setFieldsValue(boarderToBoarderGeneralInfoForm(boarder));
  }, [boarder]);

  const [
    updateBoarderGeneralInfo,
    loading,
  ] = useUpdateBoarderGeneralInfo({ form, boarder });

  const isUpdateAllowed = user?.isAllowed(GqlMutation.UpdateBoarder);

  return (
    <Spin spinning={fetchParentsLoading}>
      {archive
        ? <BoarderGeneralInfoTabHeaderArchive boarder={boarder} />
        : <BoarderGeneralInfoTabHeader boarder={boarder} />}

      <Collapse defaultActiveKey={['generalInfo', 'parents', 'boarderData', 'studentData']}>
        <Collapse.Panel header={t('General data')} key="generalInfo">
          <BoarderGeneralInfoForm
            boarder={boarder}
            form={form}
            disabled={!isUpdateAllowed || archive}
          />
        </Collapse.Panel>
        {user?.isAllowed(GqlQuery.GetParents) && (
          <Collapse.Panel header={t('Parents')} key="parents">
            <BoarderParentsForm
              form={form}
              parents={fetchParentsData?.parents}
              disabled={!isUpdateAllowed || archive}
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel header={t('Boarder data')} key="boarderData">
          <BoarderDormitoryInfoForm
            form={form}
            disabled={!isUpdateAllowed || archive}
          />
        </Collapse.Panel>
        <Collapse.Panel header={t('Student data')} key="studentData">
          <BoarderSchoolInfoForm
            boarder={boarder}
            form={form}
            disabled={!isUpdateAllowed || archive}
          />
        </Collapse.Panel>
      </Collapse>

      {user?.isAllowed(GqlMutation.UpdateBoarder) && !archive && (
        <div className="boarder-tab-content-wrapper">
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            onClick={updateBoarderGeneralInfo}
            className="vertical-base-margin "
            block
          >
            {t('Save')}
          </Button>
        </div>
      )}
    </Spin>
  );
};
