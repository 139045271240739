import logo from 'src/assets/bursa-logo-no-margin-alpha.png';
import {
  Typography,
} from 'antd';
import { CONFIG } from 'src/config';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';

export const RecruitmentTitle = () => {
  const { t } = useTranslation();

  return (
    <>
      <a
        href={CONFIG.DORMITORY_WEB_PAGE}
        target="_blank"
        className="center"
        style={{ display: 'block', marginBottom: STYLE.BASE_MARGIN * 3 }}
        rel="noreferrer"
      >
        <img
          src={logo}
          alt="logo"
          style={{ width: '75%', maxWidth: 400 }}
        />
      </a>

      <Typography.Title level={1} className="center" style={{ marginBottom: STYLE.BASE_MARGIN * 2 }}>
        {t('Remote recruitment')}
      </Typography.Title>
    </>
  );
};
