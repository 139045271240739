import { CheckboxOptionType, Form, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendMessage } from 'src/gql/mutations';
import { SendMessageForm, SendMessageModalForm } from '../forms';

type SendMessageModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  recipients?: CheckboxOptionType[];
}

export const SendMessageModal = ({
  isModalVisible,
  setIsModalVisible,
  recipients,
}: SendMessageModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<SendMessageForm>();
  const [sendMessage, loading] = useSendMessage({
    form,
    onSuccess: () => setIsModalVisible(false),
  });

  // If `recipients` is not defined, a button to show the modal shouldn't be visible
  if (!isModalVisible || !recipients) {
    return null;
  }

  return (
    <Modal
      title={t('Sending message')}
      visible={isModalVisible}
      onOk={sendMessage}
      confirmLoading={loading}
      onCancel={() => setIsModalVisible(false)}
      okText={t('Send')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <SendMessageModalForm form={form} recipients={recipients} />
    </Modal>
  );
};
