import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const agreementRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please accept the agreement'),
    validator: (_, value?: string[]) => {
      if (value?.length === 1) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
  });
};
