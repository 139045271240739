const borderColor = '#dcdfe6';

export const tokens = {
  color: {
    primary: '#e5b002',
    primary$hover: '#f2ca29',
    primary$active: '#bf8c00',
    white: '#ffffff',
    border: borderColor,
  },
  space: {
    line: 1,
    xxs: 2,
    xs: 4,
    s: 8,
    m: 16,
    l: 32,
  },
  font: {
    size: {
      xxs: 8,
      xs: 10,
      s: 12,
      m: 14,
      l: 18,
      xl: 24,
      xxl: 32,
    },
    weight: {
      thin: 100,
      light: 300,
      normal: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
    color: {
      title: '#303133',
      primary: '#606266',
      secondary: '#c0c4cc',
    },
  },
  shadow: {
    card: '0 2px 4px 0 #borderColor',
  },
} as const;
