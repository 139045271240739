import { Result, ResultProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  MainCard,
} from 'src/components';

export const OnlinePaymentConfirmationView = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const resultProps: ResultProps = searchParams.get('error') ? {
    status: 'error',
    title: t('Online payment has not been completed successfully'),
    subTitle: t('Online payment has not been completed successfully subtitle'),
  } : {
    status: 'success',
    title: t('Online payment has been completed successfully'),
    subTitle: t('Online payment has been completed successfully subtitle'),
  };

  return (
    <MainCard className="header-margin">
      <Result {...resultProps} />
    </MainCard>
  );
};
