import { Form } from 'antd';
import { ChargeMonthlyFeeInput, Dormitory } from 'src/shared/types/api';
import { MonthNumber } from 'src/shared/types/common';
import { monthsMapReverted } from 'src/shared/utils';
import { FormProps } from '.';
import { MonthFormItem } from './items';

type ChargeMonthlyFeeFormProps = FormProps<ChargeMonthlyFeeInput> & {
  loading: boolean;
  chargedMonths?: Dormitory['chargedMonths']
}

export const ChargeMonthlyFeeForm = ({
  form,
  loading,
  chargedMonths,
}: ChargeMonthlyFeeFormProps) => {
  const suggestedMonthName = monthsMapReverted[
    new Date().getMonth() + 1 as MonthNumber
  ];
  const initialMonthValue = ['july', 'august'].includes(suggestedMonthName) ? '' : suggestedMonthName;

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        month: initialMonthValue,
      }}
    >
      <MonthFormItem loading={loading} chargedMonths={chargedMonths} />
    </Form>
  );
};
