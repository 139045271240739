import {
  Address, ContactDetails, IsoStringDate, IsoStringDateTime,
} from '../common';
import { BoarderPaymentsDoc } from './boarder';

export const enum RecruitmentEventEnum {
  REQUEST_CREATED = 'REQUEST_CREATED',
  REQUEST_ACCEPTED = 'REQUEST_ACCEPTED',
  REQUEST_DECLINED = 'REQUEST_DECLINED',
  REQUEST_CONFIRMED = 'REQUEST_CONFIRMED',
  BOARDER_CREATED = 'BOARDER_CREATED'
}

export type RecruitmentEvent = {
  timestamp: IsoStringDateTime;
  createdBy: string;
  event: RecruitmentEventEnum;
}

export type UnregisteredBoarderRecruitmentDoc = {
  firstName: string;
  lastName: string;
  personalIdentityNumber?: string; // PL: PESEL
  email: string;
  phoneNumber: string;
  address: Address;
  birthDate?: IsoStringDate;
  birthPlace?: string;
  parents: ContactDetails[];
  payments: BoarderPaymentsDoc['payments'];
  balance: number;
}

export type BoarderRecruitmentDoc = {
  boarderRecruitmentId: string;
  boarderId?: string;
  acceptedClausesAndAgreements: string[]
  unregisteredBoarder?: UnregisteredBoarderRecruitmentDoc;
  questionnaire: {
    isAcolyte: boolean;
    parishName?: string;
    isVolunteer: boolean;
    groupName?: string;
    isLargeFamily: boolean;
    isSingleParent: boolean;
    isRefugee: boolean;
    isFoster: boolean;
    hadReligiousEducation: boolean;
    religiousEducationPlans: boolean;
    isOrWantsToBeConfirmed: boolean;
    isSportsman: boolean;
    clubName?: string;
    alcohol: boolean;
    cigarettes: boolean;
    narcotics: boolean;
    gambling: boolean;
    addiction: boolean;
    isDemoralized: boolean;
    isPsychotic: boolean;
    psychotic?: string;
    isIll: boolean;
    ill?: string;
    isAllergic: boolean;
    allergies?: string;
  }
  recruitmentPoints: number;
  events: RecruitmentEvent[];
}
