// https://ant.design/docs/react/replace-moment

import { forwardRef } from 'react';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, {
  RangePickerProps, PickerTimeProps,
} from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { Dayjs } from 'dayjs';
import locale from 'antd/lib/date-picker/locale/pl_PL';

// Typing issue is caused by some incompatibilities between Dayjs/rc-picker/Ant.
// They are fixed by importing plugins in index.tsx
export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any);
export const RangeTimePicker = (
  props: RangePickerProps<Dayjs>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <DatePicker.RangePicker {...props} picker="time" format="HH:mm" locale={locale} />;

export const RangePicker = (
  props: RangePickerProps<Dayjs>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => (
  <DatePicker.RangePicker {...props} locale={locale} />
);

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TimePicker = forwardRef<any, TimePickerProps>(
  // eslint-disable-next-line react/jsx-props-no-spreading
  (props, ref) => (
    <DatePicker
      {...props}
      format="HH:mm"
      picker="time"
      mode={undefined}
      ref={ref}
      locale={locale}
    />
  ),
);

TimePicker.displayName = 'TimePicker';
