import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { RangePicker } from 'src/components';
import { startsAtEndsAtRule } from '../rules';

export const StartsAtEndsAtFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Date')}
      name="startsAtEndsAt"
      rules={[startsAtEndsAtRule()]}
    >
      <RangePicker />
    </Form.Item>
  );
};
