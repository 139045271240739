import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';
import { WHOLE_ADDRESS_FRAGMENT } from '../../fragments';

export const GET_SPECIFIC_BOARDER = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetSpecificBoarder($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      boarderId

      firstName
      lastName
      phoneNumber
      email
      address { ...WholeAddressFragment }
      personalIdentityNumber
      birthDate
      birthPlace

      registeredParentIds
      unregisteredParents {
        firstName
        lastName
        phoneNumber
        email
        address { ...WholeAddressFragment }
      }

      labels
      cardId
      groupId
      roomId
      livingStartDate
      livingEndDate

      schoolDetails {
        schoolName
        schoolKind
        classNumber
      }

      isSuspended
      accountStatus
    }
  }
`;

export type SpecificBoarder = Pick<Boarder,
  'boarderId' |
  'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'address' | 'personalIdentityNumber' | 'birthDate' | 'birthPlace' |
  'registeredParentIds' | 'unregisteredParents' |
  'labels' | 'cardId' | 'groupId' | 'roomId' | 'livingStartDate' | 'livingEndDate' |
  'schoolDetails' | 'isSuspended' | 'accountStatus'
>;

export type GetSpecificBoarderResponse = { boarder: SpecificBoarder }

export const useGetSpecificBoarder = (
  boarderId?: string,
): UseQueryReturnType<SpecificBoarder> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetSpecificBoarderResponse>(
    GET_SPECIFIC_BOARDER,
    { variables: { boarderId } },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch boarder') });
  }

  return [data?.boarder, loading, error];
};
