import { CheckboxOptionType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { DormitoryRecipients, getGroupTutorValue } from 'src/shared/dormitoryRecipients';
import { DormitoryDetailsApi } from 'src/shared/types/api';
import { getFullName } from 'src/shared/utils';

type UseSendMessageRecipientsArgs = {
  dormitoryDetails: DormitoryDetailsApi | undefined;
}

export const useSendMessageRecipients = (
  { dormitoryDetails }: UseSendMessageRecipientsArgs,
): CheckboxOptionType[] | undefined => {
  const user = useUserContext();
  const { t } = useTranslation();

  if (!dormitoryDetails) {
    return [];
  }

  const groupTutorTranslation = t('Group tutor');

  const emailsFromContactViewCheckboxes: CheckboxOptionType[] = dormitoryDetails.emails.map(
    (email) => ({
      label: `${email.label} (${email.value})`,
      value: email.value,
    }),
  );

  const dormitoryRecipientCheckboxes: CheckboxOptionType[] = Object.values(DormitoryRecipients)
    .filter(((recipient) => user && recipient.allowedFor.includes(user.group)));

  const dormitoryGroupCheckboxes: CheckboxOptionType[] = dormitoryDetails.groups
    .filter(({ tutors }) => tutors.length).map(
      ({ groupId, tutors }) => ({
        label: `${groupTutorTranslation} ${groupId} (${tutors.map((tutor) => getFullName({ item: tutor })).join(', ')})`,
        value: getGroupTutorValue(groupId),
      }),
    );

  return [
    ...emailsFromContactViewCheckboxes,
    ...dormitoryGroupCheckboxes,
    ...dormitoryRecipientCheckboxes,
  ];
};
