import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { groupNameRule } from '../rules';

export const GroupNameFormItem = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Group name')}
      name="groupName"
      rules={[groupNameRule()]}
    >
      <Input />
    </Form.Item>
  );
};
