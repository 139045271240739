import { Form } from 'antd';
import { UpdateBoarderSubscriptionInput } from 'src/shared/types/api';
import { FormProps } from '.';
import { SubscriptionFormItem } from './items';

type UpdateBoarderSubscriptionFormProps = FormProps<Omit<UpdateBoarderSubscriptionInput, 'boarderId'>>

export const UpdateBoarderSubscriptionForm = ({ form }: UpdateBoarderSubscriptionFormProps) => (
  <Form
    layout="vertical"
    form={form}
  >
    <SubscriptionFormItem />
  </Form>
);
