import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteForm } from 'src/components';
import { GET_DORMITORY_NOTES_HISTORY } from 'src/gql/queries';
import { UpdateDormitoryNoteInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_DORMITORY_NOTE = gql`
  mutation UpdateDormitoryNote($input: UpdateDormitoryNoteInput!) {
    updateDormitoryNote(input: $input) {
      noteId
    }
  }
`;

type UseUpdateDormitoryNoteArgs = UseOnMutationArgs<NoteForm> & { noteId?: string };

export const useUpdateDormitoryNote = (
  { form, noteId, onSuccess }: UseUpdateDormitoryNoteArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateDormitoryNote, { loading }] = useMutation<
    unknown, { input: UpdateDormitoryNoteInput }
  >(UPDATE_DORMITORY_NOTE, { refetchQueries: [GET_DORMITORY_NOTES_HISTORY] });

  const onUpdateDormitoryNote = useCallback(async () => {
    if (!noteId) {
      return notification.error({ message: t('Note has not been updated successfully') });
    }

    const formFields = await form.validateFields();
    try {
      await updateDormitoryNote({
        variables: {
          input: {
            noteId,
            title: formFields.title,
            description: formFields.description,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Note has been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Note has not been updated successfully') });
    }
  }, [form, noteId, onSuccess]);

  return [onUpdateDormitoryNote, loading];
};
