import {
  Spin, Descriptions, Typography, Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetDormitoryFinanceStats } from 'src/gql/queries';
import { numberToPLN } from 'src/utils';

const { Text } = Typography;

export const DormitoryFinanceStats = () => {
  const { t } = useTranslation();

  const [financeStats, loading] = useGetDormitoryFinanceStats();

  return (
    <Spin spinning={loading}>

      <Divider />

      <Descriptions bordered column={1} title={t('Finance stats')}>
        <Descriptions.Item label={t('Boarders surplus')}>
          {numberToPLN(financeStats?.boardersSurplus || 0)}
        </Descriptions.Item>
        <Descriptions.Item label={t('Boarders debt')}>
          {numberToPLN(financeStats?.boardersDebt || 0)}
        </Descriptions.Item>
        <Descriptions.Item label={t('Boarders balance')}>
          <Text strong>{numberToPLN(financeStats?.boardersBalance || 0)}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
};
