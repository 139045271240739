import { Schema } from 'write-excel-file';
import { TFunction } from 'react-i18next';
import {
  GenerateExcelReportFormType,
  OtherSettingsLabels,
  BoarderGeneralInfoLabels,
  BoarderDataLabels,
  StudentDataLabels,
  ParentsDataLabels,
} from 'src/components';
import { getFullName, FullName, phoneNumberToDisplay } from 'src/shared/utils';
import { BoarderReportData } from './getExcelReportData';

const CELL_WIDTH_BASE = 4;

type GetExcelSchemaArgs = {
  config: GenerateExcelReportFormType;
  t: TFunction;
  maxParentCount: number
}

export const getExcelSchema = ({
  config, t, maxParentCount,
}: GetExcelSchemaArgs): Schema<BoarderReportData> => {
  const schema: Schema<BoarderReportData> = [];
  const lastNameFirst = config?.otherSettings?.includes(
    OtherSettingsLabels.LastNameFirst,
  );
  const fullNameSeparately = config?.otherSettings?.includes(
    OtherSettingsLabels.FullNameSeparately,
  );

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.FullName)) {
    if (fullNameSeparately) {
      const lastNameSchemaItem = {
        column: t('Last name'),
        type: String,
        value: (boarder: BoarderReportData) => boarder.lastName,
        width: CELL_WIDTH_BASE * 3,
      };

      const firstNameSchemaItem = {
        column: t('First name'),
        type: String,
        value: (boarder: BoarderReportData) => boarder.firstName,
        width: CELL_WIDTH_BASE * 3,
      };

      if (lastNameFirst) {
        schema.push(lastNameSchemaItem, firstNameSchemaItem);
      } else {
        schema.push(firstNameSchemaItem, lastNameSchemaItem);
      }
    } else {
      schema.push({
        column: lastNameFirst ? t('Full name') : t('Full name reverted'),
        type: String,
        value: (boarder: BoarderReportData) => getFullName({
          item: boarder,
          lastNameFirst,
        }),
        width: CELL_WIDTH_BASE * 6,
      });
    }
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.PersonalIdentityNumber)) {
    schema.push({
      column: t('PESEL'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.personalIdentityNumber,
      width: CELL_WIDTH_BASE * 3,
    });
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.PhoneNumber)) {
    schema.push({
      column: t('Phone number'),
      type: String,
      value: (
        boarder: BoarderReportData,
      ) => phoneNumberToDisplay(boarder.phoneNumber),
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.Email)) {
    schema.push({
      column: t('Email'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.email,
      width: CELL_WIDTH_BASE * 6,
    });
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.BirthDate)) {
    schema.push({
      column: t('Birth date'),
      type: Date,
      format: 'dd.mm.yyyy',
      value: (boarder: BoarderReportData) => boarder.birthDate && new Date(boarder.birthDate),
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.BirthPlace)) {
    schema.push({
      column: t('Birth place'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.birthPlace,
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderGeneralInfo?.includes(BoarderGeneralInfoLabels.Address)) {
    if (config.otherSettings?.includes(OtherSettingsLabels.AddressAsOneCell)) {
      schema.push({
        column: t('Address'),
        type: String,
        value: (boarder: BoarderReportData) => `${boarder.address.streetAddress || ''} ${boarder.address.postCode || ''} ${boarder.address.city || ''}`,
        width: CELL_WIDTH_BASE * 8,
      });
    } else {
      schema.push({
        column: t('Street address'),
        type: String,
        value: (boarder: BoarderReportData) => boarder.address.streetAddress,
        width: CELL_WIDTH_BASE * 4,
      }, {
        column: t('Post code'),
        type: String,
        value: (boarder: BoarderReportData) => boarder.address.postCode,
        width: CELL_WIDTH_BASE * 2,
      }, {
        column: t('City'),
        type: String,
        value: (boarder: BoarderReportData) => boarder.address.city,
        width: CELL_WIDTH_BASE * 4,
      });
    }
  }

  if (config.boarderData?.includes(BoarderDataLabels.Labels)) {
    schema.push({
      column: t('Labels'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.labels.join(', '),
      width: CELL_WIDTH_BASE * 3,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.CardId)) {
    schema.push({
      column: t('Card ID'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.cardId,
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.GroupId)) {
    schema.push({
      column: t('Group ID'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.groupId,
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.RoomId)) {
    schema.push({
      column: t('Room ID'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.roomId,
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.LivingStartData)) {
    schema.push({
      column: t('Living start date'),
      type: Date,
      format: 'dd.mm.yyyy',
      value: (
        boarder: BoarderReportData,
      ) => boarder.livingStartDate && new Date(boarder.livingStartDate),
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.LivingEndData)) {
    schema.push({
      column: t('Living end date'),
      type: Date,
      format: 'dd.mm.yyyy',
      value: (
        boarder: BoarderReportData,
      ) => boarder.livingEndDate && new Date(boarder.livingEndDate),
      width: CELL_WIDTH_BASE * 4,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.Presence)) {
    schema.push({
      column: t('Presence'),
      type: String,
      value: (boarder: BoarderReportData) => (boarder.isPresent ? t('Yes') : t('No')),
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.Suspension)) {
    schema.push({
      column: t('Suspension'),
      type: String,
      value: (boarder: BoarderReportData) => (boarder.isSuspended ? t('Yes') : t('No')),
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.Balance)) {
    schema.push({
      column: t('Balance'),
      type: Number,
      format: '0.00',
      value: (boarder: BoarderReportData) => boarder.balance,
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (config.boarderData?.includes(BoarderDataLabels.Subscription)) {
    schema.push({
      column: t('Subscription'),
      type: String,
      value: (boarder: BoarderReportData) => t(boarder.subscription),
      width: CELL_WIDTH_BASE * 8,
    });
  }

  if (config.studentData?.includes(StudentDataLabels.SchoolName)) {
    schema.push({
      column: t('School name'),
      type: String,
      value: (boarder: BoarderReportData) => boarder.schoolDetails.schoolName,
      width: CELL_WIDTH_BASE * 8,
    });
  }

  if (config.studentData?.includes(StudentDataLabels.SchoolKind)) {
    schema.push({
      column: t('School kind'),
      type: String,
      value: (
        boarder: BoarderReportData,
      ) => boarder.schoolDetails.schoolKind && t(boarder.schoolDetails.schoolKind),
      width: CELL_WIDTH_BASE * 3,
    });
  }

  if (config.studentData?.includes(StudentDataLabels.ClassNumber)) {
    schema.push({
      column: t('Class number'),
      type: Number,
      value: (
        boarder: BoarderReportData,
      ) => boarder.schoolDetails.classNumber,
      width: CELL_WIDTH_BASE * 2,
    });
  }

  if (maxParentCount && config.parentsData?.length) {
    for (let i = 0; i < maxParentCount; i += 1) {
      if (config.parentsData.includes(ParentsDataLabels.FullName)) {
        if (fullNameSeparately) {
          const lastNameSchemaItem = {
            column: `${t('Parent')} ${i + 1} : ${t('Last name')}`,
            type: String,
            value: (boarder: BoarderReportData) => boarder.parents[i]?.lastName,
            width: CELL_WIDTH_BASE * 3,
          };

          const firstNameSchemaItem = {
            column: `${t('Parent')} ${i + 1} : ${t('First name')}`,
            type: String,
            value: (boarder: BoarderReportData) => boarder.parents[i]?.firstName,
            width: CELL_WIDTH_BASE * 3,
          };

          if (lastNameFirst) {
            schema.push(lastNameSchemaItem, firstNameSchemaItem);
          } else {
            schema.push(firstNameSchemaItem, lastNameSchemaItem);
          }
        } else {
          schema.push({
            column: lastNameFirst ? `${t('Parent')} ${i + 1} : ${t('Full name')}` : `${t('Parent')} ${i + 1} : ${t('Full name reverted')}`,
            type: String,
            value: (boarder: BoarderReportData) => (
              boarder.parents[i]?.firstName && boarder.parents[i]?.lastName ? getFullName({
                item: boarder.parents[i] as FullName, // checked one line above
                lastNameFirst,
              }) : undefined),
            width: CELL_WIDTH_BASE * 6,
          });
        }
      }

      if (config.parentsData?.includes(ParentsDataLabels.PhoneNumber)) {
        schema.push({
          column: `${t('Parent')} ${i + 1} : ${t('Phone number')}`,
          type: String,
          value: (
            boarder: BoarderReportData,
          ) => boarder.parents[i]?.phoneNumber && phoneNumberToDisplay(
            boarder.parents[i]?.phoneNumber as string, // checked one line above
          ),
          width: CELL_WIDTH_BASE * 4,
        });
      }

      if (config.parentsData?.includes(ParentsDataLabels.Email)) {
        schema.push({
          column: `${t('Parent')} ${i + 1} : ${t('Email')}`,
          type: String,
          value: (boarder: BoarderReportData) => boarder.parents[i]?.email,
          width: CELL_WIDTH_BASE * 6,
        });
      }
    }
  }

  return schema;
};
