import { Menu } from 'antd';
import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getRouteTranslated, ROUTE, STYLE } from 'src/constants';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';
import { uuidToBase64Url } from 'src/utils';

// todo: use styled-components
const LOGOUT_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  lineHeight: 'normal',
  height: '100%',
  justifyContent: 'center',
  gap: STYLE.BASE_MARGIN / 4,
};

type NavigationProps = {
  isMobile: boolean;
  signOut: () => void;
  setIsResponsiveMenuVisible: Dispatch<SetStateAction<boolean>>
}

export const Navigation = ({
  isMobile,
  signOut,
  setIsResponsiveMenuVisible,
}: NavigationProps) => {
  const user = useUserContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (!user) {
    return null;
  }

  const pathRegex = /\/[a-z]+/g;
  const selectedMenu = pathname.match(pathRegex)?.[0];

  return (
    <Menu
      theme="light"
      mode={isMobile ? 'vertical' : 'horizontal'}
      selectedKeys={selectedMenu ? [selectedMenu] : undefined}
      style={!isMobile ? { width: 1100 } : undefined}
    >
      {user.isAllowed(GqlQuery.GetReports) && (
      /**
       * extracting the following code to a separate component is impossible
       * because if a different React.FC is used, all menu items are always selected
       */
        <Menu.Item
          key={getRouteTranslated(ROUTE.DORMITORY)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.DORMITORY)}>{t('Dormitory')}</Link>
        </Menu.Item>
      )}
      {user.isAllowed(GqlQuery.GetBoarders) ? (
        <Menu.Item
          key={getRouteTranslated(ROUTE.BOARDERS)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.BOARDERS)}>{t('Boarders')}</Link>
        </Menu.Item>
      ) : (
        <Menu.Item
          key={getRouteTranslated(ROUTE.BOARDER)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={`${getRouteTranslated(ROUTE.BOARDERS)}/${uuidToBase64Url(user.boarderId || '')}`}>{t('My data')}</Link>
        </Menu.Item>
      ) }
      {user.isAllowed(GqlQuery.GetParents) && (
        <Menu.Item
          key={getRouteTranslated(ROUTE.PARENTS)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.PARENTS)}>{t('Parents')}</Link>
        </Menu.Item>
      )}
      { user.isAllowed(GqlQuery.GetTutors) && (
        <Menu.Item
          key={getRouteTranslated(ROUTE.TUTORS)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.TUTORS)}>{t('Tutors')}</Link>
        </Menu.Item>
      )}
      {user.isAllowed(GqlQuery.GetAdministrators) && (
        <Menu.Item
          key={getRouteTranslated(ROUTE.ADMINISTRATORS)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.ADMINISTRATORS)}>{t('Administrators')}</Link>
        </Menu.Item>
      )}
      {user.isAllowed(GqlQuery.GetBoardersArchive) && (
        <Menu.Item
          key={getRouteTranslated(ROUTE.BOARDERS_ARCHIVE)}
          onClick={() => setIsResponsiveMenuVisible(false)}
        >
          <Link to={getRouteTranslated(ROUTE.BOARDERS_ARCHIVE)}>{t('Boarders archive')}</Link>
        </Menu.Item>
      )}
      <Menu.Item
        key={getRouteTranslated(ROUTE.CONTACT)}
        onClick={() => setIsResponsiveMenuVisible(false)}
      >
        <Link to={getRouteTranslated(ROUTE.CONTACT)}>{t('Contact')}</Link>
      </Menu.Item>
      <Menu.Item
        key="logout"
        onClick={() => signOut()}
      >
        <div style={LOGOUT_STYLE}>
          <span>{t('Log out')}</span>
          <span>{`(${user.fullName})`}</span>
        </div>
      </Menu.Item>
    </Menu>
  );
};
