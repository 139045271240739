/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validPhoneNumber } from 'src/shared/validatorUtils';
import { RuleArgs } from '.';

export function phoneNumberToDto(phoneNumberFormValue: string): string;
export function phoneNumberToDto(phoneNumberFormValue: undefined): null;
export function phoneNumberToDto(phoneNumberFormValue: null): null;
export function phoneNumberToDto(phoneNumberFormValue: string | undefined): string | null;
export function phoneNumberToDto(phoneNumberFormValue: string | undefined | null): string | null {
  if (!phoneNumberFormValue) {
    return null;
  }

  const phoneNumber = phoneNumberFormValue.replace(/[-\s]/g, '');
  if (phoneNumber.charAt(0) === '+') {
    return phoneNumber;
  }

  return `+48${phoneNumber}`;
}

export const phoneNumberRule = ({ required }: RuleArgs): Rule => {
  const { t } = useTranslation();

  return ({
    required,
    message: t('Please enter correct phone number'),
    transform: phoneNumberToDto,
    validator: (_, value: string) => (
      (!required && !value) || validPhoneNumber(value)
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
