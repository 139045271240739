import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateRecruitmentQuestionnaireInput } from 'src/shared/types/api';
import { BoarderRecruitmentDoc } from 'src/shared/types/docs';
import { UseOnMutation } from '..';

export const GENERATE_RECRUITMENT_QUESTIONNAIRE = gql`
  mutation GenerateRecruitmentQuestionnaire($input: GenerateRecruitmentQuestionnaireInput!) {
    generateRecruitmentQuestionnaire(input: $input)
  }
`;

type useGenerateRecruitmentQuestionnaireArgs = Pick<BoarderRecruitmentDoc, 'boarderRecruitmentId'>

export const useGenerateRecruitmentQuestionnaire = (
  { boarderRecruitmentId }: useGenerateRecruitmentQuestionnaireArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [generateQuestionnaire, { loading }] = useMutation<
    { generateRecruitmentQuestionnaire: string }, { input: GenerateRecruitmentQuestionnaireInput }
  >(GENERATE_RECRUITMENT_QUESTIONNAIRE);

  const onGenerateRecruitmentQuestionnaire = useCallback(async () => {
    try {
      const { data } = await generateQuestionnaire({
        variables: {
          input: {
            boarderRecruitmentId,
          },
        },
      });

      if (data) {
        window.open(data.generateRecruitmentQuestionnaire);
      } else {
        notification.error({ message: t('Recruitment questionnaire has not been generated successfully') });
      }
    } catch (err) {
      notification.error({ message: t('Recruitment questionnaire has not been generated successfully') });
    }
  }, [boarderRecruitmentId]);

  return [onGenerateRecruitmentQuestionnaire, loading];
};
