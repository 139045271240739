import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const accountStatusColumn = <T extends { accountStatus: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Account status'),
    key: 'accountStatus',
    render: (_, { accountStatus }) => t(accountStatus),
  });
