import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_SPECIFIC_BOARDER_PAYMENTS } from 'src/gql/queries';
import { UpdateBoarderSubscriptionInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_BOARDER_SUBSCRIPTION = gql`
  mutation UpdateBoarderSubscription($input: UpdateBoarderSubscriptionInput!) {
    updateBoarderSubscription(input: $input) {
      boarderId
    }
  }
`;

type UseUpdateBoarderSubscriptionArgs =
  UseOnMutationArgs<Omit<UpdateBoarderSubscriptionInput, 'boarderId'>> & {boarderId?: string}

export const useUpdateBoarderSubscription = (
  { form, boarderId, onSuccess }: UseUpdateBoarderSubscriptionArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateBoarderSubscription, { loading }] = useMutation<
    unknown, { input: UpdateBoarderSubscriptionInput }
  >(UPDATE_BOARDER_SUBSCRIPTION, { refetchQueries: [GET_SPECIFIC_BOARDER_PAYMENTS] });

  const onUpdateBoarderSubscription = useCallback(async () => {
    if (!boarderId) {
      return notification.error({ message: t('Boarder subscription has not been updated successfully') });
    }

    const { subscription } = await form.validateFields();
    try {
      await updateBoarderSubscription({
        variables: {
          input: {
            boarderId,
            subscription,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Boarder subscription has been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder subscription has not been updated successfully') });
    }
  }, [form, boarderId, onSuccess]);

  return [onUpdateBoarderSubscription, loading];
};
