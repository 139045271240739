import { BoardersFilter } from 'src/context';
import { itemContainsFullName } from 'src/shared/utils';
import { BoarderAsViewed } from 'src/views/BoardersView';

type FilterBoardersArgs = {
  allBoarders?: BoarderAsViewed[]
} & BoardersFilter

export const filterBoarders = ({
  allBoarders,
  name,
  groupIds,
  labels,
  minBalance,
  maxBalance,
  onlyAwaitingBoarders,
}: FilterBoardersArgs) => (
  allBoarders
    ? allBoarders.filter(
      (boarder) => {
        const matchesFullName = name
          ? itemContainsFullName({ item: boarder, searchText: name })
          : true;
        const matchesGroupIds = groupIds?.length
          ? groupIds.some((groupId) => groupId === boarder.groupId)
          : true;
        const matchesLabels = labels?.length
          ? labels.some((label) => boarder.labels.some(((boarderLabel) => boarderLabel === label)))
          : true;
        const matchesMinBalance = typeof minBalance === 'number'
          ? boarder.balance > minBalance
          : true;
        const matchesMaxBalance = typeof maxBalance === 'number'
          ? boarder.balance < maxBalance
          : true;
        const matchesOnlyAwaitingBoarders = onlyAwaitingBoarders
          ? boarder.awaitingRequests
          : true;

        return matchesFullName
         && matchesGroupIds
         && matchesLabels
         && matchesMinBalance
         && matchesMaxBalance
         && matchesOnlyAwaitingBoarders;
      },
    )
    : []
);
