import { getRouteTranslated, ROUTE } from 'src/constants';
import { UserInContext } from 'src/context';
import { UserGroup } from 'src/shared/types/common';
import { uuidToBase64Url } from 'src/utils';

export const defaultRoute = (user?: UserInContext | null): string => {
  if (!user) {
    return '/';
  }

  switch (user.group) {
    case UserGroup.Administrators:
    case UserGroup.Parents:
    case UserGroup.Tutors:
      return getRouteTranslated(ROUTE.BOARDERS);
    case UserGroup.Boarders:
      return `${getRouteTranslated(ROUTE.BOARDERS)}/${uuidToBase64Url(user.boarderId || '')}`;
    default:
      throw new Error('Implementation error');
  }
};
