import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';

type PreviousStepProps = {
  onClick: () => void;
}

export const PreviousStep = ({ onClick }: PreviousStepProps) => {
  const { t } = useTranslation();

  return (
    <Typography.Link onClick={onClick}>
      <ArrowLeftOutlined style={{ marginRight: STYLE.BASE_MARGIN }} />
      {t('Previous step')}
    </Typography.Link>
  );
};
