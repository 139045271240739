import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { getFullName } from 'src/shared/utils';
import { BoarderLink } from './BoarderLink';

export type BoarderDormitoryInfo = Pick<Boarder, 'boarderId' | 'firstName' | 'lastName' | 'groupId' | 'roomId'>

type BoarderDormitoryInfoLinkProps = {
  boarder: BoarderDormitoryInfo;
  lastNameFirst?: boolean;
}

export const BoarderDormitoryInfoLink = ({
  boarder,
  lastNameFirst,
}: BoarderDormitoryInfoLinkProps) => {
  const { t } = useTranslation();
  const fullName = getFullName({ item: boarder, lastNameFirst });
  const groupId = boarder.groupId ? ` · ${t('Group ID abbreviation')} ${boarder.groupId}` : '';
  const roomId = boarder.roomId ? ` · ${t('Room ID abbreviation')} ${boarder.roomId}` : '';

  const text = `${fullName}${groupId}${roomId}`;

  return (
    <BoarderLink boarderId={boarder.boarderId}>
      {text}
    </BoarderLink>
  );
};
