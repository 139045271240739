import { Dayjs } from 'dayjs';
import { UserInContext } from 'src/context';
import { Subscription, MealType, UserGroup } from 'src/shared/types/common';
import { isWeekend } from 'src/shared/utils';
import { isBoarderDayUpdateForbiddenByDate } from './isBoarderDayUpdateForbiddenByDate';

type MealUpdateForbiddenArgs = {
  user: UserInContext | null;
  boarderSubscription?: Subscription;
  meal: MealType;
  date: Dayjs;
}

export const mealUpdateForbidden = ({
  user, boarderSubscription, meal, date,
}: MealUpdateForbiddenArgs) => {
  if (isWeekend(date.toDate())) {
    return true;
  }

  if ([
    UserGroup.Administrators,
    UserGroup.Tutors,
  ].includes(user?.group as UserGroup)) {
    return false;
  }

  if (
    meal === MealType.LUNCH && (
      (user?.group === UserGroup.Boarders && boarderSubscription === Subscription.ACADEMIC)
      || user?.group === UserGroup.Parents
    )
  ) {
    return isBoarderDayUpdateForbiddenByDate(date);
  }

  return true;
};
