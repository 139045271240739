import { CanteenManagerEmails, DormitoryWeeklyOrderedMeals } from 'src/components';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';

export const DormitoryMealsTab = () => {
  const user = useUserContext();

  return (
    <div className="dormitory-tab-content-wrapper">
      {user?.isAllowed(GqlQuery.GetWeeklyOrderedMeals)
        && <DormitoryWeeklyOrderedMeals />}
      {user?.isAllowed(GqlQuery.GetCanteenManagerEmails)
        && <CanteenManagerEmails />}
    </div>
  );
};
