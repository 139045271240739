import { Divider, Alert, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { ChargeMonthlyFeeModal } from './modals';

export const DormitoryChargeMonthlyFees = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Divider />

      <Alert
        style={{ marginTop: STYLE.BASE_MARGIN * 2.5 }}
        message={t('Tip')}
        description={t('Dormitory fees tip')}
        type="info"
        showIcon
      />

      <Button
        type="default"
        size="large"
        onClick={() => setIsModalVisible(true)}
        className="vertical-base-margin"
        block
      >
        {t('Charge monthly fee')}
      </Button>

      {isModalVisible && (
        <ChargeMonthlyFeeModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};
