import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';

export const dateRangeColumn = <
  T extends { startsAt: string, endsAt: string }
>(t: TFunction): ColumnType<T> => ({
    title: t('Date range'),
    render: (_, item) => {
      const start = new Date(item.startsAt).toLocaleDateString(CONFIG.LOCALE);
      const end = new Date(item.endsAt).toLocaleDateString(CONFIG.LOCALE);

      return `${start} - ${end}`;
    },
  });
