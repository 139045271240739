import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorForm } from 'src/components';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { GET_ALL_TUTORS } from 'src/gql/queries';
import { UpdateTutorInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_TUTOR = gql`
  mutation UpdateTutor($input: UpdateTutorInput!) {
    updateTutor(input: $input) {
      tutorId
    }
  }
`;

type UseUpdateTutorArgs = UseOnMutationArgs<TutorForm> & {
  tutorId?: string
}

export const useUpdateTutor = (
  { form, tutorId, onSuccess }: UseUpdateTutorArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateTutor, { loading }] = useMutation<
    unknown, { input: UpdateTutorInput }
  >(UPDATE_TUTOR, { refetchQueries: [GET_ALL_TUTORS] });

  const onUpdateTutor = useCallback(async () => {
    if (!tutorId) {
      return notification.error({ message: t('Tutor has not been updated successfully') });
    }

    const formValues = await form.validateFields();
    const tutorDto = {
      ...formValues,
      phoneNumber: phoneNumberToDto(formValues.phoneNumber) as string,
    };
    try {
      await updateTutor({
        variables: {
          input: {
            tutorId,
            tutor: tutorDto,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Tutor has been updated successfully') });
    } catch (error) {
      if ((error as GraphQLError)?.message === 'UserExists') {
        return notification.error({ message: t('Tutor has not been updated successfully. User exists') });
      }

      return notification.error({ message: t('Tutor has not been updated successfully') });
    }
  }, [form, tutorId, onSuccess]);

  return [onUpdateTutor, loading];
};
