import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { groupIdRule } from '../rules';
import { FormItemCommonProps } from '.';

export const GroupIdInputFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Group ID')}
      name="groupId"
      rules={[groupIdRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
