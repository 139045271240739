import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validBirthPlace } from 'src/shared/validatorUtils';

export const birthPlaceRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct birth place'),
    validator: (_, value: string) => (
      !value || validBirthPlace(value)
        ? Promise.resolve()
        : Promise.reject()),
  });
};
