import {
  Address,
  DormitoryChargedMonths,
  DormitoryFees,
  DormitoryYear,
  LabeledValue,
  Note,
  RoomRental,
  Uuid,
} from '../common';

export const enum DormitoryPropertyKey {
  DormitoryDetails = 'dormitoryDetails',
  SchoolYear = 'schoolYear',
  Fees = 'fees',
  Year = 'year',
  ChargedMonths = 'chargedMonths',
  CanteenManagerEmails = 'canteenManagerEmails',
  DormitoryNotes = 'dormitoryNotes',
  RoomRentals = 'roomRentals',
}

export type DormitorySchoolYearDoc = {
  key: DormitoryPropertyKey.SchoolYear;
  value: number;
}

export type DormitoryDetailsDocValue = {
  name: string;
  accountNumber: string;
  address: Address;
  emails: LabeledValue[];
  phoneNumbers: LabeledValue[];
  groupIds: string[]
}

export type DormitoryDetailsDoc = {
  key: DormitoryPropertyKey.DormitoryDetails;
  value: DormitoryDetailsDocValue;
}

export type DormitoryFeesDoc = {
  key: DormitoryPropertyKey.Fees;
  value: DormitoryFees;
}

export type DormitoryYearDoc = {
  key: DormitoryPropertyKey.Year;
  value: DormitoryYear;
}

export type DormitoryChargedMonthsDoc = {
  key: DormitoryPropertyKey.ChargedMonths;
  value: DormitoryChargedMonths;
}

export type DormitoryCanteenManagerEmailsDoc = {
  key: DormitoryPropertyKey.CanteenManagerEmails;
  value: string[];
}

export type DormitoryNotesDoc = {
  key: DormitoryPropertyKey.DormitoryNotes;
  value: Record<Uuid, Note>;
}

export type DormitoryRoomRentalsDoc = {
  key: DormitoryPropertyKey.RoomRentals;
  value: Record<Uuid, RoomRental>;
}
