import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const noteCategoryColumn = <T extends { category: string; }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Note category'),
    key: 'noteCategory',
    render: (_, item) => t(item.category),
  });
