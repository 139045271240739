import { useTranslation } from 'react-i18next';
import { Space, Spin } from 'antd';
import { Boarder } from 'src/shared/types/api';
import { BoarderActivitiesTable, BoarderPresenceStateSwitch } from 'src/components';
import { useUserContext } from 'src/context';
import { GqlMutation } from 'src/shared/types/common';
import { GET_SPECIFIC_BOARDER_ACTIVITIES, useGetSpecificBoarderActivities } from 'src/gql/queries';

type BoarderActivitiesTabProps = {
  boarderId?: Boarder['boarderId'];
  archive?: true;
}

export const BoarderActivitiesTab = ({ boarderId, archive }: BoarderActivitiesTabProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [boarder, loading] = useGetSpecificBoarderActivities({ boarderId, archive });

  return (
    <Spin spinning={loading}>
      {user?.isAllowed(GqlMutation.ToggleBoarderPresenceState) && !archive && (
        <div className="sub-actions-wrapper">
          <Space>
            {`${t('Presence')}:`}
            <BoarderPresenceStateSwitch
              boarderId={boarderId}
              currentState={boarder?.isPresent ?? false}
              refetchQuery={GET_SPECIFIC_BOARDER_ACTIVITIES}
            />
          </Space>
        </div>
      )}
      <BoarderActivitiesTable activities={boarder?.activities} />
    </Spin>
  );
};
