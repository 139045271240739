import { gql } from '@apollo/client';
import { Boarder } from 'src/shared/types/api';
import { ALL_MONTHS_FRAGMENT } from '../../fragments';

export const GET_SPECIFIC_BOARDER_CALENDAR = gql`
  ${ALL_MONTHS_FRAGMENT}

  query GetSpecificBoarderCalendar($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      boarderId
      subscription
      calendar {
        ...AllMonthsFragment
      }
    }
  }
`;

export type GetSpecificBoarderCalendarResponse = Pick<Boarder, 'boarderId' | 'calendar' | 'subscription'>;
