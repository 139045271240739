import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import plDayjs from 'dayjs/locale/pl';
import {
  Alert,
  Button,
  notification,
  Spin,
} from 'antd';
import { ApolloError, useQuery } from '@apollo/client';

import {
  AsyncButton,
  Calendar,
  CalendarBoarderDay,
  CalendarMonthSwitcher,
  TooSmallScreenWarning,
} from 'src/components';
import { GetSpecificBoarderCalendarResponse, GET_SPECIFIC_BOARDER_CALENDAR } from 'src/gql/queries';
import {
  Boarder,
} from 'src/shared/types/api';
import { BoarderYear } from 'src/shared/types/common';
import { useCanUpdateCalendar, useIsMobile } from 'src/hooks';
import { STYLE } from 'src/constants';
import { parseBoarderYear } from './utils';
import { useOnBoarderCalendarSubmit } from './useOnBoarderCalendarSubmit';

type BoarderCalendarTabProps = { boarderId: Boarder['boarderId'] | undefined }

export const BoarderCalendarTab = ({ boarderId }: BoarderCalendarTabProps) => {
  const { t } = useTranslation();
  const [boarderYear, setBoarderYear] = useState<BoarderYear>();
  const [firstDayOfUpdatedMonth, setFirstDayOfUpdatedMonth] = useState(dayjs().startOf('month'));
  const [getBoarderYearLoading, setGetBoarderYearLoading] = useState<boolean>();
  const [getBoarderYearError, setGetBoarderYearError] = useState<ApolloError>();
  const [
    getBoarderYearData,
    setGetBoarderYearData,
  ] = useState<{ boarder: GetSpecificBoarderCalendarResponse }>();
  const isMobile = useIsMobile();

  const {
    loading: internalGetBoarderYearLoading,
    error: internalGetBoarderYearError,
    data: internalGetBoarderYearData,
    refetch,
  } = useQuery<{ boarder: GetSpecificBoarderCalendarResponse }>(
    GET_SPECIFIC_BOARDER_CALENDAR,
    { variables: { boarderId } },
  );

  const canUserUpdateCalendar = useCanUpdateCalendar({
    boarderSubscription: internalGetBoarderYearData?.boarder.subscription,
    firstDayOfUpdatedMonth,
  });

  useEffect(() => {
    setGetBoarderYearError(internalGetBoarderYearError);
  }, [internalGetBoarderYearError]);
  useEffect(() => {
    setGetBoarderYearData(internalGetBoarderYearData);
  }, [internalGetBoarderYearData]);
  useEffect(() => {
    setGetBoarderYearLoading(internalGetBoarderYearLoading);
  }, [internalGetBoarderYearLoading]);

  if (getBoarderYearError) {
    notification.error({ message: t('Unable to fetch boarder calendar') });
    setBoarderYear(undefined);
  }

  useEffect(() => {
    if (getBoarderYearData?.boarder.calendar) {
      setBoarderYear(parseBoarderYear(getBoarderYearData.boarder.calendar));
    }
  }, [getBoarderYearData]);

  const CalendarBoarderCellRender = useCallback(CalendarBoarderDay({
    boarderYear,
    setBoarderYear,
    boarderSubscription: getBoarderYearData?.boarder.subscription,
  }), [boarderYear]);

  const onSubmit = useOnBoarderCalendarSubmit({
    boarderId,
    boarderYear,
    firstDayOfUpdatedMonth,
  });

  if (isMobile) {
    return <TooSmallScreenWarning />;
  }

  return (
    <Spin spinning={getBoarderYearLoading}>
      <Calendar
        // eslint-disable-next-line react/no-unstable-nested-components
        headerRender={({
          value, onChange,
        }) => (
          <CalendarMonthSwitcher
            onChange={onChange}
            value={value}
            setFirstDayOfUpdatedMonth={setFirstDayOfUpdatedMonth}
          />
        )}
        dateCellRender={CalendarBoarderCellRender}
        className="body-align-top"
      />
      <div className="boarder-tab-content-wrapper">
        <Alert
          message={t('Tip')}
          description={t('Boarder calendar tip')}
          type="info"
          showIcon
          style={{ marginBottom: STYLE.BASE_MARGIN }}
        />
        <div style={{ display: 'flex' }}>
          <Button
            type="default"
            size="large"
            loading={getBoarderYearLoading}
            onClick={async () => {
              setGetBoarderYearLoading(true);
              const { data, error } = await refetch();
              setGetBoarderYearData(data);
              setGetBoarderYearError(error);
              setGetBoarderYearLoading(false);
              if (!error) {
                notification.success({ message: t('Boarder calendar has been fetched') });
              }
            }}
            style={{ width: '100%', marginRight: STYLE.BASE_MARGIN }}
          >
            {t('Cancel')}
          </Button>
          <AsyncButton
            type="primary"
            size="large"
            htmlType="submit"
            onClick={onSubmit}
            disabled={!canUserUpdateCalendar}
            style={{ width: '100%' }}
          >
            {t('Save month', {
              month: firstDayOfUpdatedMonth.locale(plDayjs).format('MMMM'),
            })}
          </AsyncButton>
        </div>
      </div>
    </Spin>

  );
};
