import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';

export const decisionInfoColumn = <T extends { decidedAt?: string; decidedBy?: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Decided'),
    key: 'decided',
    render: (_, item) => item.decidedAt && `${new Date(item.decidedAt).toLocaleString(CONFIG.LOCALE)} (${item.decidedBy})`,
  });
