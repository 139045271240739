import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';
import { Activity } from 'src/shared/types/common';

export const activityDateColumn = (t: TFunction): ColumnType<Activity> => ({
  title: t('Activity date'),
  key: 'date',
  render: (_, { date }) => (
    date && new Date(date).toLocaleString(CONFIG.LOCALE)
  ),
});
