import {
  BoarderDay, Month, BoarderYear, DormitoryDay, DormitoryYear,
} from '../types/common';
import { range } from './misc';
import { thirtyOneDayMonths } from './time';

const emptyBoarderDay = (): BoarderDay => ({
  isAbsenceReported: false,
  isWeekendStayReported: false,
  breakfast: { isPaid: false },
  lunch: { isPaid: false },
  dinner: { isPaid: false },
});

export const emptyBoarderMonth = (args: {
  monthNumber: number,
  isLeapYear: boolean
}): Month<BoarderDay> => ({
  1: emptyBoarderDay(),
  2: emptyBoarderDay(),
  3: emptyBoarderDay(),
  4: emptyBoarderDay(),
  5: emptyBoarderDay(),
  6: emptyBoarderDay(),
  7: emptyBoarderDay(),
  8: emptyBoarderDay(),
  9: emptyBoarderDay(),
  10: emptyBoarderDay(),
  11: emptyBoarderDay(),
  12: emptyBoarderDay(),
  13: emptyBoarderDay(),
  14: emptyBoarderDay(),
  15: emptyBoarderDay(),
  16: emptyBoarderDay(),
  17: emptyBoarderDay(),
  18: emptyBoarderDay(),
  19: emptyBoarderDay(),
  20: emptyBoarderDay(),
  21: emptyBoarderDay(),
  22: emptyBoarderDay(),
  23: emptyBoarderDay(),
  24: emptyBoarderDay(),
  25: emptyBoarderDay(),
  26: emptyBoarderDay(),
  27: emptyBoarderDay(),
  28: emptyBoarderDay(),
  29: args.monthNumber !== 1 || args.isLeapYear ? emptyBoarderDay() : undefined,
  30: args.monthNumber !== 1 ? emptyBoarderDay() : undefined,
  31: thirtyOneDayMonths.some(
    (thirtyOneDayMonth) => thirtyOneDayMonth === args.monthNumber,
  ) ? emptyBoarderDay()
    : undefined,
});

export const emptyBoarderYear = (args: {
  isLeapYear: boolean,
}): BoarderYear => (range(0, 12)).reduce((prevIteration, currentMonthNumber) => ({
  ...prevIteration,
  [currentMonthNumber]: emptyBoarderMonth({
    monthNumber: currentMonthNumber,
    isLeapYear: args.isLeapYear,
  }),
}), {} as BoarderYear);

const emptyDormitoryDay = (): DormitoryDay => ({
  breakfast: false,
  dinner: false,
  holiday: false,
  lunch: false,
});

export const emptyDormitoryMonth = (args: {
  monthNumber: number,
  isLeapYear: boolean
}): Month<DormitoryDay> => ({
  1: emptyDormitoryDay(),
  2: emptyDormitoryDay(),
  3: emptyDormitoryDay(),
  4: emptyDormitoryDay(),
  5: emptyDormitoryDay(),
  6: emptyDormitoryDay(),
  7: emptyDormitoryDay(),
  8: emptyDormitoryDay(),
  9: emptyDormitoryDay(),
  10: emptyDormitoryDay(),
  11: emptyDormitoryDay(),
  12: emptyDormitoryDay(),
  13: emptyDormitoryDay(),
  14: emptyDormitoryDay(),
  15: emptyDormitoryDay(),
  16: emptyDormitoryDay(),
  17: emptyDormitoryDay(),
  18: emptyDormitoryDay(),
  19: emptyDormitoryDay(),
  20: emptyDormitoryDay(),
  21: emptyDormitoryDay(),
  22: emptyDormitoryDay(),
  23: emptyDormitoryDay(),
  24: emptyDormitoryDay(),
  25: emptyDormitoryDay(),
  26: emptyDormitoryDay(),
  27: emptyDormitoryDay(),
  28: emptyDormitoryDay(),
  29: args.monthNumber !== 1 || args.isLeapYear ? emptyDormitoryDay() : undefined,
  30: args.monthNumber !== 1 ? emptyDormitoryDay() : undefined,
  31: thirtyOneDayMonths.some(
    (thirtyOneDayMonth) => thirtyOneDayMonth === args.monthNumber,
  ) ? emptyDormitoryDay()
    : undefined,
});

export const emptyDormitoryYear = (args: {
  isLeapYear: boolean,
}): DormitoryYear => (range(0, 12)).reduce((prevIteration, currentMonthNumber) => ({
  ...prevIteration,
  [currentMonthNumber]: emptyDormitoryMonth({
    monthNumber: currentMonthNumber,
    isLeapYear: args.isLeapYear,
  }),
}), {} as DormitoryYear);
