import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { Subscription } from 'src/shared/types/common';
import { subscriptionRule } from '../rules';

export const SubscriptionFormItem = () => {
  const { t } = useTranslation();

  const options: DefaultOptionType[] = [
    { value: Subscription.BREAKFAST_LUNCH_DINNER, label: t(Subscription.BREAKFAST_LUNCH_DINNER) },
    { value: Subscription.BREAKFAST_DINNER, label: t(Subscription.BREAKFAST_DINNER) },
    { value: Subscription.NO_MEALS, label: t(Subscription.NO_MEALS) },
    { value: Subscription.NO_SUBSCRIPTION, label: t(Subscription.NO_SUBSCRIPTION) },
    { value: Subscription.ACADEMIC, label: t(Subscription.ACADEMIC) },
  ];

  return (
    <Form.Item
      label={t('Subscription')}
      name="subscription"
      rules={[subscriptionRule()]}
    >
      <Select options={options} />
    </Form.Item>
  );
};
