import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ROUTE } from 'src/constants';
import { useGetAllTutors } from 'src/gql/queries';
import {
  TutorModal,
  BreadcrumbItem,
  ViewHeader,
  useTutorsTableColumns,
  ModalState,
  ModalAction,
  ViewHeaderButton,
  ViewHeaderSearch,
} from 'src/components';
import { Tutor } from 'src/shared/types/api';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';
import { defaultRoute } from 'src/utils';
import { filterByFullName } from 'src/shared/utils';
import { UserAddOutlined } from '@ant-design/icons';

export const TutorsView = () => {
  const { t } = useTranslation();
  const [allTutors, setAllTutors] = useState<Tutor[]>();
  const [filteredTutors, setFilteredTutors] = useState<Tutor[]>();
  const [modalState, setModalState] = useState<ModalState<Tutor>>({ visible: false });
  const navigate = useNavigate();
  const user = useUserContext();

  const [tutorsData, loading] = useGetAllTutors();

  useEffect(() => {
    setAllTutors(tutorsData);
    setFilteredTutors(tutorsData);
  }, [tutorsData]);

  const onSearch = useCallback((searchText: string) => {
    setFilteredTutors(filterByFullName({ array: allTutors, searchText }));
  }, [allTutors]);

  const onUpdateTutor = (tutor: Tutor) => setModalState({
    visible: true,
    action: ModalAction.Update,
    data: tutor,
  });

  const tutorsTableColumns = useTutorsTableColumns(onUpdateTutor);

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.TUTORS, title: t('Tutors') },
  ];

  const HeaderButton = (
    <ViewHeaderButton
      PrefixIcon={UserAddOutlined}
      title={t('Add tutor')}
      onClick={() => setModalState({ visible: true, action: ModalAction.Create })}
      type="primary"
    />
  );

  const HeaderFilter = <ViewHeaderSearch onSearch={onSearch} />;

  if (user && !user?.isAllowed(GqlQuery.GetTutors)) {
    navigate(defaultRoute(user));
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
        Filter={HeaderFilter}
        Buttons={HeaderButton}
      />
      <Table
        loading={loading}
        dataSource={filteredTutors}
        size="middle"
        columns={tutorsTableColumns}
        pagination={false}
        rowKey="tutorId"
      />
      {modalState.visible && (
        <TutorModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
