import { useForm } from 'antd/lib/form/Form';
import { useRecruitmentFormContext } from 'src/context';
import { RecruitmentUnregisteredBoarderForm } from './RecruitmentUnregisteredBoarderForm';
import { RecruitmentUnregisteredBoarderDataFormFields } from './forms';

export const RecruitmentSummaryUnregisteredBoarder = () => {
  const recruitmentFormContext = useRecruitmentFormContext();
  const recruitmentForm = recruitmentFormContext?.recruitmentForm;
  const [unregisteredBoarderForm] = useForm<RecruitmentUnregisteredBoarderDataFormFields>();
  if (recruitmentForm?.unregisteredBoarderData) {
    unregisteredBoarderForm.setFieldsValue(recruitmentForm?.unregisteredBoarderData);
  }

  if (!recruitmentForm?.unregisteredBoarderData) {
    return null;
  }

  return (
    <RecruitmentUnregisteredBoarderForm form={unregisteredBoarderForm} disabled />
  );
};
