import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const isExistingBoarderColumn = <T extends { isExistingBoarder: boolean }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Is existing boarder?'),
    key: 'isExistingBoarder',
    width: 120,
    render: (_, { isExistingBoarder }) => (isExistingBoarder ? t('Yes') : t('No')),
  });
