import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteForm } from 'src/components';
import { GET_DORMITORY_NOTES_HISTORY, GET_SPECIFIC_BOARDER_NOTES } from 'src/gql/queries';
import { CreateBoarderNoteInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const CREATE_BOARDER_NOTE = gql`
  mutation CreateBoarderNote($input: CreateBoarderNoteInput!) {
    createBoarderNote(input: $input) {
      boarderId
    }
  }
`;

export const useCreateBoarderNote = (
  { form, onSuccess } : UseOnMutationArgs<NoteForm>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [createBoarderNote, { loading }] = useMutation<
    unknown, { input: CreateBoarderNoteInput }
  >(
    CREATE_BOARDER_NOTE,
    { refetchQueries: [GET_SPECIFIC_BOARDER_NOTES, GET_DORMITORY_NOTES_HISTORY] },
  );

  const onCreateBoarderNote = useCallback(async () => {
    const formFields = await form.validateFields();
    try {
      await Promise.all(formFields.boarderIds.map((boarderId) => createBoarderNote({
        variables: {
          input: {
            boarderId,
            note: {
              title: formFields.title,
              category: formFields.category,
              description: formFields.description,
            },
          },
        },
      })));
      onSuccess?.();

      return notification.success({ message: t('Note has been created successfully') });
    } catch (error) {
      return notification.error({ message: t('Note has not been created successfully') });
    }
  }, [form, onSuccess]);

  return [onCreateBoarderNote, loading];
};
