import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useTranslation } from 'react-i18next';
import { Dormitory, ExtendedNote, NotesHistoryArgs } from 'src/shared/types/api';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_NOTES_HISTORY = gql`
  query GetDormitoryNotesHistory($dateStart: String!, $dateEnd: String!) {
    dormitory {
      notesHistory(dateStart: $dateStart, dateEnd: $dateEnd) {
        noteId
        createdAt
        createdBy
        description
        title
        updatedAt
        category
        boarder {
          boarderId
          firstName
          lastName
          groupId
          roomId
        }
      }
    }
  }
`;

type GetDormitoryNotesHistoryResponse = { dormitory: Pick<Dormitory, 'notesHistory'> };

export const useGetDormitoryNotesHistory = (
  range: RangeValue<Dayjs>,
): UseQueryReturnType<ExtendedNote[]> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryNotesHistoryResponse, NotesHistoryArgs>(
    GET_DORMITORY_NOTES_HISTORY,
    {
      variables: {
        dateStart: range?.[0]?.startOf('day').toISOString() || '',
        dateEnd: range?.[1]?.endOf('day').toISOString() || '',
      },
    },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory notes history') });
  }

  return [data?.dormitory.notesHistory, loading, error];
};
