import {
  Input,
  InputNumber,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { BoardersFilter, useUserContext } from 'src/context';
import { useIsMobile } from 'src/hooks';
import { UserGroup } from 'src/shared/types/common';
import { toSelectOptions } from 'src/utils';
import { ViewHeaderSearch } from './ViewHeaderSearch';

type BoardersViewFilterProps = {
  boardersFilter: BoardersFilter;
  setBoardersFilter: Dispatch<SetStateAction<BoardersFilter>>;
  groupIds: string[];
  labels: string[];
}

export const BoardersViewFilter = ({
  groupIds,
  labels,
  boardersFilter,
  setBoardersFilter,
}: BoardersViewFilterProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isMobile = useIsMobile(1060);

  if (user?.group === UserGroup.Parents) {
    return null;
  }

  return (
    <>
      <ViewHeaderSearch
        defaultValue={boardersFilter.name}
        onSearch={(searchText: string) => setBoardersFilter(
          (prevBoardersFilter) => ({ ...prevBoardersFilter, name: searchText }),
        )}
      />
      {!isMobile && (
        <>
          <Select
            mode="tags"
            allowClear
            placeholder={t('Group IDs')}
            defaultValue={boardersFilter.groupIds}
            onChange={(value: string[]) => setBoardersFilter(
              (prevBoardersFilter) => ({ ...prevBoardersFilter, groupIds: value }),
            )}
            className="header-item header-item-small"
            options={toSelectOptions(groupIds)}
          />
          <Select
            mode="tags"
            allowClear
            placeholder={t('Labels')}
            defaultValue={boardersFilter.labels}
            onChange={(value: string[]) => setBoardersFilter(
              (prevBoardersFilter) => ({ ...prevBoardersFilter, labels: value }),
            )}
            className="header-item header-item-medium"
            options={toSelectOptions(labels)}
          />
          <Tooltip title={t('Filter boarders by balance')}>
            <div className="header-item">
              <InputNumber
                placeholder={t('Min.')}
                addonAfter={t('PLN')}
                min={-100_000}
                max={100_000}
                className="header-item-small"
                defaultValue={boardersFilter.minBalance}
                onChange={(value: number) => setBoardersFilter(
                  (prevBoardersFilter) => ({ ...prevBoardersFilter, minBalance: value }),
                )}
              />
              <Input style={{ width: STYLE.BASE_MARGIN * 2 }} placeholder="~" disabled />
              <InputNumber
                placeholder={t('Max.')}
                addonAfter={t('PLN')}
                min={-100_000}
                max={100_000}
                className="header-item-small"
                defaultValue={boardersFilter.maxBalance}
                onChange={(value: number) => setBoardersFilter(
                  (prevBoardersFilter) => ({ ...prevBoardersFilter, maxBalance: value }),
                )}
              />
            </div>
          </Tooltip>
          <Tooltip title={t('Switch boarders awaiting for decision tooltip')}>
            <Switch
              className="header-item"
              defaultChecked={boardersFilter.onlyAwaitingBoarders}
              onChange={(value: boolean) => setBoardersFilter(
                (prevBoardersFilter) => ({ ...prevBoardersFilter, onlyAwaitingBoarders: value }),
              )}
            />
          </Tooltip>
        </>
      )}
    </>
  );
};
