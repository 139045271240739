import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'src/components';
import { FormItemCommonProps } from '.';
import { livingEndDateRule } from '../rules';

export const LivingEndDateFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Living end date')}
      name="livingEndDate"
      rules={[livingEndDateRule()]}
    >
      <DatePicker disabled={disabled} />
    </Form.Item>
  );
};
