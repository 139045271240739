import React, { useCallback, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { useApproveUpdateBoarderGeneralInfoRequest, useDeclineUpdateBoarderGeneralInfoRequest } from 'src/gql/mutations';
import { UpdateGeneralInfoRequest } from 'src/shared/types/common';

// TODO: refactor: merge with `paymentDecision` (WEB-116)
export const updateBoarderGeneralInfoRequestDecisionColumn = (
  t: TFunction,
  boarderId?: string,
): ColumnType<UpdateGeneralInfoRequest> => ({
  title: t('Decision'),
  key: 'decision',
  render: (_, item) => {
    const [declinePopConfirmVisible, setDeclinePopConfirmVisible] = useState(false);
    const [approvePopConfirmVisible, setApprovePopConfirmVisible] = useState(false);

    const [
      declineUpdateBoarderGeneralInfoRequest,
      declineUpdateBoarderGeneralInfoRequestLoading,
    ] = useDeclineUpdateBoarderGeneralInfoRequest({
      boarderId,
      requestId: item.requestId,
      onComplete: () => setDeclinePopConfirmVisible(false),
    });
    const [
      approveUpdateBoarderGeneralInfoRequest,
      approveUpdateBoarderGeneralInfoRequestLoading,
    ] = useApproveUpdateBoarderGeneralInfoRequest({
      boarderId,
      requestId: item.requestId,
      onComplete: () => setApprovePopConfirmVisible(false),
    });

    const stopEventPropagation = useCallback((
      e?: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => e?.stopPropagation(), []);

    return (
      <Space>
        <Popconfirm
          title={t('Decline update boarder general info request popconfirm')}
          okButtonProps={{ loading: declineUpdateBoarderGeneralInfoRequestLoading }}
          visible={declinePopConfirmVisible}
          okType="danger"
          onCancel={(event) => {
            stopEventPropagation(event);
            setDeclinePopConfirmVisible(false);
          }}
          onConfirm={(event) => {
            stopEventPropagation(event);
            declineUpdateBoarderGeneralInfoRequest();
          }}
        >
          <Button
            type="link"
            size="small"
            icon={<CloseOutlined />}
            danger
            onClick={(event) => {
              stopEventPropagation(event);
              setDeclinePopConfirmVisible(true);
            }}
          >
            {t('Decline')}
          </Button>
        </Popconfirm>
        <Popconfirm
          title={t('Approve update boarder general info request popconfirm')}
          okButtonProps={{ loading: approveUpdateBoarderGeneralInfoRequestLoading }}
          visible={approvePopConfirmVisible}
          onCancel={(event) => {
            stopEventPropagation(event);
            setApprovePopConfirmVisible(false);
          }}
          onConfirm={(event) => {
            stopEventPropagation(event);
            approveUpdateBoarderGeneralInfoRequest();
          }}
        >
          <Button
            type="primary"
            size="small"
            icon={<CheckOutlined />}
            onClick={(event) => {
              stopEventPropagation(event);
              setApprovePopConfirmVisible(true);
            }}
          >
            {t('Approve')}
          </Button>
        </Popconfirm>
      </Space>
    );
  },
});
