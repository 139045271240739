import { NoteForm, NoteModalStateData } from 'src/components';
import {
  useCreateBoarderNote,
  useCreateDormitoryNote,
  UseOnMutation,
  UseOnMutationArgs,
  useUpdateBoarderNote,
  useUpdateDormitoryNote,
} from 'src/gql/mutations';
import { NoteCategory } from 'src/shared/types/common';

type UseOnNoteModalSubmitArgs = UseOnMutationArgs<NoteForm> & {
  modalStateData?: NoteModalStateData;
}

export const useOnNoteModalSubmit = (
  { form, modalStateData, onSuccess }: UseOnNoteModalSubmitArgs,
): UseOnMutation => {
  const [
    createDormitoryNote,
    createDormitoryNoteLoading,
  ] = useCreateDormitoryNote({ form, onSuccess });
  const [
    updateDormitoryNote,
    updateDormitoryNoteLoading,
  ] = useUpdateDormitoryNote({ form, noteId: modalStateData?.noteId, onSuccess });
  const [
    createBoarderNote,
    createBoarderNoteLoading,
  ] = useCreateBoarderNote({
    form,
    onSuccess,
  });
  const [
    updateBoarderNote,
    updateBoarderNoteLoading,
  ] = useUpdateBoarderNote({
    form,
    boarderId: modalStateData?.boarderIds[0],
    noteId: modalStateData?.noteId,
    onSuccess,
  });

  const onSubmit = async () => {
    const noteCategory = form.getFieldValue('category');

    if (noteCategory === NoteCategory.DORMITORY) {
      if (modalStateData?.noteId) {
        await updateDormitoryNote();
      } else {
        await createDormitoryNote();
      }
    } else {
      if (modalStateData?.noteId) {
        await updateBoarderNote();
      } else {
        await createBoarderNote();
      }
    }
  };

  const loading = createDormitoryNoteLoading
    || updateDormitoryNoteLoading
    || createBoarderNoteLoading
    || updateBoarderNoteLoading;

  return [onSubmit, loading];
};
