import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { DailyOrderedMeals } from 'src/shared/types/api';
import {
  breakfastsCountColumn,
  dateColumn,
  dinnersCountColumn,
  lunchesCountColumn,
  weekDayColumn,
} from './utils';

export const useWeeklyOrderedMealsTableColumns = () => {
  const { t } = useTranslation();
  const isMobileTable = useIsMobile(600);

  const columnsMemo = useMemo(() => ({
    weekDayColumn: weekDayColumn<DailyOrderedMeals>(t),
    dateColumn: dateColumn<DailyOrderedMeals>(t),
    breakfastsCountColumn: breakfastsCountColumn<DailyOrderedMeals>(t),
    lunchesCountColumn: lunchesCountColumn<DailyOrderedMeals>(t),
    dinnersCountColumn: dinnersCountColumn<DailyOrderedMeals>(t),
  }), [t]);

  return isMobileTable ? [
    columnsMemo.dateColumn,
    columnsMemo.breakfastsCountColumn,
    columnsMemo.lunchesCountColumn,
    columnsMemo.dinnersCountColumn,
  ] : [
    columnsMemo.weekDayColumn,
    columnsMemo.dateColumn,
    columnsMemo.breakfastsCountColumn,
    columnsMemo.lunchesCountColumn,
    columnsMemo.dinnersCountColumn,
  ];
};
