import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { dormitoryAcademicFeeRule } from '../rules';

export const DormitoryAcademicFeeFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Academic fee')}
      name="academic"
      rules={[dormitoryAcademicFeeRule()]}
    >
      <Input type="number" disabled={disabled} />
    </Form.Item>
  );
};
