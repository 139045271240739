import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validLabels } from 'src/shared/validatorUtils';

export const labelsRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct labels'),
    validator: (_, value: string[]) => (
      validLabels(value)
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
