import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DormitoryFees } from 'src/shared/types/common';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_DORMITORY_FEES = gql`
  mutation UpdateDormitoryFees($input: DormitoryFeesInput!) {
    updateDormitoryFees(input: $input) {
      base
      breakfast
      lunch
      dinner
      academic
      weekendStay
    }
  }
`;

export const useUpdateDormitoryFees = (
  { form, onSuccess } : UseOnMutationArgs<DormitoryFees>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [updateDormitoryFees, { loading }] = useMutation<
    unknown, { input: DormitoryFees }
  >(UPDATE_DORMITORY_FEES);

  const onUpdateDormitoryFees = useCallback(async () => {
    const formFields = await form.validateFields();
    try {
      await updateDormitoryFees({
        variables: {
          input: {
            academic: Number(formFields.academic),
            base: Number(formFields.base),
            weekendStay: Number(formFields.weekendStay),
            breakfast: Number(formFields.breakfast),
            lunch: Number(formFields.lunch),
            dinner: Number(formFields.dinner),
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Dormitory fees have been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Dormitory fees have not been updated successfully') });
    }
  }, [form, onSuccess]);

  return [onUpdateDormitoryFees, loading];
};
