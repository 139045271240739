import { useNavigate } from 'react-router-dom';
import { useRecruitmentFormContext } from 'src/context';
import { useEffect } from 'react';
import { useRecruitmentStepPaths } from './useRecruitmentStepPaths';
import { useRecruitmentStepPathIndex } from './useRecruitmentStepPathIndex';

export const useNavigateBackIfEmptyForm = () => {
  const navigate = useNavigate();
  const formContext = useRecruitmentFormContext();
  const recruitmentStepPaths = useRecruitmentStepPaths();
  const recruitmentForm = formContext?.recruitmentForm;
  const recruitmentStepPathIndex = useRecruitmentStepPathIndex();

  useEffect(() => {
    if (recruitmentStepPathIndex === 1 && !recruitmentForm?.startStep) {
      navigate(recruitmentStepPaths[0]);
    } else if (
      recruitmentStepPathIndex === 2
       && !(recruitmentForm?.registeredBoarderData || recruitmentForm?.unregisteredBoarderData)
    ) {
      if (recruitmentForm?.startStep) {
        navigate(recruitmentStepPaths[1]);
      } else {
        navigate(recruitmentStepPaths[0]);
      }
    } else if (recruitmentStepPathIndex === 3 && !recruitmentForm?.questionnaire) {
      if (recruitmentForm?.registeredBoarderData || recruitmentForm?.unregisteredBoarderData) {
        navigate(recruitmentStepPaths[2]);
      } else if (recruitmentForm?.startStep) {
        navigate(recruitmentStepPaths[1]);
      } else {
        navigate(recruitmentStepPaths[0]);
      }
    } else if (recruitmentStepPathIndex === 4 && !recruitmentForm?.agreements) {
      if (recruitmentForm?.questionnaire) {
        navigate(recruitmentStepPaths[3]);
      } else if (
        recruitmentForm?.registeredBoarderData
        || recruitmentForm?.unregisteredBoarderData
      ) {
        navigate(recruitmentStepPaths[2]);
      } else if (recruitmentForm?.startStep) {
        navigate(recruitmentStepPaths[1]);
      } else {
        navigate(recruitmentStepPaths[0]);
      }
    }
  }, []);
};
