import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dormitory } from 'src/shared/types/api';
import { DormitoryFees } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_DORMITORY_FEES = gql`
  query GetDormitoryFees {
    dormitory {
      fees {
        base
        breakfast
        lunch
        dinner
        academic
        weekendStay
      }
    }
  }
`;

export type GetDormitoryFeesResponse = { dormitory: Pick<Dormitory, 'fees'> }

export const useGetDormitoryFees = (): UseQueryReturnType<DormitoryFees> => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useQuery<GetDormitoryFeesResponse>(
    GET_DORMITORY_FEES,
  );

  if (error) {
    notification.error({ message: t('Unable to fetch dormitory fees') });
  }

  return [data?.dormitory.fees, loading, error];
};
