import { Form } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { CreateTutorInput } from 'src/shared/types/api';
import { FormProps } from '.';
import { ContactDetailsFormItems, GroupIdsFormItem } from './items';

export type TutorForm = CreateTutorInput;

type TutorModalFormProps = FormProps<TutorForm> & { groupIdOptions?: DefaultOptionType[]; }

export const TutorModalForm = ({
  form, groupIdOptions,
}: TutorModalFormProps) => (
  <Form form={form} layout="vertical">
    <ContactDetailsFormItems emailRequired phoneNumberRequired />
    <GroupIdsFormItem mode="multiple" options={groupIdOptions} />
  </Form>
);
