import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { FullName, getFullName } from 'src/shared/utils';
import { sorterByFullName } from '../sorters';

export function fullNameColumn<T extends FullName>(t: TFunction): ColumnType<T> {
  return {
    title: t('Full name'),
    key: 'fullName',
    sorter: sorterByFullName({ lastNameFirst: true }),
    defaultSortOrder: 'ascend',
    render: (_, item) => getFullName({ item, lastNameFirst: true }),
  };
}
