type UniqByKeyArgs<T, K> = {
  array?: T[];
  key: K;
}

export const uniqByKey = <T, K extends keyof T>({ array, key }: UniqByKeyArgs<T, K>) => Array.from(
  new Set(array?.map(
    (item) => (item[key]),
  )),
).filter((item) => item) as Array<NonNullable<T[K]>>;

type UniqByMapArgs<T, U> = {
  array?: T[];
  mapper: (item: T) => U;
}

export const uniqByMap = <T, U>({ array, mapper }: UniqByMapArgs<T, U>) => Array.from(
  new Set(array?.map(mapper)),
).filter((item) => item) as Array<NonNullable<U>>;

export const uniq = <T>(array?: T[]) => Array.from(
  new Set(array),
).filter((item) => item) as Array<NonNullable<T>>;
