import { CONFIG } from 'src/config';
import { SortOrder } from 'antd/lib/table/interface';
import { assertIsString } from 'src/utils';

export const sorterByString = <K extends string, T extends Record<K, string | null | undefined>>(
  args: {
    obj1: T,
    obj2: T,
    key: keyof T,
    order?: SortOrder,
    numeric?: boolean
  },
) => {
  const value1 = args.obj1[args.key];
  const value2 = args.obj2[args.key];

  if (!assertIsString(value1)) {
    return args.order === 'descend' ? -1 : 1;
  }

  if (!assertIsString(value2)) {
    return args.order === 'descend' ? 1 : -1;
  }

  return value1.localeCompare(
    value2,
    CONFIG.LOCALE,
    { numeric: args.numeric },
  );
};
