import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { RecruitmentBoarderRequestDataSource } from 'src/gql/queries/recruitment';
import {
  emailColumn,
  isExistingBoarderColumn,
  phoneNumberColumn,
  recruitmentActionsColumn,
  recruitmentBalanceColumn,
  recruitmentCreatedAtColumn,
  recruitmentFullNameColumn,
  recruitmentPointsColumn,
} from './utils/columns';
import { CreateBoarderPaymentModalProps } from '../modals';
import { recruitmentStatusColumn } from './utils/columns/recruitmentStatus';

type UseRecruitmentBoarderTableColumnsArgs = {
  setPaymentModalProps: Dispatch<SetStateAction<Pick<CreateBoarderPaymentModalProps, 'boarderId' | 'isRecruitmentBoarder'>>>
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

export const useRecruitmentBoarderTableColumns = ({
  setPaymentModalProps,
  setIsModalVisible,
}: UseRecruitmentBoarderTableColumnsArgs) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const columnsMemo = useMemo(() => ({
    fullNameColumn: recruitmentFullNameColumn(t),
    emailColumn: emailColumn<RecruitmentBoarderRequestDataSource>(t),
    phoneNumberColumn: phoneNumberColumn<RecruitmentBoarderRequestDataSource>(t),
    recruitmentPointsColumn: recruitmentPointsColumn<RecruitmentBoarderRequestDataSource>(t),
    isExistingBoarderColumn: isExistingBoarderColumn<RecruitmentBoarderRequestDataSource>(t),
    createdAtColumn: recruitmentCreatedAtColumn(t),
    balanceColumn: recruitmentBalanceColumn(t),
    recruitmentStatusColumn: recruitmentStatusColumn<RecruitmentBoarderRequestDataSource>(t),
    recruitmentActionsColumn: recruitmentActionsColumn({
      isMobile, t, setPaymentModalProps, setIsModalVisible,
    }),
  }), [t, isMobile]);

  return isMobile
    ? [
      columnsMemo.fullNameColumn,
      columnsMemo.recruitmentPointsColumn,
      columnsMemo.recruitmentActionsColumn,
    ]
    : [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.recruitmentPointsColumn,
      columnsMemo.isExistingBoarderColumn,
      columnsMemo.balanceColumn,
      columnsMemo.createdAtColumn,
      columnsMemo.recruitmentStatusColumn,
      columnsMemo.recruitmentActionsColumn,
    ];
};
