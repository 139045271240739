import { Form, Modal } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateBoarderSubscription } from 'src/gql/mutations';
import { UpdateBoarderSubscriptionInput } from 'src/shared/types/api';
import { ModalAction, ModalState } from '.';
import { UpdateBoarderSubscriptionForm } from '../forms';

type UpdateBoarderSubscriptionModalProps = {
  modalState: ModalState<UpdateBoarderSubscriptionInput>
  setModalState: Dispatch<SetStateAction<ModalState<UpdateBoarderSubscriptionInput>>>;
}

export const UpdateBoarderSubscriptionModal = (
  { modalState, setModalState }: UpdateBoarderSubscriptionModalProps,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Omit<UpdateBoarderSubscriptionInput, 'boarderId'>>();

  const [
    updateBoarderSubscription,
    loading,
  ] = useUpdateBoarderSubscription({
    form,
    boarderId: (modalState.visible && modalState.data?.boarderId) || undefined,
    onSuccess: () => setModalState({ visible: false }),
  });

  if (!modalState.visible) {
    return null;
  }

  useEffect(() => {
    if (modalState.action === ModalAction.Update) {
      form.setFieldsValue({
        subscription: modalState.data.subscription,
      });
    }
  }, [modalState.action]);

  return (
    <Modal
      title={t('Update boarder subscription')}
      visible={modalState.visible}
      onOk={updateBoarderSubscription}
      confirmLoading={loading}
      onCancel={() => setModalState({ visible: false })}
      okText={t('Save')}
      cancelText={t('Cancel')}
      maskClosable={false}
      afterClose={() => form.resetFields()}
    >
      <UpdateBoarderSubscriptionForm form={form} />
    </Modal>
  );
};
