import { Button, List, Table } from 'antd';
import { TFunction } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CONFIG } from 'src/config';
import { useWindowDimensions } from 'src/hooks';
import { Payment } from 'src/shared/types/common';
import { AddBoarderPaymentCommentModal, AddBoarderPaymentCommentModalState } from '../modals';
import {
  amountColumn,
  creationInfoColumn,
  paymentConfirmationColumn,
  paymentTypeColumn,
} from './utils';

export const useBoardersPaymentColumns = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const columnsMemo = useMemo(() => ({
    amountColumn: amountColumn<Payment>(t),
    paymentTypeColumn: paymentTypeColumn(t),
    creationInfoColumn: creationInfoColumn<Payment>(t),
    paymentConfirmationColumn: paymentConfirmationColumn(t),
  }), [t]);

  const columns = [
    columnsMemo.amountColumn,
    columnsMemo.paymentTypeColumn,
  ];

  if (width > 800) {
    columns.push(

      columnsMemo.creationInfoColumn,
    );
  }

  if (width > 1024) {
    columns.push(
      columnsMemo.paymentConfirmationColumn,
    );
  }

  return columns;
};

type BoarderPaymentCommentsTableProps = {
  t: TFunction
  // eslint-disable-next-line no-unused-vars
  onAddCommentButtonClick: (paymentId: string) => void
}

const BoarderPaymentCommentsTable = (
  { onAddCommentButtonClick, t } : BoarderPaymentCommentsTableProps,
  // eslint-disable-next-line react/function-component-definition
) => ({ comments, paymentId }: Payment) => (
  <>
    <List
      itemLayout="horizontal"
      dataSource={comments}
      renderItem={(item, index) => (
        <List.Item key={`comment_${index}`}>
          <List.Item.Meta
            title={`${new Date(item.createdAt).toLocaleString(CONFIG.LOCALE)} (${item.createdBy})`}
            description={item.comment}
          />
        </List.Item>
      )}
    />
    <Button
      type="default"
      onClick={() => onAddCommentButtonClick(paymentId)}
    >
      {t('Add comment')}
    </Button>
  </>
);

type BoarderPaymentsTableProps = {
  boarderId?: string;
  payments?: Payment[];
}

export const BoarderPaymentsTable = ({
  boarderId,
  payments,
}: BoarderPaymentsTableProps) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<AddBoarderPaymentCommentModalState>(
    { visible: false },
  );

  const onAddCommentButtonClick = useCallback(
    (paymentId) => setModalState({
      visible: true,
      boarderId,
      paymentId,
    }),
    [],
  );

  const columns = useBoardersPaymentColumns();

  return (
    <>
      <Table
        dataSource={payments}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        size="middle"
        columns={columns}
        rowKey="paymentId"
        expandable={{
          expandedRowRender: BoarderPaymentCommentsTable({
            onAddCommentButtonClick,
            t,
          }),
        }}
      />
      {modalState.visible && (
        <AddBoarderPaymentCommentModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </>
  );
};
