import { Form, Select } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentType } from 'src/shared/types/common';
import { NEGATIVE_PAYMENT_TYPES } from 'src/shared/utils/payments';
import { FormItemCommonProps } from '.';
import { paymentTypeRule } from '../rules';

type PaymentTypeFormItemProps = {
  setIsAmountNegative: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setAmount: (amount: number | undefined) => void;
} & FormItemCommonProps

export const PaymentTypeFormItem = ({
  setIsAmountNegative,
  setAmount,
  disabled,
}: PaymentTypeFormItemProps) => {
  const { t } = useTranslation();

  const onChange = (value: PaymentType) => {
    let amount: number | undefined;
    switch (value) {
      case PaymentType.WEEKEND_STAY:
        amount = 50;
        break;
      case PaymentType.CARD_DUPLICATE:
        amount = 5;
        break;
      case PaymentType.RECRUITMENT_PAYMENT:
        amount = 250;
        break;
      default:
        amount = undefined;
    }

    setAmount(amount);
    setIsAmountNegative(NEGATIVE_PAYMENT_TYPES.includes(value));
  };

  return (
    <Form.Item
      label={t('Payment type')}
      name="type"
      rules={[paymentTypeRule()]}
    >
      <Select onChange={onChange} disabled={disabled}>
        <Select.OptGroup label={t('Negative payment type')}>
          <Select.Option value={PaymentType.WEEKEND_STAY}>
            {t(PaymentType.WEEKEND_STAY)}
          </Select.Option>
          <Select.Option value={PaymentType.DAMAGE}>
            {t(PaymentType.DAMAGE)}
          </Select.Option>
          <Select.Option value={PaymentType.CARD_DUPLICATE}>
            {t(PaymentType.CARD_DUPLICATE)}
          </Select.Option>
          <Select.Option value={PaymentType.FEE}>
            {t(PaymentType.FEE)}
          </Select.Option>
          <Select.Option value={PaymentType.MEALS}>
            {t(PaymentType.MEALS)}
          </Select.Option>
          <Select.Option value={PaymentType.ADVANCE}>
            {t(PaymentType.ADVANCE)}
          </Select.Option>
          <Select.Option value={PaymentType.OTHER_FEE}>
            {t(PaymentType.OTHER_FEE)}
          </Select.Option>
        </Select.OptGroup>

        <Select.OptGroup label={t('Positive payment type')}>
          <Select.Option value={PaymentType.BOARDER_PAYMENT}>
            {t(PaymentType.BOARDER_PAYMENT)}
          </Select.Option>
          <Select.Option value={PaymentType.CORRECTION}>
            {t(PaymentType.CORRECTION)}
          </Select.Option>
          <Select.Option value={PaymentType.OTHER_REFUND}>
            {t(PaymentType.OTHER_REFUND)}
          </Select.Option>
          <Select.Option value={PaymentType.RECRUITMENT_PAYMENT}>
            {t(PaymentType.RECRUITMENT_PAYMENT)}
          </Select.Option>
        </Select.OptGroup>
      </Select>
    </Form.Item>
  );
};
