import logo from 'src/assets/bursa-logo-no-margin-alpha.png';
import { Center, Footer } from 'src/components';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { I18n } from 'aws-amplify/utils';
import { PropsWithChildren } from 'react';
import { tokens } from 'src/constants/tokens';

const Header = ({ children, withPadding }: PropsWithChildren<{withPadding?: boolean}>) => (
  <div style={{
    marginTop: withPadding ? tokens.space.l : undefined,
    textAlign: 'center',
    fontSize: tokens.font.size.xxl,
    fontWeight: tokens.font.weight.black,
  }}
  >
    {children}
  </div>
);

export const amplifyComponents: DefaultComponents = {
  Header: () => (
    <Center>
      <img
        src={logo} // todo: get from api/s3 (#435)
        style={{ marginBottom: 32, width: '75%' }}
        alt="Logo"
      />
    </Center>
  ),
  Footer,
  SignIn: {
    Header: () => <Header withPadding>{I18n.get('Sign In')}</Header>,
  },
  ConfirmResetPassword: {
    Header: () => <Header>{I18n.get('Reset your password')}</Header>,
  },
  ForgotPassword: {
    Header: () => <Header>{I18n.get('Reset your password')}</Header>,
  },
};
