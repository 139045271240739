import dayjs, { Dayjs } from 'dayjs';
import { IsoStringDate, IsoStringDateTime } from 'src/shared/types/common';
import { isIsoStringDate, isIsoStringDateTime } from 'src/shared/validatorUtils';

export type MinMaxRange = { min: number; max: number }

export const dayjsOrUndefined = (
  date?: IsoStringDate | IsoStringDateTime,
): Dayjs | undefined => (
  isIsoStringDate(date) || isIsoStringDateTime(date)
    ? dayjs(date)
    : undefined
);

export const nbsp = String.fromCharCode(160);
