import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';

export const updateInfoColumn = <T extends {'updatedAt'?: string}>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Updated'),
    key: 'updated',
    render: (_, item) => (item.updatedAt ? `${new Date(item.updatedAt).toLocaleString(CONFIG.LOCALE)}` : '-'),
  });
