import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps, nameWithPrefix } from '.';
import { firstNameRule } from '../rules';

export const FirstNameFormItem = ({ keyPrefix, disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('First name')}
      name={nameWithPrefix({ name: 'firstName', keyPrefix })}
      rules={[firstNameRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
