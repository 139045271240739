import { Table } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/context';
import { DELETE_BOARDER_NOTE } from 'src/gql/mutations';
import { GET_SPECIFIC_BOARDER_NOTES } from 'src/gql/queries';
import { useIsMobile } from 'src/hooks';
import { DeleteBoarderNoteInput } from 'src/shared/types/api';
import { GqlMutation, Note, NoteCategory } from 'src/shared/types/common';
import {
  creationInfoColumn,
  titleColumn,
  updateInfoColumn,
  actionsColumn,
} from './utils';

type BoarderNotesTableProps = {
  boarderId?: string;
  notes?: Note[]
  noteCategory: NoteCategory;
  // eslint-disable-next-line no-unused-vars
  onNoteUpdate: (noteCategory: NoteCategory) => (note: Note) => void;
  archive?: boolean;
}

export const BoarderNotesTable = ({
  boarderId,
  notes,
  noteCategory,
  onNoteUpdate,
  archive,
}: BoarderNotesTableProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const user = useUserContext();

  const columnsMemo = useMemo(() => {
    const commonColumns = {
      noteTitleColumn: titleColumn(t),
      noteCreationInfoColumn: creationInfoColumn(t),
      noteUpdateInfoColumn: updateInfoColumn(t),
    };

    return user?.isAllowed(GqlMutation.UpdateBoarderNote) && !archive ? {
      ...commonColumns,
      actionsColumn: actionsColumn<Note, DeleteBoarderNoteInput>({
        t,
        rendererArgs: {
          update: {
            onUpdate: onNoteUpdate(noteCategory),
          },
          remove: {
            messageOnError: t('Note has not been removed successfully'),
            messageOnSuccess: t('Note has been removed successfully'),
            mutation: DELETE_BOARDER_NOTE,
            refetchQueries: [GET_SPECIFIC_BOARDER_NOTES],
            recordToInput: (note) => ({
              boarderId: boarderId || '', // `notification.error` will be shown if the empty string is used
              note: {
                noteId: note.noteId,
                category: noteCategory,
              },
            }),
          },
        },
        isMobile,
      }),
    } : commonColumns;
  }, [t, user, isMobile]);

  // TODO: useBoarderNotesTableColumns (BUR-194)
  const columns = isMobile
    ? Object.values(columnsMemo).filter((column) => column.key !== 'updated')
    : Object.values(columnsMemo);

  return (
    <Table
      dataSource={notes}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 10,
      }}
      size="middle"
      columns={columns}
      rowKey="noteId"
      expandable={{
        rowExpandable: (note) => !!note.description.length,
        expandedRowRender: (note) => (note.description),
      }}
    />
  );
};
