import { Tag } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const groupIdsColumn = (t: TFunction): ColumnType<{ groupIds?: string[] }> => ({
  title: t('Group IDs'),
  key: 'groupIds',
  render: (_, { groupIds }) => (groupIds?.length ? groupIds.map(
    (groupId) => (
      <Tag key={groupId}>{groupId}</Tag>
    ),
  ) : null),
});
