import {
  DormitoryChargeMonthlyFees,
  DormitoryFees,
  DormitoryFinanceStats,
  DormitoryPaymentReminder,
} from 'src/components';
import { useUserContext } from 'src/context';
import { GqlMutation, GqlQuery } from 'src/shared/types/common';

export const DormitoryPaymentsTab = () => {
  const user = useUserContext();

  return (
    <div className="dormitory-tab-content-wrapper">
      <DormitoryFees />
      {user?.isAllowed(GqlMutation.ChargeMonthlyFee)
        && <DormitoryChargeMonthlyFees />}
      {user?.isAllowed(GqlQuery.GetFinanceStats)
        && <DormitoryFinanceStats />}
      {user?.isAllowed(GqlMutation.SendPaymentReminder)
        && <DormitoryPaymentReminder />}
    </div>
  );
};
