import { DollarOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBoarderPaymentModalProps } from 'src/components/modals';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';

type AddRecruitmentPaymentButtonProps = {
  record: RecruitmentBoarderRequest,
  setPaymentModalProps: Dispatch<SetStateAction<Pick<CreateBoarderPaymentModalProps, 'boarderId' | 'isRecruitmentBoarder'>>>
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

export const AddRecruitmentPaymentButton = ({
  record,
  setPaymentModalProps,
  setIsModalVisible,
}: AddRecruitmentPaymentButtonProps) => {
  const { t } = useTranslation();

  return (
    <Popover content={t('Add recruitment payment')}>
      <Typography.Link>
        <DollarOutlined onClick={() => {
          setPaymentModalProps(() => ({
            boarderId: record.boarder.boarderId || record.boarderRecruitmentId,
            isRecruitmentBoarder: !record.boarder.boarderId,
          }));
          setIsModalVisible(true);
        }}
        />
      </Typography.Link>
    </Popover>
  );
};
