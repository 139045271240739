import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';
import { IsoStringDate } from 'src/shared/types/common';

export const creationInfo = (
  { createdAt, createdBy }: {createdAt: string; createdBy: string;},
) => `${new Date(createdAt).toLocaleString(CONFIG.LOCALE)} (${createdBy})`;

export const creationInfoColumn = <T extends { createdAt: IsoStringDate; createdBy: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Created'),
    key: 'created',
    render: (_, item) => creationInfo(item),
  });
