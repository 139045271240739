import { Alert, Space } from 'antd';
import { useTranslation } from 'react-i18next';

export const CalendarNotAvailable = () => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" align="center" style={{ width: '100%' }}>
      <Alert
        message={t('Warning')}
        description={t('Calendar not available during holidays')}
        type="warning"
        showIcon
      />
    </Space>
  );
};
