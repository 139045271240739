import { Uuid } from '../../common/misc';

export const enum BoarderOtherInfoType {
  Activities = 'activities',
  Calendar = 'calendar',
  Notes = 'notes',
  Payments = 'payments',
  UpdateGeneralInfoRequests = 'updateGeneralInfoRequests'
}

export type BoarderOtherInfoDocKey = {
  boarderId: Uuid;
  infoType: BoarderOtherInfoType;
}
