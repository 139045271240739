import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { phoneNumberToDisplay } from 'src/shared/utils';

export const phoneNumberColumn = <T extends { phoneNumber: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Phone number'),
    width: 120,
    key: 'phoneNumber',
    render: (_, { phoneNumber }) => phoneNumberToDisplay(phoneNumber),
  });
