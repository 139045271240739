import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';

export const recruitmentPointsColumn = <T extends { recruitmentPoints: number }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Recruitment points'),
    key: 'recruitmentPoints',
    width: 90,
    sorter: (a, b) => Number(a.recruitmentPoints) - Number(b.recruitmentPoints),
    render: (_, { recruitmentPoints }) => recruitmentPoints,
  });
