import { Form, FormInstance, Input } from 'antd';
import dayjs from 'dayjs';
import { PESEL } from 'polish-utils';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateBoarderForm } from 'src/views/BoarderTabs';
import { FormItemCommonProps } from '.';
import { peselRule } from '../rules';

type PeselFormItemProps = {
  form: FormInstance<Pick<UpdateBoarderForm, 'personalIdentityNumber' | 'birthDate'>>;
  setIsBirthDateDisabled: Dispatch<SetStateAction<boolean>>
} & FormItemCommonProps

export const PeselFormItem = ({
  form,
  setIsBirthDateDisabled,
  disabled,
}: PeselFormItemProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('PESEL')}
      name="personalIdentityNumber"
      rules={[peselRule()]}
    >
      <Input
        disabled={disabled}
        onChange={({ currentTarget: { value } }) => {
          const personalIdentityNumber = new PESEL(value);
          if (personalIdentityNumber.isValid) {
            setIsBirthDateDisabled(true);
            form.setFields([{ name: 'birthDate', value: dayjs(personalIdentityNumber.getDateOfBirth()) }]);
          } else {
            setIsBirthDateDisabled(false);
          }
        }}
      />
    </Form.Item>
  );
};

export const PersonalIdentityNumberFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('PESEL')}
      name="personalIdentityNumber"
      rules={[peselRule()]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
