import { ContactDetails } from 'src/shared/types/common';
import { ContactDetailsFormValues } from 'src/components/forms/items';
import { phoneNumberToDto } from 'src/components/forms/rules';
import { formValuesToAddress } from './formValuesToAddress';

export function formValuesToContactDetails(formValues: ContactDetailsFormValues): ContactDetails {
  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    email: formValues.email,
    phoneNumber: phoneNumberToDto(formValues.phoneNumber) || undefined,
    address: formValuesToAddress(formValues),
  };
}
