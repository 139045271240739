import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SendPaymentReminderInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const SEND_PAYMENT_REMINDER = gql`
  mutation SendPaymentReminder($input: SendPaymentReminderInput!) {
    sendPaymentReminder(input: $input)
  }
`;

export const useSendPaymentReminder = (
  { form, onSuccess } : UseOnMutationArgs<SendPaymentReminderInput>,
): UseOnMutation => {
  const { t } = useTranslation();
  const [sendPaymentReminder, { loading }] = useMutation<
    unknown, { input: SendPaymentReminderInput }
  >(SEND_PAYMENT_REMINDER);

  const onSendPaymentReminder = useCallback(async () => {
    const formValues = await form.validateFields();
    try {
      await sendPaymentReminder({
        variables: {
          input: {
            amountThreshold: Number(formValues.amountThreshold),
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Reminders have been sent successfully') });
    } catch (error) {
      return notification.error({ message: t('Reminders have not been sent successfully') });
    }
  }, [form, onSuccess]);

  return [onSendPaymentReminder, loading];
};
