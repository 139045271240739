import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { ClosedQuestionRadioOption } from 'src/components/ClosedQuestionRadio';

export const questionnaireRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please select an available option'),
    validator: (_, value?: ClosedQuestionRadioOption) => {
      if (!value) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
  });
};
