import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps, nameWithPrefix } from '.';
import { cityRule } from '../rules';

export const CityFormItem = ({ keyPrefix, disabled, required }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('City')}
      name={nameWithPrefix({ name: 'city', keyPrefix })}
      rules={[cityRule({ required: Boolean(required) })]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
