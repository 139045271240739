import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Popconfirm, Popover, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeclineRecruitmentRequest } from 'src/gql/mutations/recruitment';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';
import { RecruitmentEventEnum } from 'src/shared/types/docs';

type DeclineRecruitmentButtonProps = {
  record: RecruitmentBoarderRequest;
}

export const DeclineRecruitmentButton = ({ record }: DeclineRecruitmentButtonProps) => {
  const { t } = useTranslation();

  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(record.events.some(
    ({ event }) => event === RecruitmentEventEnum.REQUEST_DECLINED,
  ));

  const [
    declineRecruitmentRequest,
    loading,
  ] = useDeclineRecruitmentRequest({
    boarderRecruitmentId: record.boarderRecruitmentId,
    onSuccess: () => setDisabled(true),
    onComplete: () => setPopConfirmVisible(false),
  });

  return (
    <Popconfirm
      title={t('Confirm decline recruitment request')}
      okText={t('Yes')}
      visible={popConfirmVisible}
      onConfirm={declineRecruitmentRequest}
      okButtonProps={{ loading, danger: true }}
      onCancel={() => setPopConfirmVisible(false)}
    >
      <Popover content={t('Decline recruitment request')}>
        <Typography.Link disabled={disabled}>
          <CloseCircleOutlined onClick={() => setPopConfirmVisible(true)} />
        </Typography.Link>
      </Popover>
    </Popconfirm>
  );
};
