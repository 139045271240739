import { useTranslation } from 'react-i18next';

export const useRecruitmentStepPaths = () => {
  const { t } = useTranslation();

  return [
    t('start-step'),
    t('boarder-data-step'),
    t('questionnaire-step'),
    t('agreements-step'),
    t('summary-step'),
  ];
};
