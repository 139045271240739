import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemCommonProps } from '.';
import { emailsRule } from '../rules';

export const CanteenManagerEmailsFormItem = ({ disabled }: FormItemCommonProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('Canteen manager emails')}
      name="canteenManagerEmails"
      rules={[emailsRule()]}
    >
      <Select
        disabled={disabled}
        mode="tags"
        tokenSeparators={[',', ' ']}
        notFoundContent={null}
      />
    </Form.Item>
  );
};
