import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { BreadcrumbItem, MainCard, ViewHeader } from 'src/components';
import { ROUTE } from 'src/constants';
import { Boarder } from 'src/shared/types/api';
import { base64UrlToUuid } from 'src/utils';
import { useGetSpecificBoarderArchive } from 'src/gql/queries';
import { getFullName } from 'src/shared/utils';
import {
  BoarderActivitiesTab, BoarderGeneralInfoTab, BoarderNotesTab, BoarderPaymentsTab,
} from './BoarderTabs';

export const BoarderArchiveView = () => {
  const { t } = useTranslation();
  const { boarderId: encodedBoarderId } = useParams<Pick<Boarder, 'boarderId'>>();
  const boarderId = encodedBoarderId && base64UrlToUuid(encodedBoarderId);

  const [boarderArchive, loading] = useGetSpecificBoarderArchive(boarderId);

  const breadcrumbItems: BreadcrumbItem[] = [
    { route: ROUTE.BOARDERS_ARCHIVE, title: t('Boarders archive') },
  ];

  if (boarderArchive) {
    breadcrumbItems.push(
      { title: getFullName({ item: boarderArchive }) },
    );
  }

  return (
    <>
      <ViewHeader
        breadcrumbItems={breadcrumbItems}
      />
      <MainCard loading={loading}>
        <Tabs centered defaultActiveKey="general-data" destroyInactiveTabPane>
          <Tabs.TabPane tab={t('General data')} key="general-data">
            <BoarderGeneralInfoTab boarder={boarderArchive} archive />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Activities')} key="activities">
            <BoarderActivitiesTab boarderId={boarderId} archive />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Notes')} key="notes">
            <BoarderNotesTab boarderId={boarderId} archive />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Payments')} key="payments">
            <BoarderPaymentsTab boarderId={boarderId} archive />
          </Tabs.TabPane>
        </Tabs>
      </MainCard>
    </>
  );
};
