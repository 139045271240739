import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { sorterByString } from '../sorters';

export const roomColumn = <T extends {
  roomId?: string;
  isRoomRented?: boolean;
}>(
    t: TFunction,
  ): ColumnType<T> => ({
    title: t('Room ID'),
    render: (_, { isRoomRented, roomId }) => (
      <Space>
        {roomId}
        {isRoomRented && <Tooltip title={t('Room is rented in this week')}><ExclamationCircleOutlined /></Tooltip>}
      </Space>
    ),
    sorter: (obj1, obj2, order) => sorterByString({
      obj1: obj1 as { roomId?: string },
      obj2: obj2 as { roomId?: string },
      key: 'roomId',
      order,
      numeric: true,
    }),
  });
