import { Address } from 'src/shared/types/common';

export function formValuesToAddress <T extends {
  streetAddress?: string;
  postCode?: string;
  city?: string;
}>(formValues: T): Address {
  return {
    city: formValues.city,
    postCode: formValues.postCode,
    streetAddress: formValues.streetAddress,
  };
}
