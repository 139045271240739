import { Card, Spin } from 'antd';
import { PropsWithChildren, useMemo } from 'react';
import { STYLE } from 'src/constants';
import { useIsMobile } from 'src/hooks';

export const MainCard = (
  { children, loading, className }: PropsWithChildren<{ loading?: boolean; className?: string; }>,
) => {
  const isMobile = useIsMobile();
  const padding = useMemo(() => (
    isMobile
      ? STYLE.BASE_MARGIN
      : STYLE.BASE_MARGIN * 2
  ), [isMobile]);

  return (
    <Spin spinning={loading ?? false}>
      <Card className={`with-shadow ${className}`} style={{ padding }}>
        {children}
      </Card>
    </Spin>
  );
};
