import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { validPersonalIdentityNumber } from 'src/shared/validatorUtils';

export const peselRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct PESEL'),
    validator: (_, value: string) => (
      !value || validPersonalIdentityNumber(value)
        ? Promise.resolve()
        : Promise.reject()
    ),
  });
};
