import { Row, Col } from 'antd';
import { STYLE } from 'src/constants';
import { FormItemCommonProps, LabelFormItem, PhoneNumberFormItem } from '.';

export const LabeledPhoneNumberFormItem = ({
  keyPrefix,
  disabled,
}: FormItemCommonProps) => (
  <Row gutter={STYLE.BASE_MARGIN}>
    <Col xs={24} sm={12}>
      <LabelFormItem
        keyPrefix={keyPrefix}
        disabled={disabled}
      />
    </Col>
    <Col xs={24} sm={12}>
      <PhoneNumberFormItem
        keyPrefix={keyPrefix}
        required
        disabled={disabled}
      />
    </Col>
  </Row>
);
