import { CheckboxOptionType, Form } from 'antd';
import { DormitoryRecipient, GroupTutorValue } from 'src/shared/dormitoryRecipients';
import { MessageFormItem, RecipientsFormItem, TitleFormItem } from './items';
import { FormProps } from '.';

export type SendMessageForm = {
  title: string;
  message: string;
  recipients: Array<DormitoryRecipient | GroupTutorValue | string>;
}

type SendMessageModalFormProps = FormProps<SendMessageForm> & {
  recipients: CheckboxOptionType[]
}

export const SendMessageModalForm = ({
  form, recipients,
}: SendMessageModalFormProps) => (
  <Form form={form} layout="vertical">
    <TitleFormItem />
    <MessageFormItem />
    <RecipientsFormItem recipients={recipients} />
  </Form>
);
