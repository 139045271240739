import { monthsMap } from './time';
import { GqlCalendar } from '../types/api';
import { Year } from '../types/common';

export const stringifyYear = (dormitoryYear: Year<unknown>): GqlCalendar => ({
  january: JSON.stringify(dormitoryYear[monthsMap.january]),
  february: JSON.stringify(dormitoryYear[monthsMap.february]),
  march: JSON.stringify(dormitoryYear[monthsMap.march]),
  april: JSON.stringify(dormitoryYear[monthsMap.april]),
  may: JSON.stringify(dormitoryYear[monthsMap.may]),
  june: JSON.stringify(dormitoryYear[monthsMap.june]),
  july: JSON.stringify(dormitoryYear[monthsMap.july]),
  august: JSON.stringify(dormitoryYear[monthsMap.august]),
  september: JSON.stringify(dormitoryYear[monthsMap.september]),
  october: JSON.stringify(dormitoryYear[monthsMap.october]),
  november: JSON.stringify(dormitoryYear[monthsMap.november]),
  december: JSON.stringify(dormitoryYear[monthsMap.december]),
});
