import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteTranslated, ROUTE } from 'src/constants';
import { SpecificBoarder } from 'src/gql/queries';
import { BoarderId } from 'src/shared/types/common';
import { UseOnMutation } from '..';

const RESTORE_BOARDER_FROM_ARCHIVE = gql`
  mutation RestoreBoarderFromArchive($input: RestoreBoarderFromArchiveInput!) {
    restoreBoarderFromArchive(input: $input) {
      boarderId
    }
  }
`;

type UseRestoreBoarderFromArchiveArgs = {
  boarder?: SpecificBoarder;
}

export const useRestoreBoarderFromArchive = (
  { boarder }: UseRestoreBoarderFromArchiveArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [restoreBoarderFromArchive, { loading }] = useMutation<
    unknown, { input: BoarderId }
  >(RESTORE_BOARDER_FROM_ARCHIVE);

  const onRestoreBoarderFromArchive = useCallback(async () => {
    if (!boarder?.boarderId) {
      return notification.error({ message: t('Boarder has not been restored from archive successfully') });
    }
    try {
      await restoreBoarderFromArchive({
        variables: {
          input: {
            boarderId: boarder?.boarderId,
          },
        },
      });
      navigate(getRouteTranslated(ROUTE.BOARDERS));

      return notification.success({ message: t('Boarder has been restored from archive successfully') });
    } catch (error) {
      return notification.error({ message: t('Boarder has not been restored from archive successfully') });
    }
  }, [boarder]);

  return [onRestoreBoarderFromArchive, loading];
};
