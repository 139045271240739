import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks';
import { DELETE_ADMINISTRATOR, RESEND_CREDENTIALS_TO_ADMINISTRATOR } from 'src/gql/mutations';
import { GET_ALL_ADMINISTRATORS } from 'src/gql/queries';
import { Administrator, DeleteAdministratorInput } from 'src/shared/types/api';
import { useUserContext } from 'src/context';
import { disabledByAccountStatus } from 'src/utils';
import {
  accountStatusColumn,
  actionsColumn,
  emailColumn,
  fullNameColumn,
  phoneNumberColumn,
} from './utils/columns';

export const useAdministratorsTableColumns = (
  // eslint-disable-next-line no-unused-vars
  onUpdateAdministrator: (administrator: Administrator) => void, // eslint bug
) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const user = useUserContext();

  const recordToInput = (administrator: Administrator) => (
    { administratorId: administrator.administratorId }
  );
  const refetchQueries = [GET_ALL_ADMINISTRATORS];

  const columnsMemo = useMemo(() => ({
    fullNameColumn: fullNameColumn(t),
    emailColumn: emailColumn(t),
    phoneNumberColumn: phoneNumberColumn(t),
    accountStatusColumn: accountStatusColumn(t),
    actionsColumn: actionsColumn<Administrator, DeleteAdministratorInput>({
      t,
      rendererArgs: {
        update: { onUpdate: onUpdateAdministrator },
        remove: {
          recordToInput,
          refetchQueries,
          messageOnError: t('Administrator has not been removed successfully'),
          messageOnSuccess: t('Administrator has been removed successfully'),
          mutation: DELETE_ADMINISTRATOR,
          disabledBy: (record) => record.administratorId === user?.administratorId,
        },
        resendCredentials: {
          recordToInput,
          refetchQueries,
          mutation: RESEND_CREDENTIALS_TO_ADMINISTRATOR,
          disabledBy: disabledByAccountStatus,
        },
      },
      isMobile,
    }),
  }), [t, onUpdateAdministrator, isMobile]);

  // TODO: useAdministratorsTableColumns (BUR-194)
  return isMobile
    ? [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.actionsColumn,
    ]
    : [
      columnsMemo.fullNameColumn,
      columnsMemo.emailColumn,
      columnsMemo.phoneNumberColumn,
      columnsMemo.accountStatusColumn,
      columnsMemo.actionsColumn,
    ];
};
