import type { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export const titleRule = (): Rule => {
  const { t } = useTranslation();

  return ({
    message: t('Please enter correct title'),
    required: true,
    min: 1,
    max: 256,
  });
};
