import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { BoarderDormitoryInfo, BoarderDormitoryInfoLink } from 'src/components';

export const boarderDormitoryInfoColumn = <T extends { boarder?: BoarderDormitoryInfo }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Boarder'),
    key: 'boarder',
    render: (_, { boarder }) => {
      if (!boarder) {
        return '-';
      }

      return <BoarderDormitoryInfoLink boarder={boarder} />;
    },
  });
