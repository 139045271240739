import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGenerateRecruitmentQuestionnaire } from 'src/gql/mutations/recruitment';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';

type GenerateRecruitmentQuestionnaireButtonProps = {
  record: RecruitmentBoarderRequest;
}

export const GenerateRecruitmentQuestionnaireButton = (
  { record }: GenerateRecruitmentQuestionnaireButtonProps,
) => {
  const { t } = useTranslation();

  const [
    generateQuestionnaire,
    loading,
  ] = useGenerateRecruitmentQuestionnaire({
    boarderRecruitmentId: record.boarderRecruitmentId,
  });

  return (
    loading
      ? <LoadingOutlined />
      : (
        <Popover content={t('Generate recruitment questionnaire')}>
          <Typography.Link>
            <FilePdfOutlined onClick={generateQuestionnaire} />
          </Typography.Link>
        </Popover>
      )
  );
};
