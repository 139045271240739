import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_RECRUITMENT_BOARDERS } from 'src/gql/queries/recruitment';
import { DeclineRecruitmentRequestInput } from 'src/shared/types/api';
import { BoarderRecruitmentDoc } from 'src/shared/types/docs';
import { UseOnMutation } from '..';

export const DECLINE_RECRUITMENT_REQUEST = gql`
  mutation DeclineRecruitmentRequest($input: DeclineRecruitmentRequestInput!) {
    declineRecruitmentRequest(input: $input) {
      boarderRecruitmentId
    }
  }
`;

type useDeclineRecruitmentRequestArgs =
  Pick<BoarderRecruitmentDoc, 'boarderRecruitmentId'> & {
    onSuccess?: () => void;
    onComplete?: () => void;
  }

export const useDeclineRecruitmentRequest = (
  { boarderRecruitmentId, onSuccess, onComplete }: useDeclineRecruitmentRequestArgs,
): UseOnMutation => {
  const { t } = useTranslation();
  const [declineRecruitment, { loading }] = useMutation<
    unknown, { input: DeclineRecruitmentRequestInput }
  >(DECLINE_RECRUITMENT_REQUEST, { refetchQueries: [GET_RECRUITMENT_BOARDERS] });

  const onDeclineRecruitmentRequest = useCallback(async () => {
    try {
      await declineRecruitment({
        variables: {
          input: {
            boarderRecruitmentId,
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Recruitment request has been declined successfully') });
    } catch (error) {
      return notification.error({ message: t('Recruitment request has not been declined successfully') });
    } finally {
      onComplete?.();
    }
  }, [boarderRecruitmentId, onSuccess, onComplete]);

  return [onDeclineRecruitmentRequest, loading];
};
