import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteForm } from 'src/components';
import { GET_DORMITORY_NOTES_HISTORY, GET_SPECIFIC_BOARDER_NOTES } from 'src/gql/queries';
import { UpdateBoarderNoteInput } from 'src/shared/types/api';
import { UseOnMutation, UseOnMutationArgs } from '..';

export const UPDATE_BOARDER_NOTE = gql`
  mutation UpdateBoarderNote($input: UpdateBoarderNoteInput!) {
    updateBoarderNote(input: $input) {
      boarderId
    }
  }
`;

type UseUpdateBoarderNoteArgs = UseOnMutationArgs<NoteForm> & {
  boarderId?: string;
  noteId?: string;
};

export const useUpdateBoarderNote = ({
  form, boarderId, noteId, onSuccess,
}: UseUpdateBoarderNoteArgs): UseOnMutation => {
  const { t } = useTranslation();
  const [updateBoarderNote, { loading }] = useMutation<
    unknown, { input: UpdateBoarderNoteInput }
  >(
    UPDATE_BOARDER_NOTE,
    { refetchQueries: [GET_SPECIFIC_BOARDER_NOTES, GET_DORMITORY_NOTES_HISTORY] },
  );

  const onUpdateBoarderNote = useCallback(async () => {
    if (!noteId || !boarderId) {
      return notification.error({ message: t('Note has not been updated successfully') });
    }

    const formFields = await form.validateFields();
    try {
      await updateBoarderNote({
        variables: {
          input: {
            boarderId,
            note: {
              category: formFields.category,
              description: formFields.description,
              title: formFields.title,
              noteId,
            },
          },
        },
      });
      onSuccess?.();

      return notification.success({ message: t('Note has been updated successfully') });
    } catch (error) {
      return notification.error({ message: t('Note has not been updated successfully') });
    }
  }, [form, boarderId, noteId, onSuccess]);

  return [onUpdateBoarderNote, loading];
};
