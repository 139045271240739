import { createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_E_BURSA_API_ENDPOINT}/graphql`,
});

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    authorization: (await fetchAuthSession()).tokens?.idToken,
  },
}));

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
    mutate: { fetchPolicy: 'no-cache' },
  },
});
