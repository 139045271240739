import {
  createContext, Dispatch, SetStateAction, useContext,
} from 'react';
import { RecruitmentForm } from 'src/components';

export type RecruitmentFormContextValue = {
  recruitmentForm: RecruitmentForm;
  setRecruitmentForm: Dispatch<SetStateAction<RecruitmentForm>>
} | undefined

export const RecruitmentFormContext = createContext<
  RecruitmentFormContextValue
>(undefined);

export const useRecruitmentFormContext = () => useContext(RecruitmentFormContext);
