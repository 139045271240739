import {
  Radio, Form, Space, RadioChangeEvent, Checkbox, CheckboxOptionType, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClosedQuestionRadioOption } from 'src/components';
import { ExistingBoarderConfirmationOption } from '../RecruitmentStartStepForm';
import { existingBoarderConfirmationOptionsRule, closedQuestionRule } from '../rules';

const { Paragraph, Text } = Typography;

const useExistingBoarderOptions = (): CheckboxOptionType[] => {
  const { t } = useTranslation();

  return [
    { label: t('Boarder has access to account'), value: ExistingBoarderConfirmationOption.BoarderHasAccess },
    { label: t('Boarder general info is up to date'), value: ExistingBoarderConfirmationOption.BoarderInfoIsUpToDate },
    { label: t('At least one parent has access to account'), value: ExistingBoarderConfirmationOption.ParentsHaveAccess },
    { label: t('Parent contact details are up to date'), value: ExistingBoarderConfirmationOption.ParentsContactDetailsAreUpToDate },
  ];
};

type ExistingBoarderFormItemProps = {
  isExistingInitialValue: boolean;
}

export const ExistingBoarderFormItem = (
  { isExistingInitialValue }: ExistingBoarderFormItemProps,
) => {
  const [isExistingBoarder, setIsExistingBoarder] = useState<boolean>(
    isExistingInitialValue,
  );
  useEffect(() => {
    setIsExistingBoarder(isExistingInitialValue);
  }, [isExistingInitialValue]);
  const { t } = useTranslation();
  const existingBoarderOptions = useExistingBoarderOptions();
  const existingBoarderConfirmationOptionsRules = [
    existingBoarderConfirmationOptionsRule(isExistingBoarder),
  ];

  const onExistingBoarderChange = (event: RadioChangeEvent) => {
    setIsExistingBoarder(event.target.value === ClosedQuestionRadioOption.Yes);
  };

  return (
    <>
      <Form.Item
        name="existingBoarder"
        rules={[closedQuestionRule()]}
      >
        <Radio.Group onChange={onExistingBoarderChange}>
          <Space direction="vertical" size={0}>
            <Radio value={ClosedQuestionRadioOption.No}>{t('No')}</Radio>
            <Radio value={ClosedQuestionRadioOption.Yes}>{t('Yes')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      {isExistingBoarder && (
        <>
          <Paragraph>
            <Text>
              {t('Confirm data is up to date before recruitment')}
            </Text>
          </Paragraph>
          <Form.Item
            name="existingBoarderConfirmationOptions"
            rules={existingBoarderConfirmationOptionsRules}
          >
            <Checkbox.Group options={existingBoarderOptions} className="flex-column" />
          </Form.Item>
        </>
      )}
    </>
  );
};
