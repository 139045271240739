import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  notification,
  Typography,
  Popconfirm,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DocumentNode } from 'graphql';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STYLE } from 'src/constants';
import { FAKE_MUTATION } from 'src/gql/mutations';

export type ActionGraphqlArgs<RecordType, MutateItemType> = {
  refetchQueries: DocumentNode[],
  // eslint-disable-next-line no-unused-vars
  recordToInput: (record: RecordType) => MutateItemType, // eslint bug
  messageOnSuccess?: string,
  messageOnError?: string,
  // eslint-disable-next-line no-unused-vars
  disabledBy?: (record: RecordType) => boolean // eslint bug
} & ({
  mutation: DocumentNode;
  mutationBy?: undefined;
} | {
  mutation?: undefined;
  // eslint-disable-next-line no-unused-vars
  mutationBy: (record: RecordType) => DocumentNode // eslint bug,
})

type ActionsColumnProps<RecordType, MutateItemType> ={
  record: RecordType,
  remove: ActionGraphqlArgs<RecordType, MutateItemType>,
  resendCredentials?: ActionGraphqlArgs<RecordType, MutateItemType>,
  update: {
    // eslint-disable-next-line no-unused-vars
    onUpdate: (record: RecordType) => void // eslint bug
    // eslint-disable-next-line no-unused-vars
    disabledBy?: (record: RecordType) => boolean // eslint bug
  }
}

const actionsRenderer = <RecordType, MutateItemType>({
  update,
  record,
  remove,
  resendCredentials,
}: ActionsColumnProps<RecordType, MutateItemType>) => {
  const { t } = useTranslation();
  const [removePopConfirmVisible, setRemovePopConfirmVisible] = useState(false);
  const [
    resendCredentialsPopConfirmVisible,
    setResendCredentialsPopConfirmVisible,
  ] = useState(false);

  const deleteMutation = remove.mutation || remove.mutationBy(record);
  const [fetchDelete, { loading: fetchDeleteLoading }] = useMutation<
    RecordType, { input: MutateItemType }
  >(deleteMutation, { refetchQueries: remove.refetchQueries });

  const resendCredentialsMutation = resendCredentials?.mutation
    || resendCredentials?.mutationBy(record)
    || FAKE_MUTATION;
  const [fetchResendCredentials, { loading: fetchResendCredentialsLoading }] = useMutation<
    RecordType, { input: MutateItemType }
  >(
    resendCredentialsMutation,
    { refetchQueries: resendCredentials?.refetchQueries || [] },
  );

  const handleDelete = async () => {
    try {
      await fetchDelete({
        variables: {
          input: remove.recordToInput(record),
        },
      });

      notification.success({ message: remove.messageOnSuccess });
    } catch (err) {
      notification.error({ message: remove.messageOnError });
    } finally {
      setRemovePopConfirmVisible(false);
    }
  };

  const handleResendCredentials = async () => {
    if (!resendCredentials) return;

    try {
      await fetchResendCredentials({
        variables: {
          input: resendCredentials.recordToInput(record),
        },
      });
      notification.success({ message: t('Email with credentials has been sent successfully') });
    } catch (err) {
      notification.error({ message: t('Email with credentials has not been sent successfully') });
    } finally {
      setResendCredentialsPopConfirmVisible(false);
    }
  };

  return (
    <div className="space-between">
      {resendCredentials && (
        <Popconfirm
          title={t('Confirm resend credentials')}
          okText={t('Yes')}
          visible={resendCredentialsPopConfirmVisible}
          onConfirm={handleResendCredentials}
          okButtonProps={{ loading: fetchResendCredentialsLoading }}
          onCancel={() => setResendCredentialsPopConfirmVisible(false)}
        >
          <Tooltip title={resendCredentials.disabledBy && resendCredentials.disabledBy(record) && t('Resend email with credentials disabled tooltip')}>
            <Typography.Link
              disabled={resendCredentials.disabledBy && resendCredentials.disabledBy(record)}
            >
              <MailOutlined onClick={() => setResendCredentialsPopConfirmVisible(true)} />
            </Typography.Link>
          </Tooltip>
        </Popconfirm>
      )}
      <Typography.Link disabled={remove.disabledBy && remove.disabledBy(record)}>
        <EditOutlined onClick={() => update.onUpdate(record)} />
      </Typography.Link>
      <Popconfirm
        title={t('Confirm delete')}
        okText={t('Yes')}
        visible={removePopConfirmVisible}
        onConfirm={handleDelete}
        okButtonProps={{ loading: fetchDeleteLoading }}
        onCancel={() => setRemovePopConfirmVisible(false)}
      >
        <Typography.Link disabled={remove.disabledBy && remove.disabledBy(record)}>
          <DeleteOutlined onClick={() => setRemovePopConfirmVisible(true)} />
        </Typography.Link>
      </Popconfirm>
    </div>
  );
};

type GetActionsColumnWidthArgs = {
  resendCredentials: boolean;
  isMobile: boolean;
}

const getActionsColumnWidth = ({ isMobile, resendCredentials }: GetActionsColumnWidthArgs) => {
  if (resendCredentials) {
    if (isMobile) {
      return STYLE.ACTIONS_COLUMN_WIDTH_3_MOBILE;
    }

    return STYLE.ACTIONS_COLUMN_WIDTH_3;
  } if (isMobile) {
    return STYLE.ACTIONS_COLUMN_WIDTH_2_MOBILE;
  }

  return STYLE.ACTIONS_COLUMN_WIDTH_2;
};

type ActionsColumnArgs<RecordType, DeleteItemInputType> = {
  t: TFunction;
  rendererArgs: Omit<ActionsColumnProps<RecordType, DeleteItemInputType>, 'record'>;
  isMobile: boolean;
}

export function actionsColumn<RecordType, DeleteItemInputType>(
  { isMobile, rendererArgs, t }: ActionsColumnArgs<RecordType, DeleteItemInputType>,
): ColumnType<any> {
  const width = getActionsColumnWidth({
    isMobile,
    resendCredentials: Boolean(rendererArgs.resendCredentials),
  });

  return ({
    title: t('Actions'),
    key: 'actions',
    render: (_, record) => actionsRenderer({
      ...rendererArgs,
      record,
    }),
    width,
  });
}
