import { gql, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { t } from 'i18next';
import { RecruitmentBoarderRequest } from 'src/shared/types/api';
import { WHOLE_ADDRESS_FRAGMENT } from 'src/gql/fragments';
import { RecruitmentEventEnum } from 'src/shared/types/docs';
import { useUserContext } from 'src/context';
import { GqlQuery } from 'src/shared/types/common';
import { UseQueryReturnType } from '..';

export const GET_RECRUITMENT_BOARDERS = gql`
  ${WHOLE_ADDRESS_FRAGMENT}

  query GetRecruitmentBoarders {
    recruitment {
      recruitmentBoarderRequests {
        boarderRecruitmentId
        acceptedClausesAndAgreements
        questionnaire {
          isAcolyte
          parishName
          isVolunteer
          groupName
          isLargeFamily
          isSingleParent
          isRefugee
          isFoster
          hadReligiousEducation
          religiousEducationPlans
          isOrWantsToBeConfirmed
          isSportsman
          clubName
          alcohol
          cigarettes
          narcotics
          gambling
          addiction
          isDemoralized
          isPsychotic
          psychotic
          isIll
          ill
          isAllergic
          allergies
        }
        recruitmentPoints
        boarder {
          boarderId
          firstName
          lastName
          personalIdentityNumber
          email
          phoneNumber
          address { ...WholeAddressFragment }
          birthDate
          birthPlace
          parents {
            firstName
            lastName
            phoneNumber
            email
          }
          address { ...WholeAddressFragment }
          isExistingBoarder
          balance
          payments {
            paymentId
            type
            createdAt
            createdBy
            decidedAt
            decidedBy
            amount
          }
        }
        events {
          timestamp
          createdBy
          event
        }
      }
    }
  }
`;

export type RecruitmentBoarderRequestDataSource = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  recruitmentPoints: number;
  isExistingBoarder: boolean;
  lastEvent?: RecruitmentEventEnum
} & RecruitmentBoarderRequest

export const useGetRecruitmentBoarders = (): UseQueryReturnType<
  RecruitmentBoarderRequestDataSource[]
> => {
  const user = useUserContext();

  const {
    loading,
    error,
    data,
  } = useQuery<{ recruitment: { recruitmentBoarderRequests: RecruitmentBoarderRequest[] } }>(
    GET_RECRUITMENT_BOARDERS,
    { skip: !user?.isAllowed(GqlQuery.GetRecruitmentBoarders) },
  );

  if (error) {
    notification.error({ message: t('Unable to fetch recruitment boarders') });
  }

  const dataSource: RecruitmentBoarderRequestDataSource[] | undefined = data
    ?.recruitment.recruitmentBoarderRequests.map((boarderRequest) => ({
      ...boarderRequest,
      firstName: boarderRequest.boarder.firstName,
      lastName: boarderRequest.boarder.lastName,
      email: boarderRequest.boarder.email,
      phoneNumber: boarderRequest.boarder.phoneNumber,
      recruitmentPoints: boarderRequest.recruitmentPoints,
      isExistingBoarder: boarderRequest.boarder.isExistingBoarder,
      lastEvent: boarderRequest.events[boarderRequest.events.length - 1]?.event,
    }));

  return [dataSource, loading, error];
};
