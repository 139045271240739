import { UserGroup } from './types/common';

export const enum DormitoryRecipient {
  AllAdministrators = 'ALL_ADMINISTRATORS',
  AllBoarders = 'ALL_BOARDERS',
  AllTutors = 'ALL_TUTORS',
  AllParents = 'ALL_PARENTS',
}

type Recipient = { value: string, allowedFor: UserGroup[], label: string }
export const DormitoryRecipients: Readonly<Record<DormitoryRecipient, Recipient>> = {
  [DormitoryRecipient.AllAdministrators]: {
    value: DormitoryRecipient.AllAdministrators,
    allowedFor: [UserGroup.Administrators],
    label: 'Wszyscy administratorzy',
  },
  [DormitoryRecipient.AllBoarders]: {
    value: DormitoryRecipient.AllBoarders,
    allowedFor: [UserGroup.Administrators, UserGroup.Tutors],
    label: 'Wszyscy wychowankowie',
  },
  [DormitoryRecipient.AllTutors]: {
    value: DormitoryRecipient.AllTutors,
    allowedFor: [UserGroup.Administrators, UserGroup.Tutors],
    label: 'Wszyscy wychowawcy',
  },
  [DormitoryRecipient.AllParents]: {
    value: DormitoryRecipient.AllParents,
    allowedFor: [UserGroup.Administrators, UserGroup.Tutors],
    label: 'Wszyscy rodzice',
  },
};

export const DormitoryRecipientsArray = Object.keys(DormitoryRecipients);

export const GroupTutorPrefix = 'GROUP_TUTOR';
export type GroupTutorValue = `${typeof GroupTutorPrefix}:${string}`;
export const getGroupTutorValue = (groupId: string): GroupTutorValue => `GROUP_TUTOR:${groupId}`;
export const extractGroupId = (groupTutorValue: string): string => groupTutorValue.split(':')[1];
