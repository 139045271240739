export const enum GqlQuery {
  GetMe = 'getMe',

  GetBoarder = 'getBoarder',
  GetBoarderArchive = 'getBoarderArchive',
  GetBoarders = 'getBoarders',
  GetBoardersArchive = 'getBoardersArchive',

  GetTutor = 'getTutor',
  GetTutors = 'getTutors',

  GetAdministrator = 'getAdministrator',
  GetAdministrators = 'getAdministrators',

  GetParent = 'getParent',
  GetParents = 'getParents',

  GetSchoolYear = 'getSchoolYear',
  GetDormitoryYear = 'getDormitoryYear',
  GetDormitoryDetails = 'getDormitoryDetails',
  GetDormitoryFees = 'getDormitoryFees',
  GetDormitoryChargedMonths = 'getDormitoryChargedMonths',
  GetCanteenManagerEmails = 'getCanteenManagerEmails',
  GetReport = 'getReport',
  GetReports = 'getReports',
  GetFinanceStats = 'getFinanceStats',
  GetWeeklyOrderedMeals = 'getWeeklyOrderedMeals',
  GetNotesHistory = 'getNotesHistory',
  GetDormitoryNotes = 'getDormitoryNotes',
  GetRoomRentals = 'getRoomRentals',
  GetRecruitmentBoarders = 'getRecruitmentBoarders'
}

export const enum GqlMutation {
  CreateBoarder = 'createBoarder',
  UpdateBoarder = 'updateBoarder',
  ToggleBoarderSuspensionStatus = 'toggleBoarderSuspensionStatus',
  ToggleBoarderPresenceState = 'toggleBoarderPresenceState',
  ResendCredentialsToBoarder = 'resendCredentialsToBoarder',
  UpdateBoarderSubscription = 'updateBoarderSubscription',
  UpdateBoarderAbsences = 'updateBoarderAbsences',
  UpdateBoarderWeekendStays = 'updateBoarderWeekendStays',
  UpdateBoarderMeals = 'updateBoarderMeals',
  PickUpMeal = 'pickUpMeal',
  ArchiveBoarder = 'archiveBoarder',
  RestoreBoarderFromArchive = 'restoreBoarderFromArchive',
  RemoveBoarderFromArchive = 'removeBoarderFromArchive',

  CreateBoarderNote = 'createBoarderNote',
  UpdateBoarderNote = 'updateBoarderNote',
  DeleteBoarderNote = 'deleteBoarderNote',

  CreateBoarderPayment = 'createBoarderPayment',
  AddBoarderPaymentComment = 'addBoarderPaymentComment',
  InitOnlinePayment = 'initOnlinePayment',

  CreateUpdateBoarderGeneralInfoRequest = 'createUpdateBoarderGeneralInfoRequest',
  ApproveUpdateBoarderGeneralInfoRequest = 'approveUpdateBoarderGeneralInfoRequest',
  DeclineUpdateBoarderGeneralInfoRequest = 'declineUpdateBoarderGeneralInfoRequest',

  CreateTutor = 'createTutor',
  UpdateTutor = 'updateTutor',
  DeleteTutor = 'deleteTutor',
  ResendCredentialsToTutor = 'resendCredentialsToTutor',

  CreateAdministrator = 'createAdministrator',
  UpdateAdministrator = 'updateAdministrator',
  DeleteAdministrator = 'deleteAdministrator',
  ResendCredentialsToAdministrator = 'resendCredentialsToAdministrator',

  CreateParent = 'createParent',
  UpdateParent = 'updateParent',
  DeleteParent = 'deleteParent',
  ResendCredentialsToParent = 'resendCredentialsToParent',

  UpdateDormitoryDetails= 'updateDormitoryDetails',
  UpdateDormitoryYear = 'updateDormitoryYear',
  UpdateDormitoryFees = 'updateDormitoryFees',
  ChargeMonthlyFee = 'chargeMonthlyFee',
  UpdateCanteenManagerEmails = 'updateCanteenManagerEmails',
  SendPaymentReminder = 'sendPaymentReminder',
  GenerateReport = 'generateReport',
  SendMessage = 'sendMessage',

  CreateDormitoryNote = 'createDormitoryNote',
  UpdateDormitoryNote = 'updateDormitoryNote',
  DeleteDormitoryNote = 'deleteDormitoryNote',

  CreateRoomRental = 'createRoomRental',
  UpdateRoomRental = 'updateRoomRental',
  DeleteRoomRental = 'deleteRoomRental',

  AcceptRecruitmentRequest = 'acceptRecruitmentRequest',
  DeclineRecruitmentRequest = 'declineRecruitmentRequest',
  CreateBoarderRecruitmentPayment = 'createBoarderRecruitmentPayment',
  ConfirmRecruitmentRequest = 'confirmRecruitmentRequest',
  ArchiveRecruitmentRequest = 'archiveRecruitmentRequest',
  CreateBoarderAndParentsByRecruitmentId = 'createBoarderAndParentsByRecruitmentId',
  GenerateRecruitmentQuestionnaire = 'generateRecruitmentQuestionnaire'
}

export type GqlRequest = GqlQuery | GqlMutation | 'handler' | 'authorizeBoarderAccess' | 'notifyAboutOnlinePayment';

export const enum HttpStatusCode {
  Ok = 200,
  Created = 201,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,

  InternalServerError = 500,
}

export type HttpResponse = {
  statusCode: HttpStatusCode,
  body: string,
  headers?: Record<string, string | boolean>
}
