import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { WHOLE_ACTIVITY_FRAGMENT } from 'src/gql/fragments';
import { Boarder } from 'src/shared/types/api';
import { useGetSpecificBoarderOtherInfo, UseGetSpecificBoarderOtherInfoArgsCore, UseQueryReturnType } from '..';

export const GET_SPECIFIC_BOARDER_ACTIVITIES = gql`
  ${WHOLE_ACTIVITY_FRAGMENT}

  query GetSpecificBoarderActivities($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      boarderId
      isPresent
      activities { ...WholeActivityFragment }
    }
  }
`;

const GET_SPECIFIC_BOARDER_ACTIVITIES_ARCHIVE = gql`
  ${WHOLE_ACTIVITY_FRAGMENT}

  query GetSpecificBoarderActivitiesArchive($boarderId: ID!) {
    boarderArchive(boarderId: $boarderId) {
      boarderId
      isPresent
      activities { ...WholeActivityFragment }
    }
  }
`;

type GetSpecificBoarderActivitiesResponse = Pick<Boarder, 'boarderId' | 'activities' | 'isPresent'>;

export const useGetSpecificBoarderActivities = (
  args: UseGetSpecificBoarderOtherInfoArgsCore,
): UseQueryReturnType<GetSpecificBoarderActivitiesResponse> => {
  const { t } = useTranslation();

  return useGetSpecificBoarderOtherInfo<GetSpecificBoarderActivitiesResponse>({
    ...args,
    GET_SPECIFIC_BOARDER_OTHER_INFO: GET_SPECIFIC_BOARDER_ACTIVITIES,
    GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE: GET_SPECIFIC_BOARDER_ACTIVITIES_ARCHIVE,
    errorMessage: t('Unable to fetch boarder activities'),
  });
};
