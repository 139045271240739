import { Dayjs } from 'dayjs';
import { useUserContext } from 'src/context';
import { useGetDormitoryChargedMonths } from 'src/gql/queries';
import { GqlCalendar } from 'src/shared/types/api';
import { Subscription, UserGroup } from 'src/shared/types/common';
import enDayjs from 'dayjs/locale/en';

type UseCanUpdateCalendarArgs = {
  boarderSubscription?: Subscription;
  firstDayOfUpdatedMonth: Dayjs;
}

export const useCanUpdateCalendar = ({
  boarderSubscription,
  firstDayOfUpdatedMonth,
}: UseCanUpdateCalendarArgs): boolean => {
  const user = useUserContext();
  const [chargedMonths, loading, error] = useGetDormitoryChargedMonths({ onError: () => null });

  if (loading || error) {
    return false;
  }

  if (!chargedMonths?.[firstDayOfUpdatedMonth.locale(enDayjs).format('MMMM').toLocaleLowerCase() as keyof GqlCalendar]) {
    return false;
  }

  if ([
    UserGroup.Administrators,
    UserGroup.Parents,
    UserGroup.Tutors,
  ].includes(user?.group as UserGroup)) {
    return true;
  }

  if (boarderSubscription === Subscription.ACADEMIC) {
    return true;
  }

  return false;
};
