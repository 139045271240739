import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Boarder } from 'src/shared/types/api';
import { useGetSpecificBoarderOtherInfo, UseGetSpecificBoarderOtherInfoArgsCore, UseQueryReturnType } from '..';
import { WHOLE_NOTE_FRAGMENT } from '../../fragments';

export const GET_SPECIFIC_BOARDER_NOTES = gql`
  ${WHOLE_NOTE_FRAGMENT}

  query GetSpecificBoarderNotes($boarderId: ID!) {
    boarder(boarderId: $boarderId) {
      notes {
        behavior { ...WholeNoteFragment }
        health { ...WholeNoteFragment }
        other { ...WholeNoteFragment }
      }
    }
  }
`;

const GET_SPECIFIC_BOARDER_NOTES_ARCHIVE = gql`
  ${WHOLE_NOTE_FRAGMENT}

  query GetSpecificBoarderNotesArchive($boarderId: ID!) {
    boarderArchive(boarderId: $boarderId) {
      notes {
        behavior { ...WholeNoteFragment }
        health { ...WholeNoteFragment }
        other { ...WholeNoteFragment }
      }
    }
  }
`;

type BoarderNotes = Pick<Boarder, 'notes'>

export const useGetSpecificBoarderNotes = (
  args: UseGetSpecificBoarderOtherInfoArgsCore,
): UseQueryReturnType<BoarderNotes> => {
  const { t } = useTranslation();

  return useGetSpecificBoarderOtherInfo<BoarderNotes>({
    ...args,
    GET_SPECIFIC_BOARDER_OTHER_INFO: GET_SPECIFIC_BOARDER_NOTES,
    GET_SPECIFIC_BOARDER_OTHER_INFO_ARCHIVE: GET_SPECIFIC_BOARDER_NOTES_ARCHIVE,
    errorMessage: t('Unable to fetch boarder notes'),
  });
};
