import type { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { CONFIG } from 'src/config';
import { sorterByString } from '../sorters';

export const lastActivityColumn = <T extends { lastActivity?: string }>(
  t: TFunction,
): ColumnType<T> => ({
    title: t('Last activity'),
    key: 'lastActivity',
    render: (_, { lastActivity }) => (
      lastActivity && new Date(lastActivity).toLocaleString(CONFIG.LOCALE)
    ),
    sorter: (obj1, obj2, order) => sorterByString({
      obj1, obj2, key: 'lastActivity', order,
    }),
  });
