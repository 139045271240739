import { Uuid } from './misc';

// Remember to update UserGroups if changing UserGroup
export const enum UserGroup {
  Administrators = 'administrators',
  Boarders = 'boarders',
  Parents = 'parents',
  Tutors = 'tutors'
}

// Remember to update UserGroup if changing UserGroups
export const UserGroups = [
  UserGroup.Administrators,
  UserGroup.Boarders,
  UserGroup.Parents,
  UserGroup.Tutors,
] as const;

export type ContextUser = {
  userId: Uuid;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  group: UserGroup;
  notFound?: true;
}

export type ContextUserAsViewed = Omit<ContextUser, 'userId'> & (
  { group: UserGroup.Administrators, administratorId: Uuid } |
  { group: UserGroup.Boarders, boarderId: Uuid, cardId: string } |
  { group: UserGroup.Tutors, tutorId: Uuid } |
  { group: UserGroup.Parents, parentId: Uuid }
)
