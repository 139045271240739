import { ParentForm } from 'src/components';
import {
  UseOnMutation,
  UseOnMutationArgs,
  useCreateParent,
  useUpdateParent,
} from 'src/gql/mutations';

type UseOnParentModalSubmitArgs = UseOnMutationArgs<ParentForm> & {
    parentId?: string;
  };

export const useOnParentModalSubmit = (
  args: UseOnParentModalSubmitArgs,
): UseOnMutation => {
  const [create, createLoading] = useCreateParent(args);
  const [update, updateLoading] = useUpdateParent(args);

  const onSubmit = async () => {
    const action = args.parentId ? update : create;
    await action();
  };

  return [onSubmit, createLoading || updateLoading];
};
