import { Checkbox, Form, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { RecruitmentAgreementsFormFields } from 'src/components';
import { agreementRule, statementRule } from 'src/components/forms/rules';
import { useSetCurrentRecruitmentForm, useSetInitialFormFields } from 'src/hooks';

const { Paragraph, Text } = Typography;

export const AgreementsStep = () => {
  const { t } = useTranslation();
  const [form] = useForm<RecruitmentAgreementsFormFields>();
  useSetCurrentRecruitmentForm(form);
  useSetInitialFormFields(form, 'agreements');

  return (
    <>
      <Paragraph>
        <Text>{t('Agreements step')}</Text>
      </Paragraph>
      <Form form={form} layout="vertical">
        <Paragraph strong>
          <Text>{t('Agreement1Title')}</Text>
        </Paragraph>
        <Form.Item name="agreement1" rules={[agreementRule()]}>
          <Checkbox.Group options={[{ label: t('Agreement1'), value: 'agreement1' }]} />
        </Form.Item>

        <Paragraph strong>
          <Text>{t('Agreement2Title')}</Text>
        </Paragraph>
        <Form.Item name="agreement2" rules={[agreementRule()]}>
          <Checkbox.Group options={[{ label: t('Agreement2'), value: 'agreement2' }]} />
        </Form.Item>

        <Paragraph strong>
          <Text>{t('Agreement3Title')}</Text>
        </Paragraph>
        <Form.Item name="agreement3" rules={[statementRule()]}>
          <Checkbox.Group options={[{ label: t('Agreement3'), value: 'agreement3' }]} />
        </Form.Item>

        <Paragraph strong>
          <Text>{t('Agreement4Title')}</Text>
        </Paragraph>
        <Form.Item name="agreement4" rules={[statementRule()]}>
          <Checkbox.Group options={[{ label: t('Agreement4'), value: 'agreement4' }]} />
        </Form.Item>
      </Form>
    </>
  );
};
