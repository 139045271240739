import { gql } from '@apollo/client';

export const ALL_MONTHS_FRAGMENT = gql`
  fragment AllMonthsFragment on Year {
    january
    february
    march
    april
    may
    june
    july
    august
    september
    october
    november
    december
  }
`;
